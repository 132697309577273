import {
  Cronjobs as ClockIcon,
  GoLogo,
  OrderAssignment as OrderAssignmentIcon,
  OrderOverview as OrderOverviewIcon,
  Today as DashboardIcon,
  OrderCreation as OrderCreationIcon,
  Performance as AnalyticsIcon,
  Saturn as SaturnIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Help as HelpIcon,
} from '@yourmileag/ui-kit';
import React from 'react';
import Cronjobs from './Modules/Cronjobs';
import Migration from './Modules/Dev/Migration';
import MoveCars from './Modules/Dev/MoveCars';
import Analytics from './Modules/Analytics';
import Dispatcher from './Modules/Dispatcher';
import Dashboard from './Modules/Essentials/Screens/Dashboard';
import Inventory from './Modules/Essentials/Screens/Inventory';
import OrderCreation from './Modules/Essentials/Screens/OrderCreation';
import OrderOverview from './Modules/Essentials/Screens/OrderOverview';
import HelpCenter from './Modules/HelpCenter';
import Help from './Modules/Essentials/Screens/Help';
import Settings from './Modules/Settings';
import Web from './Modules/Web';
import { isDevMode } from './Utils/Helpers';

export default (t, organisationId) => {
  const navItems = [
    {
      id: 'main-nav-dashboard',
      path: '/essentials/dashboard',
      icon: <DashboardIcon />,
      title: t('dashboard'),
      component: Dashboard,
    },
    {
      id: 'main-nav-order-creation',
      path: '/essentials/orders/create',
      icon: <OrderCreationIcon />,
      title: t('createOrder'),
      component: OrderCreation,
    },
    {
      path: '/cronjobs',
      icon: <ClockIcon />,
      title: t('cronjobs'),
      component: Cronjobs,
    },
    {
      path: '/dispatcher',
      id: 'main-nav-dispatcher',
      icon: <OrderAssignmentIcon />,
      title: t('dispatcher'),
      component: Dispatcher,
    },
    {
      id: 'main-nav-order-overview',
      path: '/essentials/orders/overview',
      icon: <OrderOverviewIcon />,
      title: t('orderOverview'),
      component: OrderOverview,
    },
    {
      path: '/essentials/inventory',
      id: 'main-nav-inventory',
      icon: <UserIcon />,
      title: t('inventory'),
      component: Inventory,
    },
  ];

  if (organisationId != '1642672489635323') {
    navItems.push({
      path: '/web',
      icon: <SaturnIcon />,
      title: t('web'),
      component: Web,
    });
  }

  navItems.push({
    path: '/analytics',
    icon: <AnalyticsIcon />,
    title: t('analytics'),
    component: Analytics,
  });

  if (isDevMode()) {
    navItems.push(
      {
        path: '/migration',
        icon: <GoLogo />,
        title: 'Migration (Entwicklung)',
        component: Migration,
      },
      {
        path: '/move-cars',
        icon: <GoLogo />,
        title: 'Fahrzeuge bewegen (Entwicklung)',
        component: MoveCars,
      }
    );
  }

  navItems.push({
    path: '/essentials/settings',
    id: 'main-nav-settings',
    icon: <SettingsIcon />,
    title: t('settings'),
    component: Settings,
  });

  if (false) {
    // Help Center ready
    navItems.push({
      id: 'main-nav-help-center',
      path: '/help-center',
      icon: <HelpIcon />,
      title: t('helpCenter'),
      component: HelpCenter,
    });
  } else {
    navItems.push({
      id: 'main-nav-help',
      path: '/help',
      icon: <HelpIcon />,
      title: t('help'),
      component: Help,
    });
  }

  return navItems;
};
