import { createSnackbar, InfoRow, TextInput, Theme, Button } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { bugsnagClient } from '../../bugsnag';
import { apiKeyEndpoint } from '../../Utils/API';
import { convertEmptyStringsToNull } from '../../Utils/Helpers';
import Content from './Components/Content';
import useDeleteModal from '../../Utils/useDeleteModal';

export const useApiKeyValidator = (apiKey) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!apiKey) {
      return;
    }

    const newErrors = {};

    if (!apiKey.label) {
      newErrors.label = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [apiKey, t]);

  return errors;
};

const { useEntries: useApi, createEntryWithResponse, deleteEntry: deleteApi } = apiKeyEndpoint;

const ApiKeyEntry = ({ apiKey }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { show, hide, RenderModal } = useDeleteModal();

  const { t } = useTranslation();

  const deleteAction = (id) => {
    deleteApi(id);
    createSnackbar('success', t('successfullyDeleted', { name: t('apikeys') }));
    hide();
  };

  return (
    <div className={css(styles.tokenList)}>
      <div className={css(styles.tokenName)}>{apiKey.label}</div>
      <div className={css(styles.tokenShort)}>{apiKey.username}</div>
      <div className={css(styles.tokenAction)}>
        <Button onPress={() => show()} variant="dark">
          {t('remove')}
        </Button>
      </div>
      <RenderModal deleteAction={() => deleteAction(apiKey.id)} />
    </div>
  );
};

export default () => {
  const [apiKeys] = useApi({
    query: 'type:api',
  });
  const [token, setToken] = useState();
  const [apiKey, setApiKey] = useState({ label: null });
  const errors = useApiKeyValidator(apiKey);
  const isSubmitDisabled = Object.keys(errors).length > 0;
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const { t } = useTranslation();

  const onChange = (attribute) => (e) => setApiKey({ ...apiKey, [attribute]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    const newApiKey = convertEmptyStringsToNull({
      ...apiKey,
    });

    try {
      const data = await createEntryWithResponse(newApiKey);
      setToken(data.token);
      createSnackbar('success', t('successfullySaved', { name: t('apikeys') }));
    } catch (exc) {
      bugsnagClient.notify('Could not create an apikey', { metaData: exc });

      createSnackbar('error', t('couldNotSave', { name: t('apikeys') }));
    }
  };

  return (
    <>
      <Content
        id="settings-form"
        title={t('apikeys')}
        onSubmit={onSubmit}
        isSubmitDisabled={isSubmitDisabled}
      >
        {token ? <div className={css(styles.token)}>{token}</div> : null}
        <InfoRow center label={t('name')} required>
          <TextInput
            id="label"
            onChange={onChange('label')}
            required
            errorMessage={errors.label}
            clean
            size="s"
          />
        </InfoRow>
        <div className={css(styles.tokenHolder)}>
          {apiKeys &&
            apiKeys.map((apiKey, index) => (
              <ApiKeyEntry key={index} apiKey={apiKey}/>
            ))}
        </div>
      </Content>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    tokenHolder: {
      // paddingTop: 10,
      verticalAlign: 'center',
    },
    tokenName: {
      fontWeight: 600,
      flex: 0.3,
    },
    tokenAction: {
      flex: 0.2,
    },
    tokenShort: {
      flex: 0.3,
    },
    tokenList: {
      alignItems: 'baseline',
      display: 'flex',
      fontSize: 13,
      color: theme.color17,
      fontWeight: 100,
      paddingTop: 7,
      paddingBottom: 7,
      borderBottom: `solid ${theme.color12} 1px`,
    },
    token: {
      wordBreak: 'break-all',
      borderRadius: 3,
      padding: 10,
      backgroundColor: theme.color12,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 100,
    },
    zipAndCity: {
      display: 'flex',
    },
    zipCode: {
      width: 150,
      marginRight: 10,
    },
    city: {
      flex: 1,
    },
  });
