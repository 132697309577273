import { InfoRow, SectionHeader } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useTranslation } from 'react-i18next';
import { purchasesEndpoint } from '../../../Utils/API';
import { s } from '../../../Utils/Helpers';
import InventorySidePanel from '../../Essentials/Components/InventorySidePanel';
import SidePanelHeader from '../../Essentials/Components/SidePanelHeader';
import CostOverviewRow from '../License/Components/CostOverviewRow';
import CostOverviewRowSubSummary from '../License/Components/CostOverviewRowSubSummary';
import CostOverviewRowSummary from '../License/Components/CostOverviewRowSummary';

const { useEntry: usePurchase } = purchasesEndpoint;

const Detail = ({ entry }) => {
  const { t } = useTranslation();

  const number = entry.transactionData.card.masked.replace(/x/g, '*');
  const date = moment(entry.createAt).format(t('longDateFormatAndLongTimeFormat'));
  const start = moment.unix(entry.period.start).format(t('longDateFormat'));
  const end = moment.unix(entry.period.end).format(t('longDateFormat'));

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>{t('calculation')}</SectionHeader>
        {entry.notes.map((note) => {
          let calculationDesc = `${note.licences} ${t('licenseCars')} × ${note.price}`;
          calculationDesc += ` (${note.duration} ${t('days')})`;

          return (
            <CostOverviewRow
              key={note.module}
              moduleName={note.module}
              calculationDesc={calculationDesc}
              price={note.totalPrice}
            />
          );
        })}
        <CostOverviewRowSubSummary label={t('subTotal')} price={entry.totalPrice} />
        <CostOverviewRowSubSummary
          label={t('vat', { vat: entry.vat / 10 })}
          price={entry.priceVat}
        />
        <CostOverviewRowSummary price={entry.totalPriceVat} />
      </div>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>{t('creditCard')}</SectionHeader>
        <Cards
          expiry=""
          name=" "
          cvc=""
          number={number}
          preview={true}
          locale={{ valid: t('validThru') }}
        />
      </div>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>{t('basicInformation')}</SectionHeader>
        <InfoRow label={t('numeroSign')}>{entry._id}</InfoRow>
        <InfoRow label={t('period')}>{s(start, '-', end)}</InfoRow>
        <InfoRow label={t('date')}>{date}</InfoRow>
        <InfoRow label={t('paymentReason')}>{t(entry.type)}</InfoRow>
      </div>
    </div>
  );
};

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={usePurchase}
      Detail={Detail}
      renderHeader={(purchase) => {
        if (!purchase) {
          return;
        }

        const date = moment(purchase.createAt).format(t('longDateFormat'));
        const reason = t(purchase.type);

        return <SidePanelHeader label={t('invoice')} title={s(date, '-', reason)} />;
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 45,
  },
  sectionHeader: {
    marginBottom: 20,
  },
  section: {
    marginBottom: 45,
  },
});
