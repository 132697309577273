import { StepContent } from '../Components/Tour';
import React from 'react';

export default {
  name: 'inventory-customers',
  requiredPath: '/essentials/inventory/customers',
  categoryId: 'inventory',
  getTranslatedTour: (t) => ({
    question: t('howToManageCustomers'),
    steps: [
      {
        selector: '#main-nav-inventory',
        content: (
          <>
            <StepContent>{t('inventoryDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#inventory-nav-customers',
        content: (
          <>
            <StepContent>{t('inventoryCustomersDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#inventory-add-button',
        content: (
          <>
            <StepContent>{t('inventoryAddButtonDesc')}</StepContent>
          </>
        ),
      },
    ],
  }),
};
