import { Theme, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fleetsEndpoint } from '../../../../Utils/API';
import { defaultFleet, useFleetValidator } from '../../SidePanels/Fleet/CreateOrUpdate';
import Page from './Components/Page';

export default ({ onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const { t } = useTranslation();
  const [fleet, setFleet] = useState({ ...defaultFleet, label: 'Standard' });
  const errors = useFleetValidator(fleet);
  const isNextDisabled = Object.keys(errors).length > 0;

  const onNextPress = async () => {
    await fleetsEndpoint.createEntry(fleet);
    onRequestNextPage();
  };

  const onChange = ({ attribute, isPrice = false }) => (e) => {
    let value = e.target.value;
    if (value && isPrice && !isNaN(value)) {
      value = parseFloat(value, 10).toFixed(2);
    }

    setFleet({ ...fleet, [attribute]: value });
  };

  let content = (
    <>
      <h2 className={css(styles.title)}>{t('registerFleetTitle')}</h2>
      <h5 className={css(styles.desc)}>{t('registerFleetDesc')}</h5>

      <TextInput
        id="name"
        label={t('name')}
        value={fleet.label}
        onChange={onChange({ attribute: 'label' })}
        errorMessage={fleet.label.length > 0 ? errors.label : ''}
        required
        className={css(styles.input)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
        }}
      >
        <div className={css(styles.input50)}>
          {t('registerFleetBaseFee')}
          <TextInput
            id="rateBaseFee"
            label={t('baseFee')}
            value={fleet.rateBaseFee}
            onChange={onChange({ attribute: 'rateBaseFee' })}
            errorMessage={fleet.rateBaseFee.length > 0 ? errors.rateBaseFee : ''}
            required
            className={css(styles.input, styles.marginTop)}
          />
        </div>
        <div className={css(styles.input50)}>
          {t('registerFleetPerKm')}
          <TextInput
            id="ratePerKm"
            label={t('perKm')}
            value={fleet.ratePerKm}
            onChange={onChange({ attribute: 'ratePerKm' })}
            errorMessage={fleet.ratePerKm.length > 0 ? errors.ratePerKm : ''}
            required
            className={css(styles.input, styles.marginTop)}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <Page onNextPress={onNextPress} isNextDisabled={isNextDisabled}>
        {content}
      </Page>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    input50: {
      flex: 0.49,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    marginTop: {
      marginTop: 20,
    },
    input: {
      marginBottom: 15,
    },
  });
