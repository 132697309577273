import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import {Dropdown, InfoRow}  from '@yourmileag/ui-kit';
import { fullScreenStyles, verticalScrollStyles } from '../../Utils/Helpers';
import KPIs from './KPIs';
export default () => {

    return (
        <div className={css(styles.container)}>
          <KPIs/>
        </div>
      );
    };
    
    const styles = StyleSheet.create({
      container: {
        ...fullScreenStyles,
        ...verticalScrollStyles,
        padding: 20,
      },
      code: {
        marginTop: 20,
        backgroundColor: '#1E1E1E',
        color: '#CCCCCC',
        fontFamily: '"Courier New", Courier, monospace',
      },
      autoMigration: {
        display: 'inline-flex',
        marginLeft: 20,
        width: 'auto',
      },
    });