import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getTimeAndDateAsUnix, today } from '../../../../Utils/Helpers';
import { validateMail, validatePhoneNumber } from '../../../../Utils/Validators';

const useValidator = ({
  type,
  lastName,
  mailAddress,
  route,
  date,
  time,
  endDate,
  endTime,
  pricing,
  price,
  phone,
  fleet,
}) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!phone || phone === '') {
      newErrors.phone = t('fieldIsRequired');
    }

    if (!!phone && validatePhoneNumber(phone) !== true) {
      newErrors.phone = validatePhoneNumber(phone);
    }

    if (!lastName) {
      newErrors.lastName = t('fieldIsRequired');
    }

    if (!route) {
      newErrors.route = t('pleaseProvideRoute');
    } else if (type == 'one-way' && !route.duration.value) {
      newErrors.route = t('routeFallsBelowOfMinimumDuration');
    }

    if (pricing === 'fixed') {
      if (!price) {
        newErrors.price = t('fieldIsRequired');
      } else if (isNaN(price)) {
        newErrors.price = t('priceHasToBeValid');
      }
    }

    const day = moment(date).startOf('day').unix();

    if (!date) {
      newErrors.date = t('fieldIsRequired');
    } else if (day < today) {
      newErrors.date = t('canNotBeInPast');
    } else if (!time) {
      newErrors.time = t('fieldIsRequired');
    } else if (
      day === today &&
      getTimeAndDateAsUnix(time, date) < moment().subtract(5, 'minutes').unix()
    ) {
      newErrors.time = t('canNotBeInPast');
    }

    if (type == 'day-trip') {
      const endDay = moment(endDate).startOf('day').unix();

      if (!endDay) {
        newErrors.endDate = t('fieldIsRequired');
      } else if (day < today) {
        newErrors.endDate = t('canNotBeInPast');
      } else if (!endTime) {
        newErrors.endTime = t('fieldIsRequired');
      } else if (
        day === today &&
        getTimeAndDateAsUnix(endTime, endDate) < moment().subtract(5, 'minutes').unix()
      ) {
        newErrors.endTime = t('canNotBeInPast');
      } else if (getTimeAndDateAsUnix(endTime, endDate) < (getTimeAndDateAsUnix(time, date) + (route ? (route.duration.value * 60) : 0))) {
        newErrors.endTime = t('canNotBeShorterThanRoute');
      }
    } 

    const isMailValid = mailAddress ? validateMail(mailAddress) : true;
    if (isMailValid !== true) {
      newErrors.mailAddress = isMailValid;
    }

    if (!fleet) {
      newErrors.fleet = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [type, lastName, mailAddress, route, date, time, endDate, endTime, pricing, price, phone, fleet, t]);

  return errors;
};

export default useValidator;
