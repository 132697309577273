import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles, usePageTitle } from '../../Utils/Helpers';

export default () => {
  const { t } = useTranslation();
  usePageTitle(t('web'));

  return (
    <div className={css(styles.container)}>
      <iframe className={css(styles.iframe)} title="web" src={'//web.go-fleet.ch?token=' + Cookies.get('token')} frameBorder="0" />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    ...fullScreenStyles,
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
});
