export const getStateLabels = t => ({
  new: t('new'),
  assigning: t('assigning'),
  assigned: t('assigned'),
  arriving: t('arriving'),
  arrived: t('arrived'),
  completing: t('completing'),
  completed: t('completed'),
  canceled: t('canceled'),
  deleted: t('deleted'),
  problem: t('problem'),
});
