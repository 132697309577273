import { ArrowLeft, ArrowRight, Button, Theme, Crossmark as CloseIcon } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import Tour, { Navigation, Dot, Controls } from 'reactour';
import { useTranslation } from 'react-i18next';

export const StepTitle = ({ children }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return <h3 className={css(styles.title)}>{children}</h3>;
};

export const StepContent = ({ children }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return <p className={css(styles.content)}>{children}</p>;
};

export const StepClose = ({ onClose }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  return (
    <div className={css(styles.close)} onClick={onClose}>
      <CloseIcon />
    </div>
  );
};

export const StepContainer = ({ current, content, totalSteps, gotoStep, close }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  return (
    <div className={css(styles.stepContainer)}>
      {content}
      <Controls data-tour-elem="controls" className={css(styles.controls)}>
        <Button size="l" iconLeft={<ArrowLeft />} onPress={() => gotoStep(current - 1)}>
          {t('back')}
        </Button>
        <Navigation data-tour-elem="navigation">
          {Array.from(Array(totalSteps).keys()).map((li, i) => (
            <Dot
              className={`${css(styles.dot)} ${current === i ? css(styles.dotActive) : ''}`}
              key={li}
              onClick={() => current !== i && gotoStep(i)}
              index={i}
              current={current}
              disabled={current === i}
              data-tour-elem="dot"
            />
          ))}
        </Navigation>
        {current === totalSteps - 1 ? (
          <Button onPress={close} variant="special" size="l" icon={<ArrowRight />}>
            {t('endTour')}
          </Button>
        ) : (
          <Button
            onPress={() => gotoStep(current + 1)}
            variant="special"
            size="l"
            icon={<ArrowRight />}
          >
            {t('continue')}
          </Button>
        )}
      </Controls>
      <StepClose onClose={close} />
    </div>
  );
};

export default ({ steps, onRequestClose }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <Tour
      rounded={5}
      className={css(styles.tourContainer)}
      steps={steps}
      isOpen={true}
      onRequestClose={onRequestClose}
      CustomHelper={StepContainer}
      maskSpace={0}
      maskClassName={css(styles.mask)}
      highlightedBorder={{
        width: 4,
        color: theme.color2,
      }}
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    tourContainer: {
      transform: 'none',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    stepContainer: {
      width: 720,
      padding: '60px 50px 50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.color2,
      marginBottom: 60,
      position: 'relative',
      borderRadius: 5,
    },
    title: {
      fontWeight: 'bold',
      marginBottom: 12,
      fontSize: 30,
      lineHeight: 'normal',
    },
    content: {
      fontSize: 18,
      color: theme.color1,
      lineHeight: 1.67,
      minHeight: 60,
      fontWeight: 'lighter',
      width: '100%',
      textAlign: 'center',
      paddingBottom: 26,
    },
    controls: {
      justifyContent: 'space-between',
      width: '100%',
      margin: 0,
    },
    close: {
      position: 'absolute',
      top: 20,
      right: 20,
      fontSize: 40,
      ':hover': {
        cursor: 'pointer',
      },
    },
    dot: {
      outline: 0,
      height: 12,
      width: 12,
    },
    dotActive: {
      boxShadow: '0 2px 4px 0 rgba(0, 167, 231, 0.5)',
      backgroundImage: 'linear-gradient(135deg, #01cbff, #00a7e7)',
      transform: 'scale(1.1)',
    },
    mask: {
      opacity: 0.5,
    },
  });
