import { login } from '@yourmileag/essentials-api-client';
import {
  ArrowRight,
  Button,
  ButtonLink,
  createSnackbar,
  GoLogo,
  TextInput,
  ArrowLeft,
  Theme,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles, usePageTitle, verticalScrollStyles } from '../../../../Utils/Helpers';
import { validateMail } from '../../../../Utils/Validators';
import MagicLink from '../../Components/MagicLink';

const STATUS = {
  waiting: 0,
  submitting: 1,
  submitted: 2,
};

const useValidator = (mailAddress, isRegistration) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    newErrors.mailAddress = validateMail(mailAddress);
    if (newErrors.mailAddress === true) {
      delete newErrors.mailAddress;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [mailAddress, isRegistration, t]);

  return errors;
};

export default ({ match, history }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const { t } = useTranslation();
  usePageTitle(t('login'));
  const [status, setStatus] = useState(STATUS.waiting);
  const [isRegistration] = useState(false);
  const [mailAddress, setMailAddress] = useState('');
  const errors = useValidator(mailAddress, isRegistration);
  const isSubmitDisabled = Object.keys(errors).length > 0;

  const onRegistrationPress = () => history.push('/onboarding');

  const onChange = (set) => (e) => set(e.target.value);
  const onSubmit = async (e) => {
    e.preventDefault();
    setStatus(STATUS.submitting);

    window.gtag('event', 'login', { method: 'Email' });

    try {
      await login(mailAddress);
      setStatus(STATUS.submitted);
    } catch (exc) {
      setStatus(STATUS.waiting);
      createSnackbar('error', t('loginFailed'));
    }
  };

  const onSubmitReset = () => setStatus(STATUS.waiting);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <GoLogo />
      </div>
      <div className={css(styles.containerInner)}>
        {status !== STATUS.waiting ? (
          <>
            {status === STATUS.submitted && (
              <>
                <h2 className={css(styles.title)}>
                  <MagicLink
                    isInitialized={status === STATUS.submitted}
                    className={css(styles.icon)}
                  />
                  {t('oneMoreStep')}
                </h2>
                <p className={css(styles.description)}>{t('pleaseCheckYourInboxToVerify')}</p>
                <div className={css(styles.button)}>
                  <ButtonLink styles={styles.buttonLink} onClick={onSubmitReset}>
                    <ArrowLeft style={{ marginRight: 5 }} />
                    {t('noMailReceived')}
                  </ButtonLink>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <h2 className={css(styles.title)}>{t('login')}</h2>
            <form onSubmit={onSubmit}>
              <div className={css(styles.input)}>
                <TextInput
                  id="l-email"
                  type="email"
                  clean={true}
                  autoFocus={!isRegistration}
                  label={t('mailAddress')}
                  value={mailAddress}
                  onChange={onChange(setMailAddress)}
                  errorMessage={errors.mailAddress}
                  required={isRegistration}
                  styles={{ border: 'solid red 2px' }}
                />
              </div>
              <div className={css(styles.button)}>
                <Button
                  size="l"
                  variant="special"
                  icon={<ArrowRight />}
                  disabled={isSubmitDisabled}
                  type="submit"
                >
                  {t('continue')}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
      {status === STATUS.waiting ? (
        <div className={css(styles.footer)}>
          <h4 className={css(styles.noAccount)}>{t('no_account')}</h4>
          <Button onPress={onRegistrationPress}>{t('signUp')}</Button>
        </div>
      ) : null}
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.color13,
      zIndex: 10,
    },
    containerInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.color2,
      width: 620,
      height: 340,
      padding: 30,
      borderRadius: 5,
      boxShadow: theme.shadow1,
    },
    noAccount: {
      color: theme.color3,
      fontSize: 14,
      fontWeight: 300,
      paddingRight: 20,
    },
    icon: {
      fontSize: 40,
      marginRight: 20,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      padding: 40,
    },
    introduction: {
      flex: 1,
      backgroundImage: 'url(/assets/images/login-bg.jpg)',
      backgroundSize: 'cover',
      color: theme.color2,
      display: 'flex',
      flexDirection: 'column',
    },
    introductionContent: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: 60,
    },
    introductionTitle: {
      fontWeight: 800,
      fontSize: 36,
      lineHeight: '46px',
      letterSpacing: 0.18,
      marginBottom: 30,
    },
    introductionText: {
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: 0.19,
    },
    login: {
      ...verticalScrollStyles,
      width: 720,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: 80,
      color: theme.color3,
      textAlign: 'right',
      paddingBottom: 67,
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    formSubmitted: {
      backgroundColor: theme.color2,
      padding: 40,
      borderRadius: 5,
      boxShadow: theme.shadow1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 30,
      alignItems: 'center',
    },
    description: {
      color: theme.color1,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      letterSpacing: 0,
      padding: '0 60px',
    },
    input: {
      width: 500,
      marginBottom: 15,
    },
    button: {
      marginTop: 45,
      textAlign: 'right',
    },
    buttonLink: {
      display: 'flex',
      color: theme.color6,
      ':before, :after': {
        backgroundColor: theme.color6,
      },
    },
  });
