import { FleetColumn } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fleetsEndpoint } from '../../../../Utils/API';
import { getFleetIcon } from '../../../../Utils/Fleet';
import InventoryScreen from '../../Components/InventoryScreen';
import Rate from '../../Components/Rate';
import FleetSidePanel from '../../SidePanels/Fleet';

const { useEntries: useFleets } = fleetsEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  const columns = [
    { title: t('name'), orderKey: 'label', searchKeys: ['label'], flex: 1 },
    {
      title: t('rate'),
      orderKey: 'ratePerKm',
      searchKeys: ['rateCurrency', 'rateBaseFee', 'ratePerKm'],
      flex: 1,
    },
  ];

  const rowMap = (fleet) => ({
    id: fleet.id,
    columns: [
      <FleetColumn icon={getFleetIcon(fleet.icon)} car={t(fleet.label)} />,
      <Rate currency={fleet.rateCurrency} baseFee={fleet.rateBaseFee} perKm={fleet.ratePerKm} />,
    ],
  });

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={FleetSidePanel}
      useData={useFleets}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchFleet')}
      addButtonLabel={t('addFleet')}
    />
  );
};
