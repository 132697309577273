import { css, StyleSheet } from 'aphrodite/no-important';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import acceptedAnimation from '../Animations/OrderRequestAccepted.json';
import loadingDataAnimation from '../Animations/OrderRequestWaiting.json';

export default ({ isInitialized }) => {
  const container = useRef();

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: true,
      loop: !isInitialized,
      animationData: isInitialized ? acceptedAnimation : loadingDataAnimation,
    });

    return () => {
      animationInstance.destroy();
    };
  }, [isInitialized]);

  return <div className={css(styles.animation)} ref={container} />;
};

const styles = StyleSheet.create({
  animation: {
    width: 40,
    height: 40,
  },
});
