import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { essentialsWsEndpoint } from './API';

const useSettings = () => {
  const settings = essentialsWsEndpoint.useLiveEntries('settings', undefined, {});

  useEffect(() => {
    if (!settings) {
      return;
    }

    if (settings.length > 0) {
      Cookies.set(
        'driverRequestTime',
        !settings[0].timelimit.driver ? 30 : settings[0].timelimit.driver
      );
      Cookies.set(
        'dispoRequestTime',
        !settings[0].timelimit.dispo ? 30 : settings[0].timelimit.dispo
      );
    } else {
      Cookies.set('driverRequestTime', 30);
      Cookies.set('dispoRequestTime', 30);
    }
  }, [settings]);

  return null;
};

export default () => {
  const settings = useSettings();

  return null;
};
