import { createSnackbar, Clock, ContextMenu, Crossmark, OrderAssignment, OrderCreation, Pen, Checkmark, Trash } from '@yourmileag/ui-kit';
import moment from 'moment';
import { getIdentityId } from '@yourmileag/essentials-api-client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { carsEndpoint, employeesEndpoint, ordersEndpoint } from '../../../Utils/API';
import { s } from '../../../Utils/Helpers';
import CompleteOrder from '../Screens/CompleteOrder';
import CancelOrder from '../Screens/CancelOrder';
import DeleteEntry from '../Screens/DeleteEntry';

const { updateEntry: updateOrder } = ordersEndpoint;
const { updateEntry: updateCar } = carsEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;

export default ({ order, history, label = null }) => {
  const { t } = useTranslation();
  const [, setOrderTemplate] = useGlobal('orderTemplate');
  const [, setReturnTemplate] = useGlobal('returnTemplate');

  const [showDeleteOrder, setShowDeleteOrder] = useState(false);
  const [showCompleteOrder, setShowCompleteOrder] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);

  const onDispatchPress = () => history.push(`/dispatcher/orders/${order._id}`);

  const isEditDisabled = ['new', 'assigning', 'assigned', 'problem'].indexOf(order.status) === -1;

  const isCancelDisabled =
    order.status === 'canceled' || order.status === 'completed' || order.status === 'deleted';

  const deleteAction = async () => {
    const author = await getEmployee(getIdentityId());

    const data = {
      status: 'deleted',
      deletionTimestamp: moment().unix(),
      deletionAuthor: s(author.lastName, author.firstName),
    };

    await updateOrder(order._id, data);

    if (order.car) {
      await updateCar(order.car.id, { status: 'free' });
    }

    setShowDeleteOrder(false);

    createSnackbar('info', t('orderDeleted'));
  }

  const onDeletePress = async () => {
    setShowDeleteOrder(true);
  };

  const completeAction = async (price, completionNoteText) => {
    const now = moment().unix();

    const author = await getEmployee(getIdentityId());

    const data = {
      status: 'completed',
      stage: 1,
      isPaid: true,
      price: price,
      timespan: {
        startTimestamp: order.estimatedTimespan.startTimestamp,
        endTimestamp: order.estimatedTimespan.endTimestamp > now ? now : order.estimatedTimespan.endTimestamp,
      },
      invoiceStatus: 'pending',
      currency: order.fleet.rateCurrency,
      completionNoteText: completionNoteText,
      completionNoteTimestamp: moment().unix(),
      completionNoteAuthor: s(author.lastName, author.firstName),
    };

    await updateOrder(order._id, data);

    if (order.car) {
      await updateCar(order.car.id, { status: 'free' });
    }

    setShowCompleteOrder(false);

    createSnackbar('info', t('orderCompleted'));
  }

  const onCompletePress = async () => {
    setShowCompleteOrder(true);
  };

  const cancelAction = async (message, sendMessage) => {
    const data = {
      status: 'canceled',
      cancellationTrigger: 'cockpit',
      cancellationMessage: message,
      cancellationSendMessage: sendMessage
    };
    const now = moment().unix();

    if (order.timespan) {
      if (order.timespan.startTimestamp < now) {
        data.timespan = { endTimestamp: now };
      }
    } else {
      if (order.estimatedTimespan.startTimestamp < now) {
        data.estimatedTimespan = { endTimestamp: now };
      }
    }

    await updateOrder(order._id, data);

    if (order.car) {
      await updateCar(order.car.id, { status: 'free' });
    }

    setShowCancelOrder(false);

    createSnackbar('info', t('orderCanceled'));
  }

  const onCancelPress = async () => {
    setShowCancelOrder(true);
  };

  const onEditOrder = order => () => {
    history.push(`/essentials/orders/overview/0/${order._id}/update/${order.customer.id}`);
  };

  const onCreateOrderFromExisting = order => () => {
    setOrderTemplate(order);
    history.push(`/essentials/orders/create/${order.customer.id}`);
  };

  const onCreateReturnFromExisting = order => () => {
    setReturnTemplate(order);
    history.push(`/essentials/orders/create/${order.customer.id}`);
  };

  const onCreateCronjob = order => () => {
    setOrderTemplate(order);
    history.push('/cronjobs/0/create');
  };

  return (
    <>
      <ContextMenu
        label={label}
        buttons={[
          {
            icon: <Pen />,
            label: t('updateOrder'),
            onPress: onEditOrder(order),
            isDisabled: isEditDisabled,
          },
          {
            icon: <OrderCreation />,
            label: t('useAsReturnForNewOrder'),
            onPress: onCreateReturnFromExisting(order),
            isDisabled: !order.customer,
          },
          {
            icon: <OrderCreation />,
            label: t('useAsTemplateForNewOrder'),
            onPress: onCreateOrderFromExisting(order),
            isDisabled: !order.customer,
          },
          {
            icon: <Clock />,
            label: t('addCronjob'),
            onPress: onCreateCronjob(order),
            isDisabled: !order.customer,
          },
          {
            label: t('dispatch'),
            icon: <OrderAssignment />,
            onPress: onDispatchPress,
            isDisabled: order.status !== 'new' && order.status !== 'problem',
          },
          {
            label: t('completeOrder'),
            icon: <Checkmark />,
            onPress: onCompletePress,
            isDisabled: ['new', 'assigning', 'completed'].indexOf(order.status) >= 0,
          },
          {
            label: t('cancelOrder'),
            icon: <Crossmark />,
            onPress: onCancelPress,
            isDisabled: isCancelDisabled,
          },
          {
            icon: <Trash />,
            label: t('deleteOrder'),
            onPress: onDeletePress,
            isDisabled: false,
          },
        ]}
      />
      {showDeleteOrder && (
        <DeleteEntry deleteAction={deleteAction} closeModal={() => setShowDeleteOrder(false)} />
      )}
      {showCompleteOrder && (
        <CompleteOrder order={order} completeAction={completeAction} closeModal={() => setShowCompleteOrder(false)} />
      )}
      {showCancelOrder && (
        <CancelOrder order={order} cancelAction={cancelAction} closeModal={() => setShowCancelOrder(false)} />
      )}
    </>
  );
};
