import { StatusSmall, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getStateLabels } from '../../../../Utils/StateLabels';
import CheckBoxLabel from '../CheckBoxLabel';
import CheckBoxWithLabel from '../CheckBoxWithLabel';

const availableStatuses = {
  new: false,
  assigning: false,
  assigned: false,
  arriving: false,
  arrived: false,
  completing: false,
  completed: false,
  canceled: false,
  problem: false,
};

export default ({ filter, onChangeValues }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const checked = {};
  filter.values.forEach((value) => (checked[value.value] = true));

  const onChange = (status) => () => {
    const newValues = { ...checked };

    if (newValues[status]) {
      delete newValues[status];
    } else {
      newValues[status] = true;
    }

    onChangeValues(
      Object.keys(newValues).map((value) => ({
        label: getStateLabels(t)[value],
        value,
      }))
    );
  };

  return (
    <div className={css(styles.container)}>
      {Object.keys(availableStatuses).map((status) => (
        <CheckBoxWithLabel
          key={status}
          id={status}
          checked={checked[status]}
          onChange={onChange(status)}
          clean
        >
          <StatusSmall status={status} />
          <CheckBoxLabel clean>{getStateLabels(t)[status]}</CheckBoxLabel>
        </CheckBoxWithLabel>
      ))}
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  });
