import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

export default ({ price }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return <div className={css(styles.costOverviewRow, styles.costOverviewRowSummary)}>{price}</div>;
};

const getThemedStyles = theme =>
  StyleSheet.create({
    costOverviewRow: {
      display: 'flex',
      borderBottom: `1px solid ${theme.color9}`,
      paddingBottom: 10,
      marginBottom: 10,
      alignItems: 'center',
    },
    costOverviewRowSummary: {
      marginTop: 30,
      borderBottom: 'none',
      fontWeight: 'bold',
      fontSize: 24,
      justifyContent: 'flex-end',
    },
  });
