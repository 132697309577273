import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import Tour from './Components/Tour';
import { getTour } from './Registry';

export const useTourOnMount = name => {
  const [, setActiveTour] = useGlobal('activeTour');

  useEffect(() => {
    if (localStorage.getItem(`hasCompletedTour:${name}`)) {
      return;
    }

    setActiveTour(name);

    // eslint-disable-next-line
  }, [name]);
};

export const useTourManager = () => {
  const [activeTour, setActiveTour] = useGlobal('activeTour');

  return [activeTour, setActiveTour];
};

export default () => {
  const { t } = useTranslation();
  const [activeTour, setActiveTour] = useGlobal('activeTour');

  if (!activeTour) {
    return null;
  }

  const { steps } = getTour(activeTour).getTranslatedTour(t);
  const onRequestClose = () => {
    localStorage.setItem(`hasCompletedTour:${activeTour}`, 'YES');
    setActiveTour(null);
  };

  return <Tour steps={steps} onRequestClose={onRequestClose} />;
};
