import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

export default ({ label, price }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <div className={css(styles.costOverviewRow)}>
      <div className={css(styles.label)}>{label}</div>
      <div className={css(styles.price)}>{price}</div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    costOverviewRow: {
      display: 'flex',
      marginTop: 10,
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'lighter',
      letterSpacing: 0.1,
      textAlign: 'right',
      color: theme.color3,
    },
    label: {
      flex: 1,
    },
    price: {
      width: 100,
    },
  });
