import React from 'react';
import { customersEndpoint } from '../../../../Utils/API';
import PersonCreateOrUpdate from '../../Components/PersonCreateOrUpdate';

const {
  createEntry: createCustomer,
  deleteEntry: deleteCustomer,
  updateEntry: updateCustomer,
} = customersEndpoint;

export const defaultCustomer = {
  phone: '',
  gender: 'unknown',
  status: 'active',
  type: 'private',
};

export default ({ entry, history, match }) => (
  <PersonCreateOrUpdate
    person={entry}
    history={history}
    match={match}
    defaultPerson={defaultCustomer}
    createPerson={createCustomer}
    updatePerson={updateCustomer}
    deletePerson={deleteCustomer}
  />
);
