import { Theme, Button, External, VehicleStandard } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { forwardRef, useContext, useEffect, useState  } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersEndpoint, driversEndpoint, breaksEndpoint, customersEndpoint, getQueryForFilters } from '../../../Utils/API';
import { usePageTitle } from '../../../Utils/Helpers';
import AvgCustomer from './AvgCustomer';
import TopX from './TopX';
import OrderAnalytics from './OrderAnalytics';
import RevenueAnalytics from './RevenueAnalytics';
import ChannelDistribution from './ChannelDistribution';
import Filter from '../../Essentials/Components/Filter';
import moment from 'moment';

export const Card = forwardRef(({ children, title, subline, label, labelValue, cardHeight = 300}, ref) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme, cardHeight);

  return (
    <div className={css(styles.card)}>
      <div className={css(styles.cardTitle)}>
        <div className={css(styles.title)}>
          {title}
          <div className={css(styles.cardSubline)}>{subline}</div>
        </div>
        <div className={css(styles.info)}>
          <div className={css(styles.infoLabel)}>{label}</div>
          <div className={css(styles.infoValue)}>{labelValue}</div>
        </div>
      </div>
      <div className={css(styles.cardContent)} ref={ref}>
        {children}
      </div>
    </div>
  );
});

const getAvailableFilters = (t) => [
  {
    label: t('startDate'),
    type: 'start_date',
    attribute: 'start__timespan',
    values: [],
  },
  {
    label: t('endDate'),
    type: 'end_date',
    attribute: 'end__timespan',
    values: [],
  },
];

const cacheKey = 'cache:filters:analytics';

const uncacheFilterValues = (filters) => {
  const cachedFilterValues = JSON.parse(localStorage.getItem(cacheKey));
  filters.forEach((filter) => (filter.values = cachedFilterValues[filter.attribute] || filter.values));
  return filters;
};

const cacheFilterValues = (filters) => {
  const filterValues = {};
  filters.forEach((filter) => (filterValues[filter.attribute] = filter.values));
  localStorage.setItem(cacheKey, JSON.stringify(filterValues));
};

const getCachedOrDefaultFilters = (t) => {
  let cachedFilterValues = localStorage.getItem(cacheKey);
  const availableFilters = getAvailableFilters(t);

  return cachedFilterValues ? uncacheFilterValues(availableFilters) : availableFilters;
};

function timeRange(startDate, endDate) {
  let stops = {};
  let type = 'days';
  let format = '';

  let start = startDate;
  let end = endDate;

  let difference = startDate.diff(endDate, 'days');
  let positiveDifference = Math.abs(difference);

  if (positiveDifference === 0) {
    // one day
    type = 'hours';
    format = 'HH';
    start = moment().startOf('day');
    end = moment().endOf('day');
  } else {
    switch (true) {
      case (positiveDifference <= 62):
        // under two months
        type = 'days'
        format = 'D. MMMM';
        break;
      case (positiveDifference > 730):
        // over two years
        type = 'years'
        format = 'YYYY';
        break;
      case (positiveDifference > 62):
        // over two months
        type = 'months';
        format = 'MMMM';
        break;
    }
  }

  while (end.diff(start, type) >= 0) {
    stops[start.format(format)] = 0;
    start.add(1, type);
  }
  
  return [stops, format, difference];
}

export default () => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [value, setValue] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [breaks, setBreaks] = useState([]);
  const [filters, setFilters] = useState(getCachedOrDefaultFilters(t));
  const [range, setRange] = useState([]);
  const query = getQueryForFilters(filters);

  // const { getEntries: getOrganizations } = organisationsEndpoint;
  const { getEntries: getOrders } = ordersEndpoint;
  const { getEntries: getCustomers } = customersEndpoint;
  const { getEntries: getBreaks } = breaksEndpoint;
  const { getEntries: getDrivers } = driversEndpoint;

  usePageTitle(t('analytics'));

  useEffect(() => {
    cacheFilterValues(filters);
  }, [filters]);

  useEffect(() => {
    if (query) {
      window.gtag('event', 'search', { search_term: query });
    }
  }, [query]);

  // useEffect(() => {
  //   async function getOrgs(){
  //   const idents = await getOrganizations();
   
  //   setOrgs(idents.items || [])
  
  //   }
  //   getOrgs();
  // }, []);

  // const [identityId] = useGlobal('identityId');
  // const [employee] = employeesEndpoint.useEntry(identityId);
  // const onChange = (newValue) => {
  
  //   setValue(newValue)};
  //   useEffect(() => {
  //     employee && setValue(employee.owners)
  //   }, [employee]);
  // const vals = orgs.map((v)=>{
  //   return v.owners
  // })
  // debugger;

  useEffect(() => {
    async function loadData() {
      const loadedOrders = await getOrders({
        query: (query !== '' ? query + ';' : '') + 'status:completed'
      });

      const loadedCustomers = await getCustomers();
      const loadedDrivers = await getDrivers();
      const loadedBreaks = await getBreaks({
        query: query
      });
     
      setOrders(loadedOrders.items);
      setCustomers(loadedCustomers.items);
      setDrivers(loadedDrivers.items);
      setBreaks(loadedBreaks.items);
    }

    loadData();
  }, [query]);

  useEffect(() => {
    var startTimestamp, endTimestamp;
    var startMoment, endMoment;

    if (orders.length !== 0) {
      // set default timestamps
      startTimestamp = orders[0].timespan.startTimestamp;
      endTimestamp = moment().unix();

      // set dates in the filter query
      if (query !== '') { 
        filters.forEach((filter, index) => {
          if (filter.values.length !== 0) {
            if (filter.type === 'start_date') {
              startTimestamp = filter.values[0].value;
            } else if (filter.type === 'end_date') {
              endTimestamp = filter.values[0].value
            }
          }
        });
      }

      // convert timestamps to moments
      startMoment = moment.unix(startTimestamp);
      endMoment = moment.unix(endTimestamp);

      var momentRange = timeRange(startMoment, endMoment);
      setRange(momentRange);
    }
  }, [orders]);

  const setDayProgression = () => {
    const today = moment();

    let newFilters = filters;
    newFilters = newFilters.map((filter) => {
      if (filter.type == 'start_date' || filter.type == 'end_date') {
        filter.values = [{
          value: today.startOf('day').unix(),
          label: today.format(t('longDateFormat')),
        }];
      }
      return filter;
    });
    setFilters(newFilters);
  };

  return (
    <div className={css(styles.kpi)}>
      <div className={css(styles.kpiMenu)}>
        <div className={css(styles.kpiFilters)}>
          <Filter filters={filters} onChange={setFilters} />
        </div>
        <Button variant="light" size="s" onPress={setDayProgression}>
          {t('dayProgression')}
        </Button>
        {false && (
          <Button
            size="l"
            variant="dark"
            icon={ <External/> }
          >{t('export')}</Button>
        )}
      </div>
      {orders.length !== 0 ? (
        <div className={css(styles.kpiOutput)}>
          <div className={css(styles.cardRow)}>
            <OrderAnalytics range={range[0]} format={range[1]} difference={range[2]} orders={orders} drivers={drivers}/>
          </div>
          <div className={css(styles.cardRow)}>
            <RevenueAnalytics range={range[0]} format={range[1]} difference={range[2]} orders={orders} drivers={drivers}/>
          </div>
          <div className={css(styles.cardRow)}>
            <AvgCustomer orders={orders} customers={customers}/>
            <TopX type="customers" orders={orders} drivers={drivers} customers={customers} breaks={breaks}/>
            <TopX type="drivers" orders={orders} drivers={drivers} customers={customers} breaks={breaks}/>
          </div>
          <div className={css(styles.cardRow)}>
            <ChannelDistribution orders={orders}/>
          </div>
        </div>
      ) : (
        <div className={css(styles.noData)}>
          <VehicleStandard className={css(styles.noDataIcon)} />
          <div>{t('noDataAvailable')}</div>
        </div>
      )}
    </div>
  );
};

const getThemedStyles = (theme, cardHeight) =>
  StyleSheet.create({
    welcome: {
      fontWeight: 'lighter',
      fontSize: 24,
      letterSpacing: 0.12,
      color: theme.color3,
    },
    kpi: {
      overflow: 'auto',
      flex: 1,
      minWidth: 0,
      paddingBottom: 30,
    },
    kpiTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 30
    },
    kpiMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '15px 30px 30px',
    },
    kpiMenuInputs: {
      width: '75%',
      display: 'flex',
    },
    kpiMenuInputsSelectDate: {
      width: '50%',
      marginRight: 30,
    },
    kpiMenuInputsCompare: {
      width: '50%',
    },
    cardRow: {
      display: 'flex',
      minWidth: 0,
      marginTop: 30,
      paddingRight: 30,
    },
    card: {
      marginLeft: 30,
      flex: 1,
      minWidth: 0,
      backgroundColor: theme.color2,
      borderRadius: 5,
      boxShadow: theme.shadow2,
      padding: 25,
    },
    cardTitle: {
      fontWeight: 500,
      fontSize: 14,
      borderBottom: `1px solid ${theme.color12}`,
      paddingBottom: 20,
      marginBottom: 20,
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      flex: 0.5,
    },
    cardSubline: {
      fontWeight: 300,
      color: theme.color1,
      marginTop: 10,
    },
    info: {
      justifyContent: 'flex-end',
      flex: 0.5,
      display: 'flex',
    },
    infoLabel: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: 12,
      fontWeight: 300,
      color: theme.color1,
    },
    infoValue: {
      fontSize: 14,
      fontWeight: 300,
      color: theme.color3,
      paddingLeft: 10,
      justifyContent: '',
    },
    cardContent: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: cardHeight,
      fontWeight: 'lighter',
    },
    noData: {
      height: '50vh',
      marginTop: 50,
      color: theme.color10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noDataIcon: {
      fontSize: 60,
    },
  });