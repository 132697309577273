import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import getDistance from 'geolib/es/getDistance';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ordersEndpoint } from '../../../Utils/API';
import Break from '../Components/Break';
import Cookies from 'js-cookie';
import Timeline, { blockWidth } from '../Components/Timeline';
import TimelineOrder from '../Components/TimelineOrder';

const { updateEntry: updateOrder } = ordersEndpoint;

export const isOrderOngoing = order =>
  order.status !== 'new' &&
  order.status !== 'assigned' &&
  order.status !== 'assigning' &&
  order.status !== 'completed' &&
  order.status !== 'canceled' &&
  order.status !== 'problem' &&
  order.status !== 'deleted';

export const onePixelInSeconds = 3600 / blockWidth;

export const getDurationInPixels = duration => duration / onePixelInSeconds;

export const getSlotDimensions = ({ startTimestamp, endTimestamp }) => {
  const day = moment
    .unix(startTimestamp)
    .startOf('day')
    .unix();

  const left = (startTimestamp - day) / onePixelInSeconds;
  const duration = endTimestamp - startTimestamp;
  const width = getDurationInPixels(duration);

  return { left, width };
};

export const getFullTimespanForOrder = order => {
  const timestamps = [];

  if (isOrderOngoing(order)) {
    timestamps.push(moment().unix());
  }

  if (order.timespan) {
    timestamps.push(order.timespan.startTimestamp);

    if (order.timespan.endTimestamp) {
      timestamps.push(order.timespan.endTimestamp);
    } else {
      timestamps.push(order.estimatedTimespan.endTimestamp);
    }
  } else {
    timestamps.push(order.estimatedTimespan.startTimestamp);
    timestamps.push(order.estimatedTimespan.endTimestamp);
  }

  if (order.approachTimespan) {
    //timestamps.push(order.approachTimespan.startTimestamp);
    timestamps.push(order.approachTimespan.endTimestamp);
  }

  const startTimestamp = Math.min(...timestamps);
  const endTimestamp = Math.max(...timestamps);

  return {
    startTimestamp,
    endTimestamp,
  };
};

export default ({
  car,
  selectedOrder,
  isFleetMatching,
  history,
  date,
  useOrdersForCar,
  useBreaksForDriver,
  onDropZoneHover,
}) => {
  const [{ isHovering }, drop] = useDrop({
    accept: 'order',
    collect: monitor => ({ isHovering: monitor.isOver() }),
    drop: async item => {
      if (!availableSlot) {
        return;
      }

      if (car.essentials_driver) {
        await updateOrder(item.id, {
          car,
          status: 'assigning',
          requestTimestamp: moment().unix(),
          requestEndTimestamp: moment().unix() + Number(Cookies.get('driverRequestTime')),
        });
      } else {
        await updateOrder(item.id, {
          car,
          status: 'assigned',
        });
      }
    },
  });

  const [availableSlot, setAvailableSlot] = useState();
  const [reservedSlots, setReservedSlots] = useState([]);

  const orders = useOrdersForCar(car.id, date);
  const breaks = useBreaksForDriver(car.essentials_driver, date);

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  useEffect(() => {
    window.setTimeout(() => {
      onDropZoneHover(isHovering);
    }, isHovering ? 50 : 0);
  }, [isHovering]);

  useEffect(() => {
    if (!orders && !breaks) {
      setReservedSlots([]);
      return;
    }

    let slots = [];

    if (orders) {
      slots = orders.map(order => {
        const fullTimespan = getFullTimespanForOrder(order);

        return {
          order,
          dimensions: getSlotDimensions(fullTimespan),
        };
      });
    }

    if (breaks) {
      slots = slots.concat(
        breaks.map(driverBreak => ({
          order: driverBreak,
          dimensions: getSlotDimensions(driverBreak.timespan),
        }))
      );
    }

    setReservedSlots(slots);
  }, [orders, breaks]);

  useEffect(() => {

  
    if (!selectedOrder || (selectedOrder.status !== 'new' && selectedOrder.status !== 'problem')) {
      console.log(selectedOrder)
      setAvailableSlot();
      return;
    }

    const dimensions = getSlotDimensions(selectedOrder.estimatedTimespan || selectedOrder.timespan);

    const isReserved = reservedSlots.find(slot => {
      const reservedStart = slot.dimensions.left;
      const reservedEnd = reservedStart + slot.dimensions.width;

      const desiredStart = dimensions.left;
      const desiredEnd = desiredStart + dimensions.width;

      if (desiredStart >= reservedStart && desiredStart <= reservedEnd) {
        return true;
      }

      if (desiredEnd >= reservedStart && desiredEnd <= reservedEnd) {
        return true;
      }

      if (reservedStart >= desiredStart && reservedStart <= desiredEnd) {
        return true;
      }

      return false;
    });

    if (isReserved) {
      setAvailableSlot();
      return;
    }

    const carPosition = car.lastPosition && JSON.parse(car.lastPosition);
    let distance = '-';

    if (carPosition) {
      const originPosition = JSON.parse(selectedOrder.origin.position);

      distance =
        getDistance(
          originPosition,
          {
            longitude: carPosition[0],
            latitude: carPosition[1],
          },
          10
        ) / 1000;
    }

    setAvailableSlot({
      order: selectedOrder,
      dimensions,
      distance,
    });
  }, [selectedOrder, reservedSlots, car.lastPosition]);

  return (
    <div ref={drop} className={css(styles.car)}>
      <Timeline />
      <div className={css(styles.slotsLayer)}>
        {availableSlot && (
          <div
            className={css(
              styles.availableSlot,
              !isFleetMatching && styles.availableSlotFleetNotMatching,
              isHovering && styles.availableSlotHovering
            )}
            style={{ ...availableSlot.dimensions }}
          >
            <div
              className={css(styles.availableSlotDistance)}
            >{`~ ${availableSlot.distance} km`}</div>
          </div>
        )}
      </div>
      <div className={css(styles.slotsLayer)}>
        {reservedSlots.map(({ dimensions, order, layers }) =>
          order.type === 'break' ? (
            <Break key={order._id} dimensions={dimensions} />
          ) : (
            <TimelineOrder
              key={order._id}
              order={order}
              dimensions={dimensions}
              layers={layers}
              history={history}
            />
          )
        )}
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    car: {
      position: 'relative',
    },
    slotsLayer: {
      position: 'absolute',
      top: 2,
      right: 0,
      left: 0,
      bottom: 3,
    },
    availableSlot: {
      position: 'absolute',
      height: '100%',
      borderRadius: 5,
      transition: 'width 200ms, left 200ms',
      backgroundColor: '#A8E0F5',
      border: '3px dotted #59C5EE',
      boxShadow: theme.shadow1,
    },
    availableSlotDistance: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 70,
      height: 20,
      top: '50%',
      marginTop: -10,
      left: -35,
      boxShadow: theme.shadow1,
      borderRadius: 5,
      backgroundColor: theme.color12,
      fontWeight: 'lighter',
      fontSize: 12,
      lineHeight: '16px',
      zIndex: 1,
    },
    availableSlotFleetNotMatching: {
      backgroundColor: '#FAE9CA',
      border: '3px dotted #FFA718',
    },
    availableSlotHovering: {
      borderStyle: 'solid',
    },
    reservedSlot: {
      transition: 'width 200ms, left 200ms',
      boxShadow: theme.shadow1,
      backgroundColor: theme.color2,
      border: `2px solid ${theme.color4}`,
    },
  });
