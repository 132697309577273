import { Button, Theme, DeleteQuestion } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../Utils/Helpers';

const Demo = ({
  closeModal,
  deleteAction,
  text = {
    info: 'deleteEntryDesc',
    cancel: 'cancel',
    delete: 'remove',
  },
}) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        <h1 className={css(styles.title)}>
          <DeleteQuestion className={css(styles.icon)} />
          {t('deleteEntry')}
        </h1>
        <p className={css(styles.content)}>{t(text.info)}</p>
        <div className={css(styles.buttons)}>
          <Button size="m" variant="light" onPress={closeModal}>
            {t(text.cancel)}
          </Button>
          <div className={css(styles.spacer)} />
          <Button size="m" variant="dark" onPress={() => deleteAction()}>
            {t(text.delete)}
          </Button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Demo;

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(44,62,80,0.7)',
      zIndex: 10,
    },
    containerInner: {
      backgroundColor: theme.color2,
      width: 500,
      padding: 30,
      borderRadius: 5,
    },
    icon: {
      marginRight: 20,
    },
    spacer: {
      width: 20,
    },
    title: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '28px',
      marginBottom: 15,
      color: theme.color3,
    },
    content: {
      color: theme.color1,
      lineHeight: '1.5em',
      marginBottom: 50,
      fontWeight: 300,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });
