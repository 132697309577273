import { css } from 'aphrodite/no-important';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { toPrice } from '../../../../Utils/Price';
import CostOverviewRow from './CostOverviewRow';
import CostOverviewRowSubSummary from './CostOverviewRowSubSummary';
import CostOverviewRowSummary from './CostOverviewRowSummary';

export default forwardRef(
  ({ availableModules, carLicenses, moduleIds, currentSubscription, vat, style }, ref) => {
    const { t } = useTranslation();

    const moduleByIdMap = {};
    availableModules.forEach((module) => (moduleByIdMap[module._id] = module));

    let remainingDays = 30;
    let newCarLicenses = carLicenses || 0;
    let mergedModuleIds = moduleIds;
    if (currentSubscription) {
      mergedModuleIds = currentSubscription.modules.concat(mergedModuleIds);
      remainingDays = moment
        .unix(currentSubscription.end)
        .startOf('day')
        .diff(moment().startOf('day'), 'days');
      if (remainingDays < 0) {
        remainingDays = 0;
      }
    }

    let subTotal = 0;
    mergedModuleIds.forEach((id) => {
      const module = moduleByIdMap[id];
      if (!module) {
        return;
      }

      if (currentSubscription && !currentSubscription.modules.includes(id)) {
        subTotal +=
          (module.ppm / 30) * remainingDays * (newCarLicenses + currentSubscription.licences);
      } else {
        subTotal += (module.ppm / 30) * remainingDays * newCarLicenses;
      }
    });
    subTotal = toPrice(subTotal);
    const vatPrice = toPrice(subTotal * (vat / 1000));
    const totalPriceVat = toPrice(subTotal * (vat / 1000 + 1));

    useImperativeHandle(ref, () => ({
      getTotalPrice: () => totalPriceVat,
    }));

    return (
      <div className={css(style)}>
        {mergedModuleIds.map((id) => {
          const module = moduleByIdMap[id];
          if (!module) {
            return null;
          }

          let calculationDesc;
          let pricePerCar;
          let price;
          if (currentSubscription && !currentSubscription.modules.includes(id)) {
            const combinedCarLicenses = newCarLicenses + currentSubscription.licences;
            pricePerCar = (module.ppm / 30) * remainingDays;
            price = pricePerCar * combinedCarLicenses;
            calculationDesc = `${combinedCarLicenses} ${t('licenseCars')} × CHF ${toPrice(
              pricePerCar
            )}`;
          } else {
            pricePerCar = (module.ppm / 30) * remainingDays;
            price = pricePerCar * newCarLicenses;
            calculationDesc = `${newCarLicenses} ${t('licenseCars')} × CHF ${toPrice(pricePerCar)}`;
          }

          if (currentSubscription) {
            calculationDesc += ` (${remainingDays} ${t('days')})`;
          }

          if (price === 0) {
            return null;
          }

          return (
            <CostOverviewRow
              key={id}
              moduleName={module.name}
              calculationDesc={calculationDesc}
              price={`CHF ${toPrice(price)}`}
            />
          );
        })}
        <CostOverviewRowSubSummary label={t('subTotal')} price={`CHF ${subTotal}`} />
        <CostOverviewRowSubSummary label={t('vat', { vat: vat / 10 })} price={`CHF ${vatPrice}`} />
        <CostOverviewRowSummary price={`CHF ${totalPriceVat}`} />
      </div>
    );
  }
);
