import { createSnackbar, InfoRow, TextInput } from '@yourmileag/ui-kit';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { carsEndpoint, employeesEndpoint } from '../../../../Utils/API';
import { getPathParent, join, s } from '../../../../Utils/Helpers';
import InventoryCreateOrUpdate from '../../Components/InventoryCreateOrUpdate';
import useDeleteModal from '../../../../Utils/useDeleteModal';

const { createEntry: createCar, deleteEntry: deleteCar, updateEntry: updateCar } = carsEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;

export const defaultCar = {
  manufacturer: '',
  model: '',
  licensePlate: '',
};

export const useCarValidator = (car) => {
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!car.manufacturer) {
      newErrors.manufacturer = t('fieldIsRequired');
    }

    if (!car.model) {
      newErrors.model = t('fieldIsRequired');
    }

    if (!car.licensePlate) {
      newErrors.licensePlate = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [car, t]);

  return errors;
};

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const [car, setCar] = useState({ ...defaultCar, ...entry });
  const errors = useCarValidator(car);
  const isSaveDisabled = Object.keys(errors).length > 0;
  const [identityId] = useGlobal('identityId');
  const { show, hide, RenderModal } = useDeleteModal();

  useEffect(() => {
    setCar({ ...defaultCar, ...entry });
  }, [entry]);

  const onChange = (attribute) => (e) => setCar({ ...car, [attribute]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const newCar = { ...car };

    if (!entry || newCar.noteText !== entry.noteText) {
      const author = await getEmployee(identityId);
      newCar.noteTimestamp = moment().unix();
      newCar.noteAuthor = s(author.lastName, author.firstName);
    }

    if (!entry) {
      const newFleetId = await createCar(newCar);

      history.push(join(getPathParent(match.url), newFleetId));
    } else {
      await updateCar(entry.id, newCar);

      history.push(getPathParent(match.url));
    }

    createSnackbar('success', t('successfullySavedCar', { name: s(car.manufacturer, car.model) }));
  };

  const deleteAction = async () => {
    await deleteCar(entry.id);
    hide();
    history.push(getPathParent(getPathParent(match.url)));
    createSnackbar('info', t('successfullyDeletedCar', { name: s(car.manufacturer, car.model) }));
  };

  const onDelete = async () => {
    show();
  };

  return (
    <InventoryCreateOrUpdate
      history={history}
      match={match}
      onSubmit={onSubmit}
      onDelete={entry && onDelete}
      note={car.noteText}
      onNoteChange={onChange('noteText')}
      isSaveDisabled={isSaveDisabled}
      description={t('carDescription')}
    >
      <InfoRow center label={t('manufacturer')} required>
        <TextInput
          id="sfcou-manufacturer"
          value={car.manufacturer}
          onChange={onChange('manufacturer')}
          required
          errorMessage={errors.manufacturer}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('model')} required>
        <TextInput
          id="sfcou-model"
          value={car.model}
          onChange={onChange('model')}
          required
          errorMessage={errors.model}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('licensePlate')} required>
        <TextInput
          id="sfcou-licensePlate"
          value={car.licensePlate}
          onChange={onChange('licensePlate')}
          required
          errorMessage={errors.licensePlate}
          clean
          size="s"
        />
      </InfoRow>
      <RenderModal deleteAction={deleteAction} />
    </InventoryCreateOrUpdate>
  );
};
