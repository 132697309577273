import { Button, Plus, TextInput, Search } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { join } from '../../../Utils/Helpers';
import PaginatedTable from './PaginatedTable';

const InventoryScreen = ({
  match,
  history,
  SidePanelComponent,
  useData,
  columns,
  rowMap,
  searchLabel,
  addButtonLabel,
  customButton,
  emptyContainer,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState(`${columns[0].orderKey}:asc`);
  const [data, setData] = useState();

  let queryOr;
  if (searchValue && columns) {
    let queryParts = [];

    columns.forEach((column) => {
      if (!column.searchKeys) {
        return;
      }

      column.searchKeys.forEach((searchKey) => queryParts.push(`${searchKey}:${searchValue}`));
    });

    queryOr = queryParts.join(';');
  }

  const useDataAdditionalOptions = { orderBy, queryOr };

  const onChange = (set) => (e) => set(e.target.value);
  const onAddPress = () => history.push(join(match.url, 'create'));

  const basePath = match.url;

  return (
    <div className={css(styles.container)}>
      <PaginatedTable
        match={match}
        history={history}
        emptyContainer={emptyContainer}
        preHeader={
          <div className={css(styles.header)}>
            <div className={css(styles.headerInput)}>
              <TextInput
                id="is-search"
                type="search"
                placeholder={searchLabel}
                value={searchValue}
                onChange={onChange(setSearchValue)}
                size="s"
                icon={<Search />}
              />
            </div>
            <div className={css(styles.button, styles.buttonAdd)}>
              {!!customButton ? customButton : 
                <Button
                  id="inventory-add-button"
                  variant="special"
                  size="l"
                  icon={<Plus />}
                  onPress={onAddPress}
                >
                  {addButtonLabel}
                </Button>
              }
            </div>
          </div>
        }
        useData={useData}
        useDataAdditionalOptions={useDataAdditionalOptions}
        columns={columns}
        rows={data ? data.map(rowMap) : []}
        onChange={setData}
        rowLinkBasePath={join(basePath)}
        orderBy={orderBy}
        onChangeOrderBy={setOrderBy}
        useRowLinks
      />
      <Switch>
        <Route path={`${basePath}/create`} component={SidePanelComponent} />
        <Route path={`${basePath}/:id`} component={SidePanelComponent} />
      </Switch>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E9EBED',
    borderBottom: '1px solid #E9EBED',
    padding: '30px 0',
  },
  column: {
    flex: 1,
    textAlign: 'center',
  },
  headerInput: {
    flex: 1,
  },
  button: {
    marginLeft: 15,
  },
  buttonAdd: {
    marginLeft: 80,
  },
});

export default InventoryScreen;