import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { Theme } from '@yourmileag/ui-kit';

export const blockWidth = 360;
export const blockHeight = 60;

export const TimelineBlockHeader = ({ children }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return <div className={css(styles.timelineBlockHeader)}>{children}</div>;
};

export default ({ children }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return <div className={css(styles.timeline)}>{children}</div>;
};

const mainLineColor = '#E9EBED';
const lineColor = '#F2F4F6';

const getThemedStyles = theme =>
  StyleSheet.create({
    timeline: {
      width: blockWidth * 24,
      height: blockHeight,
      backgroundColor: theme.color13,
      backgroundImage: `linear-gradient(${mainLineColor} 1px, transparent 1px), linear-gradient(90deg, ${mainLineColor} 1px, transparent 1px), linear-gradient(${lineColor} 1px, transparent 1px), linear-gradient(90deg, ${lineColor} 1px, transparent 1px)`,
      backgroundSize: `${blockWidth}px ${blockHeight}px, ${blockWidth}px ${blockHeight}px, ${blockHeight}px, ${blockWidth /
        4}px ${blockHeight}px`,
      //backgroundPosition: '-1px -1px, -1px -1px, -1px -1px, -1px -1px',
    },
    timelineBlockHeader: {
      position: 'relative',
      width: blockWidth,
      height: blockHeight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '0 30px',
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: 'bold',
      color: theme.color6,
      letterSpacing: 0.78,
      ':not(:first-child)': {
        borderLeft: `1px solid ${mainLineColor}`,
      },
    },
  });
