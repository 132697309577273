import { InfoRow, Theme } from '@yourmileag/ui-kit';
import React, { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { formatThousands } from '../../../Utils/Helpers';
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { Card } from '.';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  if (active && payload) {
    return (
      <div className={css(styles.tooltip)}>
        <p className={css(styles.tooltipLable)}>{`${payload[0].payload.name}: ${payload[0].payload.revenue}`}</p>
      </div>
    );
  }

  return null;
};

export default ({range, format, difference, orders, drivers}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  let currentTotal = 0;
  let data = {
    'orderAnalyticsTotalOrders': 0,
    'orderAnalyticsAvgDay': 0,
    'orderAnalyticsAvgWeek': 0,
    'orderAnalyticsAvgMonth': 0,
    'orderAnalyticsAvgDriver': 0,
    'orderAnalyticsTelOrders': 0,
//    'orderAnalyticsAppOrders': 0,
    'orderAnalyticsWebOrders': 0,
    'orderAnalyticsOtherOrders': 0,
  };
  let driverData = {};

  var revenue = [];
  var stats = [];
  var total = 0;

  if (typeof range !== 'undefined' && orders.length !== 0 && drivers.length !== 0) {
    let localRange = JSON.parse(JSON.stringify(range));

    drivers.map(function(driver) {
      driverData[driver.id] = 0;
    });

    orders.forEach((order) => {
      if (!order.price || order.status !== 'completed' || typeof order.driver === 'undefined' || order.driver === null) {
        return;
      }

      // update total
      currentTotal++;
      data['orderAnalyticsTotalOrders']++;

      // update driver order count
      driverData[order.driver.id]++;

      // determine order source
      switch(order.source) {
        case 'cockpit':
          data['orderAnalyticsTelOrders']++;
          break;
        case 'widget':
          data['orderAnalyticsWebOrders']++;
          break;
//        case 'app':
//          data['orderAnalyticsAppOrders']++;
//          break;
        default:
          data['orderAnalyticsOtherOrders']++;
      }

      // push data to range
      localRange[moment.unix(order.timespan.endTimestamp).format(format)]++;
    });

    // calculate day avg
    // take the current total as the average if its one day
    if (Math.abs(difference) === 0) {
      data['orderAnalyticsAvgDay'] = currentTotal;
    } else {
      data['orderAnalyticsAvgDay'] = (currentTotal / Math.abs(difference)).toFixed(2);
    }

    if (Math.abs(difference) < 7) {
      data['orderAnalyticsAvgWeek'] = '-';
    } else {
      data['orderAnalyticsAvgWeek'] = data['orderAnalyticsAvgDay'] / 7;
    }

    if (Math.abs(difference) < 30) {
      data['orderAnalyticsAvgMonth'] = '-';
    } else {
      data['orderAnalyticsAvgMonth'] = data['orderAnalyticsAvgDay'] / 30;
    }

    // calculate driver avg
    let filteredDriverData = Object.values(driverData).filter(entry => entry > 0);
    let totalDriverOrders = filteredDriverData.reduce((a, b) => {
      return a + b;
    }, 0);

    data['orderAnalyticsAvgDriver'] = (totalDriverOrders / filteredDriverData.length).toFixed(1);

    if (format !== 'HH') {
      revenue = Object.keys(localRange).map((hour) => ({ name: hour, revenue: localRange[hour] }));
    } else {
      let sortedHours = Object.keys(localRange).sort((a, b) => {return new Date('1970/01/01 ' + (a + ':00')) - new Date('1970/01/01 ' + (b + ':00'))});
      revenue = sortedHours.map((hour) => ({ name: `${hour}:00`, revenue: localRange[hour] }));
    }
    
    stats = Object.entries(data);
    total = currentTotal;
  }
  
  return (
    <Card cardHeight="380px" title={t('orders')} label={t('total')} labelValue={`${formatThousands(total)}`}>
      <ResponsiveContainer width="60%" height="100%">
        <AreaChart data={revenue}>
          <defs>
            <linearGradient id="bgColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#01CBFF" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={false} stroke={theme.color12} />
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            axisLine={false}
            tickLine={false}
            stroke={theme.color1}
            fontSize="12"
            fontWeight="300"
          />
          <XAxis
            axisLine={false}
            tickLine={false}
            stroke={theme.color1}
            fontSize="12"
            fontWeight="300"
            dataKey="name"
          />
          <Area
            type="monotone"
            dataKey="revenue"
            strokeWidth="2"
            stroke={theme.color5}
            fillOpacity={1}
            fill="url(#bgColor)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div className={css(styles.divider)}></div>
      <div className={css(styles.numbers)}>
        {
          stats.map((row, rowIndex) => (
            <InfoRow
              label={t(row[0])}
              apart={true}
              key={row[0] + row[1]}
            >
              {isNaN(row[1]) ? row[1] : formatThousands(row[1])}
            </InfoRow>
          ))
        }
      </div>
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    orders: {
      display: 'flex',
      alignItems: 'stretch',
    },
    divider: {
      height: '100%',
      width: 1,
      margin: '0 30px',
      backgroundColor: theme.color12
    },
    numbers: {
      width: '40%'
    },
    tooltip: {
      backgroundColor: theme.color2,
      border: `solid ${theme.color10} 1px`,
      padding: 10,
      borderRadius: 3,
      boxShadow: theme.shadow1,
    },
    tooltipLable: {
      fontSize: 12,
      fontWeight: 300,
      color: theme.color15,
    },
  });