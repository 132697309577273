import { NavLink, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export default ({ navItems, match, style }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <div className={css(styles.container, style)}>
      <div className={css(styles.containerInner)}>
        <div className={css(styles.navItems)}>
          {navItems.map(navItem => (
            <NavLink
              id={navItem.id}
              key={navItem.title}
              to={navItem.link || navItem.path}
              exact={navItem.exact}
              styles={styles.navItem}
              activeStyles={styles.navItemActive}
            >
              <div className={css(styles.icon)}>{navItem.icon}</div>
              <div className={css(styles.title)}>{navItem.title}</div>
            </NavLink>
          ))}
        </div>
        <div className={css(styles.content)}>
          <Switch>
            <Redirect from={match.path} exact to={navItems[0].path} />
            {navItems.map(navItem => (
              <Route key={navItem.path} path={navItem.path} component={navItem.component} />
            ))}
          </Switch>
        </div>
      </div>
      <div className={css(styles.version)}>Version: {process.env.REACT_APP_BUILD_NUMBER}</div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    containerInner: {
      display: 'flex',
    },
    navItems: {
      width: 308,
      display: 'flex',
      flexDirection: 'column',
    },
    navItem: {
      display: 'flex',
      height: 60,
      alignItems: 'center',
      textDecoration: 'none',
      padding: 20,
      borderLeftStyle: 'solid',
      borderLeftWidth: 3,
      borderLeftColor: 'transparent',
      fontSize: 14,
      letterSpacing: 0.6,
      color: theme.color1,
      ':hover': {
        opacity: 0.7,
      },
    },
    navItemActive: {
      backgroundImage: theme.gradient4,
      borderLeftColor: theme.color5,
      color: theme.color3,
      ':hover': {
        opacity: 1,
      },
    },
    icon: {
      fontSize: 30,
      marginRight: 8,
    },
    title: {},
    content: {
      flex: 1,
      backgroundColor: theme.color2,
      borderRadius: 5,
      boxShadow: theme.shadow3,
    },
    contentNoPadding: {
      padding: 0,
    },
    version: {
      fontWeight: 'lighter',
      marginTop: 20,
      textAlign: 'right',
      color: theme.color1,
      fontSize: 10,
    },
  });
