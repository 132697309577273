import { ButtonPlain, CheckBox, Theme } from '@yourmileag/ui-kit';
import { StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

export default ({ id, checked, onChange, children, style, clean }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <ButtonPlain styles={[styles.checkbox, clean && styles.clean, style]} onClick={onChange}>
      {children}
      <CheckBox id={id} checked={checked || false} readOnly clean />
    </ButtonPlain>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    checkbox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.color2,
      padding: 15,
      borderRadius: 5,
      boxShadow: theme.shadow2,
      marginBottom: 10,
    },
    clean: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: '1px solid #dedede',
      borderRadius: 0,
      marginBottom: 0,
      padding: 20,
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0.18,
    },
  });
