import { OriginDestinationColumn, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrderDelays, s } from '../../../Utils/Helpers';
import OrderContextMenu from '../ContextMenus/Order';
import Loader from './Loader';

let timeout;

const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);

  const onHover = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => setIsHovered(true), 500);
  };

  const onBlur = () => {
    clearTimeout(timeout);
    setIsHovered(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return [isHovered, onHover, onBlur];
};

export default ({ order, history, dimensions }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  const [isHovered, onHover, onBlur] = useHover();
  const [fixMousePosition, setFixMousePosition] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const origin = order.origin.name;
  const destination = order.destination ? order.destination.name : t('noDestinationYet');

  const customerName = order.customer
    ? s(order.customer.lastName, order.customer.firstName)
    : t('anonymousPassenger');

  let indicatorStyle = styles.statusIndicatorOngoing;

  const onMouseMove = (event) => {
    if (!fixMousePosition) {
      const timelineOrderRect = event.target.closest('.timelineOrder').getBoundingClientRect();
      setMousePosition({
        x: event.clientX,
        y: timelineOrderRect.y + timelineOrderRect.height,
      });
    }
  }

  const onMouseEnter = (event) => {
    onHover();
  }

  const onMouseLeave = (event) => {
    setFixMousePosition(false);
    onBlur();
  }

  const onClick = (event) => {
    setFixMousePosition(true);
  }

  if (order.status === 'assigning') {
    indicatorStyle = styles.statusIndicatorAssigning;
  } else if (order.status === 'assigned') {
    indicatorStyle = styles.statusIndicatorAssigned;
  } else if (order.status === 'completing') {
    indicatorStyle = styles.statusIndicatorCompleting;
  } else if (order.status === 'completed') {
    indicatorStyle = styles.statusIndicatorCompleted;
  } else if (order.status === 'problem') {
    indicatorStyle = styles.statusIndicatorProblem;
  }  

  const { originDelay, destinationDelay } = getOrderDelays(order);

  return (
    <div
      className={'timelineOrder ' + css(styles.container)}
      style={{ ...dimensions }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <div className={css(styles.containerInner)}>
        <div className={css(styles.statusIndicator, indicatorStyle)} />
        <div className={css(styles.addressContainer)}>
          <div className={css(styles.address, styles.addressOrigin)} title={origin}>
            {origin || t('anonymousPassenger')}
          </div>
          <div className={css(styles.address)} title={destination}>
            {destination}
          </div>
        </div>
        {order.status === 'assigning' && <Loader order={order} />}
      </div>
      {isHovered && (
        <div className={css(styles.fullInfoContainer)} style={{ left: mousePosition.x, top: mousePosition.y }}>
          <div className={css(styles.fullInfoContainerHeader)}>
            <div className={css(styles.customerName)}>{customerName}</div>
            <div className={css(styles.fleet)}>{order.fleet ? order.fleet.label : '-'}</div>
            <div onClick={onClick}>
              <OrderContextMenu order={order} history={history} />
            </div>
          </div>
          <div className={css(styles.content)}>
            <OriginDestinationColumn
              origin={order.origin}
              originDelay={originDelay}
              destination={order.destination}
              destinationDelay={destinationDelay}
              hasStopovers={order.stopovers.length > 0}
              anonymousOriginLabel={t('anonymousStart')}
              anonymousDestinationLabel={t('anonymousEnd')}
              noDestinationLabel={t('noDestinationYet')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const fadeInAnimation = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      position: 'absolute',
      height: '100%',
      borderRadius: 5,
      transition: 'width 200ms, left 200ms',
      boxShadow: theme.shadow1,
      backgroundColor: theme.color2,
      border: `2px solid ${theme.color4}`,
    },
    containerInner: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    statusIndicator: {
      margin: 2,
      height: 4,
      borderRadius: 2,
      transition: `background-color ${theme.transitionStateChange}`,
    },
    statusIndicatorAssigning: {
      backgroundColor: theme.colorStatusAssigning,
    },
    statusIndicatorAssigned: {
      backgroundColor: theme.colorStatusAssigned,
    },
    statusIndicatorOngoing: {
      backgroundColor: theme.colorStatusArriving,
    },
    statusIndicatorCompleting: {
      backgroundColor: theme.colorStatusArriving,
    },
    statusIndicatorCompleted: {
      backgroundColor: theme.colorStatusCompleted,
    },
    statusIndicatorProblem: {
      backgroundColor: theme.colorStatusProblem,
    },
    addressContainer: {
      padding: '2px 10px',
    },
    address: {
      fontSize: 12,
      fontWeight: 'lighter',
      lineHeight: '15px',
      color: theme.color3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 0,
    },
    addressOrigin: {
      marginBottom: 4,
    },
    fullInfoContainer: {
      pointerEvents: 'none',
      animationName: [fadeInAnimation],
      animationDuration: '100ms',
      position: 'fixed',
      top: '50%',
      marginTop: -22,
      left: '50%',
      marginLeft: -280,
      width: 300,
      height: 100,
      backgroundColor: theme.color2,
      borderRadius: 5,
      boxShadow: theme.shadow1,
      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
      zIndex: 1,
      fontSize: 14,
      lineHeight: '18px',
    },
    fullInfoContainerHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 15,
      pointerEvents: 'all',
    },
    customerName: {
      flex: 1,
    },
    fleet: {
      backgroundColor: theme.color12,
      padding: '2px 5px',
      borderRadius: 5,
      color: theme.color3,
      fontWeight: 'lighter',
      fontSize: 12,
    },
    content: {
      padding: '0 15px',
      marginTop: -10,
    },
  });
