import { css, StyleSheet } from 'aphrodite/no-important';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import magicLink from '../Animations/magicLink.json';

export default ({ isInitialized }) => {
    const container = useRef();

    useEffect(() => {
        const animationInstance = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            autoplay: true,
            loop: !isInitialized,
            animationData: magicLink,
        });

        return () => {
            animationInstance.destroy();
        };
    }, [isInitialized]);

    return <div className={css(styles.animation)} ref={container} />;
};

const styles = StyleSheet.create({
    animation: {
        width: 90,
        height: 90,
    },
});
