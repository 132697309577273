import { GoLogo, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles, usePageTitle } from '../../../Utils/Helpers';

export default () => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  usePageTitle(t('maintenance'));

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <GoLogo />
      </div>
      <h1 className={css(styles.title)}>{t('maintenance')}</h1>
      <p className={css(styles.content)}>{t('cockpitIsInMaintenanceMode')}</p>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      fontSize: 100,
      color: theme.color3,
      marginBottom: 40,
    },
    title: {
      fontSize: 22,
      lineHeight: '28px',
      marginBottom: 15,
      color: theme.color3,
    },
    content: {
      color: theme.color1,
    },
  });
