import deepMerge from 'deepmerge';
import Dexie from 'dexie';
import { useEffect, useState } from 'react';
import {
  breaksEndpoint,
  carsEndpoint,
  driversEndpoint,
  essentialsWsEndpoint,
  fleetsEndpoint,
  ordersEndpoint,
} from '../../../Utils/API';
import { getOwner } from '@yourmileag/essentials-api-client';
import { getTimespanFilterForDay } from '../../../Utils/Helpers';

const orderSchema =
  '_id,owners,car,fleet,price,origin,status,customer,timespan,stopovers,destination,approachTimespan,estimatedTimespan,createAt,statusMessage,noteText,requestTimestamp,passengerAmount,partnerName,partnerAuthor,type,extraCustomers';
const breakSchema = '_id,type,driver,timespan';
const carSchema =
  'id,essentials_fleets,essentials_driver,manufacturer,model,licensePlate,lastPosition';
const driverSchema = 'id,firstName,lastName';
const fleetSchema = 'id,label';

const db = new Dexie('dispatcher');
db.version(1).stores({
  orders: orderSchema,
  breaks: breakSchema,
  cars: carSchema,
  drivers: driverSchema,
  fleets: fleetSchema,
});

const isTimespanOnDate = (timespan, date) => {
  const startOfDay = date.startOf('day').unix();
  const endOfDay = date.endOf('day').unix();

  return (
    timespan.startTimestamp >= startOfDay && timespan.startTimestamp <= endOfDay
//  ) || (
//    timespan.endTimestamp >= startOfDay && timespan.endTimestamp <= endOfDay
  );
};

const useTable = (table, endpoint, schema, path, timespan, date) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isTableCleared, setIsTableCleared] = useState(false);
  const [fetchedDates, setFetchedDays] = useState({});
  const [manipulations, setManipulations] = useState(0);
  const currentDay = date && date.format('YYYY-MM-DD');

  useEffect(() => {
    let isCanceled = false;

    const onUpdate = async (update) => {
      if (isCanceled) {
        return;
      }

      const id = parseInt(update.id);

      switch (update.type) {
        case 'post':
          await table.put(update.data);
          break;
        case 'put':
          await table.put(update.data);
          break;
        case 'patch':
          const entry = await table.get(id);
          if (!!entry) await table.put(deepMerge(entry, update.data));
          break;
        case 'delete':
          await table.delete(id);
          break;
        default:
          return;
      }

      if (isCanceled) {
        return;
      }

      setManipulations((manipulations) => manipulations + 1);
    };

    table.clear().then(() => {
      if (isCanceled || !essentialsWsEndpoint) {
        return;
      }

      essentialsWsEndpoint.subscribe(path, onUpdate);
      setIsTableCleared(true);
    });

    return () => {
      isCanceled = true;

      if (essentialsWsEndpoint) {
        essentialsWsEndpoint.unsubscribe(path, onUpdate);
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isTableCleared) {
      return;
    }

    if (currentDay && fetchedDates[currentDay]) {
      return;
    }

    let isCanceled = false;

    Promise.resolve().then(async () => {
      const entries = await endpoint.getEntries({
        fields: schema,
        query: date && timespan && getTimespanFilterForDay(timespan, date),
      });

      if (isCanceled) {
        return;
      }

      if (currentDay) {
        setFetchedDays({ ...fetchedDates, [currentDay]: true });
      }

      await table.bulkAdd(entries.items);
      setManipulations((manipulations) => manipulations + 1);

      if (isCanceled) {
        return;
      }

      setIsInitialized(true);
    });

    return () => {
      isCanceled = true;
    };

    // eslint-disable-next-line
  }, [isTableCleared, date]);

  return [isInitialized, manipulations];
};

export const useRealtimeDispatcherDb = (date, organisationId) => {
  const [isOrdersInitialized, orderManipulations] = useTable(
    db.orders,
    ordersEndpoint,
    orderSchema,
    '/orders',
    'estimatedTimespan',
    date
  );
  const [isBreaksInitialized, breakManipulations] = useTable(
    db.breaks,
    breaksEndpoint,
    breakSchema,
    '/breaks',
    'timespan',
    date
  );
  const [isCarsInitialized, carManipulations] = useTable(db.cars, carsEndpoint, carSchema, '/cars');
  const [isDriversInitialized, driverManipulations] = useTable(
    db.drivers,
    driversEndpoint,
    driverSchema,
    '/drivers'
  );
  const [isFleetsInitialized, fleetManipulations] = useTable(
    db.fleets,
    fleetsEndpoint,
    fleetSchema,
    '/fleets'
  );

  return {
    isBreaksInitialized,
    isCarsInitialized,
    isDriversInitialized,
    isFleetsInitialized,
    isOrdersInitialized,
    useCars: () => {
      const [data, setData] = useState();

      function sortByCallback(data) {
        let carsWithDrivers = [];
        let carsWithoutDrivers = [];
        data.forEach((car) => {
          if (!!car.essentials_driver) {
            carsWithDrivers.push(car);
          } else {
            carsWithoutDrivers.push(car);
          }
        });
        setData(carsWithDrivers.concat(carsWithoutDrivers));
      }

      useEffect(() => {
        if (organisationId == '1587040271228801') {
          db.cars.toCollection().reverse().sortBy('manufacturer', sortByCallback);
        } else {
          db.cars.toCollection().sortBy('manufacturer', sortByCallback);
        }

        // eslint-disable-next-line
      }, [carManipulations]);

      return data;
    },
    useOrders: (date, status) => {
      const [data, setData] = useState();

      useEffect(() => {
        db.orders
          .filter(
            (order) => order.status === (status || 'new') && isTimespanOnDate(order.estimatedTimespan, date)
          )
          .filter( // TODO: extend owner filter to all tables
            (order) => order.owners === getOwner()
          )
          .sortBy('estimatedTimespan.startTimestamp', setData);

        // eslint-disable-next-line
      }, [date, orderManipulations]);

      return data;
    },
    useOrdersForCar: (carId, date) => {
      const [data, setData] = useState();

      useEffect(() => {
        db.orders
          .filter(
            (order) =>
              order.status !== 'deleted' &&
              order.status !== 'prenew' &&
              order.car &&
              order.car.id === carId &&
              isTimespanOnDate(order.estimatedTimespan, date)
          )
          .toArray(setData);

        // eslint-disable-next-line
      }, [date, carId, orderManipulations]);

      return data;
    },
    useBreaksForDriver: (driverId, date) => {
      const [data, setData] = useState();

      useEffect(() => {
        db.breaks
          .filter(
            (driverBreak) =>
              driverBreak.driver.id === driverId && isTimespanOnDate(driverBreak.timespan, date)
          )
          .toArray(setData);

        // eslint-disable-next-line
      }, [date, driverId, breakManipulations]);

      return data;
    },
    useDrivers: () => {
      const [data, setData] = useState();

      useEffect(() => {
        db.drivers.toArray(setData);

        // eslint-disable-next-line
      }, [driverManipulations]);

      return data;
    },
    useDriver: (id) => {
      const [data, setData] = useState();

      useEffect(() => {
        if (!id) {
          setData();
          return;
        }

        db.drivers.get(id, setData);

        // eslint-disable-next-line
      }, [id, driverManipulations]);

      return data;
    },
    useFleets: () => {
      const [data, setData] = useState();

      useEffect(() => {
        db.fleets.toArray(setData);

        // eslint-disable-next-line
      }, [fleetManipulations]);

      return data;
    },
  };
};
