import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect } from 'react';
import { BaseControl } from 'react-map-gl';
import { essentialsWsEndpoint } from '../../../Utils/API';
import { s } from '../../../Utils/Helpers';

export default ({ carId, initialData, onChange }) => {
  const entry = essentialsWsEndpoint.useLiveEntry('cars', carId, initialData);

  useEffect(() => {
    if (onChange) {
      onChange(entry);
    }

    // eslint-disable-next-line
  }, [entry]);

  if (!entry || !entry.lastPosition) {
    return null;
  }

  return (
    <MapMarkerCar
      position={entry.lastPosition}
      status={entry.status}
      driver={entry.essentials_driver}
      label={s(entry.manufacturer, entry.model, `(${entry.licensePlate})`)}
    />
  );
};

class MapMarkerCar extends BaseControl {
  state = {
    position: JSON.parse(this.props.position),
  };

  _deltaLongitude = 0;
  _deltaLatitude = 0;
  _framesPerTransition = 100;
  _currentFrame = 0;
  _isDisposed = false;

  componentDidUpdate(prevProps) {
    if (
      prevProps.position === this.props.position ||
      JSON.stringify(this.state.position) === this.props.position
    ) {
      return;
    }

    this._moveTo(JSON.parse(this.props.position));
  }

  componentWillUnmount() {
    this._isDisposed = true;
  }

  _moveTo = (destination) => {
    this._currentFrame = 0;
    this._deltaLongitude = (destination[0] - this.state.position[0]) / this._framesPerTransition;
    this._deltaLatitude = (destination[1] - this.state.position[1]) / this._framesPerTransition;
    requestAnimationFrame(this._transitionToTargetPosition);
  };

  _transitionToTargetPosition = () => {
    if (this._isDisposed || this._currentFrame >= this._framesPerTransition) {
      return;
    }

    this._currentFrame++;
    const newPosition = [...this.state.position];

    newPosition[0] += this._deltaLongitude;
    newPosition[1] += this._deltaLatitude;

    this.setState({ position: newPosition }, () =>
      requestAnimationFrame(this._transitionToTargetPosition)
    );
  };

  _render() {
    const { position } = this.state;
    const [x, y] = this._context.viewport.project(position);

    let style = styles.markerNoDriver;
    if (this.props.driver) {
      if (this.props.status === 'free') {
        style = styles.markerFree;
      } else if (this.props.status === 'occupied') {
        style = styles.markerOccupied;
      }
    }

    return (
      <div
        title={this.props.label}
        ref={this._containerRef}
        style={{ left: x, top: y }}
        className={css(styles.marker, style)}
      />
    );
  }
}

const glowKeyframes = {
  from: {
    transform: 'scale3d(1, 1, 1)',
    opacity: 0.4,
  },
  to: {
    transform: 'scale3d(2, 2, 1)',
    opacity: 0,
  },
};

const styles = StyleSheet.create({
  marker: {
    position: 'absolute',
    boxShadow: '0 6px 15px 2px rgba(44,62,80,0.25), 0 0 0 7px rgba(44,62,80,0.15)',
    width: 30,
    height: 30,
    marginLeft: -15,
    marginTop: -15,
    borderRadius: 15,
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 15,
      animationName: [glowKeyframes],
      animationDuration: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      transformOrigin: 'center',
    },
  },
  markerFree: {
    backgroundImage: 'linear-gradient(234deg, #00DEC3 0%, #00B990 100%)',
    zIndex: 3,
    ':before': {
      backgroundImage: 'linear-gradient(234deg, #00DEC3 0%, #00B990 100%)',
    },
  },
  markerOccupied: {
    backgroundImage: 'linear-gradient(232deg, #FF6C7C 33%, #FF3A46 100%)',
    zIndex: 2,
    ':before': {
      backgroundImage: 'linear-gradient(232deg, #FF6C7C 33%, #FF3A46 100%)',
    },
  },
  markerNoDriver: {
    backgroundColor: '#8B96A3',
    zIndex: 1,
    ':before': {
      backgroundColor: '#8B96A3',
    },
  },
});
