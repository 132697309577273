import { Theme, Button, InfoRow, PersonColumn, AvatarClientCompany, Dropdown, External, AvatarClientFemale, AvatarClientMale, AvatarClientUnknown } from '@yourmileag/ui-kit';
import React, { useContext, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { formatThousands } from '../../../Utils/Helpers';
import CustomerSidePanel from '../../Essentials/SidePanels/Customer';
import DriverSidePanel from '../../Essentials/SidePanels/Driver';
import { Card } from '.';

export default ({type, orders, drivers, customers, breaks}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [currentTab, setCurrentTab] = useState(0);

  let filteredData;

  // common sorting function
  function sortNumbers(data, prop) {
    return data.sort((a, b) => { return b[prop] - a[prop]});
  }

  // prepare to get data
  
  // data container
  let data = {};
  let tabsData = [];
  // max amount of items in each list
  let limit = 5;
  // template array to store which data properties should be shown
  let tabTemplates = [];
  // name array to store tab names
  let tabNames = [];
  // translation array to store unit translations
  let tabTranslations = [];

  if (type === 'customers') {
    tabTemplates = [
      ['orders'], 
      ['revenue']
    ];
    tabNames = [
      t('topTabOrders'), 
      t('topTabRevenue')
    ];
    tabTranslations = [
      [
        [t('topOrders'), 'right'],
      ],
      [
        [t('topRevenue'), 'left']
      ]
    ];

    if (customers && customers.length !== 0) {
      customers.map(function(customer) {
        data[customer.id] = {'id': customer.id, 'group': 'customer', 'name': (customer.firstName !== null ? customer.firstName + ' ' : '') + customer.lastName, 'type': customer.type, 'gender': customer.gender, 'orders': 0, 'revenue': 0};
      });
    }
  } else if (type === 'drivers') {
    tabTemplates = [
      ['orders', 'distance', 'breaks'], 
      ['revenue']
    ];
    tabNames = [
      t('topTabOrders'), 
      t('topTabRevenue')
    ];
    tabTranslations = [
      [
        [t('topOrders'), 'right'],
        [t('topDistance'), 'right'],
        [t('topBreaks'), 'right'],
      ],
      [
        [t('topRevenue'), 'left']
      ]
    ];

    if (drivers && drivers.length !== 0) {
      drivers.map(function(driver) {
        data[driver.id] = {'id': driver.id, 'group': 'driver', 'name': driver.firstName + ' ' + driver.lastName, 'type': '', 'gender': driver.gender, 'orders': 0, 'distance': 0, 'breaks': 0, 'revenue': 0};
      });
    }
  }

  // get data
  if (typeof orders !== 'undefined' && typeof breaks !== 'undefined' && Object.keys(data).length !== 0 && orders.length !== 0) {
    if (type === 'customers') {
      orders.forEach((order) => {
        if (typeof order.customer !== 'undefined' && order.customer !== null && typeof data[order.customer.id] !== 'undefined') {
          data[order.customer.id]['orders'] += 1;
          data[order.customer.id]['revenue'] += parseFloat(order.price);
        } 
      });

      filteredData = Object.values(data).filter(entry => entry['orders'] > 0);

      let sortedData = [
        sortNumbers(filteredData, 'orders').slice(0, limit),
        sortNumbers(filteredData, 'revenue').slice(0, limit),
      ];

      // set tab data
      tabsData = sortedData;
    } else if (type === 'drivers') {
      breaks.forEach((pause) => {
        if (data[pause.driver.id]) {
          data[pause.driver.id]['breaks']++;
        }
      });

      orders.forEach((order) => {
        if (typeof order.driver !== 'undefined' && order.driver !== null) {
          if (data[order.driver.id]) {
            data[order.driver.id]['orders'] += 1;
            data[order.driver.id]['revenue'] += parseFloat(order.price);
            data[order.driver.id]['distance'] += Math.round(order.estimatedDistance / 1000);
          }
        } 
      });
  
      filteredData = Object.values(data).filter(entry => entry['orders'] > 0);

      let sortedData = [
        sortNumbers(filteredData, 'orders').slice(0, limit),
        sortNumbers(filteredData, 'revenue').slice(0, limit),
      ];

      // set tab data
      tabsData = sortedData;
    }
  }

  const onExportPress = async () => {
    let dataCopy = JSON.parse(JSON.stringify(tabsData[currentTab]));

    dataCopy.forEach((row, index) => {
      delete row['group'];
      row['rank'] = index + 1;
    });

    const url = window.URL.createObjectURL(new Blob([dataCopy]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Top${t(type)}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // window.gtag('event', 'csv_export', {});
  };

  return (
    <Card title={`Top ${t(type)}`} subline={filteredData ? `${filteredData.length} Total` : null} cardHeight="auto" labelValue={
      <div className={css(styles.tabDropdown)}>
        {Object.keys(tabNames).length !== 0 && (<Dropdown
          options={[
            ...tabNames
          ]}
          clean
          size="s"
          value={tabNames[currentTab]}
          onSelect={(target) => setCurrentTab(tabNames.indexOf(target))}
          visibleOptionsLength={4}
        />)}
      </div>
    }>
      <div className={css(styles.TopX)}>
        <div className={css(styles.TopXTabs)}>
            <div className={css(styles.TopXTab)}>
              {tabsData.length !== 0 && typeof tabsData[currentTab] !== 'undefined' && (tabsData[currentTab].map((row, rowIndex) => (
                <InfoRow
                  key={String(currentTab) + String(rowIndex)}
                  center={true}
                  label={String(rowIndex + 1)}
                  required={false}
                >
                  <PersonColumn 
                    name={row['name']} 
                    phone={
                      String(tabTemplates[currentTab].map((key, keyIndex) => (
                        (tabTranslations[currentTab][keyIndex][1] === 'left' ? tabTranslations[currentTab][keyIndex][0] + formatThousands(row[key])  : formatThousands(row[key]) + tabTranslations[currentTab][keyIndex][0]) + ((keyIndex !== (tabTemplates[currentTab].length - 1)) ? ' / ' : '')
                      )).join(''))
                    }
                    linkTo={`/analytics/${row['group']}/${row['id']}`}
                    avatar={ 
                      (row['type'] === 'company') ? <AvatarClientCompany fontSize="55"/> : (row['gender'] === 'male' ? <AvatarClientMale fontSize="55"/> : (row['gender'] === 'female' ? <AvatarClientFemale fontSize="55"/> : <AvatarClientUnknown fontSize="55"/>))
                    }
                  />
                </InfoRow>
              )))}
            </div>
        </div>
        {false && (
          <Button
            size="m"
            icon={ <External/> }
            onPress={onExportPress}
          >
            Export
          </Button>
        )}
      </div>
      <Switch>
        <Route path={`/analytics/customer/:id`} component={CustomerSidePanel} />
        <Route path={`/analytics/driver/:id`} component={DriverSidePanel} />
      </Switch>
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    TopX: {
      width: '100%',
      marginTop: -15
    },
    TopXTabs: {
      marginBottom: 15
    },
    tabDropdown: {
      width: 175
    },
  });
