import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { employeesEndpoint } from '../../../../../Utils/API';
import Cars from './Cars';
import Revenue from './Revenue';
import Orders from './Orders';
import Video from './Video';

export const Card = forwardRef(({ children, title, lable, lableValue, hideContent = false, cardHeight = 300 }, ref) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme, cardHeight);

  return (
    <div className={css(styles.card)}>
      <div className={css(styles.cardTitle, (hideContent && styles.hideTitle))}>
        <div className={css(styles.title)}>{title}</div>
        <div className={css(styles.info)}>
          <div className={css(styles.infoLable)}>{lable}</div>
          <div className={css(styles.infoValue)}>{lableValue}</div>
        </div>
      </div>
      <div className={css(styles.cardContent, (hideContent && styles.hideContent))} ref={ref}>
        {children}
      </div>
    </div>
  );
});

const cacheKey = 'cache:dashboard';

const uncacheStates = (key) => {
  const uncachedState = JSON.parse(localStorage.getItem(cacheKey));
  return uncachedState[key];
};

const cacheStates = (state) => {
  const states = state;
  localStorage.setItem(cacheKey, JSON.stringify(states));
};

const getCachedOrDefaultStates = (key, defaultState) => {
  let cachedState = localStorage.getItem(cacheKey);
  
  return cachedState !== null ? uncacheStates(key) : defaultState;
};

export default () => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [identityId] = useGlobal('identityId');
  const [employee] = employeesEndpoint.useEntry(identityId);
  const [showVideo, setShowVideo] = useState(getCachedOrDefaultStates('showVideo', true));

  useEffect(() => {
    cacheStates({'showVideo': showVideo});
  }, [showVideo]);

  return (
    <div className={css(styles.kpi)}>
      <div className={css(styles.cardRow)}>
        <Video showVideo={showVideo} setShowVideo={setShowVideo}/>
      </div>
      <div className={css(styles.cardRow)}>
        <Revenue />
      </div>
      <div className={css(styles.cardRow)}>
        <Orders />
      </div>
      {/* <div className={css(styles.cardRow)}>
        <Cars />
      </div> */}
    </div>
  );
};

const getThemedStyles = (theme, cardHeight) =>
  StyleSheet.create({
    welcome: {
      fontWeight: 'lighter',
      fontSize: 24,
      letterSpacing: 0.12,
      color: theme.color3,
      margin: 30,
    },
    kpi: {
      overflow: 'auto',
      flex: 1,
      minWidth: 0,
      paddingBottom: 30,
    },
    cardRow: {
      display: 'flex',
      minWidth: 0,
      marginTop: 30,
      paddingRight: 30,
    },
    card: {
      marginLeft: 30,
      flex: 1,
      minWidth: 0,
      backgroundColor: theme.color2,
      borderRadius: 5,
      boxShadow: theme.shadow2,
      padding: 25,
    },
    cardTitle: {
      fontWeight: 500,
      fontSize: 14,
      borderBottom: `1px solid ${theme.color12}`,
      paddingBottom: 20,
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
    },
    title: {
      flex: 0.5,
    },
    info: {
      justifyContent: 'flex-end',
      flex: 0.5,
      display: 'flex',
    },
    infoLable: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: 12,
      fontWeight: 300,
      color: theme.color1,
    },
    infoValue: {
      fontSize: 14,
      fontWeight: 300,
      color: theme.color3,
      paddingLeft: 10,
      justifyContent: '',
    },
    cardContent: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: cardHeight,
      fontWeight: 'lighter',
    },
    hideTitle: {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none',
    },
    hideContent: {
      height: 0
    },
  });