import { FleetColumn, InfoRow, SectionHeader, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fleetsEndpoint } from '../../../../Utils/API';
import { getFleetIcon } from '../../../../Utils/Fleet';
import { getRelationStringAsIdArray } from '../../../../Utils/Helpers';
import InventoryDetail from '../../Components/InventoryDetail';

const { useEntries: useFleets } = fleetsEndpoint;

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const fleetIds = getRelationStringAsIdArray(entry.essentials_fleets);
  const [fleets] = useFleets({ ids: fleetIds, skipUpdate: !fleetIds.length });

  return (
    <InventoryDetail entry={entry} history={history} match={match}>
      <InfoRow label={t('manufacturer')}>{entry.manufacturer}</InfoRow>
      <InfoRow label={t('model')}>{entry.model}</InfoRow>
      <InfoRow label={t('licensePlate')}>{entry.licensePlate}</InfoRow>
      <div className={css(styles.fleets)}>
        <SectionHeader>{t('fleets')}</SectionHeader>
        <div className={css(styles.fleets)}>
          {entry.essentials_fleets !== null &&
            fleets &&
            fleets.map((fleet) => (
              <div key={fleet.id} className={css(styles.fleet)}>
                <FleetColumn car={fleet.label} icon={getFleetIcon(fleet.icon)} />
              </div>
            ))}
        </div>
      </div>
    </InventoryDetail>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    fleets: {
      marginTop: 30,
    },
    fleet: {
      display: 'inline-block',
      width: 'calc(50% - 15px)',
      marginRight: 15,
      padding: 20,
      borderRadius: 3,
      marginBottom: 15,
      border: `solid ${theme.color16} 1px`,
    },
  });
