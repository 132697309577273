import { AvatarDriverMale, AvatarDriverFemale, AvatarDriverUnknown } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { driversEndpoint } from '../../../../Utils/API';
import { s } from '../../../../Utils/Helpers';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';
import History from './History';

const { useEntry: useDriver } = driversEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={useDriver}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      History={History}
      renderHeader={(driver) => {
        if (!driver) {
          return;
        }

        let icon;
        switch (driver.gender) {
          case 'male':
            icon = <AvatarDriverMale />;
            break;
          case 'female':
            icon = <AvatarDriverFemale />;
            break;
          default:
            icon = <AvatarDriverUnknown />;
        }

        return (
          <SidePanelHeader
            icon={icon}
            label={t('driver')}
            title={s(driver.lastName, driver.firstName)}
          />
        );
      }}
    />
  );
};
