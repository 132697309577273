import { Phone, Button, Plus, TextInputAutoComplete, Theme, Checkmark, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import { AsYouType, parse, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { customersEndpoint } from '../../../../Utils/API';
import { join, toDbPhone, toHumanPhone } from '../../../../Utils/Helpers';
import { getGenderIcon } from '../../../../Utils/Gender';
import { defaultCustomer } from '../../SidePanels/Customer/CreateOrUpdate';
import Tag from './Tag';
import { useSettings } from '../../../Settings';

const { useEntries: useCustomers, useEntry: useCustomer, getEntry: getCustomer } = customersEndpoint;

export default ({ id, onChange, onDelete, history, match, location, errors, defaultCustomerID, hideButton, passengerAmount, onPassengerAmountChange }) => {
  const { t } = useTranslation();

  const [settings, setSettings] = useSettings();

  const [userInput, setUserInput] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [customerId, setCustomerId] = useState(defaultCustomerID);
  const [customer] = useCustomer(customerId);
  const [customers] = useCustomers({ limit: 5000 });
  const [showNewCustomerMessage, setShowNewCustomerMessage] = useState(false);
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const [shouldRenderSuggestions, setShouldRenderSuggestions] = useState(true);

  const parsedPhoneNumber = userInput && parsePhoneNumberFromString(userInput, 'CH');
  const isPhoneNumberValid = parsedPhoneNumber && parsedPhoneNumber.isValid();

  useEffect(() => {
    if (customerId && customer) {
      // onChange(customer);
    }
  }, [customerId, customer, onChange]);

  useEffect(() => {
    if (!history) {
      return;
    }
    const matchCustomer = matchPath(history.location.pathname, {
      path: `${match.path}/:id`,
    });
    setCustomer(matchCustomer ? matchCustomer.params.id : customerId);
    // onChange(customer);
  }, [history, match, customerId]);

  useEffect(() => {
    if (!location.search) {
      return;
    }
    if (!customerId) {
      const searchParams = new URLSearchParams(location.search);
      setUserInput(searchParams.get('phone') || (customer && customer.phone) || userInput);
      setSuggestions(filterSuggestions());
      onChange(customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, customers]);

  useEffect(() => {
    setSuggestions(filterSuggestions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput]);

  useEffect(() => {
    if (isNew) {
      const mergedCustomer = { ...defaultCustomer, phone: toDbPhone(userInput) || userInput };
      onChange(mergedCustomer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, userInput]);

  const setCustomer = async (customerId) => {
    setCustomerId(customerId);
    if (customerId) {
      const customer = await getCustomer(customerId);
      onChange(customer);
    }
  };

  const onChangeUserInput = (e) => {
    return setUserInput(e.target.value || '');
  };

  const onNewCustomerPress = () => {
    setShouldRenderSuggestions(false);
    setIsNew(true);
    setCustomer(null);
    setUserInput(customer ? customer.phone : userInput || '');
    history.push(match.url);
  };

  const onNewCustomerButtonMouseEnter = () => setShowNewCustomerMessage(true);
  const onNewCustomerButtonMouseLeave = () => setShowNewCustomerMessage(false);

  const NewCustomerButton = () => {
    const disabled = !isPhoneNumberValid && !customer;

    if (!isNew && !customerId) {
      return (
        <div className={css(styles.newCustomerButton)} onMouseEnter={onNewCustomerButtonMouseEnter} onMouseLeave={onNewCustomerButtonMouseLeave}>
          <Button variant="special" size="m" icon={<Plus />} onPress={onNewCustomerPress} disabled={disabled}>
            {t('newCustomer')}
          </Button>
          {(showNewCustomerMessage && disabled) && (
            <div className={css(styles.newCustomerMessage)}>
              <div className={css(styles.newCustomerMessageLabel)}>{t('pleaseEnterFullPhoneNumber')}</div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const filterSuggestions = () => {
    let filteredSuggestions = [];
    if (customers) {
      filteredSuggestions = customers.filter((customer) => {
        const inputValue = userInput ? userInput.toLowerCase().replace(/ +/g, ' ').trim() : '';
        const inputLength = inputValue.length;

        if (inputLength >= 1) {
          const asYouType = new AsYouType('CH');
          asYouType.input(userInput);
          const parsedPhoneNumber = asYouType.getNumber();

          if (parsedPhoneNumber && customer.phone.includes(parsedPhoneNumber.number)) {
            return customer;
          }
        }

        const fullName = customer.firstName + ' ' + customer.lastName;
        const fullNameReversed = customer.lastName + ' ' + customer.firstName;

        if (
          inputLength >= 1 &&
          ((customer.firstName && customer.firstName.toLowerCase().includes(inputValue)) ||
            (customer.lastName && customer.lastName.toLowerCase().includes(inputValue)) ||
            (fullName && fullName.toLowerCase().includes(inputValue)) ||
            (fullNameReversed && fullNameReversed.toLowerCase().includes(inputValue)) ||
            (customer.phone && customer.phone.toLowerCase().includes(inputValue)) ||
            (customer.mailAddress && customer.mailAddress.toLowerCase().includes(inputValue)))
        ) {
          return customer;
        }
        return null;
      });
    }
    return filteredSuggestions || [];
  };

  const onUserInputDelete = () => {
    setCustomer(null);
    setUserInput('');
    onDelete && onDelete();
    if (history) {
      history.push(match.url);
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setCustomer(suggestion.id);
    setIsNew(false);
//    onChange(suggestion);
    if (history) {
      history.push(join(match.url, suggestion.id));
    }
  };

  return (
    <>
      <div className={css(styles.userInput)}>
        {customer ? (
          <div className={css(styles.userInputContent)}>
            <Tag
              onDelete={onUserInputDelete}
              firstName={customer.firstName}
              lastName={customer.lastName}
              phone={customer.phone}
              RightComponent={hideButton ? null : NewCustomerButton}
              iconLeft={<Checkmark className={css(styles.checkmark)} />}
            />
          </div>
        ) : (
          suggestions && (
            <div className={css(styles.userInputContent)}>
              <TextInputAutoComplete
                inputProps={{
                  autoFocus: true,
                  id: 'oc-phone-number-' + id,
                  autocomplete: 'off',
                  label: t('phoneNumberNameOrEmail'),
                  iconLeft: <Phone />,
                  RightComponent: hideButton ? null : NewCustomerButton,
                  onKeyDown: (event) => {
                    setShowNewCustomerMessage(false);
                    if (event.keyCode === 13) {
                      if (isPhoneNumberValid) {
                        onNewCustomerPress();
                      } else {
                        setShowNewCustomerMessage(true);
                      }
                    }
                  },
                  errorMessage: (customer || isNew) && errors.phone,
                }}
                suggestions={suggestions}
                visibleSuggestionsLength={4}
                value={userInput || ''}
                onChange={onChangeUserInput}
                highlightFirstSuggestion={false}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={() => {}}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => (
                  <div className={css(styles.suggestion)}>
                    <div className={css(styles.suggestionIconLeft)}>
                      {getGenderIcon(suggestion.gender)}
                    </div>
                    <div className={css(styles.suggestionLabel)}>
                      {suggestion.firstName} {suggestion.lastName}
                    </div>
                    <div className={css(styles.suggestionLabel, styles.suggestionPhone)}>
                      {toHumanPhone(suggestion.phone)}
                    </div>
                    <div className={css(styles.suggestionLabel, styles.suggestionPhone)}>
                      {suggestion.mailAddress}
                    </div>
                  </div>
                )}
                shouldRenderSuggestions={() => shouldRenderSuggestions}
                theme={{ suggestionHighlighted: css(styles.suggestionHighlighted) }}
              />
            </div>
          )
        )}
        {(isNew || customerId) && settings.canUsePassengerAmount && (
          <div className={css(styles.userInputExtra)}>
            <TextInput
              name={'passenger_amount'}
              label={t('passengerAmount')}
              value={passengerAmount}
              onChange={(e) => onPassengerAmountChange(e.target.value.replace(/[^\d]/,''))}
              onBlur={() => {
                if (!passengerAmount || parseInt(passengerAmount) <= 0) {
                  onPassengerAmountChange(1);
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    userInput: {
      display: 'flex',
      flex: 1,
      marginRight: 13,
      marginBottom: 13,
    },
    userInputContent: {
      flex: 1,
    },
    userInputExtra: {
      width: 220,
      marginLeft: 13,
    },
    customerContainer: {
      marginBottom: 26,
    },
    customer: {
      display: 'inline-block',
      verticalAlign: 'top',
      width: '100%',
      maxWidth: (1000 - 3 * 13) / 3,
      marginRight: 13,
      marginBottom: 13,
    },
    newCustomerButton: {
      marginLeft: 15,
      marginRight: 14,
    },
    suggestion: {
      cursor: 'pointer',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.color9,
      fontSize: 14,
      fontWeight: 'lighter',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '14px 0',
    },
    suggestionIconLeft: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 30,
      paddingLeft: 15,
    },
    suggestionLabel: {
      flex: 1,
      padding: '0 15px',
      fontWeight: 'lighter',
      fontSize: 16,
      letterSpacing: 0.2,
    },
    suggestionPhone: {
      color: theme.color1,
    },
    suggestionHighlighted: {
      backgroundColor: 'red',
    },
    checkmark: {
      fontSize: 20,
      width: 20,
      height: 20,
      borderRadius: '50%',
      color: theme.color2,
      backgroundImage: theme.gradient3,
      marginRight: 18,
    },
    newCustomerMessage: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.1,
      position: 'relative',
      marginRight: 20,
      cursor: 'default',
    },
    newCustomerMessageLabel: {
      position: 'absolute',
      zIndex: 1,
      backgroundImage: 'linear-gradient(180deg, #01cbff, #00a7e7)',
      color: theme.color2,
      boxShadow: theme.shadow2,
      borderRadius: 5,
      width: 200,
      padding: 10,
      top: 20,
      right: -20,
      width: 245,
      ':after': {
        bottom: '100%',
        right: 20,
        border: 'solid transparent',
        content: '" "',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderBottomColor: '#01cbff',
        borderWidth: 10,
        marginLeft: -10,
        backgroundColor: 'transparent',
      },
    },
  });
