import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { css, StyleSheet } from 'aphrodite/no-important';
import { PoiEmpty, Theme } from '@yourmileag/ui-kit';
import { poisEndpoint } from '../../../../Utils/API';
import InventoryScreen from '../../Components/InventoryScreen';
import POISidePanel from '../../SidePanels/POI';

const { useEntries: usePOIs } = poisEndpoint;

export default ({ match, history }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const columns = [
    { title: t('name'), orderKey: 'name', searchKeys: ['name'], width: 220 },
    { title: t('address'), orderKey: 'source', searchKeys: ['source'], flex: 1 },
  ];

  const rowMap = (poi) => ({
    id: poi.id,
    columns: [poi.name, poi.source],
  });

  const emptyContainer = () => (
    <div className={css(styles.noOrders)}>
      <PoiEmpty className={css(styles.noOrdersIcon)} />
      <div className={css(styles.noPOITitle)}>{t('noPOIAddedTitle')}</div>
      <div className={css(styles.noPOIDescription)}>{t('noPOIAddedDescription')}</div>
    </div>
  );

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={POISidePanel}
      useData={usePOIs}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchPOI')}
      addButtonLabel={t('addPOI')}
      emptyContainer={emptyContainer}
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    noOrders: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#E7EAEC',
    },
    noOrdersIcon: {
      fontSize: 60,
      color: '#b1b7bc',
      marginBottom: 50,
    },
    noPOITitle: {
      fontSize: 26,
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#8a96a2',
      marginBottom: 20,
    },
    noPOIDescription: {
      fontSize: 20,
      color: '#b1b7bc',
      textAlign: 'center',
      width: 566,
      lineHeight: '1.5',
    },
  });
