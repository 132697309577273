import React from 'react';
import { useTranslation } from 'react-i18next';
import { fleetsEndpoint } from '../../../../Utils/API';
import { getFleetIconAvatar } from '../../../../Utils/FleetAvatar';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import Cars from './Cars';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';

const { useEntry: useFleet } = fleetsEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();
  const isCreateRoute = !match.params.id;

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={useFleet}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      additionalNavItems={
        !isCreateRoute && [{ title: t('cars'), path: `${match.url}/cars`, component: Cars }]
      }
      renderHeader={(fleet) =>
        fleet && (
          <SidePanelHeader
            icon={getFleetIconAvatar(fleet.icon)}
            label={t('fleet')}
            title={fleet.label}
          />
        )
      }
    />
  );
};
