import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme, Crossmark } from '@yourmileag/ui-kit';
import React from 'react';
import { toHumanPhone } from '../../../../Utils/Helpers';

const Tag = ({ iconLeft, firstName, lastName, phone, onDelete, RightComponent }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        {iconLeft}
        <div className={css(styles.name)}>
          {firstName} {lastName}
        </div>
        <div className={css(styles.phone)}>{toHumanPhone(phone)}</div>
        <Crossmark className={css(styles.icon)} onClick={onDelete} />
      </div>
      {RightComponent && <RightComponent />}
    </div>
  );
};

Tag.propTypes = {};

Tag.defaultProps = {};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      background: theme.color2,
      boxShadow: theme.shadow1,
      border: '1px solid transparent',
      borderRadius: 5,
      cursor: 'text',
      alignItems: 'center',
      height: 60,
    },

    containerInner: {
      display: 'flex',
      flex: 1,
      borderRadius: 5,
      background: '#e5f5fc',
      height: 46,
      marginLeft: 7,
      marginRight: 7,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 18,
      paddingRight: 18,
    },
    icon: {
      cursor: 'pointer',
    },
    name: {
      fontWeight: 300,
      marginRight: 18,
    },
    phone: {
      fontWeight: 300,
      color: '#00A7E7',
      flex: 1,
    },
  });

export default Tag;
