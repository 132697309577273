import { getOwner } from '@yourmileag/essentials-api-client';
import { createSnackbar, InfoRow, TextInput, Theme } from '@yourmileag/ui-kit';
import { StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import Lightbox from 'react-datatrans-light-box';
import { useTranslation } from 'react-i18next';
import { modulesEndpoint, subscriptionsEndpoint } from '../../../Utils/API';
import Content from '../Components/Content';
import CostOverview from './Components/CostOverview';
import Module from './Components/Module';
import TimeLine from './Components/TimeLine';
import { useChangeableSubscription } from './Effects/ChangeableSubscription';
import { useSubscriptionPaymentHandler } from './Effects/SubscriptionPayment';
import { getChanges, lightboxConfig } from './Helpers';

export default ({ history, match }) => {
  let protocol = null;
  const [availableModules] = modulesEndpoint.useEntries({
    query: 'sis:true',
  });
  const [subscriptions] = subscriptionsEndpoint.useEntries({
    query: `owners:${getOwner()}`,
    limit: 1,
  });

  const subscription = subscriptions && subscriptions[0];

  let subscriptionModules;
  if (availableModules && availableModules.length && subscription) {
    subscriptionModules = {};

    availableModules.forEach(
      (module) => (subscriptionModules[module._id] = subscription.modules.includes(module._id))
    );
  }

  const costOverviewNew = useRef();
  const costOverviewInstant = useRef();
  const costOverviewCurrent = useRef();

  const [isPaymentOngoing, setIsPaymentOngoing] = useState(false);
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const [apiBase] = useGlobal('apiBase');

  if (process.env.REACT_APP_BUILD_LOCAL === 'true') {
    protocol = 'http';
  } else {
    protocol = 'https';
  }

  let lightboxConfigUpdate = {
    ...lightboxConfig,
    successUrl: `${protocol}://${apiBase}/saas/payment/success`,
    cancelUrl: `${protocol}://${apiBase}/saas/payment/cancel`,
    errorUrl: `${protocol}://${apiBase}/saas/payment/error`,
  };

  const [
    carLicenses,
    setCarLicenses,
    selectedModules,
    setSelectedModules,
  ] = useChangeableSubscription(subscription, subscriptionModules);
  useSubscriptionPaymentHandler(subscription, history, match, t);

  if (carLicenses === undefined || selectedModules === undefined) {
    return null;
  }

  const isSubmitDisabled = !carLicenses || carLicenses === '0' || isNaN(carLicenses);

  const onChangeCarLicenses = (e) => setCarLicenses(e.target.value);
  const onChangeModule = (module) => () =>
    setSelectedModules({ ...selectedModules, [module._id]: !selectedModules[module._id] });

  const selectedModuleIds = Object.keys(selectedModules).filter((a) => selectedModules[a]);

  const onPaymentCancelled = () => setIsPaymentOngoing(false);

  const onPaymentError = (e) => {
    console.error(e);
    createSnackbar('error', 'Error');
    setIsPaymentOngoing(false);
  };

  const getTotalPrice = () => {
    if (costOverviewInstant.current) {
      return costOverviewInstant.current.getTotalPrice();
    }

    if (costOverviewNew.current) {
      return costOverviewNew.current.getTotalPrice();
    }

    return costOverviewCurrent.current.getTotalPrice();
  };

  const { add, remove } = getChanges(
    subscription.licences,
    subscriptionModules,
    carLicenses,
    selectedModules
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    await subscriptionsEndpoint.updateEntry(subscription._id, {
      changes: { add: add || null, remove: remove || null },
    });

    if (subscription.status === 'demo') {
      setIsPaymentOngoing(true);
    } else {
      createSnackbar('success', t('successfullySaved', { name: t('license') }));
    }
  };

  const submitLabel = subscription.status === 'demo' ? t('confirmAndProceedToPayment') : t('save');
  let subscriptionEnd = moment.unix(subscription.end);
  let newSubscriptionStart = moment.unix(subscription.end).add('1', 'day');
  if (subscription.status === 'demo') {
    newSubscriptionStart = moment();
  }

  return (
    <Content
      title={t('license')}
      submitLabel={submitLabel}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
    >
      <InfoRow center label={t('cars')} required>
        <TextInput
          id="car-licenses"
          type="number"
          min="1"
          max="24"
          value={carLicenses}
          onChange={onChangeCarLicenses}
          required
        />
      </InfoRow>
      <InfoRow label={t('modules')}>
        {availableModules.map((module) => (
          <Module
            key={module._id}
            module={module}
            checked={selectedModules[module._id]}
            onChange={onChangeModule(module)}
          />
        ))}
      </InfoRow>
      <InfoRow label={t('current')}>
        <CostOverview
          ref={costOverviewCurrent}
          availableModules={availableModules}
          carLicenses={subscription.licences}
          moduleIds={subscription.modules}
          vat={subscription.vat}
        />
      </InfoRow>
      {carLicenses > 0 && (
        <>
          {add && subscription.status !== 'demo' && (
            <InfoRow
              label={t('instantChangesUntil', {
                date: subscriptionEnd.format(t('longDateFormat')),
              })}
            >
              <CostOverview
                style={styles.costOverviewAdd}
                ref={costOverviewInstant}
                currentSubscription={subscription}
                availableModules={availableModules}
                carLicenses={add.licences}
                moduleIds={add.modules}
                vat={subscription.vat}
              />
            </InfoRow>
          )}
          {(add || remove) && (
            <InfoRow
              label={t('newStartingFrom', {
                date: newSubscriptionStart.format(t('longDateFormat')),
              })}
            >
              <CostOverview
                style={styles.costOverviewNew}
                ref={costOverviewNew}
                availableModules={availableModules}
                carLicenses={carLicenses}
                moduleIds={selectedModuleIds}
                vat={subscription.vat}
              />
            </InfoRow>
          )}
        </>
      )}
      <InfoRow label={t('period')} center>
        <TimeLine subscription={subscription} />
      </InfoRow>
      {isPaymentOngoing && (
        <Lightbox
          {...lightboxConfigUpdate}
          refno="GFL"
          themeConfiguration={{ brandColor: theme.color5 }}
          amount={(getTotalPrice() * 100).toString()}
          onCancelled={onPaymentCancelled}
          onError={onPaymentError}
        />
      )}
    </Content>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    costOverviewAdd: {
      color: theme.colorError,
    },
    costOverviewNew: {
      color: theme.color5,
    },
  });
