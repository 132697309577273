import { Button, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Title from './Title';

export default ({
  title,
  onSubmit,
  isSubmitDisabled,
  children,
  id,
  submitLabel,
  submitSize,
  noPadding,
  style,
}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <form id={id} onSubmit={onSubmit} className={css(style)}>
      <Title style={styles.title}>{title}</Title>
      <div className={css(styles.content, noPadding && styles.contentNoPadding)}>{children}</div>
      {onSubmit && (
        <div className={css(styles.footer)}>
          <Button
            disabled={isSubmitDisabled}
            size={submitSize}
            variant="special"
            type="submit"
            onPress={onSubmit}
          >
            {submitLabel || t('save')}
          </Button>
        </div>
      )}
    </form>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    title: {
      padding: '30px 30px 0 30px',
    },
    content: {
      flex: 1,
      padding: 30,
    },
    contentNoPadding: {
      padding: 0,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 30px 30px',
    },
  });
