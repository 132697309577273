import { ButtonPlain, Calendar, Crossmark, DateInput, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { carsEndpoint } from '../../../Utils/API';
import DriverBusy from './DriverBusy';
import {
  getRelationStringAsIdArray,
  s,
  useEveryMinute,
  verticalScrollStyles,
} from '../../../Utils/Helpers';
import { blockHeight, TimelineBlockHeader } from '../Components/Timeline';
import Title from '../Components/Title';
import CarTimeline, { getFullTimespanForOrder, getSlotDimensions, isOrderOngoing } from './CarTimeline';

const { updateEntry: updateCar } = carsEndpoint;

export const hours = Array.from({ length: 24 }).map((v, index) => index);

const CarInfo = ({ car, selectedDriver, fleets, date, focus, useDriver, useOrdersForCar }) => {
  const { t } = useTranslation();
  const driver = useDriver(car.essentials_driver);
  const [{ isHovering }, drop] = useDrop({
    accept: 'driver',
    collect: monitor => ({ isHovering: monitor.isOver() }),
    drop: async item => {
      if (driver) {
        return;
      }

      await updateCar(car.id, { essentials_driver: item.id });
    },
  });

  const [showBusy, setShowBusy] = useState(false);
  const orders = useOrdersForCar(car.id, date);

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const name = driver && s(driver.lastName, driver.firstName);
  const onRemoveDriver = async () => {
    if (orders.find((order) => {
      return isOrderOngoing(order) || order.status === 'assigning';
    })) {
      setShowBusy(true);
    } else {
      updateCar(car.id, { essentials_driver: null });
    }
  };

  const fleetMap = new Map([...fleets.map(fleet => [fleet.id, fleet])]);

  const fleetLabels = getRelationStringAsIdArray(car.essentials_fleets).map(
    id => fleetMap.get(id).label
  );

  return (
    <div key={car.id} className={css(styles.name, focus && styles.nameDropZoneFocus)} ref={drop}>
      <div
        className={css(
          styles.nameContainer,
          selectedDriver && !driver && styles.driverDropZone,
          isHovering && styles.driverDropZoneHover
        )}
      >
        <div className={css(styles.nameLeft)}>
          <div className={css(styles.nameLeftCar)}>{`${car.manufacturer} ${car.model}`}</div>
          {driver && (
            <div className={css(styles.driver)}>
              <div className={css(styles.driverName)}>{name}</div>
              <ButtonPlain onClick={onRemoveDriver} styles={styles.driverRemoveButton}>
                <Crossmark />
              </ButtonPlain>
            </div>
          )}
        </div>
        <div className={css(styles.nameRight)}>
          <div className={css(styles.nameRightFleets)} title={fleetLabels.join(', ')}>
            {fleetLabels.length === 1 ? fleetLabels[0] : `${fleetLabels.length} ${t('fleets')}`}
          </div>
          <div className={css(styles.nameRightLicensePlate)}>{car.licensePlate}</div>
        </div>
      </div>
      {showBusy && <DriverBusy closeModal={() => setShowBusy(false)} />}
    </div>
  );
};

export default ({
  selectedOrder,
  selectedDriver,
  onSelect,
  history,
  date,
  onChangeDate,
  useCars,
  useOrdersForCar,
  useBreaksForDriver,
  useDriver,
  useFleets,
}) => {
  const cars = useCars();
  const fleets = useFleets();
  const timelines = useRef();
  const [currTimeIndicatorPos, setCurrTimeIndicatorPos] = useState(null);
  const [currTime, setCurrTime] = useState('');
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  const [carDropZoneFocus, setCarDropZoneFocus] = useState(null);

  const scrollTo = newScrollPosition => {
    if (timelines.current.scrollTo) {
      timelines.current.scrollTo({
        top: 0,
        left: newScrollPosition - 300,
        behavior: 'smooth',
      });
    } else {
      timelines.current.scrollLeft = newScrollPosition - 300;
    }
  };

  useEffect(() => {
    if (!timelines.current || !selectedOrder) {
      return;
    }

    scrollTo(getSlotDimensions(getFullTimespanForOrder(selectedOrder)).left);
  }, [selectedOrder]);

  useEffect(() => {
    if (!currTimeIndicatorPos || !timelines.current) {
      return;
    }

    if (timelines.current.scrollLeft > 0) {
      return;
    }

    scrollTo(currTimeIndicatorPos);
  }, [timelines.current]);

  const onDateChange = () => {
    const now = moment();
    const isToday = now.isSame(date, 'day');

    if (!isToday) {
      setCurrTimeIndicatorPos(null);
      return;
    }

    const currentTimeDimensions = getSlotDimensions({ startTimestamp: now.unix() });
    setCurrTimeIndicatorPos(currentTimeDimensions.left);
    setCurrTime(now.format(t('timeFormat')));
  };

  useEveryMinute(onDateChange);
  useEffect(onDateChange, [date]);

  const isFleetMatching = car =>
    !selectedOrder ||
    selectedOrder.status !== 'new' ||
    getRelationStringAsIdArray(car.essentials_fleets).includes(selectedOrder.fleet.id);

  const isOutsideRange = () => false;

  return (
    <div id="dispatcher-cars-table" className={css(styles.table)}>
      <div className={css(styles.names)}>
        <Title style={styles.namesTitle}>{t('cars')}</Title>
        {cars &&
          fleets &&
          cars.map(car => (
            <CarInfo
              key={car.id}
              car={car}
              useOrdersForCar={useOrdersForCar}
              focus={car.id === carDropZoneFocus}
              fleets={fleets}
              date={date}
              selectedDriver={selectedDriver}
              useDriver={useDriver}
            />
          ))}
      </div>
      <div className={css(styles.timelinesContainer)}>
        <div className={css(styles.header)}>
          <Title style={styles.timelinesTitle}>
            {t('dispatchForDate', { date: date.format(t('pronouncedDateFormat')) })}
          </Title>
          <div id="oc-date-wrapper">
            <DateInput
              className={css(styles.timelinesDatePicker)}
              anchorDirection="right"
              openDirection="down"
              id="oc-date"
              iconLeft={<Calendar />}
              value={date}
              onChange={onChangeDate}
              isOutsideRange={isOutsideRange}
              readOnly
            />
          </div>
        </div>
        <div ref={timelines} className={css(styles.timelines)}>
          <div className={css(styles.timelinesHeader)}>
            <div className={css(styles.timelinesHeader)}>
              {hours.map(hour => (
                <TimelineBlockHeader key={hour}>{hour}:00</TimelineBlockHeader>
              ))}
            </div>
          </div>
          {cars &&
            fleets &&
            cars.map(car => (
              <CarTimeline
                key={car.id}
                car={car}
                selectedOrder={selectedOrder}
                isFleetMatching={isFleetMatching(car)}
                onSelect={onSelect}
                history={history}
                date={date}
                onChangeDate={onChangeDate}
                useOrdersForCar={useOrdersForCar}
                useBreaksForDriver={useBreaksForDriver}
                onDropZoneHover={(isHovering) => setCarDropZoneFocus(isHovering ? car.id : null)}
              />
            ))}
          {currTimeIndicatorPos && moment().isSame(date, 'day') && (
            <div className={css(styles.currTimeIndicator)} style={{ left: currTimeIndicatorPos }}>
              <div className={css(styles.currTimeIndicatorValue)}>{currTime}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    table: {
      flex: 1,
      minWidth: 0,
      position: 'absolute',
      top: 0,
      left: 390,
      right: 0,
      bottom: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    names: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 280,
      zIndex: 1,
      padding: '0 0 0 20px',
      borderRight: '1px solid rgba(44, 62, 80, 0.1)',
    },
    namesTitle: {
      marginBottom: 100,
    },
    timelinesContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 280,
      overflowX: 'hidden',
    },
    header: {
      padding: '30px 20px 5px 20px',
      display: 'flex',
      marginBottom: 11,
    },
    timelinesTitle: {
      margin: 0,
      flex: 1,
    },
    timelinesDatePicker: {
      height: '40px !important',
    },
    timelines: {
      ...verticalScrollStyles,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 180,
    },
    timelinesHeader: {
      display: 'flex',
    },
    currTimeIndicator: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 4,
      backgroundImage: theme.gradient3,
    },
    currTimeIndicatorValue: {
      width: 42,
      height: 19,
      marginLeft: -19,
      backgroundImage: theme.gradient3,
      color: theme.color2,
      padding: 3,
      borderRadius: 3,
      textAlign: 'center',
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: '13px',
      letterSpacing: 0.78,
    },
    name: {
      position: 'relative',
      backgroundColor: theme.color13,
      height: blockHeight,
      borderTop: '1px solid rgba(44, 62, 80, 0.1)',
      borderLeft: '5px solid',
      borderLeftColor: theme.color13,
      padding: '4px 0',
      transition: 'background-color 150ms, border-color 150ms',
    },
    nameDropZoneFocus: {
      backgroundColor: '#FFFFFF',
      borderLeftColor: '#59C5EE',
    },
    nameContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      border: '3px solid transparent',
      padding: '5px 15px',
    },
    nameLeft: {
      flex: 1,
    },
    nameLeftCar: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.44,
    },
    nameRight: {
      marginLeft: 6,
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 'lighter',
    },
    nameRightFleets: {
      backgroundColor: theme.color12,
      padding: '2px 5px',
      borderRadius: 5,
      color: theme.color3,
    },
    nameRightLicensePlate: {
      color: theme.color1,
      marginTop: 6,
      textAlign: 'right',
    },
    nameHeader: {
      height: 'auto',
    },
    driver: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginTop: 6,
    },
    driverName: {
      fontWeight: 'lighter',
      fontSize: 12,
      color: theme.color1,
    },
    driverRemoveButton: {
      backgroundColor: '#D5D9DC',
      color: theme.color2,
      borderRadius: '50%',
      width: 12,
      height: 12,
      fontSize: 12,
      lineHeight: '12px',
      marginLeft: 6,
    },
    driverDropZone: {
      backgroundColor: '#A8E0F5',
      border: '3px dotted #59C5EE',
      borderRadius: 5,
    },
    driverDropZoneHover: {
      borderStyle: 'solid',
    },
  });
