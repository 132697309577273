import { Button, Theme } from '@yourmileag/ui-kit';
import React, { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import { Card } from '.';
import ReactPlayer from 'react-player';

export default ({showVideo, setShowVideo}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <Card title={t('Intro')} cardHeight="100%" hideContent={!showVideo} lable={
      <Button
        onPress={() => {(showVideo === true ? setShowVideo(false) : setShowVideo(true))}}
      >
        {(showVideo === true ? t('dashboardVideoButtonHide') : t('dashboardVideoButtonShow'))}
      </Button>
    }>
      {showVideo && (
        <ResponsiveContainer width="100%" height="100%">
          <div className={css(styles.playerWrapper)}>
            <ReactPlayer
              config={{
                youtube: {
                  playerVars: { rel: 0, showinfo: 0, ecver: 2, modestbranding: 1},
                },
              }}
              className={css(styles.reactPlayer)}
              url="https://youtu.be/-2lpYCD-qfQ"
              width="100%"
              height="100%"
              controls
            />
          </div>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    playerWrapper: {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
    },
    reactPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
    }
});