import React from 'react';
import { Marker } from 'react-map-gl';
import { css, StyleSheet } from 'aphrodite/no-important';

const defaultProps = Object.assign({}, Marker.defaultProps, {
  offsetLeft: -50,
  offsetTop: -35,
});

export default class MapMarker extends Marker {
  static defaultProps = defaultProps;

  _render() {
    const { draggable, children } = this.props;
    const { dragPos } = this.state;

    const [x, y] = this._getPosition();

    const containerStyle = {
      position: 'absolute',
      left: x,
      top: y,
      cursor: draggable ? (dragPos ? 'grabbing' : 'grab') : 'auto',
    };

    return (
      <div className={css(styles.marker)} ref={this._containerRef} style={containerStyle}>
        {children}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  marker: {
    position: 'absolute',
    background: '#fff',
    width: 100,
    height: 20,
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: 9,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 0.6,
    zIndex: 1,
    borderRadius: 3,
    ':after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: -15,
      marginLeft: -5,
      width: 10,
      height: 10,
      borderRadius: '50%',
      border: '2px solid black',
    },
  },
});
