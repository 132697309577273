import { Theme, Female, InfoRow, Male } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { s, toHumanPhone } from '../../../Utils/Helpers';
import InventoryDetail from './InventoryDetail';

export default ({ person, history, match, isIdentifiedByMailAddress }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  let genderIcon = t('unknown');

  if (person.gender === 'male') {
    genderIcon = (
      <div className={css(styles.genderIcon)}>
        <Male height={'1.5em'} width={'1.5em'} /> {t('male')}
      </div>
    );
  }

  if (person.gender === 'female') {
    genderIcon = (
      <div className={css(styles.genderIcon)}>
        <Female height={'1.5em'} width={'1.5em'} /> {t('female')}
      </div>
    );
  }

  return (
    <InventoryDetail entry={person} history={history} match={match}>
      <InfoRow label={t('name')}>{s(person.lastName, person.firstName)}</InfoRow>
      <InfoRow label={t('gender')}>{genderIcon}</InfoRow>
      <InfoRow label={t('phone')}>{toHumanPhone(person.phone)}</InfoRow>
      {isIdentifiedByMailAddress && (
        <InfoRow label={t('mail')}>{s(person.mailAddress) || '-'}</InfoRow>
      )}
      <InfoRow label={t('status')}>{t(person.status)}</InfoRow>
      <InfoRow label={t('address')}>
        {person.street ? (
          <>
            <div>{s(person.street)}</div>
            <div>{`${s(person.zipCode)} ${s(person.city)}`}</div>
          </>
        ) : (
          '-'
        )}
      </InfoRow>
    </InventoryDetail>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    genderIcon: {
      display: 'flex',
      alignItems: 'center'
    },
  });