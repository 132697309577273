import React, { useState } from 'react';

import QRCode from '../Modules/Essentials/Screens/QRCode';

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const RenderModal = ({ text }) => {
    return <React.Fragment>{isVisible && <QRCode text={text} closeModal={hide} />}</React.Fragment>;
  };
  return {
    show,
    hide,
    RenderModal,
  };
};

export default useModal;
