import { PersonColumn } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { join, s, toHumanPhone } from '../../../Utils/Helpers';

export default ({ customer, isLinkDisabled, basePath, ...rest }) => {
  const { t } = useTranslation();
  let link;

  if (!isLinkDisabled && customer) {
    if (basePath) {
      link = join(basePath, customer.id);
    } else {
      link = `/essentials/inventory/customers/0/${customer.id}`;
    }
  }

  return (
    <PersonColumn
      {...rest}
      name={customer ? s(customer.lastName, customer.firstName) : t('anonymousPassenger')}
      phone={customer && toHumanPhone(customer.phone)}
      linkTo={link}
    />
  );
};
