import { Crossmark, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { bugsnagClient } from '../../../bugsnag';
import { useEffect } from 'react';

export default ({ error }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  let title;
  let desc;
  let isUnhandled = false;

  if (error.status === 401) {
    title = t('unauthorizedAccess');
    desc = t('unauthorizedAccessDesc');
  } else if (error.status === 403) {
    title = t('unauthorizedAccess');
    desc = t('unauthorizedAccessDesc');
  } else {
    desc = t('unexpectedError');
    isUnhandled = true;
  }

  useEffect(() => {
    if (isUnhandled) {
      bugsnagClient.notify('Unhandled network request error', { metaData: error });
    }
  }, [error, isUnhandled]);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        <Crossmark className={css(styles.icon)} />
        {title && <div className={css(styles.title)}>{title}</div>}
        <div className={css(styles.desc)}>{desc}</div>
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      color: theme.color1,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '1.2em',
    },
    containerInner: {
      flex: 1,
      textAlign: 'center',
      maxWidth: 400,
    },
    icon: {
      fontSize: 60,
    },
    title: {
      fontSize: 20,
      marginBottom: 20,
    },
    desc: {
      fontWeight: 'lighter',
    },
  });
