import React from 'react';
import { Route } from 'react-router-dom';
import { isIOS } from 'react-device-detect';

export default () => {
  let url;

  if (isIOS) {
    url = 'https://apps.apple.com/us/app/go-fleet-driver/id1495296646';
  } else {
    url = 'https://play.google.com/store/apps/details?id=com.gofleet.driver';
  }

  return (
    <>
      <Route
        path="/detectos"
        component={() => {
          global.window && (global.window.location.href = url);
          return null;
        }}
      />
    </>
  );
};
