import { css, StyleSheet } from 'aphrodite/no-important';
import { Cash } from '@yourmileag/ui-kit';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { purchasesEndpoint } from '../../../Utils/API';
import { join } from '../../../Utils/Helpers';
import PaginatedTable from '../../Essentials/Components/PaginatedTable';
import Content from '../Components/Content';

export default ({ match, history }) => {
  const { t } = useTranslation();

  const basePath = match.url;

  const columns = [
    { title: t('numeroSign'), orderKey: '_id', width: 100 },
    { title: t('date'), orderKey: 'createAt', width: 140 },
    { title: t('paymentReason'), orderKey: 'type', flex: 1 },
    { title: t('price'), orderKey: 'totalPrice', width: 170 },
  ];

  const rowMap = (purchase) => ({
    id: purchase._id,
    columns: [
      `...${purchase._id.toString().slice(-5)}`,
      moment(purchase.createAt).format(t('longDateFormat')),
      t(purchase.type),
      purchase.totalPriceVat,
    ],
  });

  const [orderBy, setOrderBy] = useState(`createAt:desc`);
  const [data, setData] = useState();
  const useDataAdditionalOptions = { orderBy };

  const emptyContainer = () => (
    <div className={css(styles.noCash)}>
      <Cash className={css(styles.noCashIcon)} />
      <div>{t('noDataAvailable')}</div>
    </div>
  );

  return (
    <Content style={styles.container} title={t('invoice')} noPadding>
      <PaginatedTable
        match={match}
        history={history}
        useData={purchasesEndpoint.useEntries}
        useDataAdditionalOptions={useDataAdditionalOptions}
        columns={columns}
        rows={data ? data.map(rowMap) : []}
        onChange={setData}
        rowLinkBasePath={join(basePath)}
        orderBy={orderBy}
        onChangeOrderBy={setOrderBy}
        useRowLinks
        emptyContainer={emptyContainer}
      />
    </Content>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 800,
  },
  noCashIcon: {
    fontSize: 60,
  },
  noCash: {
    marginTop: 50,
    color: '#E7EAEC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
