import { TextInput, Theme, Search as SearchIcon, Unknown as UnknownIcon } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxLabel from '../CheckBoxLabel';
import CheckBoxWithLabel from '../CheckBoxWithLabel';

export default ({ filter, onChangeValues }) => {
  const { attribute, dateSourceOptions } = filter;
  const { useHook } = dateSourceOptions;

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const selected = {};
  filter.values.forEach((value) => (selected[value.value] = value.label));

  const [renderedSelected] = useState({ ...selected });
  const renderedSelectedValues = Object.keys(renderedSelected);

  let query = '';
  if (searchValue) {
    query = `${attribute}:${searchValue}`;
  }

  if (renderedSelectedValues.length > 0) {
    if (query) {
      query += ';';
    }

    query += `[ne]${attribute}:${renderedSelectedValues.join(',')}`;
  }

  const [data] = useHook({
    query,
    skipUpdate: !searchValue,
    fields: attribute,
    limit: 20,
    group: attribute,
  });

  const available = {};
  data && data.forEach((entry) => (available[entry[attribute]] = entry[attribute]));

  const onChange = (id) => () => {
    const newValues = { ...selected };

    if (newValues[id]) {
      delete newValues[id];
    } else {
      newValues[id] = available[id];
    }

    onChangeValues(
      Object.keys(newValues).map((id) => ({
        label: newValues[id],
        value: id,
      }))
    );
  };

  const onChangeSearchValue = (e) => setSearchValue(e.target.value);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.searchInput)}>
        <TextInput
          id="is-search"
          autoComplete="off"
          type="search"
          label={t('search')}
          placeholder={filter.placeholder || t('search')}
          value={searchValue}
          onChange={onChangeSearchValue}
          clean
          size="s"
          icon={<SearchIcon />}
        />
      </div>
      {Object.keys(available).length === 0 && renderedSelectedValues.length === 0 && searchValue && (
        <div className={css(styles.noResultsContainer)}>
          <UnknownIcon className={css(styles.noResultsIcon)} />
          <span className={css(styles.noResultsLabel)}>{t('noResultsFound')}</span>
        </div>
      )}
      {renderedSelectedValues.map((value) => (
        <CheckBoxWithLabel
          key={value}
          id={value.toString()}
          checked={!!selected[value]}
          onChange={onChange(value)}
          clean
        >
          <CheckBoxLabel>{value}</CheckBoxLabel>
        </CheckBoxWithLabel>
      ))}
      {Object.keys(available).map((id) => (
        <CheckBoxWithLabel
          key={id}
          id={id.toString()}
          checked={!!selected[id]}
          onChange={onChange(id)}
          clean
        >
          <CheckBoxLabel>{available[id]}</CheckBoxLabel>
        </CheckBoxWithLabel>
      ))}
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    searchInput: {
      marginBottom: 20,
    },
    noResultsContainer: {
      height: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    noResultsIcon: {
      width: 70,
      height: 70,
      opacity: 0.4,
      color: theme.color1,
    },
    noResultsLabel: {
      color: theme.color1,
      fontSize: 14,
    },
  });
