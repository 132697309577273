import {
  ArrowRight,
  Button,
  ButtonAdd,
  Cash,
  Calendar,
  Clock,
  OrderCreation,
  Cronjobs,
  DateInput,
  FleetDropdown,
  Message,
  PersonSpeechLeft,
  PersonSpeechRight,
  PriceFix,
  RadioButton,
  TextInput,
  Theme,
  Route,
  CalendarCompare,
  Mail,
  Start,
  Target,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fleetsEndpoint } from '../../../../../Utils/API';
import { s } from '../../../../../Utils/Helpers';
import { getCalculatedPrice } from '../../../../../Utils/Price';
import GenderSelection from '../../../Components/GenderSelection';
import RouteSection from './Route';
import TextInputAddress from '../../../Components/TextInputAddress';
import CustomerSelection from '../CustomerSelection';

const { useEntries: useFleets } = fleetsEndpoint;

export default ({
  onSubmit,
  settings,
  customer,
  passengerAmount,
  extraCustomers,
  route,
  origin,
  stopovers,
  destination,
  time,
  date,
  endTime,
  endDate,
  type,
  fleet,
  pricing,
  price,
  timing,
  briefing,
  onChangeCustomer,
  onChangeExtraCustomers,
  onChangeRoute,
  onChangeOrigin,
  onChangeStopovers,
  onChangeDestination,
  onChangeTime,
  onChangeDate,
  onChangeEndTime,
  onChangeEndDate,
  onChangeType,
  onChangeFleet,
  onChangePricing,
  onChangePrice,
  onChangeTiming,
  onChangeBriefing,
  errors,
}) => {
  const { t } = useTranslation();
  const isSaveDisabled = Object.keys(errors).length > 0;
  const [isMailFieldVisible, setMailFieldVisibility] = useState(false);
  const [fleetId, setFleetId] = useState();
  const [isCustomerDetailVisible, setIsCustomerDetailVisible] = useState(true);
  const [fleetValue, setFleetValue] = useState();
  const [fleetSuggestions, setFleetSuggestions] = useState([]);
  const [fleets] = useFleets();
  const cars = null;
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const recommendedPrice = route && fleet ? getCalculatedPrice(route, fleet) : 0;

  useEffect(() => {
    if (fleet) {
      setFleetValue(fleet);
    }
  }, [fleet]);

  useEffect(() => {
    if (!fleets || !fleetValue) {
      return;
    }

    const newFleet = fleets.find((f) => f.id === fleetValue.id);
    onChangeFleet(newFleet);
  }, [fleetId, fleets, onChangeFleet, fleetValue]);

  useEffect(() => {
    if (pricing !== 'fixed' || price > 0) {
      return;
    }

    onFormatPrice(recommendedPrice);

    // eslint-disable-next-line
  }, [pricing, recommendedPrice, onChangePrice]);

  useEffect(() => {
    if (!fleets) {
      return;
    }

    setFleetId(fleet ? fleet.id : fleets[0].id);

    // eslint-disable-next-line
  }, [fleets]);

  useEffect(() => {
    if (customer.id) {
      setIsCustomerDetailVisible(false);
      return;
    }

    setIsCustomerDetailVisible(true);
  }, [customer.id]);

  useEffect(() => {
    if (fleets) {
      setFleetSuggestions(fleets);
    }
  }, [fleets]);

//   useEffect(() => {
//     const timer = setInterval(() => tick(), 1000);
// 
//     return () => {
//       clearInterval(timer);
//     };
//     // eslint-disable-next-line
//   }, [time, date]);

//   const tick = () => {
//     if (!moment(date).isAfter(moment()) && moment(time, t('timeFormat')).isBefore(moment())) {
//       onChangeTime(moment().format(t('timeFormat')));
//     }
//   };

  const onChange = (set) => (e) => set(e.target.value);
  const onChangeCustomerAttr = (attribute) => (e) =>
    onChangeCustomer({ ...customer, [attribute]: e.target.value });
  const onChangeDirect = (set) => (e) => set(e);
  const onHideCustomerDetailPress = () => setIsCustomerDetailVisible(false);

  const onFormatPrice = (price) => {
    let value = price;

    if (!isNaN(value)) {
      value = value ? parseFloat(value, 10).toFixed(2) : '';
    }

    onChangePrice(value);
  };

  const onApplyRecommendedPrice = () => onFormatPrice(recommendedPrice);

  const onBlurPrice = (e) => onFormatPrice(e.target.value);

  const getFixedPricingLabel = () => {
    const baseLabel = t('fixedPricing');
    let suffix = '';

    let recommendationString = '';
    let priceString = '';

    if (route && fleet) {
      recommendationString = getCalculatedPrice(route, fleet).toFixed(2).replace('.00', '.-');
      suffix = `(${t('recommendation')}: ${fleet.rateCurrency} ${recommendationString})`;
    }

    if (price) {
      let priceString = parseFloat(price).toFixed(2).replace('.00', '.-');
      if (priceString !== recommendationString) {
        suffix = `(${fleet.rateCurrency} ${priceString})`;
      }
    }
    
    return s(baseLabel + ' ' + suffix);
  };

  const addExtraCustomers = () => {
    let id = Math.floor(Math.random() * 1000);
    onChangeExtraCustomers([...extraCustomers, {
      id: id,
      passengerAmount: 1,
    }]);
  };

  const onExtraCustomerChange = (index) => (customer) => {
    const newExtraCustomers = [...extraCustomers];
    Object.assign(newExtraCustomers[index], {
      ...customer,
      passengerAmount: customer.passengerAmount ? customer.passengerAmount : 1,
    });
    onChangeExtraCustomers(newExtraCustomers);
  };

  const onExtraCustomerPassengerAmountChange = (index) => (passengerAmount) => {
    const newExtraCustomers = [...extraCustomers];
    Object.assign(newExtraCustomers[index], {
      passengerAmount: passengerAmount,
    });
    onChangeExtraCustomers(newExtraCustomers);
  };

  const onExtraCustomerDelete = (index) => () => {
    const newExtraCustomers = [...extraCustomers];
    newExtraCustomers.splice(index, 1);
    onChangeExtraCustomers(newExtraCustomers);
  };

  let totalPassengerAmount = 0;
  if (passengerAmount) {
    totalPassengerAmount += parseInt(passengerAmount);
  }
  if (extraCustomers) {
    extraCustomers.forEach((customer) => {
      if (customer.passengerAmount) {
        totalPassengerAmount += parseInt(customer.passengerAmount);
      }
    });
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={isCustomerDetailVisible ? css(styles.set) : null}>
        {isCustomerDetailVisible ? (
          <>
            <div className={css(styles.row)}>
              <GenderSelection
                id="oc-gender"
                disabled={!!customer.id}
                selected={customer.gender}
                onChange={onChangeCustomerAttr('gender')}
              />
            </div>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <TextInput
                  id="oc-last-name"
                  iconLeft={<PersonSpeechRight />}
                  label={t('lastName')}
                  value={customer.lastName || ''}
                  onChange={onChangeCustomerAttr('lastName')}
                  disabled={!!customer.id}
                  errorMessage={errors.lastName}
                  required
                />
              </div>
              <div className={css(styles.column)}>
                <TextInput
                  id="oc-first-name"
                  iconLeft={<PersonSpeechLeft />}
                  label={t('firstName')}
                  value={customer.firstName || ''}
                  onChange={onChangeCustomerAttr('firstName')}
                  disabled={!!customer.id}
                />
              </div>
            </div>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                {isMailFieldVisible || customer.mailAddress ? (
                  <TextInput
                    type="email"
                    id="oc-email"
                    iconLeft={<Mail />}
                    label={t('mailAddress')}
                    value={customer.mailAddress || ''}
                    onChange={onChangeCustomerAttr('mailAddress')}
                    disabled={!!customer.id}
                    errorMessage={errors.mailAddress}
                  />
                ) : (
                  !customer.id && (
                    <ButtonAdd onPress={setMailFieldVisibility} tabIndex="-1">{t('addMailAddress')}</ButtonAdd>
                  )
                )}
              </div>
            </div>
            {customer.id && (
              <div className={css(styles.row)}>
                <div className={css(styles.column)}>
                  <ButtonAdd onPress={onHideCustomerDetailPress} tabIndex="-1">{t('hideCustomerDetail')}</ButtonAdd>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {false && (
              <div className={css(styles.row)}>
                <div className={css(styles.column)}>
                  <ButtonAdd onPress={setIsCustomerDetailVisible} tabIndex="-1">{t('addCustomerDetail')}</ButtonAdd>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={css(styles.set)}>
        {extraCustomers.map((customer, index) => {
          return (
            <CustomerSelection
              id={customer.id}
              defaultCustomerID={customer.id}
              location={{}}
              passengerAmount={customer.passengerAmount}
              onPassengerAmountChange={onExtraCustomerPassengerAmountChange(index)}
              onChange={onExtraCustomerChange(index)}
              onDelete={onExtraCustomerDelete(index)}
              hideButton={true}
            />
          );
        })}
        {settings.canAddExtraCustomers && (
          <div className={css(styles.row)}>
            <div className={css(styles.column)}>
              <ButtonAdd onPress={addExtraCustomers} tabIndex="-1">{t('addCustomer')}</ButtonAdd>
            </div>
            {extraCustomers.length >= 1 && (
              <div className={css(styles.columnExtra)}>
                <div className={css(styles.passengerAmountTotal)}>
                  {totalPassengerAmount}
                  <span className={css(styles.passengerAmountTotalLabel)}>
                    {t('passengerAmountTotal')}
                  </span>
                </div>  
              </div>
            )}
          </div>
        )}
      </div>
      {settings.canUseDayTrip && (
        <div className={css(styles.set)}>
          <div className={css(styles.row)}>
            <div className={css(styles.column)}>
              <RadioButton
                id="oc-type-one-way"
                name="type"
                iconLeft={<Route />}
                label={'One-way'}
                value={'one-way'}
                selected={type}
                onChange={onChange(onChangeType)}
              />
            </div>
            <div className={css(styles.column)}>
              <RadioButton
                id="oc-type-day-trip"
                name="type"
                iconLeft={<CalendarCompare />}
                label={'Day trip'}
                value={'day-trip'}
                selected={type}
                onChange={onChange(onChangeType)}
              />
            </div>
          </div>
        </div>
      )}
      {type === 'day-trip' ? (
        <>
          <div className={css(styles.set)}>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <TextInputAddress
                  id="oc-origin-address"
                  iconLeft={
                    <Start/>
                  }
                  label={t('origin')}
                  onSelect={onChangeOrigin}
                  initialValue={origin && origin.name}
                  required
                  errorMessage={errors.route && !origin ? errors.route : null}
                />
              </div>
            </div>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <TextInput
                  type="time"
                  id="oc-time"
                  iconLeft={<Clock />}
                  label={t('time')}
                  value={time}
                  onChange={onChange(onChangeTime)}
                  required
                  errorMessage={errors.time}
                />
              </div>
              <div className={css(styles.column)}>
                <DateInput
                  anchorDirection="right"
                  openDirection="down"
                  id="oc-date"
                  iconLeft={<Calendar />}
                  label={t('date')}
                  value={date}
                  onChange={onChangeDirect(onChangeDate)}
                  required
                  errorMessage={errors.date}
                />
              </div>
            </div>
          </div>
          <div className={css(styles.set)}>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <TextInputAddress
                  id="oc-destination-address"
                  iconLeft={
                    <Target/>
                  }
                  label={t('destination')}
                  onSelect={onChangeDestination}
                  initialValue={destination && destination.name}
                  required
                  errorMessage={errors.route && !destination ? errors.route : null}
                />
              </div>
            </div>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <TextInput
                  type="time"
                  id="oc-end-time"
                  iconLeft={<Clock />}
                  label={t('endTime')}
                  value={endTime}
                  onChange={onChange(onChangeEndTime)}
                  required
                  errorMessage={errors.endTime}
                />
              </div>
              <div className={css(styles.column)}>
                <DateInput
                  anchorDirection="right"
                  openDirection="down"
                  id="oc-date"
                  iconLeft={<Calendar />}
                  label={t('endDate')}
                  value={endDate}
                  onChange={onChangeDirect(onChangeEndDate)}
                  required
                  errorMessage={errors.endDate}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <RouteSection
            route={route}
            onChangeRoute={onChangeRoute}
            origin={origin}
            onChangeOrigin={onChangeOrigin}
            stopovers={stopovers}
            onChangeStopovers={onChangeStopovers}
            destination={destination}
            onChangeDestination={onChangeDestination}
            autoFocus={!!customer.id && !origin}
            errorMessage={errors.route}
          />
          <div className={css(styles.set)}>
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <RadioButton
                  id="oc-timing-now"
                  name="timing"
                  iconLeft={<OrderCreation />}
                  label={t('orderTypeNow')}
                  value={'now'}
                  selected={timing}
                  onChange={onChange(onChangeTiming)}
                />
              </div>
              <div className={css(styles.column)}>
                <RadioButton
                  id="oc-timing-preorder"
                  name="timing"
                  iconLeft={<Cronjobs />}
                  label={t('orderTypePreorder')}
                  value={'preorder'}
                  selected={timing}
                  onChange={onChange(onChangeTiming)}
                />
              </div>
            </div>
            {timing === 'preorder' && (
              <div className={css(styles.row)}>
                <div className={css(styles.column)}>
                  <TextInput
                    type="time"
                    id="oc-time"
                    iconLeft={<Clock />}
                    label={t('time')}
                    value={time}
                    onChange={onChange(onChangeTime)}
                    required
                    errorMessage={errors.time}
                  />
                </div>
                <div className={css(styles.column)}>
                  <DateInput
                    anchorDirection="right"
                    openDirection="down"
                    id="oc-date"
                    iconLeft={<Calendar />}
                    label={t('date')}
                    value={date}
                    onChange={onChangeDirect(onChangeDate)}
                    required
                    errorMessage={errors.date}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className={css(styles.set)}>
        <div className={css(styles.row)}>
          <div className={css(styles.column)}>
            {fleets && fleetId && fleets.length > 0 && (
              <FleetDropdown
                value={fleetValue}
                setValue={setFleetValue}
                options={fleetSuggestions}
                errorMessage={errors.fleet}
                label={t('preferredFleet')}
                required
              />
            )}
          </div>
        </div>
      </div>
      {!settings.disablePricing && (
        <div className={css(styles.set)}>
          <div className={css(styles.row)}>
            <div className={css(styles.column)}>
              <RadioButton
                id="oc-pricing-taxometer"
                name="payment-method"
                iconLeft={<Cash />}
                label={t('taxometer')}
                value={'taxometer'}
                selected={pricing}
                onChange={onChange(onChangePricing)}
              />
            </div>
            <div className={css(styles.column)}>
              <RadioButton
                id="oc-pricing-fixed"
                name="pricing"
                iconLeft={<PriceFix />}
                label={getFixedPricingLabel()}
                value={'fixed'}
                selected={pricing}
                onChange={onChange(onChangePricing)}
              />
            </div>
          </div>
          {pricing === 'fixed' && fleet && (
            <div className={css(styles.row)}>
              <div className={css(styles.column)}>
                <div className={css(styles.priceContainer)}>
                  <div className={css(styles.priceContainerColumnFirst)}>
                    {fleet && route ? (
                      <>
                        <div className={css(styles.priceContainerLabel)}>
                          {t('recommendedPriceIsBasedOnCalculation')}
                        </div>
                        <Button variant="dark" size="s" onPress={onApplyRecommendedPrice}>
                          {t('applyPrice', {
                            price: `${fleet.rateCurrency} ${recommendedPrice.toFixed(2)}`,
                          })}
                        </Button>
                      </>
                    ) : (
                      <div className={css(styles.priceContainerLabel)}>
                        {t('provideInfoForEstimatedPrice')}
                      </div>
                    )}
                  </div>
                  <div className={css(styles.priceContainerRateCurrency)}>
                    <TextInput
                      id="oc-price"
                      label={t('priceInCurrency', { currency: fleet.rateCurrency })}
                      value={price || ''}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          event.preventDefault();
                          event.target.blur();
                        }
                      }}
                      onChange={onChange(onChangePrice)}
                      onBlur={onBlurPrice}
                      required
                      errorMessage={errors.price}
                      clean
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={css(styles.row)}>
        <div className={css(styles.column)}>
          <TextInput
            type="textarea"
            id="oc-briefing"
            iconLeft={<Message />}
            label={t('driverBriefing')}
            value={briefing}
            onChange={onChange(onChangeBriefing)}
          />
        </div>
      </div>
      <div className={css(styles.row, styles.footer)}>
        <div className={css(styles.column)}>
          {cars && (
            <div className={css(styles.availability)}>
              <div className={css(styles.availabilityContainer)}>
                <div className={css(styles.availabilityTitle)}>{t('available')}</div>
                <div className={css(styles.availabilityValue)}>{`${cars.length} ${t('cars')}`}</div>
              </div>
              <div className={css(styles.availabilityContainer, styles.availabilityContainerLast)}>
                <div className={css(styles.availabilityTitle)}>{t('total')}</div>
                <div className={css(styles.availabilityValue)}>{`${cars.length} ${t('cars')}`}</div>
              </div>
            </div>
          )}
        </div>
        <Button
          disabled={isSaveDisabled}
          variant="special"
          size="xl"
          type="submit"
          icon={<ArrowRight />}
          className={css(styles.goButton)}
        >
          GO!
        </Button>
      </div>
    </form>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    set: {
      position: 'relative',
      marginBottom: 50,
    },
    row: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      marginBottom: 13,
    },
    column: {
      flex: 1,
      marginRight: 13,
    },
    columnExtra: {
      width: 220,
      marginRight: 13,
    },
    passengerAmountTotal: {
      fontSize: 16,
      paddingLeft: 15,
      paddingBottom: 8,
      borderBottom: '2px solid #E0E2E5',
    },
    passengerAmountTotalLabel: {
      color: theme.color3,
      fontSize: 12,
      marginLeft: 12,
      fontWeight: 'lighter',
    },
    title: {
      fontSize: 16,
      color: '#8B97A3',
      fontWeight: 'lighter',
      marginBottom: 30,
    },
    footer: {
      marginTop: 30,
    },
    availability: {
      display: 'inline-flex',
      border: '1px solid rgba(44, 62, 80, 0.25)',
      borderRadius: 5,
      padding: 6,
    },
    availabilityContainer: {
      padding: '10px 20px',
      borderRight: '1px solid rgba(44, 62, 80, 0.25)',
    },
    availabilityContainerLast: {
      borderRight: 'none',
    },
    availabilityTitle: {
      fontSize: 10,
      fontWeight: 'lighter',
      letterSpacing: 0.32,
      color: theme.color1,
      marginBottom: 5,
    },
    availabilityValue: {
      fontSize: 14,
      fontWeight: 'lighter',
      letterSpacing: 0.17,
      color: theme.color3,
    },
    priceContainer: {
      display: 'flex',
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
      borderRadius: 5,
      padding: 20,
      position: 'relative',
      ':after': {
        backgroundColor: 'transparent',
        bottom: '100%',
        right: 30,
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        border: 'solid transparent',
        borderColor: 'rgba(255, 255, 255, 0)',
        borderBottomColor: theme.color2,
        borderWidth: 10,
        marginRight: -10,
      },
    },
    priceContainerColumnFirst: {
      flex: 1,
      marginRight: 20,
    },
    priceContainerRateCurrency: {
      width: 200,
    },
    priceContainerLabel: {
      color: theme.color1,
      fontWeight: 'lighter',
      fontSize: 14,
      lineHeight: '16px',
      marginBottom: 10,
    },
    goButton: {
      marginRight: 13,
    },
  });
