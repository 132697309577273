import { ContextMenu, Crossmark, Plus } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ordersEndpoint } from '../../../Utils/API';

const { updateEntry: updateOrder } = ordersEndpoint;

export default ({ order, history }) => {
  const { t } = useTranslation();

  const onRemoveAssignment = async () =>
    updateOrder(order._id, {
      car: null,
      status: order.statusMessage ? 'problem' : 'new',
    });

  const onMoreInformation = () => history.push(`/essentials/orders/overview/0/${order._id}`);

  return (
    <ContextMenu
      buttons={[
        {
          icon: <Plus />,
          label: t('moreInformation'),
          onPress: onMoreInformation,
        },
        {
          icon: <Crossmark />,
          label: t('removeAssignment'),
          onPress: onRemoveAssignment,
          isDisabled: ['assigning', 'assigned'].indexOf(order.status) === -1,
        },
      ]}
    />
  );
};
