import { ButtonPlain, Button, Crossmark, Theme, Filter as FilterIcon } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { verticalScrollStyles } from '../../../../Utils/Helpers';
import Date from './Date';
import Person from './Person';
import Status from './Status';
import Text from './Text';

const filterComponents = new Map([
  ['status', Status],
  ['person', Person],
  ['text', Text],
  ['date', Date],
  ['start_date', Date],
  ['end_date', Date],
]);

const FilterButton = ({ filter, onChangeValues }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [isExpanded, setExpanded] = useState(false);
  const container = useRef();

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    const listener = (e) => !container.current.contains(e.target) && onToggle();

    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };

    // eslint-disable-next-line
  }, [isExpanded]);

  const onToggle = () => setExpanded(!isExpanded);
  const onResetPress = () => onChangeValues([]);

  const FilterComponent = filterComponents.get(filter.type);
  const hasActiveFilters = filter.values.length > 0;
  // const filterValueLabels =
  //   hasActiveFilters && filter.values.map((value) => value.label).join(', ');

  return (
    <div ref={container} className={css(styles.container)}>
      <div
        className={css(
          styles.buttonContainer,
          filter.values.length > 0 && styles.buttonContainerActive
        )}
      >
        <ButtonPlain
          styles={hasActiveFilters ? styles.toggleButtonWithFilter : styles.toggleButton}
          onClick={onToggle}
        >
          <div
            className={css(
              styles.buttonLabel,
              !hasActiveFilters && styles.buttonLabelWithoutFilters
            )}
          >
            {filter.label}
          </div>
        </ButtonPlain>
        {hasActiveFilters && (
          <ButtonPlain styles={styles.resetButton} onClick={onResetPress}>
            <Crossmark />
          </ButtonPlain>
        )}
      </div>
      {isExpanded && (
        <div className={`${css(styles.filterContainer, styles[filter.type])} filterContainer`}>
          <div className={css(styles.content)}>
            <div key={filter.attribute} className={css(styles.filter)}>
              <FilterComponent filter={filter} onChangeValues={onChangeValues} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ({ filters, onChange }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const onChangeValues = (filter) => (newValues) => {
    const newFilters = [...filters];
    const index = newFilters.findIndex((item) => item.attribute === filter.attribute);
    newFilters[index] = {
      ...newFilters[index],
      values: newValues,
    };

    onChange(newFilters);
  };
  const onResetPress = () => {
    let newFilters = [...filters];
    newFilters = newFilters.map((filter) => {
      return {
        ...filter,
        values: [],
      };
    });

    onChange(newFilters);
  };

  return (
    <div className={css(styles.filterBarContainer)}>
      <div className={css(styles.filterLabelContainer)}>
        <FilterIcon className={css(styles.filterIcon)} />
        <div className={css(styles.filterLabel)}>Filter</div>
      </div>
      {filters.map((filter) => (
        <FilterButton
          key={filter.attribute}
          filter={filter}
          onChangeValues={onChangeValues(filter)}
        />
      ))}
      <Button variant="special" size="s" icon={<Crossmark />} onPress={onResetPress} disabled={!filters.find((filter) => {
        return filter.values.length > 0;
      })}>
        {t('resetAll')}
      </Button>
    </div>
  );
};

const arrowBaseStyles = {
  bottom: '100%',
  left: 49,
  border: 'solid transparent',
  content: '""',
  height: 0,
  width: 0,
  position: 'absolute',
  pointerEvents: 'none',
};

const containerFadeIn = {
  from: {
    opacity: 0,
    transform: 'translateY(-50px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

const toggleButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  minWidth: 0,
  fontWeight: 'lighter',
  padding: '10px 26px',
  lineHeight: 1.5,
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'inline-block',
      marginRight: 20,
      position: 'relative',
      minWidth: 98,
      height: '100%',
    },
    date: {
      width: 360,
    },
    start_date: {
      width: 360,
    },
    end_date: {
      width: 360,
    },
    buttonContainer: {
      height: '100%',
      display: 'flex',
      fontSize: 14,
      lineHeight: '15px',
      letterSpacing: 1.15,
      color: theme.color3,
      borderRadius: 20,
      boxShadow: '0 5px 24px -6px rgba(44, 62, 80, 0.25)',
      backgroundColor: theme.color2,
      textAlign: 'center',
    },
    buttonContainerActive: {
      backgroundImage: theme.gradient3,
      color: theme.color2,
      boxShadow: theme.shadow1,
    },
    toggleButton: {
      ...toggleButtonStyles,
    },
    toggleButtonWithFilter: {
      ...toggleButtonStyles,
      paddingRight: 10,
    },
    buttonLabelWithoutFilters: {
      flex: 1,
    },
    resetButton: {
      fontSize: 20,
      width: 20,
      height: 20,
      alignSelf: 'center',
      paddingRight: 36,
    },
    buttonValues: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 0,
      fontWeight: 'bold',
      marginLeft: '0.25em',
    },
    buttonIcon: {
      fontSize: 20,
      marginLeft: 7,
    },
    filterBarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    filterContainer: {
      zIndex: 1,
      animationName: [containerFadeIn],
      animationDuration: '200ms',
      position: 'absolute',
      width: 440,
      height: 445,
      top: 51,
      left: 0,
      boxShadow: theme.shadow4,
      backgroundColor: theme.color2,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      ':before': {
        ...arrowBaseStyles,
        borderWidth: 11,
        marginLeft: -11,
      },
      ':after': {
        ...arrowBaseStyles,
        backgroundColor: 'transparent',
        borderBottomColor: theme.color2,
        borderWidth: 10,
        marginLeft: -10,
      },
    },
    content: {
      ...verticalScrollStyles,
      overflowX: 'hidden',
      flex: 1,
      padding: 20,
      height: '100%',
    },
    filterLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 30,
      color: theme.color1,
    },
    filterIcon: {
      fontSize: 20,
    },
    filterLabel: {
      fontSize: 18,
      marginLeft: 15,
      marginRight: 35,
    },
    filter: {
      height: '100%',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 10,
    },
  });
