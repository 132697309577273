import { TextInput, Theme, Unknown as UnknownIcon } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { s } from '../../../../Utils/Helpers';
import CheckBoxLabel from '../CheckBoxLabel';
import CheckBoxWithLabel from '../CheckBoxWithLabel';

export default ({ filter, onChangeValues }) => {
  const { key, useHook } = filter.dateSourceOptions;

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const selectedPersons = {};
  filter.values.forEach((value) => (selectedPersons[value.value] = value.label));

  const [renderedSelectedPersons] = useState({ ...selectedPersons });
  const renderedSelectedPersonsIds = Object.keys(renderedSelectedPersons);

  let query = '';
  if (firstName) {
    query = `firstName:${firstName}`;
  }

  if (lastName) {
    if (query) {
      query += ';';
    }

    query += `lastName:${lastName}`;
  }

  if (renderedSelectedPersonsIds.length > 0) {
    if (query) {
      query += ';';
    }

    query += `[ne]id:${renderedSelectedPersonsIds.join(',')}`;
  }

  const [data] = useHook({
    query,
    skipUpdate: !firstName && !lastName,
    limit: 20,
  });

  const availablePersons = {};
  data &&
    data.forEach(
      (entry) => (availablePersons[entry.id] = s(entry.lastName, entry.firstName))
    );

  const onChange = (id) => () => {
    const newValues = { ...selectedPersons };

    if (newValues[id]) {
      delete newValues[id];
    } else {
      newValues[id] = availablePersons[id];
    }

    onChangeValues(
      Object.keys(newValues).map((id) => ({
        label: newValues[id],
        value: id,
      }))
    );
  };

  const onChangeFirstName = (e) => setFirstName(e.target.value);
  const onChangeLastName = (e) => setLastName(e.target.value);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.searchInputs)}>
        <TextInput
          className={css(styles.searchInput)}
          id={`filter-${key}-last-name`}
          autoComplete="off"
          label={t('lastName')}
          placeholder={t('lastName')}
          value={lastName}
          onChange={onChangeLastName}
          clean
          size="s"
        />
        <TextInput
          className={css(styles.searchInput)}
          id={`filter-${key}-first-name`}
          autoComplete="off"
          label={t('firstName')}
          placeholder={t('firstName')}
          value={firstName}
          onChange={onChangeFirstName}
          clean
          size="s"
        />
      </div>
      <div className={css(styles.searchNote)}>* Bitte Gross-/Kleinschreibung beachten</div>
      {Object.keys(availablePersons).length === 0 && Object.keys(renderedSelectedPersons).length === 0 && (firstName || lastName) && (
        <div className={css(styles.noResultsContainer)}>
          <UnknownIcon className={css(styles.noResultsIcon)} />
          <span className={css(styles.noResultsLabel)}>{t('noResultsFound')}</span>
        </div>
      )}
      {Object.keys(renderedSelectedPersons).map((id) => (
        <CheckBoxWithLabel
          key={id}
          id={id.toString()}
          checked={!!selectedPersons[id]}
          onChange={onChange(id)}
          clean
        >
          <CheckBoxLabel>{renderedSelectedPersons[id]}</CheckBoxLabel>
        </CheckBoxWithLabel>
      ))}
      {Object.keys(availablePersons).map((id) => (
        <CheckBoxWithLabel
          key={id}
          id={id.toString()}
          checked={!!selectedPersons[id]}
          onChange={onChange(id)}
          clean
        >
          <CheckBoxLabel>{availablePersons[id]}</CheckBoxLabel>
        </CheckBoxWithLabel>
      ))}
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    searchInputs: {
      display: 'flex',
      marginBottom: 10,
    },
    searchNote: {
      fontWeight: 'lighter',
      color: theme.color1,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: 0.25,
      borderBottom: `1px solid ${theme.color12}`,
      paddingBottom: 20,
      marginBottom: 20,
    },
    searchInput: {
      flex: 1,
      ':nth-child(1)': {
        marginRight: 5,
      },
      ':nth-child(2)': {
        marginLeft: 5,
      },
    },
    noResultsContainer: {
      height: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    noResultsIcon: {
      width: 70,
      height: 70,
      opacity: 0.4,
      color: theme.color1,
    },
    noResultsLabel: {
      color: theme.color1,
      fontSize: 14,
    },
  });
