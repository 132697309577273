import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

export default ({ children, style }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return <h2 className={css(styles.title, style)}>{children}</h2>;
};

const getThemedStyles = theme =>
  StyleSheet.create({
    title: {
      fontSize: 16,
      fontWeight: 'lighter',
      color: theme.color6,
      margin: '30px 0',
    },
  });
