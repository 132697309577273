import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { Theme, Button, ArrowLeft, ArrowRight } from '@yourmileag/ui-kit';
import { fullScreenStyles } from '../../../../Utils/Helpers';

export default ({ onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const onNextPress = async () => {
    await localStorage.setItem('onBoarding:login', false);
    onRequestNextPage({ force: true });
  };

  return (
    <>
      <div className={css(styles.container)}>
        <div className={css(styles.form)}>
          <h2 className={css(styles.title)}>{t('loginDriver')}</h2>
          <h5 className={css(styles.desc)}>{t('loginDriverDesc')}</h5>
          <div className={css(styles.squareContainer)}>
            <div className={css(styles.button, styles.removeMargin)}>
              <div className={css(styles.square, styles.step01)} />
              <div className={css(styles.square, styles.step02)} />
              <div className={css(styles.square, styles.step03)} />
            </div>
            <div className={css(styles.button)}>
              <div className={css(styles.description)}>
                <h3 className={css(styles.descriptionTitle)}>{t('loginDriverFirstTitle')}</h3>
                {t('loginDriverFirstDesc')}
              </div>
              <div className={css(styles.description)}>
                <h3 className={css(styles.descriptionTitle)}>{t('loginDriverSecondTitle')}</h3>
                {t('loginDriverSecondDesc')}
              </div>
              <div className={css(styles.description)}>
                <h3 className={css(styles.descriptionTitle)}>{t('loginDriverThirdTitle')}</h3>
                {t('loginDriverThirdDesc')}
              </div>
            </div>
            <div className={css(styles.button)}>
              <Button size="m" iconLeft={<ArrowLeft />}>
                {t('back')}
              </Button>
              <Button
                size="m"
                variant="special"
                icon={<ArrowRight />}
                onPress={() => onNextPress()}
              >
                {t('continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={css(styles.squareImage)} /> */}
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    squareContainer: {
      display: 'flex',
      width: 1080,
      flexDirection: 'column',
      //   flexShrink: 20,
      justifyContent: 'space-between',
    },
    descriptionTitle: {
      fontWeight: 500,
      fontSize: 18,
      color: theme.color3,
      paddingBottom: 25,
    },
    description: {
      width: 340,
      textAlign: 'left',
      fontWeight: 300,
      color: theme.color1,
      fontSize: 16,
      lineHeight: '24px',
    },
    removeMargin: {
      margin: 0,
    },
    step01: {
      backgroundImage: 'url(/assets/images/step01.jpg)',
      backgroundSize: 'cover',
    },
    step02: {
      backgroundImage: 'url(/assets/images/step02.jpg)',
      backgroundSize: 'cover',
    },
    step03: {
      backgroundImage: 'url(/assets/images/step03.jpg)',
      backgroundSize: 'cover',
    },
    square: {
      display: 'flex',
      width: 340,
      height: 270,
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
    },
    squareImage: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 11,
      width: '70%',
      height: '70%',
      backgroundImage: 'url(/assets/images/mockup.png)',
      backgroundSize: 'cover',
    },
    dividerLine: {
      paddingTop: 60,
      marginBottom: 50,
      borderBottom: `solid ${theme.color4} 1px`,
      width: '100%',
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 45,
      textAlign: 'right',
    },
    buttonLink: {
      display: 'flex',
      color: theme.color6,
      ':before, :after': {
        backgroundColor: theme.color6,
      },
    },
    input: {
      marginBottom: 15,
    },
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    titleSmall: {
      fontSize: 20,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    form: {
      alignItems: 'left',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      ...fullScreenStyles,
      position: 'fixed',
      top: 0,
      left: 540,
      bottom: 0,
      backgroundColor: theme.color13,
      zIndex: 10,
      display: 'flex',
      overflow: 'scroll',
      justifyContent: 'center',
    },
  });
