import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { fullScreenStyles, verticalScrollStyles } from '../../../Utils/Helpers';
import OrderDetail from '../../Essentials/Components/OrderDetail';

export default ({
    order,
    history,
    closeModal,
}) => {
    const theme = useContext(Theme);
    const styles = getThemedStyles(theme);

    return ReactDOM.createPortal(
        <div className={css(styles.container)}>
            <div className={css(styles.containerInner)}>
                <OrderDetail orderId={order._id} history={history} onBackPress={closeModal} />
            </div>
        </div>,
        document.body
    );
};

const getThemedStyles = (theme) =>
    StyleSheet.create({
        container: {
            ...fullScreenStyles,
            ...verticalScrollStyles,
            display: 'flex',
            padding: 50,
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(44,62,80,0.7)',
            zIndex: 10,
        },
        containerInner: {
            backgroundColor: theme.color2,
            width: 1060,
            borderRadius: 5,
        },
    });
