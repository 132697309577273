import moment from 'moment';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toKm } from './Helpers';
import {
  getOrganisationId,
  organisationsEndpoint,
} from './API';

export const apiAccessToken = process.env.REACT_APP_MAPBOX;

const API = axios.create({
  baseURL: 'https://api.mapbox.com',
  params: {
    access_token: apiAccessToken,
  },
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getShortestRoute = async (origin, destination, stopovers = []) => {
  let url = 'directions/v5/mapbox/driving';

  const originPosition = JSON.parse(origin.position);
  const destinationPosition = JSON.parse(destination.position);

  url = `${url}/${originPosition.longitude},${originPosition.latitude}`;

  if (stopovers.length) {
    stopovers.forEach((stopover) => {
      const stopoverPosition = JSON.parse(stopover.position);

      url = `${url};${stopoverPosition.longitude},${stopoverPosition.latitude}`;
    });
  }

  url = `${url};${destinationPosition.longitude},${destinationPosition.latitude}`;
  url = `${url}?geometries=geojson`;

  const response = await API.get(url);
  const { routes } = response.data;

  let shortestDistance;
  let shortestDuration;

  routes.forEach((route) => {
    if (shortestDistance > route.distance || shortestDistance === undefined) {
      shortestDistance = route.distance;
      shortestDuration = route.duration;
    }
  });

  if (!routes[0]) {
    return null;
  }

  return {
    coordinates: routes[0].geometry.coordinates,
    distance: {
      value: shortestDistance,
      text: `${toKm(shortestDistance)} km`,
    },
    duration: {
      value: Math.floor(shortestDuration / 60),
      text: moment.duration(shortestDuration, 's').humanize(),
    },
  };
};

export const getAutoCompleteSuggestions = async (searchValue, language, proximity) => {
  let params = {
    types: 'address,poi',
    autocomplete: true,
    fuzzyMatch: false,
    language,
  };

  if (proximity) {
    params.proximity = proximity;
    params.fuzzyMatch = true;
  } else {
    params.country = 'CH';
  }

  const response = await API.get(
    `/geocoding/v5/mapbox.places/${searchValue.replace(new RegExp(';', 'g'), ',')}.json`,
    {
      params: params,
    }
  );

  const { features } = response.data;

  return features;
};

export const getPlaceDetails = (place) => {
  const [longitude, latitude] = place.center;

  return {
    position: JSON.stringify({ longitude, latitude }),
    name: place.place_name,
    source: place.place_name,
  };
};
