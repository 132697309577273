import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Onboarding from '../Onboarding';
import DetectOS from '../DetectOS';

export default ({ match }) => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/detectos" component={DetectOS} />
      <Route path="/onboarding" component={Onboarding} />
      <Redirect from="/" to="/login" />
    </Switch>
  );
};
