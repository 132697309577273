import { Tag, TextInputAutoComplete, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { poisEndpoint } from '../../../Utils/API';
import { useSettings } from '../../Settings';
import { getAutoCompleteSuggestions } from '../../../Utils/Mapbox';

const { useEntries: usePOIs } = poisEndpoint;

export default ({
  initialValue,
  onSelect,
  excludePOIs,
  clean,
  proximity,
  onChange: onInputChange,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [suggestions, setSuggestions] = useState([]);
  const [pois] = usePOIs({ orderBy: 'name:asc', skipUpdate: excludePOIs });
  const { t, i18n } = useTranslation();
  const hasIconLeft = !!rest.iconLeft;
  const [settings, setSettings] = useSettings();
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme, hasIconLeft);
  const poiPrefix = 'POI: '

  const onSuggestionsFetchRequested = async ({ value }) => {
    let proximity = null;
    if (settings) {
      if (settings.orderCreation) {
        if (settings.orderCreation.proximityLng && settings.orderCreation.proximityLat) {
          proximity = settings.orderCreation.proximityLng + ',' + settings.orderCreation.proximityLat
        }
      }
    }
    const poiSuggestions =
      excludePOIs || !pois
        ? []
        : pois.filter((poi) => poi.name.toLowerCase().includes(value.toLowerCase()));
    const mapBoxSuggestions = await getAutoCompleteSuggestions(value, i18n.language, proximity);
    setSuggestions([...poiSuggestions, ...mapBoxSuggestions]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    onInputChange && onInputChange(newValue);
    onSelect();
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);
  const onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();

    onSelect({
      name: suggestion.place_name || (poiPrefix + suggestion.name + ' (' + suggestion.source + ')'),
      position: suggestion.center
        ? JSON.stringify({ longitude: suggestion.center[0], latitude: suggestion.center[1] })
        : suggestion.position,
      source: suggestion.place_name || suggestion.source,
    });
  };
  const getSuggestionValue = (suggestion) => suggestion.place_name || (poiPrefix + suggestion.name + ' (' + suggestion.source + ')');

  return (
    <TextInputAutoComplete
      suggestions={suggestions}
      value={value}
      inputProps={rest}
      onChange={onChange}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      clean={!!clean}
      renderSuggestion={(suggestion) => {
        if (suggestion.place_name) {
          const placeNameParts = suggestion.place_name.split(', ');
          return (
            <div className={css(styles.suggestion)}>
              <div>
                <b className={css(styles.suggestionHighlight)}>{placeNameParts.shift()}</b>,&nbsp;
                {placeNameParts.join(', ')}
              </div>
            </div>
          );
        } else {
          return (
            <div className={css(styles.suggestion, styles.poi)}>
              <div className={css(styles.label)}>
                <b className={css(styles.suggestionHighlight)}>{suggestion.name}</b>,&nbsp;
                {suggestion.source}
              </div>
              <Tag style={styles.tag}>{t('poi')}</Tag>
            </div>
          );
        }
      }}
    />
  );
};

const getThemedStyles = (theme, hasIconLeft) =>
  StyleSheet.create({
    suggestion: {
      display: 'flex',
      cursor: 'pointer',
      width: '100%',
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.color9,
      padding: hasIconLeft ? '22px 60px' : 22,
      fontSize: 14,
      fontWeight: 'lighter',
    },
    suggestionHighlight: {
      fontWeight: 'bold',
    },
    poi: {
      padding: hasIconLeft ? '16px 60px' : 22,
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    label: {
      flex: 1,
    },
    tag: {
      marginLeft: 10,
    },
  });
