import { Button, SectionHeader, Theme, TextInput, Message } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPathParent } from '../../../Utils/Helpers';

export default ({
  history,
  match,
  note,
  onNoteChange,
  onSubmit,
  onDelete,
  children,
  isSaveDisabled,
  isDeleteDisabled,
  description,
}) => {
  const { t } = useTranslation();
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const goToParent = () => history.push(getPathParent(match.url));

  return (
    <form onSubmit={onSubmit} className={css(styles.container)}>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>{t('basicInformation')}</SectionHeader>
        {description && <div className={css(styles.description)}>{description}</div>}
        {children}
      </div>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>{t('comments')}</SectionHeader>
        <TextInput
          type="textarea"
          id="oc-briefing"
          iconLeft={<Message />}
          value={note !== null ? note : ''}
          onChange={onNoteChange}
          clean
        />
      </div>
      <div className={css(styles.footer)}>
        <Button onPress={goToParent}>{t('cancel')}</Button>
        {onDelete && (
          <div className={css(styles.deleteButton)}>
            <Button onPress={onDelete} variant="dark" disabled={isDeleteDisabled}>
              {t('remove')}
            </Button>
          </div>
        )}
        <div className={css(styles.spacer)} />
        <Button disabled={isSaveDisabled} variant="special" type="submit" onPress={onSubmit}>
          {t('save')}
        </Button>
      </div>
    </form>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      padding: 45,
    },
    sectionHeader: {
      marginBottom: 20,
    },
    section: {
      marginBottom: 45,
    },
    description: {
      padding: '0 15px',
      marginBottom: 15,
      fontWeight: 'lighter',
      color: theme.color1,
      fontSize: 12,
      lineHeight: '18px',
    },
    footer: {
      display: 'flex',
    },
    spacer: {
      flex: 1,
    },
    deleteButton: {
      marginLeft: 15,
    },
  });
