import { DateInput, Theme, Calendar } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ filter, onChangeValues, className }) => {
  const { t } = useTranslation();

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const onChangeDate = (newDate) =>
    onChangeValues(
      !!newDate ? [{ value: newDate.unix(), label: newDate.format(t('longDateFormat')) }] : []
    );
  const isOutsideRange = () => false;

  const date = filter.values[0] ? moment.unix(filter.values[0].value) : null;

  return (
    <div className={css(styles.container)}>
      <DateInput
        id="filter-date"
        className={className}
        label={date === null ? t('wholeTimeSpan') : t('date')}
        value={date}
        onChange={onChangeDate}
        showClearDate={false}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        autoFocus={true}
        displayFormat={t('longDateFormat')}
        clean
        size="s"
        placeholder={date === null ? t('wholeTimeSpan') : t('date')}
        icon={<Calendar />}
      />
    </div>
  );
};

const getThemedStyles = (theme) => StyleSheet.create({});
