import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

const SidePanelHeader = ({ label, title, icon }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <div className={css(styles.container)}>
      {icon && <div className={css(styles.icon)}>{icon}</div>}
      <div className={css(styles.details)}>
        {label && <div className={css(styles.label)}>{label}</div>}
        <div className={css(styles.title)} title={title}>
          {title}
        </div>
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    icon: {
      borderRadius: 30,
      backgroundImage: 'linear-gradient(134deg, #F6F7F7 0%, #E9EBED 100%)',
      // backgroundColor: '#F1F2F3',
      color: '#A5ADB6',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 60,
      lineHeight: '29px',
      marginRight: 25,
    },
    details: {
      flex: 1,
      minWidth: 0,
    },
    label: {
      fontSize: 14,
      fontWeight: 'lighter',
      color: theme.color1,
      letterSpacing: 0.39,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minWidth: 0,
      marginBottom: 5,
    },
    title: {
      fontSize: 24,
      lineHeight: '1.2em',
      letterSpacing: 0.39,
      fontWeight: 'bold',
      color: theme.color2,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minWidth: 0,
    },
  });

export default SidePanelHeader;
