import {
  TextInput,
  InfoRow,
} from '@yourmileag/ui-kit';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextInputAddress from '../Essentials/Components/TextInputAddress';
import Content from './Components/Content';
import { useSettings, submitSettings } from '../Settings';

export const useOrderCreationValidator = (settings) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!settings) {
      return;
    }

    const newErrors = {};

    if (settings.orderCreation.proximity && (!settings.orderCreation.proximityLat || !settings.orderCreation.proximityLng)) {
      newErrors.proximity = t('selectAddress');
    }

    if (settings.orderCreation.smsSenderName) {
      if (!/^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{1,11})$/m.test(settings.orderCreation.smsSenderName)) {
        newErrors.smsSenderName = t('smsSenderNameError');
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [settings, t]);

  return errors;
};

export default () => {
  const { t } = useTranslation();

  const [settings, setSettings] = useSettings();
  const errors = useOrderCreationValidator(settings);
  const isSubmitDisabled = Object.keys(errors).length > 0;

  const onSubmit = async (e) => {
    e.preventDefault();
    setSettings(
      await submitSettings(settings, t, t('orderCreation'))
    );
  };

  const onProximitySelect = (address) => {
    if (!address) {
      return;
    }

    const { longitude, latitude } = JSON.parse(address.position);

    setSettings({
      ...settings,
      orderCreation: {
        ...settings.orderCreation,
        proximity: address.name,
        proximityLat: latitude,
        proximityLng: longitude,
      },
    })
  }

  return (
    <Content
      title={t('orderCreation')}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
    >
      <InfoRow center label={t('proximity')}>
        {settings.loaded && (
          <TextInputAddress
            id="proximity"
            onSelect={onProximitySelect}
            initialValue={settings.orderCreation.proximity}
            excludePOIs
            clean
            onChange={(value) =>
              setSettings({
                ...settings,
                orderCreation: { ...settings.orderCreation, proximity: value },
              })
            }
            required
            errorMessage={errors.proximity}
          />
        )}
      </InfoRow>
      <InfoRow center label={t('smsSenderName')}>
        <TextInput
          id="smsSenderName"
          type="text"
          value={settings.orderCreation.smsSenderName}
          onChange={(event) =>
            setSettings({
              ...settings,
              orderCreation: { ...settings.orderCreation, smsSenderName: event.target.value },
            })
          }
          errorMessage={errors.smsSenderName}
          required
          clean
          size="s"
        />
      </InfoRow>
    </Content>
  );
};

