import { StepContent } from '../Components/Tour';
import React from 'react';

export default {
  name: 'company-data',
  requiredPath: '/essentials/settings/company',
  categoryId: 'essentials',
  getTranslatedTour: (t) => ({
    question: t('howToChangeCompanyData'),
    steps: [
      {
        selector: '#main-nav-settings',
        content: (
          <>
            <StepContent>{t('settingsDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#settings-nav-company',
        content: (
          <>
            <StepContent>{t('settingsNavDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#settings-form',
        content: (
          <>
            <StepContent>{t('settingsFormDesc')}</StepContent>
          </>
        ),
      },
    ],
  }),
};
