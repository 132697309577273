import { ButtonPlain, CheckBox, Theme, GoLogo, Clock, OrderAssignment } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toPrice } from '../../../../Utils/Price';

const moduleIconMap = {
  essentials: <GoLogo />,
  dispatcher: <OrderAssignment />,
  cronjobs: <Clock />,
};

export default ({ module, checked, onChange }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const ignoreClick = () => {};

  return (
    <ButtonPlain
      styles={[
        styles.container,
        checked && styles.containerChecked,
        module.essential && styles.containerReadOnly,
      ]}
      onClick={module.essential ? ignoreClick : onChange}
    >
      <div className={css(styles.header)}>
        <CheckBox id={module._id.toString()} checked={checked || false} readOnly />
      </div>
      <div className={css(styles.icon)}>{moduleIconMap[module.name] || <GoLogo />}</div>
      <div className={css(styles.moduleContent)}>
        <div className={css(styles.title)}>{t(module.name)}</div>
        <div className={css(styles.price)}>{`+ CHF ${toPrice(module.ppm)} / ${t('car')}`}</div>
      </div>
    </ButtonPlain>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      border: '2px solid transparent',
      borderRadius: 5,
      boxShadow: theme.shadow3,
      padding: 20,
      position: 'relative',
      marginRight: 20,
      marginBottom: 20,
    },
    containerChecked: {
      borderColor: theme.color5,
    },
    containerReadOnly: {
      cursor: 'not-allowed',
      borderColor: theme.color4,
      ':hover': {
        opacity: 1,
      },
    },
    header: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 110,
      fontSize: 40,
      color: theme.color5,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      textAlign: 'center',
      marginBottom: 2,
    },
    price: {
      fontSize: 14,
      lineHeight: '20px',
      color: theme.color1,
      marginBottom: 10,
    },
  });
