import { Theme, InfoNote, OrderCreation as OrderCreationIcon, Switch, DispositionReady } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useState, useRef } from 'react';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';
import Order from './Order';
import { useGlobal, useEffect, resetGlobal } from 'reactn';
import { submitSettings, useSettings } from '../../Settings';
import autoDispatcherActive from '../Animations/AutoDispatcherActive.json';

function AutoDispatcherAnimation() {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const container = useRef();

  useEffect(() => {
      const animationInstance = lottie.loadAnimation({
          container: container.current,
          renderer: 'svg',
          autoplay: true,
          loop: true,
          animationData: autoDispatcherActive,
      });

      return () => {
          animationInstance.destroy();
      };
  }, []);

  return <div className={css(styles.autoDispatcherAnimation)} ref={container} />;
};

export default ({ hasAutoDisptacher, selectedOrder, onSelect, onDrag, date, orders, noOrdersFor, history }) => {
  const theme = useContext(Theme);
  const [activeTour] = useGlobal('activeTour');
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  const shouldShowInfoNote = !localStorage.getItem('hasSeenDispatcher') && !activeTour;
  const [showInfoNote, setShowInfoNote] = useState(shouldShowInfoNote);
  const [settings, setSettings] = useSettings();

  const closeInfoNote = () => {
    setShowInfoNote(false);
    localStorage.setItem('hasSeenDispatcher', true);
  };

  useEffect(() => {
    setShowInfoNote(shouldShowInfoNote);
  }, [activeTour, shouldShowInfoNote]);

  if (!orders) {
    return null;
  }

  return (
    <>
      {(hasAutoDisptacher && settings.canUseAutoDispatcher) && (
        <>
          <div className={css(styles.autoDispatcher)}>
            <div>
              <div className={css(styles.autoDispatcherIcon)}>
                <DispositionReady/>
              </div>
              Auto Dispatcher
              <div className={css(styles.autoDispatcherLabel)}>
                Beta
              </div>
            </div>
            <Switch
              id="auto_dispatcher"
              checked={settings.autoDispatcherActive}
              onChange={async () =>
                setSettings(
                  await submitSettings({
                    ...settings,
                    autoDispatcherActive: !settings.autoDispatcherActive,
                  }, t, t('dispatcher'))
                )
              }
              checkedLabel={'Aktiv'}
              uncheckedLabel={'Inaktiv'}
            />
          </div>
        </>
      )}
      {(hasAutoDisptacher && settings.canUseAutoDispatcher && settings.autoDispatcherActive) && (
        <div className={css(styles.autoDispatcherOverlay)}>
          <div className={css(styles.icon)}>
            <AutoDispatcherAnimation />
          </div>
          <div className={css(styles.text, styles.textAutoDispatcher)}>
            Automatische Disposition aktiv
          </div>
        </div>
      )}
      {orders.length ? (
        <>
          {orders.map((order) => (
            <Order
              key={order._id}
              order={order}
              history={history}
              isSelected={selectedOrder && selectedOrder._id === order._id}
              onSelect={onSelect}
              disabled={hasAutoDisptacher && settings.canUseAutoDispatcher && settings.autoDispatcherActive}
              onDrag={onDrag}
            />
          ))}
          {showInfoNote && (
            <InfoNote
              text={t('dispatchNote')}
              buttonLabel={t('dispatchNoteButton')}
              onClick={closeInfoNote}
            />
          )}
        </>
      ) : (
        <div className={css(styles.noOrders)}>
          <div className={css(styles.icon)}>
            <OrderCreationIcon />
          </div>
          <div className={css(styles.text)}>
            {noOrdersFor ? t(noOrdersFor) : t('noUnassignedOrdersFor', { date: date.format(t('longDateFormat')) })}
          </div>
        </div>
      )}
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    autoDispatcher: {
      display: 'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      padding: 20,
      marginBottom: 25,
      background: '#EEF0F1',
      borderRadius: 5,
      position: 'relative',
    },
    autoDispatcherIcon: {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: 26,
      marginRight: 10,
    },
    autoDispatcherLabel: {
      background: '#8A96A2',
      borderRadius: 3,
      position: 'absolute',
      top: -6,
      right: -5,
      fontWeight: 'bold',
      fontSize: 10,
      color: '#ffffff',
      letterSpacing: 0.78,
      lineHeight: 1,
      textTransform: 'uppercase',
      padding: '3px 5px 3px 6px'
    },
    autoDispatcherOverlay: {
      position: 'absolute',
      top: 300,
      left: 50,
      right: 50,
      paddingTop: 0,
      paddingBottom: 30,
      backgroundImage: `linear-gradient(245deg, #00D3F5 10%, ${theme.color5} 100%)`,
      boxShadow: theme.shadow1,
      borderRadius: 5,
      zIndex: 100,
    },
    autoDispatcherAnimation: {
      display: 'inline-block',
      width: 100,
      height: 100,
    },
    noOrders: {
      alignItems: 'center',
      justifyContent:'center',
      fontSize: 14,
      marginTop: 50
    },
    icon: {
      fontSize: 40,
      color:'#b1b7bc',
      textAlign:'center'
    },
    text:{
      width:'60%',
      margin:'auto',
      marginTop:3,
      textAlign:'center',
      color:'#b1b7bc',
      lineHeight:1.3
    },
    textAutoDispatcher: {
      color: '#ffffff',
    },
    container: {
      position: 'relative',
    },
  });
