import { css, StyleSheet } from 'aphrodite/no-important';
import lottie from 'lottie-web';
import moment from 'moment';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import waitingAnimation from '../Animations/OrderRequestWaiting.json';

export default ({ order, dimensions }) => {
  const [progress, setProgress] = useState(0);
  const container = useRef();

  useEffect(() => {
    const now = moment().unix();
    const newProgress =
      (1 / Number(Cookies.get('driverRequestTime'))) * (now - order.requestTimestamp);
    if (newProgress >= Number(Cookies.get('driverRequestTime'))) {
      setProgress(100);
    } else {
      setProgress(newProgress);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: false,
      animationData: waitingAnimation,
    });

    animationInstance.setSpeed(
      Number((30 / (Number(Cookies.get('driverRequestTime')) + 0)).toFixed(2))
    );
    animationInstance.playSegments([animationInstance.getDuration(true) * progress, animationInstance.getDuration(true)], true);

    return () => {
      animationInstance.destroy();
    };

    // eslint-disable-next-line
  }, [progress]);

  return (
    <div className={css(styles.container)} style={{ ...dimensions }}>
      <div className={css(styles.animation)} ref={container} />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  animation: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: 10,
    width: 36,
    height: 36,
    marginTop: -18,
  },
});
