import React from 'react';
import { driversEndpoint } from '../../../../Utils/API';
import IdentityCreateOrUpdate from '../../Components/IdentityCreateOrUpdate';

const {
  createEntry: createDriver,
  deleteEntry: deleteDriver,
  updateEntry: updateDriver,
} = driversEndpoint;

export const defaultDriver = {
  phone: '',
  gender: 'unknown',
  status: 'active',
};

export default ({ entry, history, match }) => (
  <IdentityCreateOrUpdate
    person={entry}
    history={history}
    match={match}
    defaultPerson={defaultDriver}
    createPerson={createDriver}
    updatePerson={updateDriver}
    deletePerson={deleteDriver}
  />
);
