import { createSnackbar, InfoRow, Dropdown } from '@yourmileag/ui-kit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n, { setLanguage } from '../../i18n';
import Content from './Components/Content';

const labelsByTag = {
  de: 'Deutsch',
  en: 'English',
};

const tagsByLabel = {
  Deutsch: 'de',
  English: 'en',
};

const useLanguageValidator = (language) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!language) {
      newErrors.language = t('fieldIsRequired');
    }

    if (!Object.values(labelsByTag).includes(language)) {
      newErrors.language = t('selectSupportedLanguageFromList');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [language, t]);

  return errors;
};

export default () => {
  const [value, setValue] = useState(labelsByTag[i18n.language]);
  const { t } = useTranslation();
  const errors = useLanguageValidator(value);
  const isSubmitDisabled = Object.keys(errors).length > 0;

  const onChange = (newValue) => setValue(newValue);

  const onSubmit = async (e) => {
    e.preventDefault();
    const lng = tagsByLabel[value];
    await setLanguage(lng);
    localStorage.setItem('lng', lng);
    createSnackbar('success', t('successfullySaved', { name: t('language') }));
  };

  return (
    <Content title={t('general')} onSubmit={onSubmit} isSubmitDisabled={isSubmitDisabled}>
      <InfoRow center label={t('language')}>
        <Dropdown
          label={t('language')}
          onSelect={(e) => onChange(e)}
          options={Object.values(labelsByTag)}
          size="s"
          value={value}
          clean
        />
      </InfoRow>
    </Content>
  );
};
