import { createSnackbar, InfoRow, Switch, TextInput, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { cronjobsEndpoint, employeesEndpoint, ordersEndpoint } from '../../../Utils/API';
import { getPathParent, join, s } from '../../../Utils/Helpers';
import CheckBoxWithLabel from '../../Essentials/Components/CheckBoxWithLabel';
import InventoryCreateOrUpdate from '../../Essentials/Components/InventoryCreateOrUpdate';
import OrderPreview from '../Components/OrderPreview';

const {
  createEntry: createCronjob,
  deleteEntry: deleteCronjob,
  updateEntry: updateCronjob,
} = cronjobsEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;
const { useEntry: useOrder } = ordersEndpoint;

const defaultCronjob = {
  essentials_orders: '',
  name: '',
  cron: '',
  status: 'active',
};

const defaultDays = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

const useValidator = (name, order) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!name) {
      newErrors.name = t('fieldIsRequired');
    }

    if (!order) {
      newErrors.essentials_orders = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [name, order, t]);

  return errors;
};

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const [cronjob, setCronjob] = useState({ ...defaultCronjob, ...entry });
  const [days, setDays] = useState(defaultDays);
  const [identityId] = useGlobal('identityId');
  const [orderTemplate, setOrderTemplate] = useGlobal('orderTemplate');
  const [order] = useOrder(cronjob.essentials_orders.length === 16 && cronjob.essentials_orders);
  const errors = useValidator(cronjob.name, order);
  const isSaveDisabled = Object.keys(errors).length > 0;
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  useEffect(() => {
    const newCronjob = { ...defaultCronjob, ...entry };

    if (newCronjob.cron) {
      const newDays = { ...defaultDays };

      newCronjob.cron.split(',').forEach((day) => {
        newDays[day] = true;
      });

      setDays(newDays);
    }

    setCronjob(newCronjob);
  }, [entry]);

  useEffect(() => {
    if (!orderTemplate) {
      return;
    }

    setCronjob({ ...cronjob, essentials_orders: orderTemplate._id.toString() });
    setOrderTemplate(null);
  }, [orderTemplate, cronjob, setOrderTemplate]);

  const onChange = (attribute) => (e) => setCronjob({ ...cronjob, [attribute]: e.target.value });
  const onChangeStatus = (e) =>
    setCronjob({ ...cronjob, status: e.target.checked ? 'active' : 'inactive' });
  const onChangeDay = (day) => () => {
    setDays({
      ...days,
      [day]: !days[day],
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newCronjob = { ...cronjob, time: order.estimatedTimespan.startTimestamp };

    const selectedDays = [];
    Object.keys(days).forEach((day) => {
      if (!days[day]) {
        return;
      }

      selectedDays.push(day);
    });

    newCronjob.cron = selectedDays.sort().join(',');

    if (!entry || newCronjob.noteText !== entry.noteText) {
      const author = await getEmployee(identityId);
      newCronjob.noteTimestamp = moment().unix();
      newCronjob.noteAuthor = s(author.lastName, author.firstName);
    }

    if (!entry) {
      const newCronjobId = await createCronjob(newCronjob);

      history.push(join(getPathParent(match.url), newCronjobId));
    } else {
      await updateCronjob(entry.id, newCronjob);

      history.push(getPathParent(match.url));
    }

    createSnackbar('success', t('successfullySavedCronjob', { name: cronjob.name }));
  };

  const onDelete = async () => {
    await deleteCronjob(entry.id);
    history.push(getPathParent(getPathParent(match.url)));
    createSnackbar('info', t('successfullyDeletedCronjob', { name: cronjob.name }));
  };

  return (
    <InventoryCreateOrUpdate
      history={history}
      match={match}
      onSubmit={onSubmit}
      onDelete={entry && onDelete}
      note={cronjob.noteText}
      onNoteChange={onChange('noteText')}
      isSaveDisabled={isSaveDisabled}
      description={t('cronjobDescription')}
    >
      {!orderTemplate && (
        <InfoRow label={t('orderNumber')} required>
          <TextInput
            id="sfcou-orderId"
            value={cronjob.essentials_orders}
            onChange={onChange('essentials_orders')}
            errorMessage={errors.essentials_orders}
            required
            clean
            size="s"
          />
          {order && <OrderPreview order={order} clean />}
        </InfoRow>
      )}
      <InfoRow center label={t('name')} required>
        <TextInput
          id="sfcou-name"
          value={cronjob.name}
          onChange={onChange('name')}
          errorMessage={errors.name}
          required
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('active')}>
        <Switch id="sfcou-active" checked={cronjob.status === 'active'} onChange={onChangeStatus} />
      </InfoRow>
      <InfoRow label={t('frequency')}>
        {[1, 2, 3, 4, 5, 6, 0].map((day) => (
          <CheckBoxWithLabel
            key={day}
            id={day.toString()}
            checked={days[day]}
            onChange={onChangeDay(day)}
            style={styles.clean}
          >
            <div className={css(styles.dayLabel)}>{moment.weekdays(day)}</div>
          </CheckBoxWithLabel>
        ))}
      </InfoRow>
    </InventoryCreateOrUpdate>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    mapContainer: {
      marginTop: 20,
    },
    searchInput: {
      marginBottom: 20,
    },
    dayLabel: {
      flex: 1,
    },
    clean: {
      boxShadow: 'none',
      border: `solid ${theme.color16} 1px`,
      borderRadius: 3,
      height: 40,
    },
  });
