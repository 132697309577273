import { css, StyleSheet } from 'aphrodite/no-important';
import { Button } from '@yourmileag/ui-kit';
import React, { useEffect, useState } from 'react';
import { ordersEndpoint } from '../../Utils/API';
import { fullScreenStyles, verticalScrollStyles } from '../../Utils/Helpers';
import CheckBoxWithLabel from '../Essentials/Components/CheckBoxWithLabel';

let isUpdating = false;

export default () => {
  const [isMigrationRunning, setMigrationRunning] = useState(false);
  const [isAutoMigrating, setAutoMigrating] = useState(false);
  const [orders] = ordersEndpoint.useEntries({
    query: 'estimatedTimespan:null',
    fields: '_id,timespan,estimatedTimespan',
    limit: 1000,
  });

  useEffect(() => {
    if (isAutoMigrating && !isMigrationRunning && !isUpdating && orders) {
      setMigrationRunning(true);
      return;
    }

    if (!isMigrationRunning || !orders || isUpdating) {
      return;
    }

    (async () => {
      isUpdating = true;

      const promises = [];
      orders.forEach((order) =>
        promises.push(
          ordersEndpoint.updateEntry(order._id, { estimatedTimespan: { ...order.timespan } })
        )
      );

      await Promise.all(promises);
      isUpdating = false;

      setMigrationRunning(false);
    })();
  }, [orders, isMigrationRunning, isAutoMigrating]);

  const isMigrationDisabled = !orders || !orders.length || isMigrationRunning || isAutoMigrating;
  const startMigration = () => setMigrationRunning(true);

  const onToggleAutoMigrate = () => setAutoMigrating(!isAutoMigrating);

  return (
    <div className={css(styles.container)}>
      <Button variant="special" disabled={isMigrationDisabled} onPress={startMigration}>
        Start
      </Button>
      <CheckBoxWithLabel
        style={styles.autoMigration}
        id="auto-migration"
        checked={isAutoMigrating}
        onChange={onToggleAutoMigrate}
      >
        <div>Auto migrate</div>
      </CheckBoxWithLabel>
      <pre className={css(styles.code)}>{JSON.stringify(orders, undefined, 2)}</pre>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    ...fullScreenStyles,
    ...verticalScrollStyles,
    padding: 20,
  },
  code: {
    marginTop: 20,
    backgroundColor: '#1E1E1E',
    color: '#CCCCCC',
    fontFamily: '"Courier New", Courier, monospace',
  },
  autoMigration: {
    display: 'inline-flex',
    marginLeft: 20,
    width: 'auto',
  },
});
