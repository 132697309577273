import { parsePhoneNumberFromString } from 'libphonenumber-js';
import i18n from '../i18n';

export const validatePhoneNumber = phone => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phone, 'CH');

  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
    return i18n.t('pleaseEnterFullPhoneNumber');
  }

  return true;
};

// eslint-disable-next-line
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateMail = mail =>
  emailRegexp.test(mail) ? true : i18n.t('pleaseEnterValidEmail');

export const validateZipCode = value => (isNaN(value) ? i18n.t('pleaseEnterValidZip') : true);
