import { Button, Pen, SectionHeader } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { join } from '../../../Utils/Helpers';
import Note from './Note';

export default ({ children, entry, history, match }) => {
  const { t } = useTranslation();

  const onEditButtonPress = () => history.push(join(match.url, 'update'));
  const editButton = (
    <Button variant="dark" size="s" icon={<Pen />} onPress={onEditButtonPress}>
      {t('edit')}
    </Button>
  );

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)} right={editButton}>
          {t('basicInformation')}
        </SectionHeader>
        {children}
      </div>
      <div>
        <SectionHeader className={css(styles.sectionHeader)} right={editButton}>
          {t('comments')}
        </SectionHeader>
        <Note entry={entry} />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 45,
  },
  sectionHeader: {
    marginBottom: 20,
  },
  section: {
    marginBottom: 45,
  },
});
