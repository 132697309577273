import React, { useState } from 'react';

import DeleteEntry from '../../src/Modules/Essentials/Screens/DeleteEntry';

const useModal = () => {
    const [isVisible, setIsVisible] = useState(false)

    const show = () => setIsVisible(true)
    const hide = () => setIsVisible(false)

    const RenderModal = ({ deleteAction, text }) => {
        return (
            <React.Fragment>
                {isVisible && <DeleteEntry text={text} closeModal={hide} deleteAction={deleteAction} />}
            </React.Fragment>
        )
    }
    return {
        show,
        hide,
        RenderModal,
    }
}

export default useModal;