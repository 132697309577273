import Cookies from 'js-cookie';
import { setGlobal } from 'reactn';

setGlobal({
  apiBase: null,
  identityId: null,
  isSidePanelVisible: false,
  isNavExpanded: Cookies.get('isNavExpanded') !== 'NO',
  orderTemplate: null,
  returnTemplate: null,
  activeTour: null,
});
