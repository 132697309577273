import {
  Baggage,
  createSnackbar,
  InfoRow,
  People,
  RadioButton,
  SectionHeader,
  TextInput,
  VehicleBusiness,
  VehicleSmall,
  VehicleStandard,
  VehicleVan,
  VehicleWagon,
  VehicleWheelchairTaxi,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { employeesEndpoint, fleetsEndpoint } from '../../../../Utils/API';
import { getPathParent, join, s } from '../../../../Utils/Helpers';
import InventoryCreateOrUpdate from '../../Components/InventoryCreateOrUpdate';
import useDeleteModal from '../../../../Utils/useDeleteModal';
import { carsEndpoint } from '../../../../Utils/API';

const {
  createEntry: createFleet,
  deleteEntry: deleteFleet,
  updateEntry: updateFleet,
} = fleetsEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;
const { useEntries: useCars } = carsEndpoint;

export const defaultFleet = {
  label: '',
  icon: 'standard',
  peopleCount: 1,
  baggageCount: 1,
  rateCurrency: 'CHF',
  rateBaseFee: 1,
  ratePerKm: 1,
};

export const useFleetValidator = (fleet) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!fleet.label) {
      newErrors.label = t('fieldIsRequired');
    }

    if (!fleet.peopleCount) {
      newErrors.peopleCount = t('fieldIsRequired');
    } else if (isNaN(fleet.peopleCount)) {
      newErrors.peopleCount = t('peopleCountHasToBeValid');
    }

    if (!fleet.baggageCount) {
      newErrors.baggageCount = t('fieldIsRequired');
    } else if (isNaN(fleet.baggageCount)) {
      newErrors.baggageCount = t('baggageCountHasToBeValid');
    }

    if (!fleet.rateCurrency) {
      newErrors.rateCurrency = t('fieldIsRequired');
    }

    if (!fleet.rateBaseFee) {
      newErrors.rateBaseFee = t('fieldIsRequired');
    } else if (isNaN(fleet.rateBaseFee)) {
      newErrors.rateBaseFee = t('priceHasToBeValid');
    }

    if (!fleet.ratePerKm) {
      newErrors.ratePerKm = t('fieldIsRequired');
    } else if (isNaN(fleet.ratePerKm)) {
      newErrors.ratePerKm = t('priceHasToBeValid');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [fleet, t]);

  return errors;
};

export default ({ entry, history, match }) => {
  const { t } = useTranslation();

  const [fleet, setFleet] = useState({ ...defaultFleet, ...entry });
  const errors = useFleetValidator(fleet);
  const isSaveDisabled = Object.keys(errors).length > 0;
  const [identityId] = useGlobal('identityId');
  const { show, RenderModal } = useDeleteModal();
  const [assignedCars] = useCars({ query: `essentials_fleets:${fleet.id}` });

  useEffect(() => {
    setFleet({ ...defaultFleet, ...entry });
  }, [entry]);

  const onChange = ({ attribute, isNumber = false, isPrice = false }) => (e) => {
    let value = e.target.value;

    if ((isNumber || isPrice) && !isNaN(value)) {
      if (isNumber) {
        value = value ? parseInt(e.target.value, 10) : '';
      } else {
        value = value ? parseFloat(e.target.value, 10).toFixed(2) : '';
      }
    }

    setFleet({ ...fleet, [attribute]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newFleet = { ...fleet };

    if (!entry || newFleet.noteText !== entry.noteText) {
      const author = await getEmployee(identityId);
      newFleet.noteTimestamp = moment().unix();
      newFleet.noteAuthor = s(author.lastName, author.firstName);
    }

    if (!entry) {
      const newFleetId = await createFleet(newFleet);

      history.push(join(getPathParent(match.url), newFleetId));
    } else {
      await updateFleet(entry.id, newFleet);

      history.push(getPathParent(match.url));
    }

    createSnackbar('success', t('successfullySavedFleet', { name: fleet.label }));
  };

  const deleteAction = async () => {
    await deleteFleet(entry.id);
    history.push(getPathParent(getPathParent(match.url)));
    createSnackbar('info', t('successfullyDeletedFleet', { name: fleet.label }));
  };

  const onDelete = async () => {
    show();
  };

  return (
    <InventoryCreateOrUpdate
      history={history}
      match={match}
      onSubmit={onSubmit}
      onDelete={entry && onDelete}
      note={fleet.noteText}
      onNoteChange={onChange({ attribute: 'noteText' })}
      isSaveDisabled={isSaveDisabled}
      isDeleteDisabled={assignedCars && assignedCars.length > 0}
    >
      <InfoRow center label={t('name')} required>
        <TextInput
          id="sfcou-label"
          value={fleet.label}
          onChange={onChange({ attribute: 'label' })}
          required
          errorMessage={errors.label}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('icon')}>
        <div className={css(styles.iconSelection)}>
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-standard"
            name="standard"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="standard"
            iconLeft={<VehicleStandard />}
            clean
          />
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-business"
            name="business"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="business"
            iconLeft={<VehicleBusiness />}
            clean
          />
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-van"
            name="van"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="van"
            iconLeft={<VehicleVan />}
            clean
          />
        </div>
        <div className={css(styles.iconSelection)}>
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-wagon"
            name="wagon"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="wagon"
            iconLeft={<VehicleWagon />}
            clean
          />
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-wheelchairTaxi"
            name="wheelchairTaxi"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="wheelchairTaxi"
            iconLeft={<VehicleWheelchairTaxi />}
            clean
          />
          <RadioButton
            className={css(styles.icon)}
            id="sfcou-icon-small"
            name="small"
            selected={fleet.icon}
            onChange={onChange({ attribute: 'icon' })}
            value="small"
            iconLeft={<VehicleSmall />}
            clean
          />
        </div>
      </InfoRow>
      <InfoRow center label={t('seats')} required>
        <TextInput
          id="sfcou-peopleCount"
          type="number"
          min="1"
          iconLeft={<People />}
          value={fleet.peopleCount}
          onChange={onChange({ attribute: 'peopleCount', isNumber: true })}
          required
          errorMessage={errors.peopleCount}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('baggage')} required>
        <TextInput
          id="sfcou-baggageCount"
          type="number"
          min="1"
          iconLeft={<Baggage />}
          value={fleet.baggageCount}
          onChange={onChange({ attribute: 'baggageCount', isNumber: true })}
          required
          errorMessage={errors.baggageCount}
          clean
          size="s"
        />
      </InfoRow>
      <div className={css(styles.rate)}>
        <SectionHeader>{t('rate')}</SectionHeader>
        <InfoRow center label={t('currency')} required>
          <TextInput
            id="sfcou-currency"
            value={fleet.rateCurrency}
            onChange={onChange({ attribute: 'rateCurrency' })}
            required
            errorMessage={errors.rateCurrency}
            clean
            size="s"
          />
        </InfoRow>
        <InfoRow center label={t('baseFee')} required>
          <TextInput
            id="sfcou-baseFee"
            value={fleet.rateBaseFee}
            onChange={onChange({ attribute: 'rateBaseFee' })}
            onBlur={onChange({ attribute: 'rateBaseFee', isPrice: true })}
            required
            errorMessage={errors.rateBaseFee}
            clean
            size="s"
          />
        </InfoRow>
        <InfoRow center label={t('perKm')} required>
          <TextInput
            id="sfcou-perKm"
            value={fleet.ratePerKm}
            onChange={onChange({ attribute: 'ratePerKm' })}
            onBlur={onChange({ attribute: 'ratePerKm', isPrice: true })}
            required
            errorMessage={errors.ratePerKm}
            clean
            size="s"
          />
        </InfoRow>
      </div>
      <RenderModal deleteAction={deleteAction} />
    </InventoryCreateOrUpdate>
  );
};

const styles = StyleSheet.create({
  rate: {
    marginTop: 45,
  },
  space: {
    display: 'flex',
  },
  seats: {
    flex: 1,
  },
  spacer: {
    width: 20,
  },
  baggage: {
    flex: 1,
  },
  iconSelection: {
    display: 'flex',
    marginBottom: 15,
    ':last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    flex: 1,
    marginRight: 15,
    ':last-child': {
      marginRight: 0,
    },
  },
});
