import { TextInput, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from './Components/Page';
import { validatePhoneNumber } from '../../../../Utils/Validators';
import { toDbPhone } from '../../../../Utils/Helpers';
import { organisationsEndpoint, getOrganisationId, employeesEndpoint } from '../../../../Utils/API';
import { fullScreenStyles, usePageTitle } from '../../../../Utils/Helpers';

const useValidator = (organisation, employee) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!employee.firstName) {
      newErrors.firstName = t('fieldIsrequired');
    }

    if (!employee.lastName) {
      newErrors.lastName = t('fieldIsrequired');
    }

    const isPhoneNumberValid = validatePhoneNumber(employee.phone);
    if (isPhoneNumberValid !== true) {
      newErrors.phone = isPhoneNumberValid;
    }

    if (!organisation.name) {
      newErrors.name = t('fieldIsrequired');
    }

    if (!organisation.street) {
      newErrors.street = t('fieldIsrequired');
    }

    if (!organisation.zipCode) {
      newErrors.zipCode = t('fieldIsrequired');
    }

    if (!organisation.city) {
      newErrors.city = t('fieldIsrequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [organisation, employee, t]);

  return errors;
};

export default ({ match, history, onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  usePageTitle(t('registration'));

  const [orgDisabled, setOrgDisabled] = useState(false);
  const [employee, setEmployee] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    admin: 'true',
  });
  const [organisation, setOrganisation] = useState({
    name: '',
    street: '',
    zipCode: '',
    city: '',
  });

  useEffect(() => {
    const getDefaultOrg = async () => {
      const organisation = await organisationsEndpoint.getEntry(getOrganisationId());
      if (organisation) {
        setOrgDisabled(true);
        setOrganisation(organisation);
      }
    };
    getDefaultOrg();
  }, []);

  const errors = useValidator(organisation, employee);
  const isNextDisabled = Object.keys(errors).length > 0;

  const onChange = (attribute) => (e) => {
    setOrganisation({ ...organisation, [attribute]: e.target.value });
  };

  const onChangeEmployee = (attribute) => (e) => {
    setEmployee({ ...employee, [attribute]: e.target.value });
  };

  const onNextPress = async () => {
    // setStatus(STATUS.submitting);
    // window.gtag('event', 'login', { method: 'Email' });
    // save organisation
    if (!orgDisabled) {
      try {
        await organisationsEndpoint.createEntry(organisation);
      } catch (exc) {
        console.log(exc);
      }
    }
    // save employee
    try {
      let newEmployee = { ...employee, phone: toDbPhone(employee.phone) };

      await employeesEndpoint.createEntry(newEmployee);
    } catch (exc) {
      console.log(exc);
    }
    // next onboarding step
    onRequestNextPage();
  };

  let content = (
    <>
      <h2 className={css(styles.title)}>{t('registerOrgTitle')}</h2>
      <h5 className={css(styles.desc)}>{t('registerOrgDesc')}</h5>
      <h2 className={css(styles.title, styles.titleSmall)}>{t('registerOrgCompany')}</h2>
      <div className={css(styles.input)}>
        <TextInput
          id="name"
          label={t('name')}
          value={organisation.name}
          onChange={onChange('name')}
          errorMessage={organisation.name.length > 0 ? errors.name : ''}
          required
          disabled={orgDisabled}
          className={css(styles.input)}
        />
        <div className={css(styles.input)}>
          <TextInput
            id="street"
            label={t('street')}
            value={organisation.street}
            onChange={onChange('street')}
            errorMessage={organisation.street.length > 0 ? errors.street : ''}
            required
            disabled={orgDisabled}
            className={css(styles.input)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="zipCode"
            label={t('zip')}
            value={organisation.zipCode}
            onChange={onChange('zipCode')}
            errorMessage={organisation.zipCode.length > 0 ? errors.zipCode : ''}
            required
            disabled={orgDisabled}
            className={css(styles.input, styles.input50)}
          />
          <TextInput
            id="city"
            label={t('city')}
            value={organisation.city}
            onChange={onChange('city')}
            errorMessage={organisation.city.length > 0 ? errors.city : ''}
            required
            disabled={orgDisabled}
            className={css(styles.input, styles.input50)}
          />
        </div>
        <h2 className={css(styles.title, styles.titleSmall, styles.account)}>
          {t('registerOrgYourAccount')}
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="firstName"
            label={t('firstName')}
            value={employee.firstName}
            onChange={onChangeEmployee('firstName')}
            errorMessage={employee.firstName.length > 0 ? errors.firstName : ''}
            required
            className={css(styles.input, styles.input50)}
          />
          <TextInput
            id="lastName"
            label={t('lastName')}
            value={employee.lastName}
            onChange={onChangeEmployee('lastName')}
            errorMessage={employee.lastName.length > 0 ? errors.lastName : ''}
            required
            className={css(styles.input, styles.input50)}
          />
        </div>
        <div className={css(styles.input)}>
          <TextInput
            id="phone"
            label={t('phone')}
            value={employee.phone}
            onChange={onChangeEmployee('phone')}
            errorMessage={employee.phone.length > 0 ? errors.phone : ''}
            required
            className={css(styles.input)}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      <Page onNextPress={onNextPress} isNextDisabled={isNextDisabled}>
        {content}
      </Page>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    account: {
      marginTop: 40,
    },
    input50: {
      flex: 0.49,
    },
    dividerLine: {
      paddingTop: 60,
      marginBottom: 50,
      borderBottom: `solid ${theme.color4} 1px`,
      width: '100%',
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 45,
      textAlign: 'right',
    },
    buttonLink: {
      display: 'flex',
      color: theme.color6,
      ':before, :after': {
        backgroundColor: theme.color6,
      },
    },
    input: {
      marginBottom: 15,
    },
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    titleSmall: {
      fontSize: 20,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    form: {
      alignItems: 'left',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: 720,
    },
    container: {
      ...fullScreenStyles,
      position: 'fixed',
      top: 0,
      left: 520,
      bottom: 0,
      backgroundColor: theme.color13,
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
    },
  });
