import { Button } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { carsEndpoint } from '../../Utils/API';
import { fullScreenStyles, verticalScrollStyles } from '../../Utils/Helpers';

let isUpdating = false;

const getRandomFloat = (min, max) => Math.random() * (max - min) + min;

const southWest = [6.126579, 46.2050282];
const northEast = [9.3458605, 47.4240636];

export default () => {
  const [isMigrationRunning, setMigrationRunning] = useState(false);
  const [cars] = carsEndpoint.useEntries();

  useEffect(() => {
    if (!isMigrationRunning || !cars || isUpdating) {
      return;
    }

    (async () => {
      isUpdating = true;

      for (let i = 0; i < cars.length; i++) {
        const car = cars[i];

        await carsEndpoint.updateEntry(car.id, {
          lastPosition: JSON.stringify([
            getRandomFloat(southWest[0], northEast[0]),
            getRandomFloat(southWest[1], northEast[1]),
          ]),
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      isUpdating = false;
      setMigrationRunning(false);

      setTimeout(() => setMigrationRunning(true), 2000);
    })();
  }, [cars, isMigrationRunning]);

  const isMigrationDisabled = !cars || !cars.length || isMigrationRunning;
  const startMigration = () => setMigrationRunning(true);

  return (
    <div className={css(styles.container)}>
      <Button variant="special" disabled={isMigrationDisabled} onPress={startMigration}>
        Start
      </Button>
      <pre className={css(styles.code)}>{JSON.stringify(cars, undefined, 2)}</pre>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    ...fullScreenStyles,
    ...verticalScrollStyles,
    padding: 20,
  },
  code: {
    marginTop: 20,
    backgroundColor: '#1E1E1E',
    color: '#CCCCCC',
    fontFamily: '"Courier New", Courier, monospace',
  },
});
