import React, { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { Theme, AppCode, AppSms, CheckCircle } from '@yourmileag/ui-kit';
import { fullScreenStyles } from '../../../../Utils/Helpers';
import { driversEndpoint } from '../../../../Utils/API';
import useModal from '../../../../Utils/useModal';
import PageBackground from './Components/PageBackground';

export default ({ onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const [smsStatus, setSmsStatus] = useState(false);
  const [driverPhone, setDriverPhone] = useState();

  const { t } = useTranslation();

  const { show, RenderModal } = useModal();

  const onNextPress = async () => {
    await localStorage.setItem('onBoarding:app', false);
    onRequestNextPage({ force: true, page: 'login' });
  };

  const sendSMS = async () => {
    setSmsStatus(true);
  };

  const showQRCode = async () => {
    show();
  };

  useEffect(() => {
    const fetchData = async () => {
      const driver = await driversEndpoint.getEntries({ fields: 'id,phone', limit: 1 });
      setDriverPhone(driver.items[0].phone);
    };

    fetchData();
  }, []);

  let content = (
    <>
      <div className={css(styles.appContainer)}>
        <h2 className={css(styles.title)}>{t('downloadDriverApp')}</h2>
        <h5 className={css(styles.desc)}>
          {t('downloadDriverAppDesc')
            .split('/n')
            .map((line, index) => (
              <p key={index}>{line}</p>
            ))}
        </h5>
        <div className={css(styles.squareContainer)}>
          <div className={css(styles.button, styles.removeMargin)}>
            <div className={css(styles.square)} onClick={() => sendSMS()}>
              {!smsStatus ? (
                <>
                  <AppSms className={css(styles.icon)} />
                  {t('downloadDriverAppLinkSms')}
                </>
              ) : (
                <>
                  <CheckCircle className={css(styles.icon, styles.check)} />
                  {t('downloadDriverAppLinkSmsSend', {
                    phone: `${driverPhone}`,
                  })}
                </>
              )}
            </div>
            <div className={css(styles.square)} onClick={() => showQRCode()}>
              <AppCode className={css(styles.icon)} />
              {t('downloadDriverAppLinkCode')
                .split('/n')
                .map((line, index) => (
                  <p key={`code${index}`}>{line}</p>
                ))}
            </div>
          </div>
        </div>
        <RenderModal />
      </div>
    </>
  );

  return (
    <>
      <PageBackground
        onNextPress={onNextPress}
        isNextDisabled={false}
        nextLable={t('appIsInstalled')}
      >
        {content}
      </PageBackground>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    squareContainer: {
      display: 'flex',
      width: 720,
      flexDirection: 'column',
      //   flexShrink: 20,
      justifyContent: 'space-between',
    },
    squareImage: {
      // position: 'absolute',
      // bottom: 0,
      // right: 0,
      // zIndex: 11,
      width: '100%',
      height: '80%',
      backgroundImage: 'url(/assets/images/mockup.png)',
      backgroundSize: 'cover',
    },
    removeMargin: {
      margin: 0,
    },
    icon: {
      fontSize: 110,
      paddingBottom: 20,
    },
    check: {
      color: theme.colorSuccess,
    },
    square: {
      display: 'flex',
      flexDirection: 'column',
      width: 320,
      height: 320,
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      color: theme.color3,
      fontWeight: 300,
      textAlign: 'center',
      lineHeight: '23px',
      padding: '0 30px',
    },
    dividerLine: {
      paddingTop: 60,
      marginBottom: 50,
      borderBottom: `solid ${theme.color4} 1px`,
      width: '100%',
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 45,
      textAlign: 'right',
    },
    buttonLink: {
      display: 'flex',
      color: theme.color6,
      ':before, :after': {
        backgroundColor: theme.color6,
      },
    },
    input: {
      marginBottom: 15,
    },
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    titleSmall: {
      fontSize: 20,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    form: {
      alignItems: 'left',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: 620,
    },
    container: {
      ...fullScreenStyles,
      position: 'fixed',
      top: 0,
      bottom: 0,
      display: 'flex',
    },
  });
