import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ className, dimensions }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={css(styles.breakSlot, className)} style={{ ...dimensions }}>
      <div className={css(styles.containerInner)}>{dimensions.width >= 40 && t('break')}</div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    breakSlot: {
      position: 'absolute',
      height: '100%',
      borderRadius: 5,
      boxShadow: theme.shadow1,
      overflow: 'hidden',
    },
    containerInner: {
      backgroundImage: `linear-gradient(45deg, ${theme.color4} 10%, ${theme.color12} 10%, ${theme.color12} 50%, ${theme.color4} 50%, ${theme.color4} 60%, ${theme.color12} 60%, ${theme.color12} 100%)`,
      backgroundSize: '12.73px 12.73px',
      border: `solid ${theme.color4} 2px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: 'lighter',
      height: '100%',
      padding: 10,
    },
  });
