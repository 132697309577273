import { Button, Theme, TextInput, Message } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../Utils/Helpers';

const useValidator = ({ price, completionNoteText }) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!price) {
      newErrors.price = t('fieldIsRequired');
    } else if (isNaN(price)) {
      newErrors.price = t('priceHasToBeValid');
    }

    if (!completionNoteText) {
      newErrors.completionNoteText = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [price, completionNoteText, t]);

  return errors;
};

export default ({ closeModal, completeAction, order }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const [price, setPrice] = useState(order.price);
  const [completionNoteText, setCompletionNoteText] = useState();

  const errors = useValidator({
    price: price,
    completionNoteText: completionNoteText,
  });

  return ReactDOM.createPortal(
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        <h1 className={css(styles.title)}>
          {t('completeOrder')}
        </h1>
        <p className={css(styles.content)}>{t('completeOrderText')}</p>
        <div className={css(styles.row)}>
          <div className={css(styles.column)}>
            <TextInput
              id="oc-price"
              label={t('priceInCurrency', { currency: order.fleet.rateCurrency })}
              value={price}
              onChange={(e) => {setPrice(e.target.value)}}
              required
              errorMessage={errors.price}
              clean
            />
          </div>
        </div>
        <div className={css(styles.row)}>
          <div className={css(styles.column)}>
            <TextInput
              id="oc-briefing"
              label={t('completeOrderReason')}
              value={completionNoteText}
              onChange={(e) => {setCompletionNoteText(e.target.value)}}
              required
              errorMessage={errors.completionNoteText}
              clean
            />
          </div>
        </div>
        <div className={css(styles.buttons)}>
          <Button size="m" variant="light" onPress={closeModal}>
            {t('cancel')}
          </Button>
          <div className={css(styles.spacer)} />
          <Button size="m" variant="dark" onPress={() => completeAction(price, completionNoteText)} disabled={Object.keys(errors).length > 0}>
            {t('completeOrder')}
          </Button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(44,62,80,0.7)',
      zIndex: 10,
    },
    containerInner: {
      backgroundColor: theme.color2,
      width: 500,
      padding: 30,
      borderRadius: 5,
    },
    icon: {
      marginRight: 20,
    },
    spacer: {
      width: 20,
    },
    title: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '28px',
      marginBottom: 15,
      color: theme.color3,
    },
    content: {
      color: theme.color1,
      lineHeight: '1.5em',
      marginBottom: 20,
      fontWeight: 300,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    row: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      marginBottom: 13,
    },
    column: {
      flex: 1,
    },
  });
