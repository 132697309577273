import CompanyData from './CompanyData';
import Dispatcher from './Dispatcher';
import InventoryCustomers from './InventoryCustomers';
import Main from './Main';

const tourRegistry = new Map([
  [Main.name, Main],
  [Dispatcher.name, Dispatcher],
  [CompanyData.name, CompanyData],
  [InventoryCustomers.name, InventoryCustomers],
]);

export const getTours = () => Array.from(tourRegistry.values());
export const getTour = name => tourRegistry.get(name);

export const getTranslatedQuestions = t =>
  getTours().map(tour => ({
    tourName: tour.name,
    tourCategoryId: tour.categoryId,
    question: tour.getTranslatedTour(t).question,
  }));

export const getCategories = () => {
  const tours = getTours();
  const categoryMap = {};
  tours.forEach(tour => (categoryMap[tour.categoryId] = true));

  return Object.keys(categoryMap);
};

export const getTranslatedQuestionsForCategory = (categoryId, t) =>
  getTours()
    .filter(tour => tour.categoryId === categoryId)
    .map(tour => ({
      tourName: tour.name,
      tourCategoryId: tour.categoryId,
      question: tour.getTranslatedTour(t).question,
    }));
