import { getIdentityId } from '@yourmileag/essentials-api-client';
import { GoLogo, Theme, Checkmark, Dropdown } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router-dom';
import i18n, { setLanguage } from '../../../../i18n';

import {
  carsEndpoint,
  fleetsEndpoint,
  getOrganisationId,
  iamEndpoint,
  ordersEndpoint,
  organisationsEndpoint,
  driversEndpoint,
  employeesEndpoint,
} from '../../../../Utils/API';
import { fullScreenStyles } from '../../../../Utils/Helpers';
import Registration from './Registration';
import Org from './Org';
import Fleets from './Fleets';
import Cars from './Cars';
import Driver from './Driver';
import App from './App';
import Login from './Login';

const pageComponentMap = {
  registration: Registration,
  org: Org,
  fleets: Fleets,
  cars: Cars,
  drivers: Driver,
  app: App,
  login: Login,
};

const labelsByTag = {
  de: 'Deutsch',
  en: 'English',
};

const tagsByLabel = {
  Deutsch: 'de',
  English: 'en',
};

const getIdealPageForOnboardingNecessity = (onboardingNecessity) => {
  if (!onboardingNecessity) {
    return 'registration';
  } else if (
    onboardingNecessity.isOrgOnboardingNecessary ||
    onboardingNecessity.isEmployeesOnboardingNecessary
  ) {
    return 'org';
  } else if (onboardingNecessity.isFleetsOnboardingNecessary) {
    return 'fleets';
  } else if (onboardingNecessity.isCarsOnboardingNecessary) {
    return 'cars';
  } else if (onboardingNecessity.isDriversOnboardingNecessary) {
    return 'drivers';
  } else if (JSON.parse(onboardingNecessity.isAppNecessary)) {
    return 'app';
  } else if (JSON.parse(onboardingNecessity.isLoginNecessary)) {
    return 'login';
  }

  return null;
};

const getOnboardingNecessity = (organisation, fleets, cars, drivers, employees, orders) => {
  const isOrgOnboardingNecessary = !organisation;
  const isEmployeesOnboardingNecessary = employees.length === 0;
  const isFleetsOnboardingNecessary = fleets.length === 0;
  const isCarsOnboardingNecessary = cars.length === 0;
  const isDriversOnboardingNecessary = drivers.length === 0;
  let isAppNecessary = !localStorage.getItem('onBoarding:app')
    ? true
    : JSON.parse(localStorage.getItem('onBoarding:app'));
  let isLoginNecessary = !localStorage.getItem('onBoarding:login')
    ? true
    : JSON.parse(localStorage.getItem('onBoarding:login'));

  // Quick Fix: Don't show Onboarding if there are already orders:
  if (orders.length > 0) {
    isAppNecessary = false;
    isLoginNecessary = false;
  }

  const isOnboardingNecessary =
    isOrgOnboardingNecessary ||
    isFleetsOnboardingNecessary ||
    isCarsOnboardingNecessary ||
    isEmployeesOnboardingNecessary ||
    isDriversOnboardingNecessary ||
    isLoginNecessary ||
    isAppNecessary;

  return {
    isOnboardingNecessary,
    isOrgOnboardingNecessary,
    isFleetsOnboardingNecessary,
    isCarsOnboardingNecessary,
    isEmployeesOnboardingNecessary,
    isDriversOnboardingNecessary,
    isLoginNecessary,
    isAppNecessary,
  };
};

export const isOnboardingNecessary = async () => {
  const identity = await iamEndpoint.getEntry(getIdentityId());
  if (!identity) {
    window.location = '/logout';
  }
  const organisation = await organisationsEndpoint.getEntry(getOrganisationId());

  let employees,
    fleets,
    cars,
    orders,
    drivers = null;

  if (getOrganisationId()) {
    employees = await employeesEndpoint.getEntries({ fields: 'id', limit: 1 });
    fleets = await fleetsEndpoint.getEntries({ fields: 'id', limit: 1 });
    cars = await carsEndpoint.getEntries({ fields: 'id', limit: 1 });
    drivers = await driversEndpoint.getEntries({ fields: 'id,phone', limit: 1 });
    orders = await ordersEndpoint.getEntries({ fields: 'id', limit: 1 });
  }

  return getOnboardingNecessity(
    organisation,
    fleets.items,
    cars.items,
    drivers.items,
    employees.items,
    orders.items,
  );
};

const useOnboardingNecessity = (initialOnboardingNecessity) => {
  const [onboardingNecessity, setOnboardingNecessity] = useState(initialOnboardingNecessity);
  const [organisation] = organisationsEndpoint.useEntry(getOrganisationId());

  let [employees] = [];
  let [fleets] = [];
  let [cars] = [];
  let [drivers] = [];
  let [orders] = [];

  if (getOrganisationId()) {
    [employees] = employeesEndpoint.useEntries({ fields: 'id', limit: 1 });
    [fleets] = fleetsEndpoint.useEntries({ fields: 'id', limit: 1 });
    [cars] = carsEndpoint.useEntries({ fields: 'id', limit: 1 });
    [drivers] = driversEndpoint.useEntries({ fields: 'id,phone', limit: 1 });
    [orders] = ordersEndpoint.useEntries({ fields: 'id', limit: 1 });
  }

  useEffect(() => {
    if (!organisation || !fleets || !cars || !drivers || !employees || !orders) {
      return;
    }
    setOnboardingNecessity(getOnboardingNecessity(organisation, fleets, cars, drivers, employees, orders));
  }, [organisation, employees, fleets, cars, drivers, orders]);

  return onboardingNecessity;
};

const Onboarding = ({ initialOnboardingNecessity, onLoadRequest, match, history }) => {
  const [value, setValue] = useState(labelsByTag[i18n.language]);
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const onboardingNecessity = useOnboardingNecessity(initialOnboardingNecessity);
  const [page, setPage] = useState();
  const { t } = useTranslation();

  const onChange = (newValue) => setValue(newValue);

  useEffect(() => {
    const lng = tagsByLabel[value];
    setLanguage(lng);
    localStorage.setItem('lng', lng);
  }, [value]);

  useEffect(() => {
    setPage(getIdealPageForOnboardingNecessity(onboardingNecessity));
    // eslint-disable-next-line
  }, [onboardingNecessity]);

  const onRequestNextPage = (options = {}) => {
    let nextPage;
    if (options.force && options.page) {
      nextPage = options.page;
    } else if (options.force) {
      onLoadRequest();
    } else {
      nextPage = getIdealPageForOnboardingNecessity(onboardingNecessity);
      if (!nextPage) {
        onLoadRequest();
        return;
      }
    }

    setPage(nextPage);
  };

  if (!page && page !== null) {
    setPage('registration');
    return null;
  }

  if (!page && page === null) {
    onRequestNextPage({ force: true });
    return null;
  }

  const Page = pageComponentMap[page];

  const bulletList = (items, selected) => {
    const selectedIndexKey = items.findIndex((items) => items.key === selected);
    return (
      <div className={css(styles.entryList)}>
        {items.map((item, index) => (
          <div key={index} className={css(styles.entry, index > 0 ? styles.divider : null)}>
            <div
              className={css(
                styles.bullet,
                selected === item.key ? styles.bulletActive : null,
                index < selectedIndexKey ? styles.bulletDone : styles.bulletNormal
              )}
            >
              {index < selectedIndexKey ? (
                <Checkmark width="20px" height="20px" color={theme.color2} />
              ) : null}
            </div>
            <div className={css(styles.lable, selected === item.key ? styles.lableActive : null)}>
              {t(item.lable)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Switch>
        <Redirect from="/verify" to="/onboarding" />
        <Redirect from="/" to="/onboarding" />
      </Switch>
      <div className={css(styles.containerInner)}>
        <div className={css(styles.header)}>
          <GoLogo />
        </div>
        {bulletList(
          [
            { lable: 'createAccount', key: 'registration' },
            { lable: 'createOrg', key: 'org' },
            { lable: 'createFleet', key: 'fleets' },
            { lable: 'createCar', key: 'cars' },
            { lable: 'createDriver', key: 'drivers' },
            { lable: 'downloadApp', key: 'app' },
            { lable: 'signinDriver', key: 'login' },
          ],
          page
        )}
        <div className={css(styles.language)}>
          <Dropdown
            top
            label={t('language')}
            onSelect={(e) => onChange(e)}
            options={Object.values(labelsByTag)}
            size="s"
            value={value}
            clean
          />
        </div>
      </div>
      <Page onRequestNextPage={onRequestNextPage} match={match} history={history} />
    </>
  );
};

// redirect to login
export default ({ match, history, initialOnboardingNecessity, onLoadRequest }) => {
  return (
    <Onboarding
      match={match}
      history={history}
      initialOnboardingNecessity={initialOnboardingNecessity}
      onLoadRequest={onLoadRequest}
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      position: 'fixed',
      top: 0,
      left: 520,
      bottom: 0,
      zIndex: 10,
      display: 'flex',
      overflow: 'scroll',
      justifyContent: 'center',
    },
    containerInner: {
      // zIndex: 8,
      ...fullScreenStyles,
      backgroundColor: theme.color2,
      width: 520,
      height: '100%',
      boxShadow: theme.shadow1,
    },
    divider: {
      paddingTop: 45,
    },
    language: {
      zIndex: 300,
      position: 'absolute',
      bottom: 90,
      marginLeft: 93,
    },
    entryList: {
      fontWeight: 300,
      color: theme.color3,
      display: 'inline-block',
      position: 'relative',
      marginLeft: 93,
      ':before': {
        background: theme.color12,
        bottom: 0,
        content: '""',
        left: 18,
        position: 'absolute',
        top: 15,
        width: 2,
        zIndex: 1,
      },
    },
    entry: {
      display: 'flex',
      alignItems: 'center',
    },
    bullet: {
      border: `solid ${theme.color2} 7px`,
      zIndex: 2,
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.color12,
    },
    bulletNormal: {
      ':before': {
        backgroundColor: theme.color2,
        content: '""',
        width: 22,
        height: 22,
        borderRadius: 11,
      },
    },
    bulletDone: {
      border: `solid ${theme.color2} 7px`,
      color: theme.color2,
      backgroundColor: theme.color8,
      ':after': 'none',
      // ':before': {
      //   backgroundColor: theme.color2,
      //   content: '""',
      //   width: 22,
      //   height: 22,
      //   borderRadius: 11,
      // },
    },
    bulletActive: {
      backgroundImage: theme.gradient3,
      ':before': {
        backgroundColor: theme.color2,
        content: '""',
        width: 10,
        height: 10,
        borderRadius: 5,
      },
    },
    lable: {
      fontWeight: 500,
      fontSize: 20,
      paddingLeft: 44,
      opacity: 0.5,
    },
    lableActive: {
      opacity: 1,
    },
    header: {
      fontSize: 80,
      color: theme.color3,
      textAlign: 'left',
      paddingTop: 60,
      paddingLeft: 100,
      paddingBottom: 70,
    },
  });
