import { Clock } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cronjobsEndpoint } from '../../../Utils/API';
import InventorySidePanel from '../../Essentials/Components/InventorySidePanel';
import SidePanelHeader from '../../Essentials/Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={cronjobsEndpoint.useEntry}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      renderHeader={(cronjob) =>
        cronjob && <SidePanelHeader icon={<Clock />} label={t('cronjob')} title={cronjob.name} />
      }
    />
  );
};
