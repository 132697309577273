import { Checkmark, Crossmark, Button, Unknown, InfoNote, ButtonPlain, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { cronjobsEndpoint } from '../../Utils/API';
import { usePageTitle } from '../../Utils/Helpers';
import InventoryScreen from '../Essentials/Components/InventoryScreen';
import SidePanel from './SidePanel';

export const getWeekDays = (cron) => {
  const cronDays = cron.split(',');

  const newCronDays = [];
  let hasSunday = false;

  cronDays.forEach((day) => {
    if (day === '0') {
      hasSunday = true;
      return;
    }

    newCronDays.push(day);
  });

  if (hasSunday) {
    newCronDays.push('0');
  }

  return newCronDays.map((day) => moment.weekdays(parseInt(day))).join(', ');
};

export default ({ match }) => (
  <Switch>
    <Redirect from={`${match.path}/`} exact to={`${match.path}/0`} />
    <Route path={`${match.path}/:page`} component={Cronjobs} />
  </Switch>
);

const Cronjobs = ({ match, history }) => {
  const [showInfoNote, setShowInfoNote] = useState(false);
  const { t } = useTranslation();
  usePageTitle(t('cronjobs'));

  const columns = [
    { title: t('name'), orderKey: 'name', searchKeys: ['name'], flex: 1 },
    { title: t('frequency'), orderKey: 'cron', flex: 1 },
    { title: t('status'), orderKey: 'status', width: 100 },
  ];

  const rowMap = (cronjob) => ({
    id: cronjob.id,
    columns: [
      cronjob.name,
      getWeekDays(cronjob.cron),
      cronjob.status === 'active' ? <div className={css(styles.columnHeaderStatus)}><Checkmark /><span className={css(styles.columnHeaderStatusText)}>{t(cronjob.status)}</span></div>  : <div className={css(styles.columnHeaderStatus)}><span>{t(cronjob.status)}</span><Crossmark /></div>,
    ],
  });

  function openInfoNote() {
    setShowInfoNote(true);
    document.querySelector('body').addEventListener('click', closeInfoNote);
  }

  function closeInfoNote(event) {
    if (event.target.tagName !== 'BUTTON') {
      setShowInfoNote(false);
      document.querySelector('body').removeEventListener('click', closeInfoNote);
    }
  }

  return (
    <div className={css(styles.container)}>
      <InventoryScreen
        match={match}
        history={history}
        SidePanelComponent={SidePanel}
        useData={cronjobsEndpoint.useEntries}
        columns={columns}
        rowMap={rowMap}
        searchLabel={t('searchCronjob')}
        customButton={
          <div className={css(styles.buttonContainer)}>
            <ButtonPlain
              id="inventory-add-button"
              className={css(styles.addCronjob)}
              iconLeft={<Unknown />}
              onClick={openInfoNote}
            >
              {t('addCronjob')}
            </ButtonPlain>
            {showInfoNote && (
              <div className={css(styles.noteContainer)}>
                <InfoNote
                  text={t('cronjobInfo')}
                  variant="light"
                  buttonLabel={t('linkCronjobInfo')}
                  onClick={() => {history.push(`/essentials/orders/overview/`);}}
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: 1060,
    margin: '0 auto',
    padding: 30,
  },
  buttonContainer: {
    position: 'relative',
  },
  noteContainer: {
    position: 'relative',
    top: 15,
  },
  addCronjob: {
    height: 46,
    color: 'rgb(177, 183, 188) !important',
    textTransform: 'uppercase',
    fontSize: '12px !important',
    letterSpacing: '1px !important'
  },
  columnHeaderStatus: {
    display: 'flex',
    alignItems: 'center'
  },
  columnHeaderStatusText: {
    marginLeft: 5
  },
});