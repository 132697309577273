import { Theme, User } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Driver from './Driver';

export default ({ selectedDriver, onSelect, useCars, useDrivers }) => {
  const drivers = useDrivers();
  const cars = useCars();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  if (!drivers || !cars) {
    return null;
  }

  const driversWithCar = {};
  cars.forEach(car => {
    if (!car.essentials_driver) {
      return;
    }

    driversWithCar[car.essentials_driver] = true;
  });

  const driversWithoutCar = drivers.filter(driver => !driversWithCar[driver.id]);

  return driversWithoutCar.length ? (
    driversWithoutCar.map(driver => (
      <Driver
        key={driver.id}
        driver={driver}
        isSelected={selectedDriver && selectedDriver.id === driver.id}
        onSelect={onSelect}
      />
    ))
  ) : (
    <div className={css(styles.noOrders)}>
      <div className={css(styles.icon)}>
        <User />
      </div>
      <div className={css(styles.text)}>
        {t('noUnassignedDrivers')}
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    noOrders: {
      alignItems: 'center',
      justifyContent:'center',
      fontSize: 14,
      marginTop: 20
    },
    icon: {
      fontSize: 40,
      color:'#b1b7bc',
      textAlign:'center'
    },
    text:{
      width:'60%',
      margin:'auto',
      marginTop:3,
      textAlign:'center',
      color:'#b1b7bc',
      lineHeight:1.3
    },
  });
