import {
  Cash,
  Company as CompanyIcon,
  OrderCreation as OrderCreationIcon,
  Keys,
  Card,
  Settings,
  createSnackbar,
  OrderAssignment as OrderAssignmentIcon,
} from '@yourmileag/ui-kit';
import { bugsnagClient } from '../../bugsnag';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { fullScreenStyles, usePageTitle, verticalScrollStyles } from '../../Utils/Helpers';
import Company from './Company';
import ApiKeys from './ApiKeys';
import Nav from './Components/Nav';
import Invoicing from './Invoicing';
import OrderCreation from './OrderCreation';
import Dispatcher from './Dispatcher';
import General from './General';
import { settingsEndpoint } from '../../Utils/API';
import License from './License';
import PurchaseDetail from './Purchases/PurchaseDetail';
import PurchaseOverview from './Purchases/PurchaseOverview';

const { updateEntry, createEntry, getEntries } = settingsEndpoint;

export const defaultSettings = {
  timelimit: {
    useAlternativeApp: false,
    isActive: true,
    dispo: 30,
    driver: 30,
  },
  emergency: {
    isActive: false,
    contacts: null,
    time: 5,
  },
  invoicing: {
    vatNumber: null,
    copyInvoiceEmail: null,
  },
  orderCreation: {
    proximity: null,
    proximityLat: null,
    proximityLng: null,
    smsSenderName: null,
  },
}

export const useSettings = () => {
  const [settings, setSettings] = React.useState({
    ...defaultSettings,
    loaded: false,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedSettings = await getEntries();
      if (fetchedSettings.items.length > 0) {
        setSettings({
          ...settings,
          ...fetchedSettings.items[0],
          loaded: true,
        });
      } else {
        setSettings({
          ...settings,
          loaded: true,
        });
      }
    };
    fetchData();
  }, []);

  return [settings, setSettings];
}

export const submitSettings = async (settings, t, name) => {
  const entry = cleanSettings(settings);
  try {
    if (!settings._id) {
      const _id = await createEntry(entry);
      settings = { ...settings, _id };
    } else {
      await updateEntry(settings._id, entry);
    }
    createSnackbar('success', t('successfullySaved', { name: name }));
  } catch (exc) {
    bugsnagClient.notify('Could not create or update ' + name + ' settings', { metaData: exc });
    createSnackbar('error', t('couldNotSave', { name: name }));
  }
  return settings;
}

export const cleanSettings = (settings) => {
  if (settings.emergency.contacts) {
    settings.emergency.contacts = settings.emergency.contacts.filter((contact) => (!!contact.value));
    if (settings.emergency.contacts.length === 0) {
      settings.emergency.isActive = false;
    }
  }
  const entry = {
    canUseAutoDispatcher: settings.canUseAutoDispatcher ? settings.canUseAutoDispatcher : false,
    canAddExtraCustomers: settings.canAddExtraCustomers ? settings.canAddExtraCustomers : false,
    canUsePassengerAmount: settings.canUsePassengerAmount ? settings.canUsePassengerAmount : false,
    canUseDayTrip: settings.canUseDayTrip ? settings.canUseDayTrip : false,
    autoDispatcherActive: settings.autoDispatcherActive ? settings.autoDispatcherActive : false,
//    autoDispatcher: settings.autoDispatcher ? settings.autoDispatcher : false,
    sendSMSNotifications: settings.sendSMSNotifications ? settings.sendSMSNotifications : false,
    disablePricing: settings.disablePricing ? settings.disablePricing : false,
//    extendedOrderCreation: settings.extendedOrderCreation ? settings.extendedOrderCreation : false,
    emergency: {
      isActive: settings.emergency.isActive,
      time: settings.emergency.isActive ? settings.emergency.time : null,
      contacts: !!settings.emergency.isActive
        ? settings.emergency.contacts.map((contact) => ({
          type: contact.type,
          value: contact.value,
        }))
        : null,
    },
    timelimit: settings.timelimit,
    invoicing: {
      vatNumber: settings.invoicing.vatNumber ? settings.invoicing.vatNumber : null,
      copyInvoiceEmail: settings.invoicing.copyInvoiceEmail ? settings.invoicing.copyInvoiceEmail : null,
    },
    orderCreation: {
      proximity: settings.orderCreation.proximity ? settings.orderCreation.proximity : null,
      proximityLat: settings.orderCreation.proximityLat ? settings.orderCreation.proximityLat : null,
      proximityLng: settings.orderCreation.proximityLng ? settings.orderCreation.proximityLng : null,
      smsSenderName: settings.orderCreation.smsSenderName ? settings.orderCreation.smsSenderName : null,
    },
  };
  return entry;
}

export default ({ match }) => {
  const { t } = useTranslation();
  usePageTitle(t('settings'));

  const navItems = [
    { title: t('general'), icon: <Settings />, path: `${match.path}/general`, component: General },
    {
      id: 'settings-nav-company',
      title: t('company'),
      icon: <CompanyIcon />,
      path: `${match.path}/company`,
      component: Company,
    },
    {
      title: t('invoicing'),
      icon: <Card />,
      path: `${match.path}/invoicing`,
      component: Invoicing,
    },
    {
      title: t('orderCreation'),
      icon: <OrderCreationIcon />,
      path: `${match.path}/orderCreation`,
      component: OrderCreation,
    },
    {
      title: t('dispatcher'),
      icon: <OrderAssignmentIcon />,
      path: `${match.path}/dispatcher`,
      component: Dispatcher,
    },
    {
      title: t('apikeys'),
      icon: <Keys />,
      path: `${match.path}/apikeys`,
      component: ApiKeys,
    },
    {
      title: t('license'),
      icon: <Keys />,
      path: `${match.path}/license`,
      component: License,
    },
    {
      title: t('invoice'),
      icon: <Cash />,
      link: `${match.path}/purchases/0`,
      path: `${match.path}/purchases/:page`,
      component: PurchaseOverview,
    },
  ];

  return (
    <>
      <div className={css(styles.outerContainer)}>
        <Nav style={styles.container} navItems={navItems} match={match} />
      </div>
      <Route path={`${match.url}/purchases/:page/:id`} component={PurchaseDetail} />
    </>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    ...fullScreenStyles,
    ...verticalScrollStyles,
  },
  container: {
    width: '100%',
    maxWidth: 1060,
    margin: '50px auto',
  },
});
