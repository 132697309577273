import { DateColumn, OriginDestinationColumn, StatusSmall, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router-dom';
import { ordersEndpoint } from '../../../Utils/API';
import { getStateLabels } from '../../../Utils/StateLabels';
import OrderContextMenu from '../ContextMenus/Order';
import PaginatedTable from './PaginatedTable';
import PropsRoute from './PropsRoute';

const { useEntries: useOrders } = ordersEndpoint;

export default ({ match, entry, type }) => (
  <Switch>
    <Redirect from={`${match.path}/`} exact to={`${match.path}/0`} />
    <PropsRoute
      path={`${match.path}/:page`}
      component={OrdersHistory}
      componentProps={{ entry, type }}
    />
  </Switch>
);

const OrdersHistory = ({ entry, match, history, type }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [orderBy, setOrderBy] = useState('timespan.startTimestamp:desc');

  const useDataAdditionalOptions = { orderBy, query: `${type}.id:${entry.id};status:new,assigning,assigned,arriving,arrived,completing,completed,problem` };
  const theme = useContext(Theme);

  const columns = [
    { title: 'ID', orderKey: '_id', width: 100 },
    { title: t('date'), orderKey: 'timespan.startTimestamp', width: 100 },
    { title: t('originDestination'), orderKey: 'origin.name', width: 370 },
    { title: t('status'), orderKey: 'status', width: 50 },
  ];

  return (
    <PaginatedTable
      match={match}
      history={history}
      headerContainerStyle={{ backgroundColor: theme.color2 }}
      preHeader={<div className={css(styles.header)} />}
      useData={useOrders}
      useDataAdditionalOptions={useDataAdditionalOptions}
      columns={columns}
      rows={
        data
          ? data.map((order) => ({
              id: order._id,
              columns: [
                <div className={css(styles.column)}>{`...${order._id.toString().slice(-5)}`}</div>,
                <DateColumn date={moment.unix(order.estimatedTimespan.startTimestamp).toDate()} />,
                <OriginDestinationColumn
                  origin={order.origin}
                  hasStopovers={order.stopovers.length > 0}
                  destination={order.destination}
                  anonymousOriginLabel={t('anonymousStart')}
                  anonymousDestinationLabel={t('anonymousEnd')}
                  noDestinationLabel={t('noDestinationYet')}
                />,
                <StatusSmall title={getStateLabels(t)[order.status]} status={order.status} />,
              ],
              afterRow: <OrderContextMenu order={order} history={history} />,
            }))
          : []
      }
      onChange={setData}
      rowLinkBasePath={'/essentials/orders/overview/0'}
      orderBy={orderBy}
      onChangeOrderBy={setOrderBy}
      useRowLinks
      clean
    />
  );
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E9EBED',
    padding: '22px 0',
    backgroundColor: 'white',
  },
  column: {
    flex: 1,
    textAlign: 'center',
  },
});
