import { PersonColumn, Theme, VehicleStandard } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { carsEndpoint, driversEndpoint } from '../../../../Utils/API';
import { s, toHumanPhone } from '../../../../Utils/Helpers';
import InventoryScreen from '../../Components/InventoryScreen';
import DriverDetail from '../../SidePanels/Driver';

const { useEntries: useDrivers } = driversEndpoint;
const { useEntries: useCars } = carsEndpoint;

const Car = ({ driverId }) => {
  const [car] = useCars({
    query: `[eq]essentials_driver:${driverId}`,
  });

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  if (!car || !car.length) {
    return null;
  }

  return (
    <div className={css(styles.assignedCar)}>
      <VehicleStandard />
      <div className={css(styles.assignedCarLabel)}>
        {s(car[0].manufacturer, car[0].model)} ({car[0].licensePlate})
      </div>
    </div>
  );
};

export default ({ match, history }) => {
  const { t } = useTranslation();

  const columns = [
    { title: t('name'), orderKey: 'lastName', searchKeys: ['lastName', 'firstName'], flex: 1 },
    { title: t('phone'), orderKey: 'phone', searchKeys: ['phone'], width: 220 },
  ];

  const rowMap = (driver) => ({
    id: driver.id,
    columns: [
      <div>
        <PersonColumn name={s(driver.lastName, driver.firstName)} />
        <Car driverId={driver.id} />
      </div>,
      toHumanPhone(driver.phone),
    ],
  });

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={DriverDetail}
      useData={useDrivers}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchDriver')}
      addButtonLabel={t('addDriver')}
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    assignedCar: {
      marginTop: 5,
      display: 'flex',
      color: theme.color1,
    },
    assignedCarLabel: {
      marginLeft: 10,
    },
  });
