import { FleetColumn, Theme, VehicleStandard, PersonSpeechRight } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { carsEndpoint, driversEndpoint } from '../../../../Utils/API';
import { s } from '../../../../Utils/Helpers';
import InventoryScreen from '../../Components/InventoryScreen';
import CarSidePanel from '../../SidePanels/Car';

const { useEntries: useCars } = carsEndpoint;
const { useEntry: useDriver } = driversEndpoint;

const Driver = ({ id }) => {
  const [driver] = useDriver(id);

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  if (!driver) {
    return null;
  }

  return (
    <div className={css(styles.assignedDriver)}>
      <PersonSpeechRight />
      <div className={css(styles.assignedDriverLabel)}>{s(driver.lastName, driver.firstName)}</div>
    </div>
  );
};

export default ({ match, history }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('licensePlate'),
      orderKey: 'licensePlate',
      searchKeys: ['licensePlate'],
      width: 130,
    },
    { title: t('model'), orderKey: 'manufacturer', searchKeys: ['manufacturer', 'model'], flex: 1 },
  ];

  const rowMap = (car) => ({
    id: car.id,
    driver: car.essentials_driver,
    columns: [
      car.licensePlate,
      <div>
        <FleetColumn car={s(car.manufacturer, car.model)} icon={<VehicleStandard />} />
        <Driver id={car.essentials_driver} />
      </div>,
    ],
  });

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={CarSidePanel}
      useData={useCars}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchCar')}
      addButtonLabel={t('addCar')}
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    assignedDriver: {
      marginTop: 5,
      display: 'flex',
      color: theme.color1,
    },
    assignedDriverLabel: {
      marginLeft: 10,
    },
  });
