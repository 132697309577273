import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { RadioButton, Female, Male, Unknown } from '@yourmileag/ui-kit';

export default ({ selected, onChange, disabled, id, clean, inline }) => {
  const { t } = useTranslation();

  return (
    <div className={css(styles.row, inline && styles.rowInline)}>
      <div className={css(styles.column, !!clean && styles.columnClean, inline && styles.columnInline)}>
        <RadioButton
          id={`${id}-unknown`}
          name="gender"
          iconLeft={<Unknown />}
          label={t('unknown')}
          value={'unknown'}
          selected={selected}
          onChange={onChange}
          disabled={disabled}
          clean={!!clean}
        />
      </div>
      <div className={css(styles.column, !!clean && styles.columnClean, inline && styles.columnInline)}>
        <RadioButton
          id={`${id}-female`}
          name="gender"
          iconLeft={<Female />}
          label={t('female')}
          value={'female'}
          selected={selected}
          onChange={onChange}
          disabled={disabled}
          clean={!!clean}
        />
      </div>
      <div className={css(styles.column, !!clean && styles.columnClean, inline && styles.columnInline)}>
        <RadioButton
          id={`${id}-male`}
          name="gender"
          iconLeft={<Male />}
          label={t('male')}
          value={'male'}
          selected={selected}
          onChange={onChange}
          disabled={disabled}
          clean={!!clean}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowInline: {
    display: 'block',
  },
  column: {
    flex: 1,
    marginRight: 13,
  },
  columnInline: {
    display: 'inline-block',
    marginTop: 5,
    marginBottom: 5,
  },
  columnClean: {
    ':last-child': {
      marginRight: 0,
    },
  },
});
