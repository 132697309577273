import {
  activate,
  getIdentityId,
  setBase,
  setLogout,
  setToken,
  useAppVersion,
  verify,
} from '@yourmileag/essentials-api-client';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { bugsnagClient } from '../../../bugsnag';
import { essentialsWsEndpoint, getOrganisationId } from '../../../Utils/API';
import LoadingScreen from '../Components/LoadingScreen';
import Maintenance from './Maintenance';
import Outdated from './Outdated';
import SessionTimeout from './SessionTimeout';
import Onboarding, { isOnboardingNecessary } from './Onboarding';
import { createSnackbar } from '@yourmileag/ui-kit';
import { useTranslation } from 'react-i18next';

export default ({ ContentComponent }) => {
  const { t } = useTranslation();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoadingIndicatorVisible, setLoadingIndicatorVisibility] = useState(true);
  const [hasSessionTimeout, setHasSessionTimeout] = useState(false);
  const [onboardingNecessity, setOnboardingNecessity] = useState();
  const [, setIdentityId] = useGlobal('identityId');
  const [, setApiBase] = useGlobal('apiBase');

  const appVersion = useAppVersion(
    process.env.REACT_APP_BUNDLE_IDENTIFIER,
    process.env.REACT_APP_BUNDLE_PLATFORM,
    process.env.REACT_APP_BUILD_NUMBER,
    process.env.REACT_APP_SYSTEMTOKEN
  );

  const onLoadRequest = async () => {
    const eventType = window.location.pathname.split('/')[1];
    let token;

    await essentialsWsEndpoint.disconnect();

    setLoadingProgress(0);
    setLoadingIndicatorVisibility(true);

    if (eventType === 'activate' || eventType === 'verify') {
      try {
        if (eventType === 'activate') {
          const hash = window.location.pathname.split('/')[2];

          token = await activate(hash);
        } else {
          const hash = window.location.pathname.split('/')[2];
          const otp = window.location.pathname.split('/')[3];

          token = await verify(hash, otp);
        }

        setLoadingProgress(50);
      } catch (exc) {
        bugsnagClient.notify(`Could not ${eventType}`, { metaData: exc });
        token = null;

        createSnackbar(
          'error',
          eventType === 'activate' ? t('couldNotActivateAccount') : t('couldNotVerifyAccount')
        );
      }
    } else if (eventType === 'logout') {
      token = null;
    } else {
      token = Cookies.get('token');
    }

    if (!token) {
      Cookies.remove('token');
      setIdentityId(null);
      delete bugsnagClient.user;
      setLoadingProgress(100);
      return;
    }

    Cookies.set('token', token, { expires: 30 });
    setToken(token);

    // google analytics
    window.gtag('set', { organisation: getOrganisationId() });

    const identityId = getIdentityId();
    bugsnagClient.user = { id: identityId };
    setIdentityId(identityId);
    setOnboardingNecessity(await isOnboardingNecessary());

    await essentialsWsEndpoint.connect();
    essentialsWsEndpoint.setOnHeartbeatTimeoutListener(() => setHasSessionTimeout(true));

    setLoadingProgress(100);
  };

  const onLogout = () => {
    window.location = '/logout';
  };

  useEffect(() => {
    if (!appVersion) {
      return;
    }
    setLogout(onLogout);
    setApiBase(appVersion.apiBase);
    setBase(appVersion.apiBase);
    onLoadRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion]);

  return (
    <>
      {loadingProgress === 100 &&
        (((appVersion.status === 'live' || !!localStorage.getItem('dev')) &&
          (onboardingNecessity && onboardingNecessity.isOnboardingNecessary ? (
            <Onboarding
              initialOnboardingNecessity={onboardingNecessity}
              onLoadRequest={onLoadRequest}
            />
          ) : (
            <ContentComponent />
          ))) ||
          (appVersion.status === 'maintenance' && <Maintenance />) ||
          (appVersion.status === 'outdated' && <Outdated />))}
      {isLoadingIndicatorVisible && (
        <LoadingScreen
          progress={loadingProgress}
          onAnimationsFinished={setLoadingIndicatorVisibility}
        />
      )}
      {hasSessionTimeout && <SessionTimeout />}
      {/* {hasDeleteEntry && <DeleteEntry />} */}
    </>
  );
};
