import React from 'react';
import {
  AvatarCarBusiness,
  AvatarCarStandard,
  AvatarCarVan,
  AvatarCarWagon,
  AvatarCarWheelchair,
} from '@yourmileag/ui-kit';

const iconMap = new Map([
  ['standard', <AvatarCarStandard />],
  ['business', <AvatarCarBusiness />],
  ['van', <AvatarCarVan />],
  ['wagon', <AvatarCarWagon />],
  ['wheelchairTaxi', <AvatarCarWheelchair />],
  ['small', <AvatarCarStandard />],
]);

export const getFleetIconAvatar = (category) => iconMap.get(category);
