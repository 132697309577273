import {
  Clock,
  FleetColumn,
  OriginDestinationColumn,
  Theme,
  Button,
  ProgressBar,
  Eye,
  Error as ErrorIcon,
  DispositionReady as DispositionReadyIcon,
  NoDriver as NoDriverIcon,
  People,
  Company,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getFleetIcon } from '../../../Utils/Fleet';
import { s } from '../../../Utils/Helpers';
import OrderDetail from '../Components/OrderDetail';

export default ({ order, isSelected, onSelect, onDrag, history, disabled }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: order._id, type: 'order' },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    begin: () => onDrag(order),
    end: () => onDrag(),
  });
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const [showDetail, setShowDetail] = React.useState(false);

  const progressTotalDuration = Cookies.get('dispoRequestTime')*1000
  const progressStartPercentage = 100 - (moment(order.createAt) + progressTotalDuration - moment()) * 100 / progressTotalDuration

  const startTime = moment.unix(order.estimatedTimespan.startTimestamp).format(t('timeFormat'));
  const endTime = moment.unix(order.estimatedTimespan.endTimestamp).format(t('timeFormatLong'));

  const onClick = () => onSelect(order);

  const getIconForStatusMessage = statusMessage => {
    switch (statusMessage) {
      case 'orderNotDispatched':
        return <DispositionReadyIcon className={css(styles.statusIcon)} />;
      case 'driverNotOnTheWay':
        return <NoDriverIcon className={css(styles.statusIcon)} />;
      default:
        return <ErrorIcon className={css(styles.statusIcon)} />;
    }
  }

  let orderTotalPassengerAmount = 0;
  if (order.passengerAmount) {
    orderTotalPassengerAmount += order.passengerAmount;
  }
  if (order.passengerAmount && order.extraCustomers) {
    order.extraCustomers.forEach((customer) => {
      orderTotalPassengerAmount += customer.passengerAmount;
    });
  }

  return (
    <div
      ref={drag}
      className={css(
        styles.container,
        styles[`container${order.status.toUpperCase()}`],
        disabled && styles.containerDisabled,
        isDragging && styles.containerDragging,
        isSelected && styles.containerSelected,
        isSelected && styles[`containerSelected${order.status.toUpperCase()}`]
      )}
      onClick={onClick}
    >
      {order.status === 'problem' && (
        <div
          className={css(
            styles.containerHeader,
            styles[`containerHeader${order.status.toUpperCase()}`]
          )}
        >
          { getIconForStatusMessage(order.statusMessage) }
          { order.statusMessage ? t(order.statusMessage) : t('problem') }
        </div>
      )}
      { order.status === 'new' && <ProgressBar totalDuration={progressTotalDuration} startPercentage={progressStartPercentage} /> }
      <div className={css(styles.order)} onClick={onClick}>
        <div className={css(styles.orderHeader)}>
          <div className={css(styles.customerName)}>
            {(order.extraCustomers && order.extraCustomers.length) > 0 ? (
              (1 + order.extraCustomers.length) + ' ' + t('customers')
            ) : (
              s(order.customer.lastName, order.customer.firstName)
            )}
          </div>
          <Button variant="light" iconLeft={<Eye/>} size={'s'} onPress={() => setShowDetail(true)}>{t('details')}</Button>
          {showDetail && (
            <OrderDetail order={order} history={history} closeModal={() => setShowDetail(false)}/>
          )}
        </div>
        <div className={css(styles.originDestinationContainer)}>
          <div className={css(styles.originDestination)}>
            <OriginDestinationColumn
              origin={order.origin}
              hasStopovers={order.stopovers.length > 0}
              destination={order.destination}
            />
          </div>
        </div>
        <div className={css(styles.timeAndFleet)}>
          <div className={css(styles.time)}>
            <FleetColumn icon={<Clock />} car={`${startTime} - ${endTime}${order.type == 'one-way' ? ' (One-way)' : ''}${order.type == 'day-trip' ? ' (Day trip)' : ''}`} />
          </div>
          <div className={css(styles.time)}>
            <FleetColumn icon={getFleetIcon(order.fleet.icon)} car={order.fleet.label} />
          </div>
          {orderTotalPassengerAmount ? (
            <div className={css(styles.time)}>
              <FleetColumn icon={<People />} car={orderTotalPassengerAmount} />
            </div>
          ) : null}
          {order.partnerName && (
            <div className={css(styles.time)}>
              <FleetColumn icon={<Company />} car={order.partnerName + (order.partnerAuthor ? (' [' + order.partnerAuthor + ']') : '')} />
            </div>
          )}
        </div>
        {order.noteText && (
          <div>
            <div className={css(styles.briefing)}>
              <div className={css(styles.briefingTitle)}>{t('driverBriefing')}</div>
              {order.noteText.substring(0, 80)}
              {order.noteText.length > 80 ? '...' : ''}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      boxShadow: theme.shadow1,
      border: 'none',
      borderRadius: 5,
      marginBottom: 20,
      cursor: 'grab',
      position: 'relative',
      zIndex: -1,
      ':hover': {
        zIndex: 0,
      },
      overflow: 'hidden',
    },
    containerPROBLEM: {
      backgroundColor: theme.colorError,
      background: '#FF434F',
      backgroundImage: 'linear-gradient(232deg, #FF6C7C 33%, #FF3A46 100%)',
      boxShadow: '0 5px 24px -6px rgba(255,58,70,0.40)',
      borderRadius: 5,
      padding: 3,
      fontSize: 14,
    },
    statusIcon: {
      color: theme.color2,
      marginRight: 15,
      fontSize: 22,
    },
    containerDragging: {
      backgroundColor: theme.color12,
      opacity: '0.2 !important',
      borderColor: 'transparent !important',
    },
    containerNEW: {
      margin: 2,
      marginBottom: 16,
    },
    containerSelected: {
      ':hover': {
        opacity: 1,
      },
    },
    containerSelectedNEW: {
      border: `2px solid ${theme.color5}`,
      margin: 0,
      marginTop: -2,
      marginBottom: 14,
    },
    containerDisabled: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    containerHeader: {
      color: theme.color2,
      padding: '5px 20px',
      display: 'flex',
      alignItems: 'center',
    },
    order: {
      backgroundColor: theme.color2,
      border: '2px solid transparent',
      borderRadius: 3,
      padding: '10px 20px',
    },
    orderHeader: {
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
    },
    customerName: {
      width: '100%',
    },
    originDestinationContainer: {
      display: 'flex',
    },
    originDestination: {
      fontSize: 14,
      flex: 1,
      border: '1px solid rgba(44, 62, 80, 0.1)',
      padding: '0 10px',
      borderRadius: 5,
      minWidth: 0,
    },
    timeAndFleet: {
      marginTop: 10,
    },
    time: {
      display: 'inline-block',
      fontSize: 14,
      lineHeight: '1.2em',
      marginRight: 20,
    },
    briefing: {
      padding: '15px 0 5px',
      marginTop: 5,
      borderTop: '1px solid rgba(44, 62, 80, 0.1)',
      fontSize: 14,
      lineHeight: 1.4,
    },
    briefingTitle: {
      fontSize: 12,
      opacity: 0.4,
      marginBottom: 5,
    },
  });
