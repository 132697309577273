import { PersonColumn } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customersEndpoint } from '../../../../Utils/API';
import { s, toHumanPhone } from '../../../../Utils/Helpers';
import InventoryScreen from '../../Components/InventoryScreen';
import CustomerSidePanel from '../../SidePanels/Customer';

const { useEntries: useCustomers } = customersEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  const columns = [
    { title: t('name'), orderKey: 'lastName', searchKeys: ['lastName', 'firstName'], flex: 1 },
    { title: t('phone'), orderKey: 'phone', searchKeys: ['phone'], width: 220 },
  ];

  const rowMap = (customer) => ({
    id: customer.id,
    columns: [
      <PersonColumn name={s(customer.lastName, customer.firstName)} />,
      toHumanPhone(customer.phone),
    ],
  });

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={CustomerSidePanel}
      useData={useCustomers}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchCustomer')}
      addButtonLabel={t('addCustomer')}
    />
  );
};
