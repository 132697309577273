import { InfoRow } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ordersEndpoint } from '../../../Utils/API';
import InventoryDetail from '../../Essentials/Components/InventoryDetail';
import OrderPreview from '../Components/OrderPreview';
import { getWeekDays } from '..';

const { useEntry: useOrder } = ordersEndpoint;

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const [order] = useOrder(entry.essentials_orders);

  return (
    <InventoryDetail entry={entry} history={history} match={match}>
      <InfoRow label={t('orderNumber')}>
        {entry.essentials_orders}
        {order && <OrderPreview order={order} clean />}
      </InfoRow>
      <InfoRow label={t('name')}>{entry.name}</InfoRow>
      <InfoRow label={t('status')}>{t(entry.status)}</InfoRow>
      <InfoRow label={t('frequency')}>{getWeekDays(entry.cron)}</InfoRow>
    </InventoryDetail>
  );
};
