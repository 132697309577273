import {
  Switch,
  SectionHeader,
  TimeDisplay,
  Timer,
  Theme,
  Dropdown,
  TextInput,
  AngleUp,
  AngleDown,
  Button,
  Trash as TrashIcon,
  Plus as PlusIcon,
} from '@yourmileag/ui-kit';
import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { css, StyleSheet } from 'aphrodite/no-important';
import { AsYouType } from 'libphonenumber-js';
import { validateMail, validatePhoneNumber } from '../../Utils/Validators';
import Content from './Components/Content';
import { useEffect } from 'reactn';
import { useSettings, submitSettings, defaultSettings } from '../Settings';

const defaultEmergencyMail = {
  type: 'email',
  value: '',
  errorMessage: 'shouldNotBeEmpty',
};
const defaultEmergencyMessage = {
  type: 'sms',
  value: '+41',
  errorMessage: 'shouldNotBeEmpty',
};
const defaultEmergencyContact = defaultEmergencyMail;

const costInfo = {
  email: 'infoEmail',
  sms: 'infoSms',
};

export default () => {
  const { t } = useTranslation();
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const [settings, setSettings] = useSettings();

  useEffect(() => {
    setSettings((s) =>
      !!settings.emergency.isActive
        ? {
            ...s,
            emergency: {
              ...s.emergency,
              time: settings.emergency.time ? settings.emergency.time : defaultSettings.emergency.time,
              contacts: settings.emergency.contacts ? settings.emergency.contacts : [defaultEmergencyContact],
            },
          }
        : {
            ...s,
            emergency: { ...s.emergency, time: null, contacts: null },
          }
    );
  }, [settings.emergency.isActive]);

  useEffect(() => {
    setSettings((s) =>
      !!settings.timelimit.isActive
        ? {
            ...s,
            timelimit: { ...s.timelimit, dispo: defaultSettings.timelimit.dispo, driver: defaultSettings.timelimit.driver },
          }
        : { ...s, timelimit: { ...s.timelimit, dispo: null, driver: null } }
    );
  }, [settings.timelimit.isActive]);

  const isSubmitDisabled =
    settings.emergency.isActive &&
    settings.emergency.contacts &&
    settings.emergency.contacts.length > 0 &&
    settings.emergency.contacts.filter((contact) => !!contact.errorMessage && !!contact.value).length > 0;

  const onSubmit = async (e) => {
    e.preventDefault();
    setSettings(
      await submitSettings(settings, t, t('dispatcher'))
    );
  };

  const addEmergencyContact = () => {
    setSettings({
      ...settings,
      emergency: {
        ...settings.emergency,
        contacts: settings.emergency.contacts
          ? [...settings.emergency.contacts, defaultEmergencyContact]
          : [defaultEmergencyContact],
      },
    });
  };

  const deleteEmergencyContact = (i) => {
    if (settings.emergency.contacts.length > 1) {
      setSettings({
        ...settings,
        emergency: {
          ...settings.emergency,
          contacts: settings.emergency.contacts
            .slice(0, i)
            .concat(settings.emergency.contacts.slice(i + 1, settings.emergency.contacts.length)),
        },
      });
    } else {
      setSettings({
        ...settings,
        emergency: { ...settings.emergency, contacts: [defaultEmergencyContact] },
      });
    }
  };

  const validateEntry = (value, type) => {
    const errMsg =
      type === defaultEmergencyMessage.type ? validatePhoneNumber(value) : validateMail(value);
    return errMsg !== true && errMsg;
  };

  const changeEmergencyContactValue = (value, type, i) => {
    setSettings({
      ...settings,
      emergency: {
        ...settings.emergency,
        contacts: settings.emergency.contacts.map((contact, index) =>
          index === i
            ? {
                ...contact,
                value: value,
                errorMessage: validateEntry(value, type),
              }
            : contact
        ),
      },
    });
  };

  const changeEmergencyContactType = (contact, i) =>
    setSettings({
      ...settings,
      emergency: {
        ...settings.emergency,
        contacts: settings.emergency.contacts.map((eContact, index) =>
          index === i ? contact : eContact
        ),
      },
    });

  const formatInput = (userInput, type) => {
    if (userInput.length >= 3 && type === defaultEmergencyMessage.type) {
      const asYouType = new AsYouType('CH');
      asYouType.input(userInput);
      return asYouType.formattedOutput;
    } else {
      return userInput;
    }
  };

  return (
    <Content
      title={t('dispatcher')}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
      className={css(styles.container)}
    >
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>
          {t('dispatcherSettings')}
        </SectionHeader>
        <div className={css(styles.content)}>
          <div className={css(styles.label)}>{t('useAlternativeAppSettingsLabel')}</div>
          <Switch
            id="sfd-useAlternativeApp"
            checked={settings.timelimit.useAlternativeApp}
            onChange={() =>
              setSettings({
                ...settings,
                timelimit: { ...settings.timelimit, useAlternativeApp: !settings.timelimit.useAlternativeApp },
              })
            }
            checkedLabel={t('yes')}
            uncheckedLabel={t('no')}
          />
        </div>
        <div className={css(styles.divider)} />
        <div className={css(styles.content)}>
          <div className={css(styles.label)}>{t('dispatcherSettingsLabel')}</div>
          <Switch
            id="sfd-timelimitActive"
            checked={settings.timelimit.isActive}
            onChange={() =>
              setSettings({
                ...settings,
                timelimit: { ...settings.timelimit, isActive: !settings.timelimit.isActive },
              })
            }
            checkedLabel={t('yes')}
            uncheckedLabel={t('no')}
          />
        </div>
        {!!settings.timelimit.isActive && (
          <>
            <div className={css(styles.divider)} />
            <div className={css(styles.content)}>
              <div>
                <div className={css(styles.label)}>{t('timeLimitDispatching')}</div>
                <div className={css(styles.description)}>{t('timeLimitDispatchingDesc')}</div>
              </div>
              <div className={css(styles.timerContainer)}>
                {settings.loaded && (
                  <Timer
                    clean
                    label={t('sec')}
                    value={settings.timelimit.dispo}
                    onChange={(dispo) =>
                      setSettings({
                        ...settings,
                        timelimit: { ...settings.timelimit, dispo },
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className={css(styles.divider)} />
            <div className={css(styles.content)}>
              <div>
                <div className={css(styles.label)}>{t('timeLimitDriver')}</div>
                <div className={css(styles.description)}>{t('timeLimitDriverDesc')}</div>
              </div>
              <div className={css(styles.timerContainer)}>
                {settings.loaded && (
                  <Timer
                    clean
                    label={t('sec')}
                    value={settings.timelimit.driver}
                    onChange={(driver) =>
                      setSettings({
                        ...settings,
                        timelimit: { ...settings.timelimit, driver },
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className={css(styles.divider)} />
            <TimeDisplay
              value={settings.timelimit.driver + settings.timelimit.dispo}
              totalTimeLabel={t('waitingTimeTotal', {
                time: moment.duration({
                  seconds: settings.timelimit.driver + settings.timelimit.dispo,
                }).locale(i18next.language).humanize(),
              })}
              times={[
                {
                  label: t('waitingTimeShort'),
                  type: 'success',
                },

                {
                  label: t('waitingTimeMedium'),
                  type: 'warning',
                },

                {
                  label: t('waitingTimeLong'),
                  type: 'error',
                },
              ]}
            />
          </>
        )}
      </div>
      <div className={css(styles.section)}>
        <SectionHeader className={css(styles.sectionHeader)}>
          {t('emergencyNotifications')}
        </SectionHeader>
        <div className={css(styles.content)}>
          <div className={css(styles.label)}>{t('emergencyNotificationsLabel')}</div>
          <div className={css(styles.switch)}>
            <Switch
              id="sfd-emergencyNotifications"
              checked={settings.emergency.isActive}
              onChange={() =>
                setSettings({
                  ...settings,
                  emergency: { ...settings.emergency, isActive: !settings.emergency.isActive },
                })
              }
              checkedLabel={t('yes')}
              uncheckedLabel={t('no')}
            />
          </div>
        </div>
        {!!settings.emergency.isActive && (
          <>
            {settings.emergency.contacts &&
              settings.emergency.contacts.length &&
              settings.emergency.contacts.map((emergencyContact, i) => (
                <div className={css(styles.emergencyContact)} key={`contact_${i}`}>
                  <div className={css(styles.emergencyContactType)}>
                    <Dropdown
                      onSelect={(value) => changeEmergencyContactType(value, i)}
                      options={[defaultEmergencyMail, defaultEmergencyMessage]}
                      valueLabel="type"
                      size="s"
                      clean
                      ValueComponent={({ isOpen }) => (
                        <div
                          key={`${emergencyContact.type}_val`}
                          className={css(styles.dropdownValueContainer)}
                        >
                          <div className={css(styles.dropdownValueContainerInner)}>
                            <div className={css(styles.dropdownLabel)}>
                              {t(emergencyContact.type)}
                            </div>
                            <div className={css(styles.dropdownInfo)}>
                              {t(costInfo[emergencyContact.type])}
                            </div>
                          </div>
                          {isOpen ? (
                            <AngleUp className={css(styles.dropdownAngle)} />
                          ) : (
                            <AngleDown className={css(styles.dropdownAngle)} />
                          )}
                        </div>
                      )}
                      OptionComponent={({ option }) => (
                        <div
                          key={option.type}
                          className={css(styles.dropdownValueContainer, styles.dropdownOption)}
                        >
                          <div>{t(option.type)}</div>
                          <div className={css(styles.dropdownInfo)}>{t(costInfo[option.type])}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className={css(styles.emergencyContactTo)}>{t('to')}</div>
                  <div className={css(styles.emergencyContactValue)}>
                    <TextInput
                      clean
                      id={`input_${i}`}
                      size="s"
                      required
                      value={formatInput(emergencyContact.value, emergencyContact.type)}
                      onChange={(e) =>
                        changeEmergencyContactValue(e.target.value, emergencyContact.type, i)
                      }
                      errorMessage={emergencyContact.errorMessage}
                    />
                  </div>
                  <div
                    className={css(styles.deleteEmergencyContact)}
                    onClick={() => deleteEmergencyContact(i)}
                  >
                    <TrashIcon className={css(styles.deleteEmergencyContactSvg)} />
                  </div>
                </div>
              ))}
            <div className={css(styles.content, styles.alignRight)}>
              <Button onPress={addEmergencyContact} icon={<PlusIcon />} size="s">
                {t('addEmergencyContact')}
              </Button>
            </div>
            <div className={css(styles.divider)} />
            <div className={css(styles.content)}>
              <div className={css(styles.label)}>{t('emergencyNotificationsTimelimit')}</div>
              <div className={css(styles.timerContainer)}>
                {settings.loaded && (
                  <Timer
                    clean
                    label={t('sec')}
                    value={settings.emergency.time}
                    minValue={5}
                    onChange={(time) =>
                      setSettings({
                        ...settings,
                        emergency: { ...settings.emergency, time },
                      })
                    }
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={css(styles.divider)} />
    </Content>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      padding: 45,
    },
    sectionHeader: {
      marginBottom: 20,
    },
    divider: {
      paddingBottom: 20,
      borderBottom: `1px solid ${theme.color12}`,
      marginBottom: 20,
    },
    content: {
      display: 'flex',
      padding: 15,
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'lighter',
      letterSpacing: 0.1,
    },
    section: {
      paddingBottom: 25,
    },
    timerContainer: {
      marginLeft: 20,
      width: 150,
    },
    label: {
      flex: 1,
      color: theme.color3,
      marginRight: 20,
      alignSelf: 'center',
      fontSize: 14,
    },
    description: {
      paddingTop: 10,
      color: theme.color1,
      fontSize: 12,
    },
    switch: {
      alignSelf: 'center',
    },
    emergencyContactType: {
      width: 240,
      marginRight: 16,
    },
    emergencyContact: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 15px',
    },
    dropdownValueContainer: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 20,
      flex: 1,
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 'lighter',
    },
    dropdownValueContainerInner: {
      justifyContent: 'space-between',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    dropdownOption: {
      borderTop: `1px solid ${theme.color10}`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingRight: 54,
      ':hover': {
        backgroundColor: 'rgba(1, 203, 255, 0.15)',
      },
    },
    dropdownInfo: {
      fontWeight: 'lighter',
      color: theme.color1,
      fontSize: 12,
    },
    dropdownAngle: {
      color: theme.color3,
      fontSize: 26,
      marginLeft: 14,
      marginRight: 14,
    },
    emergencyContactTo: {
      color: theme.color1,
      fontWeight: 'lighter',
    },
    emergencyContactValue: {
      flex: 1,
      marginLeft: 16,
    },
    deleteEmergencyContact: {
      background: theme.gradient1,
      width: 26,
      height: 26,
      marginLeft: 16,
      display: 'flex',
      borderRadius: 3,
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        cursor: 'pointer',
      },
    },
    deleteEmergencyContactSvg: {
      fontSize: 20,
      color: '#ffffff',
    },
    alignRight: {
      justifyContent: 'flex-end',
      fontSize: 10,
    },
  });
