import {
  TextInput,
  InfoRow,
} from '@yourmileag/ui-kit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateMail } from '../../Utils/Validators';
import Content from './Components/Content';
import { useEffect } from 'reactn';
import { useSettings, submitSettings } from '../Settings';

export const useInvoicingValidator = (settings) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!settings) {
      return;
    }

    const newErrors = {};

    if (settings.invoicing.copyInvoiceEmail) {
      const isMailValid = validateMail(settings.invoicing.copyInvoiceEmail);
      if (isMailValid !== true) {
        newErrors.copyInvoiceEmail = isMailValid;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [settings, t]);

  return errors;
};

export default () => {
  const { t } = useTranslation();

  const [settings, setSettings] = useSettings();
  const errors = useInvoicingValidator(settings);
  const isSubmitDisabled = Object.keys(errors).length > 0;

  const onSubmit = async (e) => {
    e.preventDefault();
    setSettings(
      await submitSettings(settings, t, t('invoicing'))
    );
  };

  return (
    <Content
      title={t('invoicing')}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
    >
      <InfoRow center label={t('vatNumber')}>
        <TextInput
          id="vatNumber"
          value={settings.invoicing.vatNumber}
          onChange={(event) =>
            setSettings({
              ...settings,
              invoicing: { ...settings.invoicing, vatNumber: event.target.value },
            })
          }
          required={false}
          errorMessage={errors.vatNumber}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('copyInvoiceEmail')}>
        <TextInput
          id="copyInvoiceEmail"
          type="email"
          value={settings.invoicing.copyInvoiceEmail}
          onChange={(event) =>
            setSettings({
              ...settings,
              invoicing: { ...settings.invoicing, copyInvoiceEmail: event.target.value },
            })
          }
          errorMessage={errors.copyInvoiceEmail}
          required
          clean
          size="s"
        />
      </InfoRow>
    </Content>
  );
};

