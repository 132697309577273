import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG,
  appVersion: process.env.REACT_APP_BUILD_NUMBER,
});

bugsnagClient.use(bugsnagReact, React);

export const ErrorBoundary = bugsnagClient.getPlugin('react');
