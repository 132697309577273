import {
  ButtonAdd,
  ButtonPlain,
  Crossmark,
  InfoBox,
  Start,
  Stopover,
  Target,
  Theme,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextInputAddress from '../../../Components/TextInputAddress';

export default ({
  route,
  destination,
  onChangeDestination,
  origin,
  onChangeOrigin,
  stopovers,
  onChangeStopovers,
  autoFocus,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const useStopovers = true;

  const onAddressSelect = (set) => (address) => {
    if (typeof set === 'number') {
      onStopoverSelect(set)(address);
    } else {
      set(address);
    }
  };

  const onStopoverSelect = (index) => (address) => {
    if (!address) {
      return;
    }

    const newStopovers = [...stopovers];
    Object.assign(newStopovers[index], address);
    onChangeStopovers(newStopovers);
    
  };
  const onStopoverAdd = () => {
    let id = Math.floor(Math.random() * 1000);
    onChangeStopovers([...stopovers, { id : id }]); 
  };
  const onStopoverRemove = (index) => () => {
    const newStopovers = [...stopovers];
    newStopovers.splice(index, 1);
    onChangeStopovers(newStopovers);
  };

  return (
    <>
      <div className={css(styles.row)}>
        <div className={css(styles.addressContainer)}>
          <TextInputAddress
            className={css(styles.addressInput)}
            id="oc-origin-address"
            iconLeft={
              <Start className={css(!!origin && styles.routeIconFilled)} />
            }
            label={t('origin')}
            onSelect={onAddressSelect(onChangeOrigin)}
            initialValue={origin && origin.name}
            autoFocus={autoFocus}
            required
            errorMessage={errorMessage && !origin ? errorMessage : null}
          />
          <div className={css(styles.separator)} />
          {stopovers.map((stopover, index) => (
            <div className={css(styles.stopover)} key={stopover.id}>
              <TextInputAddress
                className={css(styles.addressInput)}
                id={`oc-stop-over-address${index}`}
                iconLeft={
                  <Stopover
                    className={css(!!stopover && styles.routeIconFilled)}
                  />
                }
                label={`${t('stopover')} ${index + 1}`}
                onSelect={onAddressSelect(index)}
                initialValue={stopover && stopover.name}
                required
                errorMessage={errorMessage && !stopover.name ? errorMessage : null}
                actionIcon={
                  <Crossmark />
                }
                onActionIconClick={onStopoverRemove(index)}
              />
              <div className={css(styles.separator)} />
            </div>
          ))}
          <TextInputAddress
            className={css(styles.addressInput)}
            id="oc-destination-address"
            iconLeft={
              <Target
                className={css(!!destination && styles.routeIconFilled)}
              />
            }
            label={t('destination')}
            onSelect={onAddressSelect(onChangeDestination)}
            initialValue={destination && destination.name}
            required
            errorMessage={errorMessage ? errorMessage : null}
          />
        </div>
      </div>
      <div className={css(styles.row)}>
        {useStopovers && (
          <div className={css(styles.column)}>
            {stopovers.length < 5 && (
              <ButtonAdd onPress={onStopoverAdd}>{t('addStopover')}</ButtonAdd>
            )}
          </div>
        )}
        <div className={css(styles.infoBoxContainer)}>
          <InfoBox>
            <>
              <div className={css(styles.infoText)}>
                {(route && route.duration.text) || t('durationTime')}
              </div>
              <div className={css(styles.infoText, styles.distance)}>
                {(route && route.distance.text) || t('durationKm')}
              </div>
            </>
          </InfoBox>
        </div>
      </div>
    </>
  );
};

const routeIcon = {
  fontSize: 46,
  marginRight: -15,
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    row: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      marginBottom: 13,
    },
    infoBoxContainer: {
      marginTop: -13,
      marginRight: 22,
      marginBottom: 20,
    },
    column: {
      flex: 1,
      marginRight: 13,
    },
    addressContainer: {
      flex: 1,
      boxShadow: theme.shadow1,
      marginRight: 13,
      borderRadius: 5,
    },
    addressInput: {
      boxShadow: 'none',
    },
    infoText: {
      fontSize: 14,
      fontWeight: 'lighter',
    },
    distance: {
      marginLeft: 10,
      color: theme.color1,
    },
    stopover: {
      position: 'relative',
    },
    remove: {
      position: 'absolute',
      top: 0,
      right: 10,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: 30,
      color: theme.color1,
    },
    routeIconStart: {
      ...routeIcon,
      color: theme.color3,
      marginBottom: -10,
    },
    routeIconStopOver: {
      color: theme.color3,
      ...routeIcon,
    },
    routeIconTarget: {
      color: theme.color3,
      ...routeIcon,
      marginTop: -10,
    },
    routeIconFilled: {
      color: '#abb2b9',
    },
    separator: {
      backgroundColor: theme.color10,
      height: 1,
    },
  });
