import { AvatarEmployeeFemale, AvatarEmployeeMale, AvatarClientUnknown } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { employeesEndpoint } from '../../../../Utils/API';
import { s } from '../../../../Utils/Helpers';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';

const { useEntry: useEmployee } = employeesEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={useEmployee}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      renderHeader={(employee) => {
        if (!employee) {
          return;
        }

        let icon;
        switch (employee.gender) {
          case 'male':
            icon = <AvatarEmployeeMale />;
            break;
          case 'female':
            icon = <AvatarEmployeeFemale />;
            break;
          default:
            icon = <AvatarClientUnknown />;
        }

        return (
          <SidePanelHeader
            icon={icon}
            label={t('employee')}
            title={s(employee.lastName, employee.firstName)}
          />
        );
      }}
    />
  );
};
