import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';

export default ({ children, style }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return <h2 className={css(styles.title, style)}>{children}</h2>;
};

const getThemedStyles = theme =>
  StyleSheet.create({
    title: {
      fontSize: 22,
      marginBottom: 20,
    },
  });
