import {
  Theme,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { _MapContext } from 'react-map-gl';
import { Route } from 'react-router-dom';
import {
  fullScreenStyles,
  getPathParent,
  s,
  usePageTitle,
  verticalScrollStyles,
} from '../../../Utils/Helpers';
import OrderDetail from '../Components/OrderDetail';
import CustomerSidePanel from '../SidePanels/Customer';
import DriverSidePanel from '../SidePanels/Driver';

export default ({ match, history }) => {
  const { t } = useTranslation();
  usePageTitle(s(t('orderNumber'), match.params.id));
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  
  const onBackPress = () => history.push(getPathParent(match.url));

  return (
    <>
      <div className={css(styles.container)}>
        <OrderDetail orderId={match.params.id} onBackPress={onBackPress} match={match} history={history}></OrderDetail>
      </div>
      <Route path={`${match.url}/customer/:id`} component={CustomerSidePanel} />
      <Route path={`${match.url}/driver/:id`} component={DriverSidePanel} />
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      ...verticalScrollStyles,
    },
  });
