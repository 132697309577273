import { TabNav } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePageTitle } from '../../../../Utils/Helpers';
import Cars from './Cars';
import Customers from './Customers';
import Drivers from './Drivers';
import Employees from './Employees';
import Fleets from './Fleets';
import POIs from './POIs';

export default ({ match }) => {
  const { t } = useTranslation();
  usePageTitle(t('inventory'));

  const navItems = [
    {
      title: t('customers'),
      id: 'inventory-nav-customers',
      path: `${match.path}/customers`,
      component: Customers,
    },
    { title: t('drivers'), path: `${match.path}/drivers`, component: Drivers },
    { title: t('cars'), path: `${match.path}/cars`, component: Cars },
    { title: t('fleets'), path: `${match.path}/fleets`, component: Fleets },
    { title: t('employees'), path: `${match.path}/employees`, component: Employees },
    { title: t('pois'), path: `${match.path}/pois`, component: POIs },
  ];

  return (
    <div className={css(styles.container)}>
      <TabNav className={css(styles.nav)} navItems={navItems} />
      <Switch>
        <Redirect from={match.path} exact to={navItems[0].path} />
        {navItems.map(navItem => (
          <Redirect key={navItem.path} from={navItem.path} exact to={`${navItem.path}/0`} />
        ))}
        {navItems.map(navItem => (
          <Route key={navItem.path} path={`${navItem.path}/:page`} component={navItem.component} />
        ))}
      </Switch>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: 1060,
    margin: '0 auto',
    padding: 30,
  },
  nav: {
    padding: '45px 45px 0 30px !important',
  },
});
