import { AvatarPoi } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { poisEndpoint } from '../../../../Utils/API';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';

const { useEntry: usePOI } = poisEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={usePOI}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      renderHeader={(poi) =>
        poi && <SidePanelHeader icon={<AvatarPoi />} label={t('poi')} title={poi.name} />
      }
    />
  );
};
