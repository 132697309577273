import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { NavigationControl } from 'react-map-gl';

export default () => (
  <div className={css(styles.container)}>
    <NavigationControl showCompass={false} />
  </div>
);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
});
