import {
  createEndpoint,
  createWsEndpoint,
  getOwner,
  setProtocol,
  setWsProtocol,
} from '@yourmileag/essentials-api-client';
import { getTimespanFilterForDay, getStartFilterForDay, getEndFilterForDay } from './Helpers';

if (process.env.REACT_APP_BUILD_LOCAL === 'true') {
  setProtocol('http');
  setWsProtocol('ws');
} else {
  setProtocol('https');
  setWsProtocol('wss');
}

export const getOrganisationId = () => {
  const owner = getOwner();

  if (!owner) {
    return null;
  }

  return owner.split('_')[1];
};

export const essentialsWsEndpoint = createWsEndpoint('essentials');

export const apiKeyEndpoint = createEndpoint('identity', 'apikey');
export const iamEndpoint = createEndpoint('identity', 'identity');
export const customersEndpoint = createEndpoint('essentials', 'customers');
export const employeesEndpoint = createEndpoint('essentials', 'employees');
export const driversEndpoint = createEndpoint('essentials', 'drivers');
export const fleetsEndpoint = createEndpoint('essentials', 'fleets');
export const carsEndpoint = createEndpoint('essentials', 'cars');
export const poisEndpoint = createEndpoint('essentials', 'pois');
export const ordersEndpoint = createEndpoint('essentials', 'orders');
export const breaksEndpoint = createEndpoint('essentials', 'breaks');
export const organisationsEndpoint = createEndpoint('essentials', 'organisations');
export const orderLocationsEndpoint = createEndpoint('locations', 'orders');
export const cronjobsEndpoint = createEndpoint('cronjobs', 'orders');
export const settingsEndpoint = createEndpoint('essentials', 'settings');
export const subscriptionsEndpoint = createEndpoint('saas', 'subscriptions');
export const modulesEndpoint = createEndpoint('saas', 'modules', true);
export const purchasesEndpoint = createEndpoint('saas', 'purchases');

export const getQueryForFilters = (filters) => {
  let query = '';

  if (!filters) {
    return query;
  }

  filters.forEach((filter) => {
    if (filter.values.length === 0) {
      return;
    }

    if (query) {
      query += ';';
    }

    if (filter.type === 'date') {
      query += getTimespanFilterForDay(filter.attribute, filter.values[0].value);
      return;
    }

    if (filter.type === 'start_date') {
      query += getStartFilterForDay(filter.attribute, filter.values[0].value);
      return;
    }

    if (filter.type === 'end_date') {
      query += getEndFilterForDay(filter.attribute, filter.values[0].value);
      return;
    }

    if (filter.type === 'status') {
      query += `[eq]${filter.attribute}:${filter.values
        .map((value) => value.value)
        .join(',')}`;
      return;
    }

    query += `${filter.attribute}:${filter.values.map((value) => `[${value.value}]`).join(',')}`;
  });

  return query;
};
