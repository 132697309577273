import { createSnackbar, InfoRow, Switch, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'reactn';
import { bugsnagClient } from '../../../bugsnag';
import { employeesEndpoint } from '../../../Utils/API';
import {
  convertEmptyStringsToNull,
  getPathParent,
  join,
  s,
  toDbPhone,
  toHumanPhone,
} from '../../../Utils/Helpers';
import { validateMail, validatePhoneNumber, validateZipCode } from '../../../Utils/Validators';
import GenderSelection from './GenderSelection';
import InventoryCreateOrUpdate from './InventoryCreateOrUpdate';
import useDeleteModal from '../../../Utils/useDeleteModal';

const { getEntry: getEmployee } = employeesEndpoint;

export const useIdentityValidator = (identity, isIdentifiedByMailAddress) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!identity.lastName) {
      newErrors.lastName = t('fieldIsRequired');
    }

    if (!identity.firstName) {
      newErrors.firstName = t('fieldIsRequired');
    }

    const isPhoneNumberValid = validatePhoneNumber(identity.phone);
    if (isPhoneNumberValid !== true) {
      newErrors.phone = isPhoneNumberValid;
    }

    if (isIdentifiedByMailAddress) {
      const isMailValid = validateMail(identity.mailAddress);
      if (isMailValid !== true) {
        newErrors.mailAddress = isMailValid;
      }
    }

    const isZipCodeValid = identity.zipCode ? validateZipCode(identity.zipCode) : true;
    if (isZipCodeValid !== true) {
      newErrors.zipCode = isZipCodeValid;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [identity, t, isIdentifiedByMailAddress]);

  return errors;
};

export default ({
  defaultPerson,
  person: personProp,
  updatePerson,
  createPerson,
  deletePerson,
  history,
  match,
  isIdentifiedByMailAddress,
}) => {
  const { t } = useTranslation();
  const [person, setPerson] = useState({
    ...defaultPerson,
    ...personProp,
  });
  const [identityId] = useGlobal('identityId');
  const errors = useIdentityValidator(person, isIdentifiedByMailAddress);
  const isSaveDisabled = Object.keys(errors).length > 0;
  const { show, RenderModal } = useDeleteModal();

  useEffect(() => {
    const mergedPerson = { ...defaultPerson, ...personProp };
    setPerson({ ...mergedPerson, phone: toHumanPhone(mergedPerson.phone) });
  }, [personProp, defaultPerson]);

  const onChange = (attribute) => (e) => setPerson({ ...person, [attribute]: e.target.value });
  const onChangeStatus = (e) =>
    setPerson({ ...person, status: e.target.checked ? 'active' : 'inactive' });

  const goToParent = () => history.push(getPathParent(match.url));
  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSaveDisabled) {
      return;
    }

    let newPerson = { ...person, phone: toDbPhone(person.phone) };

    if (!personProp || newPerson.noteText !== personProp.noteText) {
      const author = await getEmployee(identityId);
      newPerson.noteTimestamp = moment().unix();
      newPerson.noteAuthor = s(author.lastName, author.firstName);
    }

    newPerson = convertEmptyStringsToNull(newPerson);

    try {
      if (personProp) {
        await updatePerson(person.id, newPerson);
        goToParent();
      } else {
        const newPersonId = await createPerson(newPerson);
        history.push(join(getPathParent(match.url), newPersonId));
      }

      createSnackbar(
        'success',
        t('successfullySaved', { name: s(newPerson.lastName, newPerson.firstName) })
      );
    } catch (exc) {
      let message = t('couldNotSave', { name: s(newPerson.lastName, newPerson.firstName) });

      if (exc.data.statusCode === 422) {
        if (isIdentifiedByMailAddress) {
          message = t('duplicateEntryMailAddress');
        } else {
          message = t('duplicateEntryPhoneNumber');
        }
      } else {
        bugsnagClient.notify('Could not create or update person', { metaData: exc });
      }

      createSnackbar('error', message);
    }
  };

  const deleteAction = async () => {
    await deletePerson(person.id);
    history.push(getPathParent(getPathParent(match.url)));

    createSnackbar(
      'info',
      t('successfullyDeleted', { name: s(person.lastName, person.firstName) })
    );
  };
  const onDelete = async () => {
    show();
  };

  return (
    <InventoryCreateOrUpdate
      history={history}
      match={match}
      onSubmit={onSubmit}
      onDelete={person && onDelete}
      note={person.noteText}
      onNoteChange={onChange('noteText')}
      isSaveDisabled={isSaveDisabled}
    >
      <InfoRow center label={t('gender')}>
        <GenderSelection
          id="pcou-gender"
          selected={person.gender}
          onChange={onChange('gender')}
          inline
          clean
        />
      </InfoRow>
      <InfoRow center label={t('lastName')} required>
        <TextInput
          autoFocus
          id="pcou-last-name"
          value={person.lastName || ''}
          onChange={onChange('lastName')}
          required
          errorMessage={errors.lastName}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('firstName')} required>
        <TextInput
          id="pcou-first-name"
          value={person.firstName || ''}
          onChange={onChange('firstName')}
          required
          errorMessage={errors.firstName}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('phone')} required>
        <TextInput
          id="pcou-phone"
          type="phone"
          value={person.phone}
          onChange={onChange('phone')}
          required
          errorMessage={errors.phone}
          disabled={!person.id ? false : true}
          clean
          size="s"
        />
      </InfoRow>
      {isIdentifiedByMailAddress && (
        <InfoRow center label={t('mail')} required>
          <TextInput
            id="pcou-mailAddress"
            type="email"
            value={person.mailAddress || ''}
            onChange={onChange('mailAddress')}
            required
            errorMessage={errors.mailAddress}
            clean
            size="s"
          />
        </InfoRow>
      )}
      <InfoRow center label={t('address')}>
        <TextInput
          id="pcou-street"
          value={person.street || ''}
          onChange={onChange('street')}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={`${t('zip')}/${t('city')}`}>
        <div className={css(styles.zipAndCity)}>
          <div className={css(styles.zipCode)}>
            <TextInput
              id="pcou-zipCode"
              value={person.zipCode || ''}
              onChange={onChange('zipCode')}
              errorMessage={errors.zipCode}
              clean
              size="s"
            />
          </div>
          <div className={css(styles.city)}>
            <TextInput
              id="pcou-city"
              value={person.city || ''}
              onChange={onChange('city')}
              clean
              size="s"
            />
          </div>
        </div>
      </InfoRow>
      <InfoRow center label={t('active')}>
        <Switch id="pcou-active" checked={person.status === 'active'} onChange={onChangeStatus} />
      </InfoRow>
      <RenderModal deleteAction={deleteAction} />
    </InventoryCreateOrUpdate>
  );
};

const styles = StyleSheet.create({
  zipAndCity: {
    display: 'flex',
  },
  zipCode: {
    width: 150,
    marginRight: 10,
  },
  city: {
    flex: 1,
  },
});
