import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Redirect, Route, Switch } from 'react-router-dom';
import { fullScreenStyles, usePageTitle } from '../../Utils/Helpers';
import { getOrganisationId } from '../../Utils/API';
import { useRealtimeDispatcherDb } from './Effects/RealtimeDb';
import Cars from './Screens/Cars';
import Loading from './Screens/Loading';
import OrdersAndDrivers from './Screens/OrdersAndDrivers';
import { useTranslation } from 'react-i18next';

const Container = ({ history }) => {
  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedDriver, setSelectedDriver] = useState();
  const [currDate, setCurrDate] = useState(moment());
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const {
    isBreaksInitialized,
    isCarsInitialized,
    isDriversInitialized,
    isFleetsInitialized,
    isOrdersInitialized,
    useCars,
    useOrders,
    useOrdersForCar,
    useBreaksForDriver,
    useDriver,
    useDrivers,
    useFleets,
  } = useRealtimeDispatcherDb(currDate, getOrganisationId());

  const onSelectOrder = (order) => {
    if (selectedOrder && order && selectedOrder._id === order._id) {
      setSelectedOrder();
    } else {
      setSelectedOrder(order);
    }

    setSelectedDriver();
  };

  const onSelectDriver = (driver) => {
    setSelectedOrder();
    setSelectedDriver(driver);
  };

  const onChangeDate = (date) => {
    setCurrDate(date);
    setSelectedDriver();
    setSelectedOrder();
  };

  return (
    <Loading
      isBreaksInitialized={isBreaksInitialized}
      isCarsInitialized={isCarsInitialized}
      isDriversInitialized={isDriversInitialized}
      isFleetsInitialized={isFleetsInitialized}
      isOrdersInitialized={isOrdersInitialized}
    >
      <div className={css(styles.container)}>
        <DndProvider backend={HTML5Backend}>
          <Cars
            selectedOrder={selectedOrder}
            selectedDriver={selectedDriver}
            onSelect={onSelectOrder}
            history={history}
            date={currDate}
            onChangeDate={onChangeDate}
            useCars={useCars}
            useOrdersForCar={useOrdersForCar}
            useBreaksForDriver={useBreaksForDriver}
            useDriver={useDriver}
            useFleets={useFleets}
          />
          <OrdersAndDrivers
            selectedOrder={selectedOrder}
            onDragOrder={setSelectedOrder}
            onSelectOrder={onSelectOrder}
            selectedDriver={selectedDriver}
            onSelectDriver={onSelectDriver}
            date={currDate}
            history={history}
            onChangeDate={setCurrDate}
            useOrders={useOrders}
            useCars={useCars}
            useDrivers={useDrivers}
          />
        </DndProvider>
      </div>
    </Loading>
  );
};

export default ({ match }) => {
  const { t } = useTranslation();
  usePageTitle(t('dispatcher'));

  return (
    <Switch>
      <Redirect from={match.path} exact={true} to={`${match.path}/orders`} />
      <Route path={`${match.path}/orders/:orderId?`} component={Container} />
      <Route path={`${match.path}/problems/:orderId?`} component={Container} />
      <Route path={`${match.path}/drivers/:driverId?`} component={Container} />
    </Switch>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      color: theme.color3,
    },
  });
