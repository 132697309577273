import {
  Button,
  DateColumn,
  External,
  FleetColumn,
  OriginDestinationColumn,
  PersonColumn,
  Status,
  Theme,
  VehicleStandard,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import { Parser } from 'json2csv';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { customersEndpoint, driversEndpoint, getQueryForFilters, ordersEndpoint } from '../../../Utils/API';
import { getFleetIcon } from '../../../Utils/Fleet';
import { s, toHumanPhone, usePageTitle } from '../../../Utils/Helpers';
import { toPrice } from '../../../Utils/Price';
import { getStateLabels } from '../../../Utils/StateLabels';
import Customer from '../Components/Customer';
import Filter from '../Components/Filter';
import PaginatedTable from '../Components/PaginatedTable';
import OrderContextMenu from '../ContextMenus/Order';
import OrderDetail from './OrderDetail';
import OrderCreation from '../Screens/OrderCreation';

const { useEntries: useOrders, getEntries: getOrders } = ordersEndpoint;
const { useEntries: useCustomers } = customersEndpoint;
const { useEntries: useDrivers } = driversEndpoint;

export default ({ match }) => (
  <Switch>
    <Redirect from={`${match.path}/`} exact to={`${match.path}/0`} />
    <Route path={`${match.path}/:page/:update_id/update`} component={OrderCreation} />
    <Route path={`${match.path}/:page/:id`} component={OrderDetail} />
    <Route path={`${match.path}/:page`} component={OrderOverview} />
  </Switch>
);

const getAvailableFilters = (t) => [
  {
    label: t('origin'),
    placeholder: t('searchAddress'),
    type: 'text',
    attribute: 'origin.name',
    values: [],
    dateSourceOptions: { useHook: useOrders },
  },
  {
    label: t('destination'),
    placeholder: t('searchAddress'),
    type: 'text',
    attribute: 'destination.name',
    values: [],
    dateSourceOptions: { useHook: useOrders },
  },
  {
    label: t('customer'),
    type: 'person',
    attribute: 'customer.id',
    values: [],
    dateSourceOptions: { useHook: useCustomers },
  },
  {
    label: t('partner'),
    type: 'text',
    attribute: 'partnerName',
    values: [],
    dateSourceOptions: {
      useHook: useOrders,
      key: 'partnerName',
    },
  },
  {
    label: t('driver'),
    type: 'person',
    attribute: 'driver.id',
    values: [],
    dateSourceOptions: { useHook: useDrivers },
  },
  {
    label: t('status'),
    type: 'status',
    attribute: 'status',
    values: [
      {
        label: getStateLabels(t).new,
        value: 'new',
      },
      {
        label: getStateLabels(t).assigning,
        value: 'assigning',
      },
      {
        label: getStateLabels(t).assigned,
        value: 'assigned',
      },
      {
        label: getStateLabels(t).arriving,
        value: 'arriving',
      },
      {
        label: getStateLabels(t).arrived,
        value: 'arrived',
      },
      {
        label: getStateLabels(t).completing,
        value: 'completing',
      },
      {
        label: getStateLabels(t).completed,
        value: 'completed',
      },
      {
        label: getStateLabels(t).problem,
        value: 'problem',
      },
    ],
  },
  {
    label: t('startDate'),
    type: 'start_date',
    attribute: 'start__estimatedTimespan',
    values: [],
  },
  {
    label: t('endDate'),
    type: 'end_date',
    attribute: 'end__estimatedTimespan',
    values: [],
  },
];

const cacheKey = 'cache:filters:orderOverview';

const uncacheFilterValues = (filters) => {
  const cachedFilterValues = JSON.parse(localStorage.getItem(cacheKey));
  filters.forEach((filter) => (filter.values = cachedFilterValues[filter.attribute] || filter.values));
  return filters;
};

const cacheFilterValues = (filters) => {
  const filterValues = {};
  filters.forEach((filter) => (filterValues[filter.attribute] = filter.values));
  localStorage.setItem(cacheKey, JSON.stringify(filterValues));
};

const getCachedOrDefaultFilters = (t) => {
  let cachedFilterValues = localStorage.getItem(cacheKey);
  const availableFilters = getAvailableFilters(t);

  return cachedFilterValues ? uncacheFilterValues(availableFilters) : availableFilters;
};

const fields = [
  '_id',
  'car',
  'customer',
  'driver',
  'fleet',
  'timespan',
  'estimatedTimespan',
  'origin',
  'stopovers',
  'destination',
  'price',
  'currency',
  'status',
  'pricing',
  'cronjob',
  'estimatedDistance',
  'extraCustomers',
];

const exportFields = [
  '_id',
  'car.manufacturer',
  'car.model',
  'car.licensePlate',
  'customer.phone',
  'customer.lastName',
  'customer.firstName',
  'driver.phone',
  'driver.lastName',
  'driver.firstName',
  'fleet.label',
  'timespan',
  'estimatedTimespan',
  'estimatedTimespan',
  'origin.name',
  'destination.name',
  'price',
  'estimatedDistance',
  'noteText',
  'currency',
  'status',
  'pricing',
  'returnOrderForId',
  'partnerName',
  'extraCustomers',
];

const OrderOverview = ({ match, history }) => {
  const { t } = useTranslation();
  usePageTitle(t('orderOverview'));
  const [filters, setFilters] = useState(getCachedOrDefaultFilters(t));
  const [orders, setOrders] = useState();
  const [orderBy, setOrderBy] = useState('estimatedTimespan.startTimestamp:desc');
  const query = getQueryForFilters(filters);

  useEffect(() => {
    cacheFilterValues(filters);
  }, [filters]);

  useEffect(() => {
    if (query) {
      window.gtag('event', 'search', { search_term: query });
    }
  }, [query]);

  const queryStatus = (query ? ';' : '') + 'status:new,assigning,assigned,arriving,arrived,completing,completed,problem';

  const options = {
    query: query + (query.indexOf('status:') == -1 ? queryStatus : ''),
    fields: fields.join(','),
    orderBy,
  };

  const columns = [
    { title: t('numeroSign'), orderKey: '_id', width: 90 },
    { title: t('date'), orderKey: 'estimatedTimespan.startTimestamp', width: 110 },
    { title: t('originDestination'), orderKey: 'origin.name', flex: 1 },
    { title: t('distance'), orderKey: 'estimatedDistance', width: 110 },
    { title: t('customer'), orderKey: 'customer.lastName', width: 180 },
    { title: t('driver'), orderKey: 'driver.lastName', width: 180 },
    { title: t('fleet'), orderKey: 'fleet.label', width: 180 },
    { title: t('price'), orderKey: 'price', width: 100 },
    { title: t('status'), orderKey: 'status', width: 180 },
  ];

  const cleanExport = (data) => {
    const defaultValue = '-';
    const dateFormat = 'DD.MM.YY';
    const timeFormat = 'HH.mm';
    let fields = [];
    const fieldsPart1 = [
      {
        label: 'Auftragsnummer',
        value: '_id',
        default: defaultValue
      },
      {
        label: 'Automarke',
        value: 'car.manufacturer',
        default: defaultValue
      },
      {
        label: 'Modell',
        value: 'car.model',
        default: defaultValue
      },
      {
        label: 'Nummernschild',
        value: 'car.licensePlate',
        default: defaultValue
      },
      {
        label: 'Telefonnummer Gast 1',
        value: 'customer.phone',
        default: defaultValue
      },
      {
        label: 'Nachname Gast 1',
        value: 'customer.lastName',
        default: defaultValue
      },
      {
        label: 'Vorname Gast 1',
        value: 'customer.firstName',
        default: defaultValue
      },
    ];
    const fieldsPart2 = [
      {
        label: 'Organisation',
        value: 'partnerName',
        default: defaultValue
      },
      {
        label: 'Telefonnummer Fahrer',
        value: 'driver.phone',
        default: defaultValue
      },
      {
        label: 'Nachname Fahrer',
        value: 'driver.lastName',
        default: defaultValue
      },
      {
        label: 'Vorname Fahrer',
        value: 'driver.firstName',
        default: defaultValue
      },
      {
        label: 'Flotte',
        value: 'fleet.label',
        default: defaultValue
      },
      {
        label: 'Startzeit',
        value: (row, field) => row.timespan ? moment.unix(row.timespan.startTimestamp).format(timeFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Startdatum',
        value: (row, field) => row.timespan ? moment.unix(row.timespan.startTimestamp).format(dateFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Endzeit',
        value: (row, field) => row.timespan ? moment.unix(row.timespan.endTimestamp).format(timeFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Enddatum',
        value: (row, field) => row.timespan ? moment.unix(row.timespan.endTimestamp).format(dateFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Fahrzeit (in Minuten)',
        value: (row, field) => {
          if (row.timespan) {
            return moment.unix(row.timespan.endTimestamp).diff(
              moment.unix(row.timespan.startTimestamp),
              'minutes'
            );
          }
          return field.default;
        },
        default: defaultValue
      },
      {
        label: 'Erwartete Startzeit',
        value: (row, field) => row.estimatedTimespan ? moment.unix(row.estimatedTimespan.startTimestamp).format(timeFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Erwartetes Startdatum',
        value: (row, field) => row.estimatedTimespan ? moment.unix(row.estimatedTimespan.startTimestamp).format(dateFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Erwartet Endzeit',
        value: (row, field) => row.estimatedTimespan ? moment.unix(row.estimatedTimespan.endTimestamp).format(timeFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Erwartetes Enddatum',
        value: (row, field) => row.estimatedTimespan ? moment.unix(row.estimatedTimespan.endTimestamp).format(dateFormat) : field.default,
        default: defaultValue
      },
      {
        label: 'Erwartete Fahrzeit (in Minuten)',
        value: (row, field) => {
          if (row.estimatedTimespan) {
            return moment.unix(row.estimatedTimespan.endTimestamp).diff(
              moment.unix(row.estimatedTimespan.startTimestamp),
              'minutes'
            );
          }
          return field.default;
        },
        default: defaultValue
      },
      {
        label: 'Start-Destination',
        value: 'origin.name',
        default: defaultValue
      },
      {
        label: 'Ziel-Destination',
        value: 'destination.name',
        default: defaultValue
      },
      {
        label: 'Distanz (in km)',
        value: (row, field) => parseFloat(row.estimatedDistance / 1000).toFixed(1) || field.default,
        default: defaultValue
      },
      {
        label: 'Rückfahrt von Auftrags Nr.',
        value: 'returnOrderForId',
        default: defaultValue
      },
      {
        label: 'Preis',
        value: 'price',
        default: defaultValue
      },
      {
        label: 'Währung',
        value: 'currency',
        default: defaultValue
      },
      {
        label: 'Status',
        value: 'status',
        default: defaultValue
      },
      {
        label: 'Pricing',
        value: 'pricing',
        default: defaultValue
      },
      {
        label: 'Bemerkungen',
        value: 'noteText',
        default: defaultValue
      },
    ];

    let extraCustomersMax = 0;

    data.forEach((order) => {
      if (order.extraCustomers) {
        if (order.extraCustomers.length > extraCustomersMax) {
          extraCustomersMax = order.extraCustomers.length;
        }
      }
    });

    fields = fields.concat([
      {
        label: 'Telefonnummer Gast',
        value: 'customer.phone',
        default: defaultValue
      },
      {
        label: 'Nachname Gast',
        value: 'customer.lastName',
        default: defaultValue
      },
      {
        label: 'Vorname Gast',
        value: 'customer.firstName',
        default: defaultValue
      },
    ]);

    fields = fields.concat(fieldsPart1);

    for (let i = 0; i < extraCustomersMax; i++) {
      const count = i + 2;
      fields = fields.concat([
        {
          label: 'Telefonnummer Gast ' + count,
          value: 'extraCustomers[' + i + '].phone',
          default: defaultValue
        },
        {
          label: 'Nachname Gast ' + count,
          value: 'extraCustomers[' + i + '].lastName',
          default: defaultValue
        },
        {
          label: 'Vorname Gast ' + count,
          value: 'extraCustomers[' + i + '].firstName',
          default: defaultValue
        },
      ]);
    }

    fields = fields.concat(fieldsPart2);
    
    const json2csvParser = new Parser({
      fields,
      excelString: true,
      withBOM: true,
    });
    const csv = json2csvParser.parse(data);

    return csv;
  }

  const onExportPress = async () => {
    const data = await getOrders({ ...options, limit: 999999999, fields: exportFields.join(',') });
    const cleanedData = cleanExport(data.items);
    const url = window.URL.createObjectURL(new Blob([cleanedData]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${t('orders')}_${moment().format(t('fileDateFormat'))}_${moment().format(t('fileTimeFormat'))}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.gtag('event', 'csv_export', { query });
  };

  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <PaginatedTable
      containerHeight={900}
      match={match}
      history={history}
      preHeader={
        <div className={css(styles.header)}>
          <div className={css(styles.headerFilters)}>
            <Filter filters={filters} onChange={setFilters} />
          </div>
          <div className={css(styles.headerButton)}>
            <Button variant="dark" size="l" icon={<External />} onPress={onExportPress}>
              {t('export')}
            </Button>
          </div>
        </div>
      }
      columns={columns}
      useData={useOrders}
      useDataAdditionalOptions={options}
      headerStyle={{ paddingRight: 40 }}
      rows={
        orders
          ? orders.map((order) => ({
              id: order._id,
              columns: [
                <div
                  title={`${order._id}${order.cronjob ? ` (${t('cronjob')})` : ''}`}
                  className={css(styles.column, order.cronjob && styles.columnCronjob)}
                >
                  ...{order._id.toString().slice(-5)}
                </div>,
                <DateColumn
                  timeFormat={t('timeFormatLong')}
                  dateFormat={t('longDateFormat')}
                  date={moment.unix(order.estimatedTimespan.startTimestamp).toDate()}
                />,
                <div className={css(styles.originDestinationContainer)}>
                  <div className={css(styles.originDestination)}>
                    <OriginDestinationColumn
                      origin={order.origin}
                      hasStopovers={order.stopovers && order.stopovers.length > 0}
                      destination={order.destination}
                      anonymousOriginLabel={t('anonymousStart')}
                      anonymousDestinationLabel={t('anonymousEnd')}
                      noDestinationLabel={t('noDestinationYet')}
                    />
                  </div>
                </div>,
                <div>{order.estimatedDistance ? (parseFloat(order.estimatedDistance / 1000).toFixed(1)) + 'km' : '-'}</div>,
                (order.extraCustomers && order.extraCustomers.length > 0) ? (
                  <div>{order.extraCustomers.length + 1} {t('customers')}</div>
                ) : (
                  <Customer customer={order.customer} isLinkDisabled={true} />
                ),
                order.driver ? (
                  <PersonColumn
                    name={s(order.driver.lastName, order.driver.firstName)}
                    phone={toHumanPhone(order.driver.phone)}
                  />
                ) : (
                  '-'
                ),
                <FleetColumn
                  car={order.fleet && order.fleet.label}
                  licensePlate={order.car ? s(order.car.manufacturer, order.car.model) : '-'}
                  licensePlateLight
                  icon={order.fleet ? getFleetIcon(order.fleet.icon) : <VehicleStandard />}
                />,
                order.timespan && order.timespan.endTimestamp ? (
                  <div className={css(styles.distanceAndDuration)}>
                    <div className={css(styles.distance)}>
                      {order.currency} {toPrice(order.price)}
                    </div>
                    <div className={css(styles.duration)}>
                      {moment
                        .duration(
                          order.timespan.endTimestamp - order.timespan.startTimestamp,
                          'seconds'
                        )
                        .humanize()}
                    </div>
                  </div>
                ) : (
                  '-'
                ),
                <Status status={order.status} stateLabels={getStateLabels(t)} />
              ],
              afterRow: <OrderContextMenu order={order} history={history} />,
            }))
          : []
      }
      onChange={setOrders}
      orderBy={orderBy}
      onChangeOrderBy={setOrderBy}
      rowLinkBasePath={match.url}
      useRowLinks
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    header: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #E9EBED',
      padding: '30px 0',
    },
    column: {
      flex: 1,
      textAlign: 'center',
    },
    columnCronjob: {
      backgroundColor: theme.color12,
      padding: 5,
      borderRadius: 5,
    },
    headerFilters: {
      flex: 1,
    },
    headerButton: {
      marginLeft: 30,
    },
    originDestinationContainer: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
      alignItems: 'center',
    },
    originDestination: {
      flex: 1,
      minWidth: 0,
    },
    distanceAndDuration: {
      flex: 1,
    },
    distance: {
      fontSize: 14,
      fontWeight: 'lighter',
      letterSpacing: 0.17,
      marginBottom: 5,
    },
    duration: {
      fontSize: 12,
      letterSpacing: 0.39,
      fontWeight: 'lighter',
      color: theme.color1,
    },
  });
