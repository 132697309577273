import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ subscription }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={css(styles.timeLineBar)}>
      <div>{moment.unix(subscription.start).format(t('longDateFormat'))}</div>
      <div className={css(styles.timeLineSeparator)} />
      <div className={css(styles.subscriptionDurationLabel)}>{`30 ${t('days')}`}</div>
      <div className={css(styles.timeLineSeparator)} />
      <div>{moment.unix(subscription.end).format(t('longDateFormat'))}</div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    timeLineBar: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'lighter',
      color: theme.color1,
      margin: '10px 0',
    },
    subscriptionDurationLabel: {
      color: theme.color3,
      fontWeight: 'bold',
    },
    timeLineSeparator: {
      height: 1,
      flex: 1,
      backgroundColor: theme.color9,
      margin: '0 20px',
    },
  });
