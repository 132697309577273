import React from 'react';
import { StepContent, StepTitle } from '../Components/Tour';

export default {
  name: 'main',
  requiredPath: '/dashboard',
  categoryId: 'essentials',
  getTranslatedTour: (t) => ({
    question: t('Wie ist die Oberfläche aufgebaut?'),
    steps: [
      {
        selector: '#map',
        content: (
          <>
            <StepTitle>{t('welcomeToGoFleetCockpit')}</StepTitle>
            <StepContent>{t('thisIsWhereYourDriverIs')}</StepContent>
          </>
        ),
        circleSize: 280,
      },
      {
        selector: '#main-nav-order-creation',
        content: (
          <>
            <StepTitle>{t('createFirstOrder')}</StepTitle>
            <StepContent>{t('thisIsTheOrderCreation')}</StepContent>
          </>
        ),
      },
      {
        selector: '#main-nav-dispatcher',
        content: (
          <>
            <StepTitle>{t('dispatch')}</StepTitle>
            <StepContent>{t('thisIsTheDispatcher')}</StepContent>
          </>
        ),
      },
      {
        selector: '#main-nav-order-overview',
        content: (
          <>
            <StepTitle>{t('orderOverview')}</StepTitle>
            <StepContent>{t('thisIsTheOrderOverview')}</StepContent>
          </>
        ),
      },
      {
        selector: '#main-nav-help-center',
        content: (
          <>
            <StepTitle>{t('alwaysThereForYou')}</StepTitle>
            <StepContent>{t('thisIsTheHelpCenter')}</StepContent>
          </>
        ),
      },
    ],
  }),
};
