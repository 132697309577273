import { GeoJsonLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
import { PathStyleExtension } from '@deck.gl/extensions';
import {
  FleetColumn,
  PersonColumn,
  RouteStep,
  SectionHeader,
  StatBox,
  Status,
  Theme,
  VehicleStandard,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticMap, _MapContext } from 'react-map-gl';
import { fitBounds } from 'viewport-mercator-project';
import { ordersEndpoint } from '../../../Utils/API';
import { getFleetIcon } from '../../../Utils/Fleet';
import {
  getOrderDelays,
  getSouthWestAndNorthEast,
  join,
  s,
  toKm,
} from '../../../Utils/Helpers';
import { apiAccessToken } from '../../../Utils/Mapbox';
import { getStateLabels } from '../../../Utils/StateLabels';
import { isOrderOngoing } from '../../Dispatcher/Screens/CarTimeline';
import Customer from '../Components/Customer';
import MapMarker from '../Components/MapMarker';
import MapMarkerCar from '../Components/MapMarkerCar';
import MapNavigationControl from '../Components/MapNavigationControl';
import NetworkRequestError from '../Components/NetworkRequestError';
import OrderHeader from '../Components/OrderHeader';
import Note from '../Components/Note';
import OrderContextMenu from '../ContextMenus/Order';

const { useEntry: useOrder } = ordersEndpoint;

const getLineSettings = (coordinates) => ({
  data: {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates,
    },
  },
  pickable: true,
  stroked: false,
  filled: true,
  extruded: true,
  getFillColor: [160, 160, 180, 200],
  getLineColor: [1, 203, 255],
  lineWidthScale: 1,
  lineWidthMinPixels: 5,
});

const isRouteStepActive = (index, order) => {
  const { stage, status } = order;

  if (status === 'completing' || status === 'completed') {
    return true;
  }

  if (stage > index) {
    return true;
  }

  if (stage === index && status === 'arrived') {
    return true;
  }

  return false;
};

const HistoryRow = ({ timestamp, children, isError, isSuccess }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div
      className={css(
        styles.historyRow,
        isError && styles.historyRowError,
        isSuccess && styles.historyRowSuccess
      )}
    >
      <div className={css(styles.historyRowDate)}>
        {moment.unix(timestamp).format(t('longDateFormatAndLongTimeFormat'))}
      </div>
      <div className={css(styles.historyRowContent)}>{children}</div>
    </div>
  );
};

export default ({ orderId, match, history, onBackPress }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [order, error] = useOrder(orderId);

  const [viewport, setViewport] = useState();
  const [routes, setRoutes] = useState();

  useEffect(() => {
    if (!order) {
      return;
    }

    const { origin, stopovers, destination } = order;
    const originPosition = JSON.parse(origin.position);

    let coords = [];
    coords.push([originPosition.longitude, originPosition.latitude]);
    if (stopovers && stopovers.length > 0) {
      stopovers.forEach((stopover) => {
        const stopoverPosition = JSON.parse(stopover.position);
        coords.push([stopoverPosition.longitude, stopoverPosition.latitude]);
      });
    }

    let bounds = {
      longitude: originPosition.longitude,
      latitude: originPosition.latitude,
      zoom: 15,
    };

    if (destination) {
      const destinationPosition = JSON.parse(destination.position);
      coords.push([destinationPosition.longitude, destinationPosition.latitude]);
    }

    const layers = [];

    if (order.mapboxGeoJson) {
      coords = coords.concat(order.mapboxGeoJson.coordinates);

      layers.push([
        new GeoJsonLayer({
          ...getLineSettings(order.mapboxGeoJson.coordinates),
          id: 'originalRoute-layer',
          getDashArray: [2, 2],
          dashJustified: true,
          extensions: [new PathStyleExtension({ highPrecisionDash: true })],
          rounded: true,
        }),
      ]);
    }

    if (order.trackingGeoJson) {
      coords = coords.concat(order.trackingGeoJson.coordinates);

      layers.push(
        new GeoJsonLayer({
          ...getLineSettings(order.trackingGeoJson.coordinates),
          id: 'trackingRoute-layer',
        })
      );
    }

    if (layers.length > 0) {
      setRoutes(layers);
    }

    bounds = fitBounds({
      width: 986,
      height: 400,
      padding: 60,
      bounds: getSouthWestAndNorthEast(coords),
    });

    setViewport({
      longitude: bounds.longitude,
      latitude: bounds.latitude,
      zoom: bounds.zoom,
      bearing: 0,
      pitch: 0,
    });
  }, [order]);

  if (error) {
    return <NetworkRequestError error={error} />;
  }

  if (!order || !viewport) {
    return null;
  }

  const { origin, destination } = order;
  const originPosition = JSON.parse(origin.position);

  const basePath = match ? match.url : `/essentials/orders/overview/0/${order._id}`;

  let destinationPosition;
  if (destination) {
    destinationPosition = JSON.parse(destination.position);
  }

  const orderHistory = [];
  orderHistory.push({ timestamp: Math.floor(order.createAt / 1000), label: t('orderCreated') });

  const { originDelay, destinationDelay } = getOrderDelays(order);

  const estimatedStart = moment.unix(order.estimatedTimespan.startTimestamp);
  const estimatedEnd = moment.unix(order.estimatedTimespan.endTimestamp);
  orderHistory.push({
    timestamp: Math.floor(order.createAt / 1000),
    label: `${t('plannedTimespan')}: ${estimatedStart.format(
      t('timeFormat')
    )} - ${estimatedEnd.format(t('timeFormatLong'))}`,
  });

  if (order.status !== 'new') {
    orderHistory.push({
      timestamp: order.requestTimestamp,
      label: t('orderAssigned'),
    });
  }

  if (order.approachTimespan) {
    orderHistory.push({
      timestamp: order.approachTimespan.startTimestamp,
      label: t('driverStartedApproach'),
    });

    if (order.stage > 0 || (order.stage === 0 && order.status === 'arrived')) {
      orderHistory.push({
        timestamp: order.approachTimespan.endTimestamp,
        label: t('driverEndedApproach'),
      });
    }
  }

  if (order.stage > 0) {
    orderHistory.push({
      timestamp: order.timespan.startTimestamp,
      label:
        originDelay > 0
          ? t('driveStartWithDelay', { delay: originDelay })
          : t('driveStartPunctual'),
      isError: originDelay > 0,
      isSuccess: originDelay <= 0,
    });

    if (order.status === 'completed') {
      orderHistory.push({
        timestamp: order.timespan.endTimestamp,
        label:
          destinationDelay > 0
            ? t('driveEndWithDelay', { delay: destinationDelay })
            : t('driveEndPunctual'),
        isError: destinationDelay > 0,
        isSuccess: destinationDelay <= 0,
      });
    }
  }

  if (order.status === 'canceled') {
    orderHistory.push({
      timestamp: order.timespan ? order.timespan.endTimestamp : order.estimatedTimespan.endTimestamp,
      label: t('orderCanceled'),
      isError: true,
    });
  }

  let channel;

  if (order.source === 'driver') {
    channel = t('anonymousPassenger');
  } else if (order.source === 'cockpit') {
    channel = t('phone');
  } else if (order.source === 'widget') {
    channel = t('widget');
  } else {
    channel = t('phone');
  }

  let orderTotalPassengerAmount = 0;
  if (order.passengerAmount) {
    orderTotalPassengerAmount += order.passengerAmount;
  }
  if (order.passengerAmount && order.extraCustomers) {
    order.extraCustomers.forEach((customer) => {
      orderTotalPassengerAmount += customer.passengerAmount;
    });
  }

  return (
    <div className={css(styles.container)}>
    <OrderHeader order={order} onBackPress={onBackPress}>
        <OrderContextMenu order={order} history={history} label={t('options')} />
    </OrderHeader>
    <div className={css(styles.content)}>
        <div className={css(styles.mapContainer)}>
        <DeckGL
            initialViewState={viewport}
            controller={true}
            layers={routes}
            ContextProvider={_MapContext.Provider}
        >
            <StaticMap
            mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
            mapOptions={{}}
            key="map"
            mapboxApiAccessToken={apiAccessToken}
            attributionControl={false}
            >
            <MapMarker {...originPosition}>{t('origin')}</MapMarker>
            {order.stopovers &&
                order.stopovers.map((stopover, index) => {
                const stopoverPosition = JSON.parse(stopover.position);

                return (
                    <MapMarker key={index} {...stopoverPosition}>
                    {`${t('stopover')} ${index + 1}`}
                    </MapMarker>
                );
                })}
            {destination && <MapMarker {...destinationPosition}>{t('destination')}</MapMarker>}
            {isOrderOngoing(order) && order.car && <MapMarkerCar carId={order.car.id} />}
            </StaticMap>
            <MapNavigationControl />
        </DeckGL>
        </div>

        <div className={css(styles.lineLegend)}>
        <div className={css(styles.lineLegendBox)}>
            <div
            className={css(styles.lineLegendIndicator)}
            style={{ borderStyle: 'dotted' }}
            />
            <div className={css(styles.lineLegendLabel)}>{t('suggestedRoute')}</div>
        </div>
        <div className={css(styles.lineLegendBox)}>
            <div
            className={css(styles.lineLegendIndicator)}
            />
            <div className={css(styles.lineLegendLabel)}>{t('drivenRoute')}</div>
        </div>
        </div>

        <div className={css(styles.contentContainer)}>
        <div className={css(styles.info)}>
            <div className={css(styles.infoSection, styles.infoSectionFirst)}>
            <Status status={order.status} stateLabels={getStateLabels(t)} />
            </div>
            <div className={css(styles.infoSection)}>
            <Customer
                customer={order.customer}
                basePath={join(basePath, 'customer')}
                label={order.extraCustomers ? t('customers') : t('customer')}
            />
            {order.extraCustomers &&
            order.extraCustomers.map((customer, index) => (
              <Customer
                  customer={customer}
                  basePath={join(basePath, 'customer')}
                  label={' '}
              />
            ))}
            </div>
            <div className={css(styles.infoSection)}>
            <PersonColumn
                name={order.driver ? s(order.driver.lastName, order.driver.firstName) : '-'}
                label={t('driver')}
                linkTo={order.driver && join(basePath, 'driver', order.driver.id)}
            />
            </div>
            <div className={css(styles.infoSection)}>
            <FleetColumn
                icon={order.fleet ? getFleetIcon(order.fleet.icon) : <VehicleStandard />}
                car={
                order.car
                    ? s(order.car.manufacturer, order.car.model, '·', order.car.licensePlate)
                    : '-'
                }
                label={t('fleet')}
            />
            </div>
        </div>
        <div className={css(styles.details)}>
            <div className={css(styles.row)}>
            <div className={css(styles.column)}>
                <RouteStep
                title={t('origin')}
                address={order.origin.name || t('anonymousStart')}
                isActive={isRouteStepActive(0, order)}
                delay={originDelay}
                />
                {order.stopovers &&
                order.stopovers.map((stopover, index) => (
                    <RouteStep
                    key={index}
                    title={`${t('stopover')} ${index + 1}`}
                    address={stopover.name}
                    isActive={isRouteStepActive(index + 1, order)}
                    />
                ))}
                <RouteStep
                title={t('destination')}
                address={
                    order.destination
                    ? order.destination.name || t('anonymousEnd')
                    : t('noDestinationYet')
                }
                isActive={isRouteStepActive(order.stopovers.length + 1, order)}
                delay={destinationDelay}
                />
            </div>
            <div className={css(styles.column)}>
                <div className={css(styles.statBoxContainer)}>
                <StatBox className={css(styles.statBox)} title={t('channel')}>
                    {channel}
                </StatBox>
                <StatBox className={css(styles.statBox)} title={t('distance')}>
                    {order.estimatedDistance ? `${toKm(order.estimatedDistance)} km` : '-'}
                </StatBox>
                <StatBox className={css(styles.statBox)} title={t('duration')}>
                    {order.status === 'completed'
                    ? moment
                        .duration(
                            order.timespan.endTimestamp - order.timespan.startTimestamp,
                            'seconds'
                        )
                        .humanize()
                    : '-'}
                </StatBox>
                </div>
                <div className={css(styles.statBoxContainer)}>
                <StatBox className={css(styles.statBox)} title={t('pricing')}>
                    {t(order.pricing)}
                </StatBox>
                <StatBox className={css(styles.statBox)} title={t('price')}>
                    {order.price ? `${order.currency} ${order.price}` : t('open')}
                </StatBox>
                <StatBox className={css(styles.statBox)} title={t('paymentStatus')}>
                    {order.isPaid ? t('paid') : t('open')}
                </StatBox>
                </div>
                <div className={css(styles.statBoxContainer)}>
                {order.partnerName && (
                    <StatBox className={css(styles.statBox)} title={'Partner'}>
                        {order.partnerName + (order.partnerAuthor ? (' [' + order.partnerAuthor + ']') : '')}
                    </StatBox>
                )}
                {order.type ? (
                    <StatBox className={css(styles.statBox)} title={'Typ'}>
                        {order.type == 'day-trip' ? 'Day trip' : 'One-way'}
                    </StatBox>
                ) : null}
                {orderTotalPassengerAmount ? (
                    <StatBox className={css(styles.statBox)} title={t('passengerAmountTotal')}>
                        {orderTotalPassengerAmount}
                    </StatBox>
                ) : null}
                </div>
            </div>
            </div>
            <div className={css(styles.row)}>
            <div className={css(styles.column)}>
                <SectionHeader>{t('history')}</SectionHeader>
                {orderHistory.map(({ timestamp, label, isError, isSuccess }, index) => (
                <HistoryRow
                    key={index}
                    timestamp={timestamp}
                    isError={isError}
                    isSuccess={isSuccess}
                >
                    {label}
                </HistoryRow>
                ))}
            </div>
            <div className={css(styles.column)}>
                <SectionHeader>{t('driverBriefing')}</SectionHeader>
                <Note entry={order} />
                {order.completionNoteText && (
                  <>
                    <SectionHeader>{t('completionNote')}</SectionHeader>
                    <Note entry={order} prefix={'completion'} />
                  </>
                )}
                {order.driverNoteText && (
                  <>
                    <SectionHeader>{t('driverNote')}</SectionHeader>
                    <Note text={order.driverNoteText} />
                  </>
                )}
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      width: '100%',
      maxWidth: 1060,
      margin: '0 auto',
      padding: 30,
    },
    content: {
      marginTop: 15,
      borderRadius: 5,
      boxShadow: theme.shadow1,
      padding: 7,
    },
    mapContainer: {
      height: 400,
      position: 'relative',
      left: 0,
      right: 0,
    },
    contentContainer: {
      padding: '0 30px',
    },
    lineLegend: {
      marginTop: 10,
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 'lighter',
      color: theme.color1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    lineLegendBox: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 20,
    },
    lineLegendIndicator: {
      width: 30,
      height: 0,
      borderBottom: '2px solid',
      borderBottomColor: theme.color5,
      marginRight: 10,
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0',
      borderBottom: `1px solid ${theme.color12}`,
    },
    infoSection: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      flex: 1,
      padding: '0 30px',
      borderLeft: `1px solid ${theme.color12}`,
    },
    infoSectionFirst: {
      paddingLeft: 0,
      borderLeft: 'none',
    },
    details: {
      marginTop: 30,
    },
    row: {
      display: 'flex',
      marginBottom: 30,
    },
    column: {
      flex: 1,
      ':first-child': {
        marginRight: 30,
      },
    },
    statBoxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 13,
    },
    historyRow: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 'lighter',
      color: theme.color3,
      padding: 10,
      borderBottom: `1px solid ${theme.color11}`,
    },
    historyRowError: {
      color: theme.colorError,
    },
    historyRowSuccess: {
      color: theme.colorSuccess,
    },
    historyRowDate: {
      width: 160,
      marginRight: 10,
      color: theme.color1,
    },
    historyRowContent: {
      flex: 1,
    },
  });
