import React from 'react';
import { Female, Male, Unknown } from '@yourmileag/ui-kit';

const iconMap = new Map([
  ['female', <Female />],
  ['male', <Male />],
  ['unknown', <Unknown />],
]);

export const getGenderIcon = (category) => iconMap.get(category);
