import { Theme, Panel } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import SidePanelEffect from '../../../Effects/SidePanelEffect';
import { getPathParent } from '../../../Utils/Helpers';
import PropsRoute from './PropsRoute';
import NetworkRequestError from './NetworkRequestError';

export default ({
  match,
  pushHistory,
  CreateOrUpdate,
  Detail,
  History,
  useEntry,
  renderHeader,
  additionalNavItems,
}) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  useEffect(SidePanelEffect, []);
  const { t } = useTranslation();
  const isCreateRoute = !match.params.id;
  const [entry, error] = useEntry(match.params.id);

  const navItems = [
    {
      title: t('overview'),
      path: `${match.url}`,
      exact: true,
      component: isCreateRoute ? CreateOrUpdate : Detail,
    },
  ];

  if (History && !isCreateRoute) {
    navItems.push({ title: t('history'), path: `${match.url}/history`, component: History });
  }

  if (additionalNavItems) {
    navItems.push(...additionalNavItems);
  }

  if (!entry && !isCreateRoute) {
    return null;
  }

  const onClose = () => pushHistory(getPathParent(match.url));

  return (
    <Panel
      onClose={onClose}
      navItems={navItems}
      header={renderHeader(entry)}
      headerClassName={css(styles.header)}
      content={
        error ? (
          <NetworkRequestError error={error} />
        ) : (
            <Switch>
              <PropsRoute
                path={`${match.url}/update`}
                component={CreateOrUpdate}
                componentProps={{ entry }}
              />
              {navItems.map((navItem) => (
                <PropsRoute
                  key={navItem.path}
                  path={navItem.path}
                  component={navItem.component}
                  componentProps={{ entry }}
                  exact={navItem.exact}
                />
              ))}
            </Switch>
          )
      }
    />
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    header: {
      backgroundImage: `url("/assets/images/pattern.png"), linear-gradient(134deg, ${theme.color3} 0%, ${theme.color14} 100%)`,
      backgroundSize: 'contain',
    },
  });
