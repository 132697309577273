import { createSnackbar, InfoRow, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bugsnagClient } from '../../bugsnag';
import { getOrganisationId, organisationsEndpoint } from '../../Utils/API';
import { convertEmptyStringsToNull, toDbPhone, toHumanPhone } from '../../Utils/Helpers';
import { validateMail, validatePhoneNumber, validateZipCode } from '../../Utils/Validators';
import Content from './Components/Content';

export const useOrganisationValidator = (organisation) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!organisation) {
      return;
    }

    const newErrors = {};

    if (!organisation.name) {
      newErrors.name = t('fieldIsRequired');
    }

    if (!organisation.street) {
      newErrors.street = t('fieldIsRequired');
    }

    if (!organisation.zipCode) {
      newErrors.zipCode = t('fieldIsRequired');
    }

    if (!organisation.city) {
      newErrors.city = t('fieldIsRequired');
    }

    const isPhoneNumberValid = validatePhoneNumber(organisation.phone);
    if (isPhoneNumberValid !== true) {
      newErrors.phone = isPhoneNumberValid;
    }

    const isMailValid = validateMail(organisation.mailAddress);
    if (isMailValid !== true) {
      newErrors.mailAddress = isMailValid;
    }

    const isZipCodeValid = validateZipCode(organisation.zipCode);
    if (isZipCodeValid !== true) {
      newErrors.zipCode = isZipCodeValid;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [organisation, t]);

  return errors;
};

export default () => {
  const [fetchedOrganisation] = organisationsEndpoint.useEntry(getOrganisationId());
  const [organisation, setOrganisation] = useState();
  const errors = useOrganisationValidator(organisation);
  const isSubmitDisabled = Object.keys(errors).length > 0;
  const { t } = useTranslation();

  useEffect(() => {
    if (!fetchedOrganisation) {
      return;
    }

    setOrganisation({ ...fetchedOrganisation, phone: toHumanPhone(fetchedOrganisation.phone) });
  }, [fetchedOrganisation]);

  const onChange = (attribute) => (e) =>
    setOrganisation({ ...organisation, [attribute]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    const newOrganisation = convertEmptyStringsToNull({
      ...organisation,
      phone: toDbPhone(organisation.phone),
    });

    try {
      await organisationsEndpoint.updateEntry(organisation.id, newOrganisation);
      createSnackbar('success', t('successfullySaved', { name: t('company') }));
    } catch (exc) {
      bugsnagClient.notify('Could not create or update organisation', { metaData: exc });

      createSnackbar('error', t('couldNotSave', { name: t('company') }));
    }
  };

  if (!organisation) {
    return null;
  }

  return (
    <Content
      id="settings-form"
      title={t('company')}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
    >
      <InfoRow center label={t('name')} required>
        <TextInput
          id="name"
          value={organisation.name}
          onChange={onChange('name')}
          required
          errorMessage={errors.name}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('phone')} required>
        <TextInput
          id="phone"
          type="phone"
          value={organisation.phone}
          onChange={onChange('phone')}
          errorMessage={errors.phone}
          required
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('mailAddress')} required>
        <TextInput
          id="mail"
          type="email"
          value={organisation.mailAddress}
          onChange={onChange('mailAddress')}
          errorMessage={errors.mailAddress}
          required
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('address')} required>
        <TextInput
          id="street"
          value={organisation.street}
          onChange={onChange('street')}
          required
          errorMessage={errors.street}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={`${t('zip')}/${t('city')}`} required>
        <div className={css(styles.zipAndCity)}>
          <div className={css(styles.zipCode)}>
            <TextInput
              id="zipCode"
              value={organisation.zipCode}
              onChange={onChange('zipCode')}
              required
              errorMessage={errors.zipCode}
              clean
              size="s"
            />
          </div>
          <div className={css(styles.city)}>
            <TextInput
              id="city"
              value={organisation.city}
              onChange={onChange('city')}
              required
              errorMessage={errors.city}
              clean
              size="s"
            />
          </div>
        </div>
      </InfoRow>
    </Content>
  );
};

const styles = StyleSheet.create({
  zipAndCity: {
    display: 'flex',
  },
  zipCode: {
    width: 150,
    marginRight: 10,
  },
  city: {
    flex: 1,
  },
});
