import { PersonColumn } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { employeesEndpoint } from '../../../../Utils/API';
import { s, toHumanPhone } from '../../../../Utils/Helpers';
import InventoryScreen from '../../Components/InventoryScreen';
import EmployeeSidePanel from '../../SidePanels/Employee';

const { useEntries: useEmployees } = employeesEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  const columns = [
    { title: t('name'), orderKey: 'lastName', searchKeys: ['lastName', 'firstName'], flex: 1 },
    { title: t('mail'), orderKey: 'mailAddress', searchKeys: ['mailAddress'], width: 220 },
    { title: t('phone'), orderKey: 'phone', searchKeys: ['phone'], width: 220 },
  ];

  const rowMap = (employee) => ({
    id: employee.id,
    columns: [
      <PersonColumn name={s(employee.lastName, employee.firstName)} />,
      employee.mailAddress,
      toHumanPhone(employee.phone),
    ],
  });

  return (
    <InventoryScreen
      match={match}
      history={history}
      SidePanelComponent={EmployeeSidePanel}
      useData={useEmployees}
      columns={columns}
      rowMap={rowMap}
      searchLabel={t('searchEmployee')}
      addButtonLabel={t('addEmployee')}
    />
  );
};
