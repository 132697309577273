import React from 'react';
import {
  VehicleBusiness,
  VehicleSmall,
  VehicleVan,
  VehicleWagon,
  VehicleWheelchairTaxi,
  VehicleStandard,
} from '@yourmileag/ui-kit';

const iconMap = new Map([
  ['standard', <VehicleStandard />],
  ['business', <VehicleBusiness />],
  ['van', <VehicleVan />],
  ['wagon', <VehicleWagon />],
  ['wheelchairTaxi', <VehicleWheelchairTaxi />],
  ['small', <VehicleSmall />],
]);

export const getFleetIcon = (category) => iconMap.get(category);
