import { Button, Theme, Connection, Update } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../Utils/Helpers';

export default () => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  const onUpdatePress = () => document.location.reload();

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        <h1 className={css(styles.title)}><Connection className={css(styles.icon)} />{t('sessionTimeout')}</h1>
        <p className={css(styles.content)}>{t('sessionTimeoutDesc')}</p>
        <div className={css(styles.buttons)}>
          <Button icon={<Update />} size="m" variant="special" onPress={onUpdatePress}>
            {t('updateNow')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(44,62,80,0.7)',
      zIndex: 10,
    },
    containerInner: {
      backgroundColor: theme.color2,
      width: 500,
      padding: 30,
      borderRadius: 5,
    },
    icon: {
      marginRight: 20
    },
    title: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '28px',
      marginBottom: 15,
      color: theme.color3,
    },
    content: {
      color: theme.color1,
      lineHeight: '1.5em',
      marginBottom: 50,
      fontWeight: 300,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  });