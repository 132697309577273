export const getChanges = (currentCarLicenses, currentModules, newCarLicenses, newModules) => {
  let add;
  let remove;

  if (newCarLicenses > currentCarLicenses) {
    if (!add) {
      add = { licences: 0 };
    }

    add.licences = newCarLicenses - currentCarLicenses;
  } else if (newCarLicenses < currentCarLicenses) {
    if (!remove) {
      remove = { licences: 0 };
    }

    remove.licences = currentCarLicenses - newCarLicenses;
  }

  const cm = Object.keys(currentModules)
    .filter((a) => currentModules[a])
    .map((a) => Number(a));
  const nm = Object.keys(newModules)
    .filter((a) => newModules[a])
    .map((a) => Number(a));

  const modulesToAdd = nm.filter((x) => !cm.includes(x));
  const modulesToRemove = cm.filter((x) => !nm.includes(x));
  if (modulesToAdd.length) {
    if (!add) {
      add = {};
    }

    add.modules = modulesToAdd;
  }
  if (modulesToRemove.length) {
    if (!remove) {
      remove = {};
    }

    remove.modules = modulesToRemove;
  }

  return { add, remove };
};

//TODO URLS
export const lightboxConfig = {
  successUrl: `${process.env.REACT_APP_PAYMENT_URL}/saas/payment/success`,
  cancelUrl: `${process.env.REACT_APP_PAYMENT_URL}/saas/payment/cancel`,
  errorUrl: `${process.env.REACT_APP_PAYMENT_URL}/saas/payment/error`,
  merchantId: '1100022541',
  currency: 'CHF',
  sign: '200311170758384316',
  production: false,
  paymentmethod: ['ECA', 'VIS'],
  useAlias: true,
};
