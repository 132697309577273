import { StepContent } from '../Components/Tour';
import React from 'react';

export default {
  name: 'dispatcher',
  requiredPath: '/dispatcher/orders',
  categoryId: 'dispatcher',
  getTranslatedTour: (t) => ({
    question: t('howToDispatchOrders'),
    steps: [
      {
        selector: '#main-nav-dispatcher',
        content: (
          <>
            <StepContent>{t('dispatcherDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#dispatcher-cars-table',
        content: (
          <>
            <StepContent>{t('dispatcherCarsDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#dispatcher-pending',
        content: (
          <>
            <StepContent>{t('dispatcherUnassignedOrdersDesc')}</StepContent>
          </>
        ),
      },
      {
        selector: '#oc-date-wrapper',
        content: (
          <>
            <StepContent>{t('dispatcherDateDesc')}</StepContent>
          </>
        ),
      },
    ],
  }),
};
