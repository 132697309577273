import { useEffect, useState } from 'react';

export const useChangeableSubscription = (subscription, subscriptionModules) => {
  const [carLicenses, setCarLicenses] = useState();
  const [selectedModules, setSelectedModules] = useState();

  useEffect(() => {
    if (!subscription || !subscriptionModules) {
      return;
    }

    if (carLicenses !== undefined || selectedModules !== undefined) {
      return;
    }

    let newCarLicenses = subscription.licences;
    let newCarModules = { ...subscriptionModules };
    if (subscription.changes && subscription.changes.remove) {
      if (subscription.changes.remove.licences) {
        newCarLicenses -= subscription.changes.remove.licences;
      }

      if (subscription.changes.remove.modules) {
        subscription.changes.remove.modules.forEach(
          moduleIdToRemove => (newCarModules[moduleIdToRemove] = false)
        );
      }
    }

    setCarLicenses(newCarLicenses);
    setSelectedModules(newCarModules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionModules, subscription]);

  return [carLicenses, setCarLicenses, selectedModules, setSelectedModules];
};
