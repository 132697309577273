import { register } from '@yourmileag/essentials-api-client';
import {
  ArrowRight,
  Button,
  ButtonLink,
  createSnackbar,
  TextInput,
  ArrowLeft,
  Theme,
  Try,
  Save,
  Fast,
  NoCreditcard,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateMail } from '../../../../Utils/Validators';
import MagicLink from '../../Components/MagicLink';
import { fullScreenStyles, usePageTitle } from '../../../../Utils/Helpers';

const STATUS = {
  waiting: 0,
  submitting: 1,
  submitted: 2,
  duplicated: 3,
};

const useValidator = (username) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    newErrors.username = validateMail(username);
    if (newErrors.username === true) {
      delete newErrors.username;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [username, t]);

  return errors;
};

export default ({ match, history }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const { t } = useTranslation();
  usePageTitle(t('registration'));
  const [status, setStatus] = useState(STATUS.waiting);
  const [username, setUsername] = useState('');
  const errors = useValidator(username);
  const isSubmitDisabled = Object.keys(errors).length > 0;
  const onLoginPress = () => history.goBack();

  const onChange = (set) => (e) => {
    set(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setStatus(STATUS.submitting);

    window.gtag('event', 'registration', { method: 'Email' });

    try {
      await register({ username });
      setStatus(STATUS.submitted);
    } catch (exc) {
      if (exc.status === 422) {
        errors.usernameex = t('userExists');
        setStatus(STATUS.waiting);
      } else {
        setStatus(STATUS.waiting);
        createSnackbar('error', t('registrationFailed'));
      }
    }
  };

  const onSubmitReset = () => setStatus(STATUS.waiting);

  return (
    <>
      <div className={css(styles.container)}>
        {status !== STATUS.waiting ? (
          <>
            <div className={css(styles.containerInner)}>
              <div className={css(styles.circle)}>
                <MagicLink
                  isInitialized={status === STATUS.submitted}
                  className={css(styles.icon)}
                />
              </div>
              <div className={css(styles.title)}>{t('oneMoreStep')}</div>
              <p className={css(styles.description)}>{t('pleaseCheckYourInboxToVerify')}</p>
              <div className={css(styles.button)}>
                <ButtonLink styles={styles.buttonLink} onClick={onSubmitReset}>
                  <ArrowLeft style={{ marginRight: 5 }} />
                  {t('useOtherEmailAddress')}
                </ButtonLink>
              </div>
            </div>
          </>
        ) : (
          <div className={css(styles.form)}>
            <h2 className={css(styles.title)}>{t('registerEmailTitle')}</h2>
            <h5 className={css(styles.desc)}>{t('registerEmailDesc')}</h5>
            <form onSubmit={onSubmit}>
              <div className={css(styles.input)}>
                <TextInput
                  id="l-email"
                  type="email"
                  autoFocus
                  label={t('mailAddress')}
                  value={username}
                  onChange={onChange(setUsername)}
                  errorMessage={errors.usernameex}
                  required
                />
              </div>
              <div className={css(styles.button)}>
                <Button size="m" iconLeft={<ArrowLeft />} onPress={onLoginPress}>
                  {t('back')}
                </Button>
                <Button
                  size="m"
                  variant="special"
                  icon={<ArrowRight />}
                  disabled={isSubmitDisabled}
                  type="submit"
                >
                  {t('continue')}
                </Button>
              </div>
            </form>
            <div className={css(styles.dividerLine)} />
            <h2 className={css(styles.title, styles.titleSmall)}>{t('readyIn5Minutes')}</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div className={css(styles.usp)}>
                <div className={css(styles.uspContainter)}>
                  <Try className={css(styles.uspIcon)} />
                  <p className={css(styles.uspInfo)}>{t('registerTestDuration')}</p>
                </div>
              </div>
              <div className={css(styles.usp)}>
                <div className={css(styles.uspContainter)}>
                  <NoCreditcard className={css(styles.uspIcon)} />
                  <p className={css(styles.uspInfo)}>{t('registerTestNoCreditCard')}</p>
                </div>
              </div>
              <div className={css(styles.usp)}>
                <div className={css(styles.uspContainter)}>
                  <Save className={css(styles.uspIcon)} />
                  <p className={css(styles.uspInfo)}>{t('registerTestNoData')}</p>
                </div>
              </div>
              <div className={css(styles.usp)}>
                <div className={css(styles.uspContainter)}>
                  <Fast className={css(styles.uspIcon)} />
                  <p className={css(styles.uspInfo)}>{t('registerTestLessTime')}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    usp: {
      flex: '0 50%',
      paddingBottom: 30,
    },
    uspContainter: {
      display: 'flex',
      flexDirection: 'row',
    },
    circle: {
      width: 100,
      height: 100,
      borderRadius: 50,
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    icon: {
      height: 100,
      fontSize: 40,
      marginRight: 20,
    },
    uspIcon: {
      fontSize: 72,
      position: 'relative',
      top: -18,
    },
    uspInfo: {
      lineHeight: '24px',
      fontSize: 16,
      fontWeight: 300,
      color: theme.color17,
      paddingRight: 40,
      paddingLeft: 15,
    },
    dividerLine: {
      paddingTop: 60,
      marginBottom: 50,
      borderBottom: `solid ${theme.color4} 1px`,
      width: '100%',
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 45,
      textAlign: 'right',
    },
    buttonLink: {
      display: 'flex',
      color: theme.color6,
      ':before, :after': {
        backgroundColor: theme.color6,
      },
    },
    description: {
      color: theme.color1,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      letterSpacing: 0,
      padding: '5px 0',
    },
    input: {
      marginBottom: 15,
    },
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
      paddingTop: 60,
    },
    titleSmall: {
      fontSize: 20,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    form: {
      alignItems: 'left',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: 720,
    },
    containerInner: {
      flexDirection: 'column',
      width: 620,
      height: 340,
      padding: 30,
    },
    container: {
      ...fullScreenStyles,
      position: 'fixed',
      top: 0,
      left: 540,
      bottom: 0,
      backgroundColor: theme.color13,
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
