import { InfoRow, Theme, Phone, Saturn } from '@yourmileag/ui-kit';
import React, { useContext } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { formatThousands } from '../../../Utils/Helpers';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Sector,
} from 'recharts';
import { Card } from '.';
import { useState } from 'reactn';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * (startAngle + 3));
  const cos = Math.cos(-RADIAN * (startAngle + 3));
  var boundaryX = 0;
  const arcStartX = cx + (outerRadius + 10) * cos;
  const arcStartY = cy + (outerRadius + 10) * sin;
  const bottomY = cy + outerRadius + 120;

  if (startAngle >= 0 && startAngle <= 180) {
    // if in the upper half, make a corner
    if (startAngle >= 0 && startAngle <= 90) {
      // to the right
      boundaryX = cx + outerRadius + 25;
    } else {
      // to the left
      boundaryX = cx - outerRadius - 25;
    }
  } else {
    // if not in the upper half, make a simple line
    boundaryX = arcStartX;
  }

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 1}
        outerRadius={outerRadius + 3}
        fill={fill}
      />
      <path 
        d={`M${arcStartX},${arcStartY}L${boundaryX},${arcStartY}L${boundaryX},${bottomY}`} 
        stroke={'rgb(138, 150, 162)'} 
        fill="none" 
        strokeDasharray="1, 6" 
        strokeLinecap="round"
      />
    </g>
  );
};

export default ({orders}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const [activeSlice, setActiveSlice] = useState(0);

  var stats = {};
  let totalOrders = 0;

  var channelData = {
    'cockpit': {
      'icon': <Phone fontSize={35}/>,
      'name': t('phone'),
      'percent': 0,
      'totalOrders': 0,
      'totalRevenue': 0,
      'avgRevenue': 0,
      'totalDistance': 0,
      'avgDistance': 0
    },
//    'app': {
//      'icon': <Phone fontSize={35}/>,
//      'name': t('app'),
//      'percent': 0,
//      'totalOrders': 0,
//      'totalRevenue': 0,
//      'avgRevenue': 0,
//      'totalDistance': 0,
//      'avgDistance': 0
//    },
    'web': {
      'icon': <Saturn fontSize={35}/>,
      'name': t('web'),
      'percent': 0,
      'totalOrders': 0,
      'totalRevenue': 0,
      'avgRevenue': 0,
      'totalDistance': 0,
      'avgDistance': 0
    },
    'other': {
      'icon': <Phone fontSize={35}/>,
      'name': t('other'),
      'percent': 0,
      'totalOrders': 0,
      'totalRevenue': 0,
      'avgRevenue': 0,
      'totalDistance': 0,
      'avgDistance': 0
    },
  }

  var data = [];

  orders.forEach((order) => {
    if (!order.price || order.status !== 'completed') {
      return;
    }

    totalOrders++;

    let price = parseFloat(order.price);
    let distance = order.estimatedDistance;

    // determine order source
    switch(order.source) {
      case 'cockpit':
        channelData['cockpit']['totalOrders']++;
        channelData['cockpit']['totalRevenue'] += price;
        channelData['cockpit']['totalDistance'] += distance / 1000;
        break;
      case 'widget':
        channelData['web']['totalOrders']++;
        channelData['web']['totalRevenue'] += price;
        channelData['web']['totalDistance'] += distance / 1000;
        break;
//        case 'app':
//          channelData['app']['totalOrders']++;
//          channelData['app']['totalRevenue'] += price;
//          channelData['app']['totalDistance'] += distance / 1000;
//          break;
      default:
        channelData['other']['totalOrders']++;
        channelData['other']['totalRevenue'] += price;
        channelData['other']['totalDistance'] += distance / 1000;
    }
  });

  Object.values(channelData).map((channel, index) => {
    let totalChannelOrders = channel['totalOrders'];
    if (channel['totalOrders'] === 0) {
      totalChannelOrders = 1;
      channel['percent'] = (0).toFixed(2);
    } else {
      channel['percent'] = ((totalChannelOrders / totalOrders) * 100).toFixed(2);
    }

    if (channel['totalRevenue' === 0]) {
      channel['avgRevenue'] = 0;
    } else {
      channel['avgRevenue'] = Math.floor(channel['totalRevenue'] / totalChannelOrders);
    }

    if (channel['totalDistance' === 0]) {
      channel['avgDistance'] = 0;
    } else {
      channel['avgDistance'] = Math.floor(channel['totalDistance'] / totalChannelOrders);
    }
  });

  data = [
    {
      'name': t('phone'),
      'value': channelData['cockpit']['totalOrders']
    },
//      {
//        'name': t('app'),
//        'value': channelData['app']['totalOrders']
//      },
    {
      'name': t('web'),
      'value': channelData['web']['totalOrders']
    },
    {
      'name': t('other'),
      'value': channelData['other']['totalOrders']
    }
  ];

  stats = Object.values(channelData);
  
  return (
    <Card cardHeight={400} title={t('channelDistribution')}>
      <div className={css(styles.container)}>
        <ResponsiveContainer width="100%" height="70%">
          <PieChart>
            <Pie 
              data={data}
              dataKey="value" 
              nameKey="name" 
              cx="50%" 
              cy="50%" 
              minAngle={10}
              outerRadius={80} 
              paddingAngle={1} 
              fill={theme.color5} 
              activeIndex={activeSlice}
              onClick={(_, index) => setActiveSlice(index)}
            >
              {
                data.map((cell, index) => 
                  <Cell
                    key={`cell-inner-${index}`}
                    fill={theme.color2}
                  />
                )
              }
            </Pie>
            <Pie 
              data={data}
              dataKey="value" 
              nameKey="name" 
              cx="50%" 
              cy="50%" 
              innerRadius={73} 
              outerRadius={80} 
              activeShape={renderActiveShape}
              minAngle={10}
              paddingAngle={1} 
              fill={theme.color5} 
              activeIndex={activeSlice}
              onClick={(_, index) => setActiveSlice(index)}
            >
              {
                data.map((cell, index) => 
                  <Cell
                    key={`cell-${index}`}
                    fill={(index === activeSlice ? theme.color5 : theme.color12)}
                  />
                )
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className={css(styles.chartLabel)}>
          <span className={css(styles.chartLabelPercentage)}>
            {stats[activeSlice]['icon']}
            {`${stats[activeSlice]['percent']}%`}
          </span>
          <div className={css(styles.chartLabelChannel)}>{`Via ${stats[activeSlice]['name']}`}</div>
        </div>
        <div className={css(styles.stats)}>
          <div className={css(styles.title)}>
            <span className={css(styles.activeChannelLabel)}>{t('performance')} </span>
            <span className={css(styles.activeChannel)}>{stats[activeSlice]['name']}</span>
          </div>
          <InfoRow
            label={t('channelDistributionTotalRevenue')}
            apart={true}
            key={t('channelDistributionTotalRevenue')}
          >
            {`CHF ${formatThousands(stats[activeSlice]['totalRevenue'])}`}
          </InfoRow>
          <InfoRow
            label={t('channelDistributionAvgRevenue')}
            apart={true}
            key={t('channelDistributionAvgRevenue')}
          >
            {`CHF ${formatThousands(stats[activeSlice]['avgRevenue'])}`}
          </InfoRow>
          <InfoRow
            label={t('channelDistributionAvgDistance')}
            apart={true}
            key={t('channelDistributionAvgDistance')}
          >
            {`${stats[activeSlice]['avgDistance']} km`}
          </InfoRow>
        </div>
      </div>
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    revenue: {
      display: 'flex',
      alignItems: 'stretch',
    },
    stats: {
      width: '100%',
      marginTop: -40
    },
    container: {
      width: '100%',
      height: '100%',
      marginTop: -80
    },
    chartLabel: {
      pointerEvents: 'none',
      position: 'absolute',
      top: -40,
      left: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70%',
      width: 100,
      transform: 'translateX(-50%)',
    },
    chartLabelPercentage: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18
    },
    chartLabelChannel: {
      marginTop: 2,
      fontSize: 12,
      fontWeight: 'lighter',
      letterSpacing: 0.1,
      color: theme.color1,
    },
    title: {
      borderBottom: `1px solid ${theme.color12}`,
      padding: 15,
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'lighter',
      letterSpacing: 0.1,
    },
    activeChannel: {
      fontWeight: 'bolder'
    },
    activeChannelLabel: {
      color: theme.color1,
    },
  });
