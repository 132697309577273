import { Theme, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { driversEndpoint } from '../../../../Utils/API';
import { toDbPhone } from '../../../../Utils/Helpers';
import { defaultDriver } from '../../SidePanels/Driver/CreateOrUpdate';
import { useIdentityValidator } from '../../../Essentials/Components/IdentityCreateOrUpdate';
import Page from './Components/Page';

export default ({ onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const { t } = useTranslation();
  const [driver, setDriver] = useState({ ...defaultDriver, firstName: '', lastName: '' });
  const errors = useIdentityValidator(driver);
  const [exists, setExists] = useState(null);
  const isNextDisabled = Object.keys(errors).length > 0;

  const onNextPress = async () => {
    let newDriver = { ...driver, phone: toDbPhone(driver.phone) };

    try {
      await driversEndpoint.createEntry(newDriver);
    } catch (exc) {
      if (exc.status === 422) {
        setExists(t('duplicateEntryPhoneNumber'));
        // setStatus(STATUS.waiting);
      } else {
        // setStatus(STATUS.waiting);
        // createSnackbar('error', t('registrationFailed'));
      }
    }

    // onRequestNextPage();
  };

  const onChange = ({ attribute }) => (e) => {
    let value = e.target.value;

    setDriver({ ...driver, [attribute]: value });
  };

  let content = (
    <>
      <h2 className={css(styles.title)}>{t('registerDriverTitle')}</h2>
      <h5 className={css(styles.desc)}>{t('registerDriverDesc')}</h5>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className={css(styles.input50)}>
          <TextInput
            id="firstName"
            label={t('firstName')}
            value={driver.firstName}
            onChange={onChange({ attribute: 'firstName' })}
            // errorMessage={driver.firstName.length > 0 ? errors.firstName : ''}
            required
            className={css(styles.input)}
          />
        </div>
        <div className={css(styles.input50)}>
          <TextInput
            id="lastName"
            label={t('lastName')}
            value={driver.lastName}
            onChange={onChange({ attribute: 'lastName' })}
            className={css(styles.input)}
            // errorMessage={driver.lastName.length > 0 ? errors.lastName : ''}
            required
          />
        </div>
      </div>
      <TextInput
        id="phone"
        label={t('phone')}
        value={driver.phone}
        onChange={onChange({ attribute: 'phone' })}
        // className={css(styles.input)}
        errorMessage={
          (driver.phone.length > 0 ? errors.phone : '') || (exists !== null ? exists : '')
        }
        required
      />
    </>
  );

  return (
    <>
      <Page onNextPress={onNextPress} isNextDisabled={isNextDisabled}>
        {content}
      </Page>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    input50: {
      flex: 0.49,
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    marginTop: {
      marginTop: 20,
    },
    input: {
      marginBottom: 15,
    },
  });
