import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { s } from '../../../Utils/Helpers';

export default ({ entry, prefix, text }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  if (text) {
    return (
      <div className={css(styles.note)}>
        <div className={css(styles.noteText)}>{text}</div>
      </div >
    );
  }

  if (!entry) {
    return null;
  }

  const noteText = prefix ? entry[prefix + 'NoteText'] : entry.noteText;
  const noteTimestamp = prefix ? entry[prefix + 'NoteTimestamp'] : entry.noteTimestamp;
  const noteAuthor = prefix ? entry[prefix + 'NoteAuthor'] : entry.noteAuthor;

  return (
    <div className={css(styles.note)}>
      {!noteText ? (
        <>
          <div className={css(styles.noNote)}>{t('noNote')}</div>
        </>
      ) : (
          <>
            <div className={css(styles.noteText)}>{noteText}</div>
            {noteTimestamp && noteAuthor && (
              <div className={css(styles.noteFooter)}>
                <div className={css(styles.noteDate)}>
                  {moment.unix(noteTimestamp).format(t('timeAndLongDateFormat'))}
                </div>
                <div className={css(styles.noteSeparator)} />
                <div className={css(styles.noteAuthor)}>{s(t('by'), noteAuthor)}</div>
              </div>
            )}
          </>
        )
      }
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    note: {
      fontSize: 14,
      fontWeight: 'lighter',
      color: theme.color3,
      letterSpacing: 0.1,
      padding: 15,
      lineHeight: '18px',
    },
    noNote: {
      fontSize: 14,
      fontWeight: 'lighter',
      color: theme.color6,
      letterSpacing: 0.1,
      padding: 15,
      lineHeight: '18px',
    },
    noteText: {
      whiteSpace: 'pre-wrap',
    },
    noteFooter: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      marginTop: 15,
      color: theme.color1,
    },
    noteSeparator: {
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.color1,
      margin: '0 10px',
    },
  });
