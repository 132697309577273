import { PersonColumn, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import { s, toHumanPhone } from '../../../Utils/Helpers';

export default ({ driver, isSelected, onSelect }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: driver.id, type: 'driver' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => onSelect(driver),
    end: () => onSelect(),
  });
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const onClick = () => (isSelected ? onSelect() : onSelect(driver));

  return (
    <div
      ref={drag}
      className={css(
        styles.container,
        isDragging && styles.containerDragging,
        isSelected && styles.containerSelected
      )}
      onClick={onClick}
    >
      <PersonColumn
        name={s(driver.lastName, driver.firstName)}
        phone={toHumanPhone(driver.phone)}
      />
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
      border: '2px solid transparent',
      borderRadius: 5,
      padding: 20,
      marginBottom: 20,
      cursor: 'pointer',
      ':hover': {
        opacity: 0.7,
      },
    },
    containerDragging: {
      backgroundColor: theme.color12,
      opacity: '0.2 !important',
      borderColor: 'transparent !important',
    },
    containerSelected: {
      borderColor: theme.color5,
      ':hover': {
        opacity: 1,
      },
    },
  });
