import { Button, Theme, ArrowRight, ArrowLeft } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../../../Utils/Helpers';

export default ({
  children,
  onNextPress,
  isNextDisabled,
  nextLable,
  contentWidth = 720,
  contentMarginLeft = 540,
}) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme, contentMarginLeft);

  return (
    <>
      <div className={css(styles.container)}>
        <div className={css(styles.form)}>
          {children}
          <div className={css(styles.footer)}>
            <Button size="m" disabled iconLeft={<ArrowLeft />}>
              {t('back')}
            </Button>
            <Button
              variant="special"
              icon={<ArrowRight />}
              size="m"
              disabled={isNextDisabled}
              onPress={onNextPress}
            >
              {nextLable ? nextLable : t('continue')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const getThemedStyles = (theme, contentMarginLeft) =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      height: '100%',
      overflow: 'scroll',
      marginLeft: contentMarginLeft,
      // backgroundColor: theme.color13,
      display: 'flex',
      justifyContent: 'center',
    },
    form: {
      alignItems: 'left',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: 720,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 45,
      textAlign: 'right',
    },
  });
