import { Theme, Button, ArrowLeft as BackIcon } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import moment from 'moment';
import OrderContextMenu from '../ContextMenus/Order';
import { s } from '../../../Utils/Helpers';
import { useTranslation } from 'react-i18next';

export default ({ order, onBackPress, children }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const date = moment.unix(order.estimatedTimespan.startTimestamp);

  return (
    <div className={css(styles.header)}>
      <Button size="s" iconLeft={<BackIcon />} onPress={onBackPress}>
        {t('back')}
      </Button>
      <h2 className={css(styles.title)}>
        {s(t('orderNumber'), order._id)}
        <div className={css(styles.date)}>
          {date.format(t('pronouncedDateFormat'))}
          <span className={css(styles.dateSeparator)} />
          {date.format(t('timeFormatLong'))}
        </div>
      </h2>
      {children}
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
    },
    title: {
      flex: 1,
      marginLeft: 24,
      fontSize: 16,
      letterSpacing: 0.5,
    },
    date: {
      fontSize: 12,
      fontWeight: 'lighter',
      color: theme.color1,
      marginLeft: 10,
      display: 'inline-flex',
      alignItems: 'center',
    },
    dateSeparator: {
      margin: '0 10px',
      width: 3,
      height: 3,
      borderRadius: '50%',
      backgroundColor: theme.color1,
    },
  });