import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';

export default ({ children }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  return <div className={css(styles.label)}>{children}</div>;
};

const getThemedStyles = theme =>
  StyleSheet.create({
    label: {
      flex: 1,
      fontWeight: 'lighter',
      color: theme.color3,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.25,
      marginLeft: 14,
    },
  });
