import React from 'react';
import { employeesEndpoint } from '../../../../Utils/API';
import IdentityCreateOrUpdate from '../../Components/IdentityCreateOrUpdate';

const {
  createEntry: createEmployee,
  deleteEntry: deleteEmployee,
  updateEntry: updateEmployee,
} = employeesEndpoint;

const defaultEmployee = {
  phone: '',
  gender: 'unknown',
  status: 'active',
};

export default ({ entry, history, match }) => (
  <IdentityCreateOrUpdate
    person={entry}
    history={history}
    match={match}
    defaultPerson={defaultEmployee}
    createPerson={createEmployee}
    updatePerson={updateEmployee}
    deletePerson={deleteEmployee}
    isIdentifiedByMailAddress={true}
  />
);
