import React from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarCarStandard } from '@yourmileag/ui-kit';
import { carsEndpoint } from '../../../../Utils/API';
import { s } from '../../../../Utils/Helpers';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';
import History from './History';

const { useEntry: useCar } = carsEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={useCar}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      History={History}
      renderHeader={(car) =>
        car && (
          <SidePanelHeader
            icon={<AvatarCarStandard />}
            label={t('car')}
            title={s(car.manufacturer, car.model)}
          />
        )
      }
    />
  );
};
