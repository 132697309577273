import { createSnackbar, InfoRow, TextInput } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map from 'react-map-gl';
import { useGlobal } from 'reactn';
import { employeesEndpoint, poisEndpoint } from '../../../../Utils/API';
import { getPathParent, join, s } from '../../../../Utils/Helpers';
import { apiAccessToken } from '../../../../Utils/Mapbox';
import InventoryCreateOrUpdate from '../../Components/InventoryCreateOrUpdate';
import MapMarker from '../../Components/MapMarker';
import MapNavigationControl from '../../Components/MapNavigationControl';
import TextInputAddress from '../../Components/TextInputAddress';
import useDeleteModal from '../../../../Utils/useDeleteModal';

const { createEntry: createPOI, deleteEntry: deletePOI, updateEntry: updatePOI } = poisEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;

const defaultPOI = {
  name: '',
  source: '',
  position: '{"longitude":7.6638449,"latitude":46.8118199}',
};

const usePOIValidator = (poi) => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newErrors = {};

    if (!poi.name) {
      newErrors.name = t('fieldIsRequired');
    }

    if (!poi.source) {
      newErrors.address = t('fieldIsRequired');
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  }, [poi, t]);

  return errors;
};

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const [poi, setPOI] = useState({ ...defaultPOI, ...entry });
  const errors = usePOIValidator(poi);
  const isSaveDisabled = Object.keys(errors).length > 0;
  const [identityId] = useGlobal('identityId');
  const position = JSON.parse(poi.position);
  const [viewport, setViewport] = useState({ width: '100%', height: 400, ...position, zoom: 15 });
  const [markerPosition, setMarkerPosition] = useState({ ...position });
  const { show, RenderModal } = useDeleteModal();

  useEffect(() => {
    setPOI({ ...defaultPOI, ...entry });
    setViewport({ ...viewport, ...position });
    setMarkerPosition({ ...position });

    // eslint-disable-next-line
  }, [entry]);

  const onChange = (attribute) => (event, flat) => {
    setPOI({ ...poi, [attribute]: flat ? event : event.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newPOI = { ...poi };

    if (!entry || newPOI.noteText !== entry.noteText) {
      const author = await getEmployee(identityId);
      newPOI.noteTimestamp = moment().unix();
      newPOI.noteAuthor = s(author.lastName, author.firstName);
    }

    if (!entry) {
      const newPOIID = await createPOI(newPOI);

      history.push(join(getPathParent(match.url), newPOIID));
    } else {
      await updatePOI(entry.id, newPOI);

      history.push(getPathParent(match.url));
    }

    createSnackbar('success', t('successfullySaved', { name: poi.name }));
  };

  const deleteAction = async () => {
    await deletePOI(entry.id);
    history.push(getPathParent(getPathParent(match.url)));
    createSnackbar('info', t('successfullyDeleted', { name: poi.name }));
  };

  const onDelete = async () => {
    show();
  };

  const onAddressSelect = (address) => {
    if (!address) {
      return;
    }

    const { longitude, latitude } = JSON.parse(address.position);

    setPOI({
      ...poi,
      ...address,
      name: poi.name,
    });

    setViewport({ ...viewport, longitude, latitude });
    setMarkerPosition({ longitude, latitude });
  };

  const onDrag = (event) => {
    const [longitude, latitude] = event.lngLat;
    setMarkerPosition({ longitude, latitude });
  };

  const onDragEnd = (event) => {
    onDrag(event);

    const [longitude, latitude] = event.lngLat;

    setPOI({
      ...poi,
      position: JSON.stringify({ longitude, latitude }),
    });
  };

  return (
    <InventoryCreateOrUpdate
      history={history}
      match={match}
      onSubmit={onSubmit}
      onDelete={entry && onDelete}
      note={poi.noteText}
      onNoteChange={onChange('noteText')}
      isSaveDisabled={isSaveDisabled}
    >
      <InfoRow center label={t('name')} required>
        <TextInput
          id="sfcou-name"
          value={poi.name}
          onChange={onChange('name')}
          required
          errorMessage={errors.name}
          clean
          size="s"
        />
      </InfoRow>
      <InfoRow center label={t('address')} required>
        <TextInputAddress
          id="sfcou-address"
          onSelect={onAddressSelect}
          initialValue={poi.source}
          excludePOIs
          clean
          onChange={(value) => onChange('source')(value, true)}
          required
          errorMessage={errors.address}
        />
      </InfoRow>
      <div className={css(styles.mapContainer)}>
        <Map
          mapboxApiAccessToken={apiAccessToken}
          {...viewport}
          onViewportChange={setViewport}
          mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
        >
          <MapMarker
            draggable={true}
            onDragStart={onDrag}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            {...markerPosition}
          >
            POI
          </MapMarker>
          <MapNavigationControl />
        </Map>
      </div>
      <RenderModal deleteAction={deleteAction} />
    </InventoryCreateOrUpdate>
  );
};

const styles = StyleSheet.create({
  mapContainer: {
    marginTop: 20,
  },
});
