import { FleetColumn, OriginDestinationColumn, PersonColumn, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getFleetIcon } from '../../../Utils/Fleet';
import { s } from '../../../Utils/Helpers';

export default ({ order, clean }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={css(styles.order, !!clean && styles.clean)}>
      <PersonColumn name={s(order.customer.lastName, order.customer.firstName)} />
      <OriginDestinationColumn
        origin={order.origin}
        destination={order.destination}
        hasStopovers={order.stopovers.length > 0}
      />
      <FleetColumn
        icon={getFleetIcon(order.fleet.icon)}
        label={order.fleet.label}
        car={moment.unix(order.estimatedTimespan.startTimestamp).format(t('timeFormatLong'))}
      />
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    order: {
      backgroundColor: theme.color2,
      boxShadow: theme.shadow1,
      borderRadius: 5,
      padding: 20,
      marginTop: 20,
    },
    clean: {
      boxShadow: 'none',
      border: `solid ${theme.color16} 1px`,
      borderRadius: 3,
    },
  });
