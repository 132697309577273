import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { GoLogo, Theme } from '@yourmileag/ui-kit';

const animationDuration = 600;

export default ({ progress, onAnimationsFinished }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const [isFinishing, setFinishing] = React.useState(false);
  const [animationPhase, setAnimationPhase] = React.useState();

  React.useEffect(() => {
    if (!isFinishing) {
      return;
    }

    setTimeout(onAnimationsFinished, animationDuration);
  }, [isFinishing, onAnimationsFinished]);

  React.useEffect(() => {
    if (progress < 100) {
      return;
    }

    setFinishing(true);
  }, [progress]);

  React.useEffect(() => {
    if (isFinishing) {
      return;
    }

    if (animationPhase === undefined) {
      setAnimationPhase(1);
    }

    const newAnimationPhase = animationPhase === 0 ? 1 : 0;

    setTimeout(() => setAnimationPhase(newAnimationPhase), animationDuration);
  }, [animationPhase, isFinishing]);

  const progressBarWidth = { transform: `scale3d(${progress / 100}, 1, 1)` };

  return (
    <div className={css(styles.container, isFinishing && styles.containerFinishing)}>
      <div
        className={css(
          styles.logo,
          styles[`logoAnimationPhase${animationPhase}`],
          isFinishing && styles.logoFinishing
        )}
      >
        <GoLogo />
      </div>
      <div className={css(styles.progressBarContainer)}>
        <div className={css(styles.progressBar)} style={progressBarWidth} />
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.color2,
      zIndex: 1,
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 1,
      transitionProperty: 'opacity',
      transitionDuration: `${animationDuration}ms`,
    },
    containerFinishing: {
      opacity: 0,
    },
    logo: {
      color: theme.color6,
      fontSize: 60,
      transform: 'scale3d(1, 1, 1)',
      transitionProperty: 'transform',
      transitionDuration: `${animationDuration}ms`,
      transitionTimingFunction: 'linear',
    },
    logoAnimationPhase0: {
      transform: 'scale3d(1, 1, 1)',
    },
    logoAnimationPhase1: {
      transform: 'scale3d(1.2, 1.2, 1)',
    },
    logoFinishing: {
      transform: 'scale3d(0, 0, 0)',
    },
    progressBarContainer: {
      marginTop: 30,
      backgroundColor: theme.color4,
      width: 125,
      height: 5,
    },
    progressBar: {
      height: '100%',
      width: '100%',
      transform: 'scale3d(0, 1, 1)',
      transformOrigin: 'left',
      backgroundColor: theme.color6,
      transition: `transform ${theme.transitionStateChange}`,
    },
  });
