import { AvatarClientFemale, AvatarClientMale, AvatarClientUnknown } from '@yourmileag/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customersEndpoint } from '../../../../Utils/API';
import { s } from '../../../../Utils/Helpers';
import InventorySidePanel from '../../Components/InventorySidePanel';
import SidePanelHeader from '../../Components/SidePanelHeader';
import CreateOrUpdate from './CreateOrUpdate';
import Detail from './Detail';
import History from './History';

const { useEntry: useCustomer } = customersEndpoint;

export default ({ match, history }) => {
  const { t } = useTranslation();

  return (
    <InventorySidePanel
      match={match}
      pushHistory={history.push}
      useEntry={useCustomer}
      CreateOrUpdate={CreateOrUpdate}
      Detail={Detail}
      History={History}
      renderHeader={(customer) => {
        if (!customer) {
          return;
        }

        let icon;
        switch (customer.gender) {
          case 'male':
            icon = <AvatarClientMale />;
            break;
          case 'female':
            icon = <AvatarClientFemale />;
            break;
          default:
            icon = <AvatarClientUnknown />;
        }

        return (
          <SidePanelHeader
            icon={icon}
            label={t('customer')}
            title={s(customer.lastName, customer.firstName)}
          />
        );
      }}
    />
  );
};
