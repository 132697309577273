import { Theme, Phone, Mail } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../Utils/Helpers';

export default () => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerInner)}>
        <h1 className={css(styles.title)}>
          {t('helpTitle')}
        </h1>
        <p className={css(styles.content)}>{t('helpDesc')}</p>
        <a className={css(styles.contact)} href="tel:+41447777703">
          <span className={css(styles.footerContactIcon)}>
            <Phone />
          </span>
          +41 44 777 77 03
        </a>
        <a className={css(styles.contact)} href="mailto:hello@go-fleet.ch">
          <span className={css(styles.footerContactIcon)}>
            <Mail />
          </span>
          hello@go-fleet.ch
        </a>
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(44,62,80,0.7)',
      zIndex: 10,
    },
    containerInner: {
      backgroundColor: theme.color2,
      width: 500,
      padding: 30,
      borderRadius: 5,
    },
    title: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '28px',
      marginBottom: 15,
      color: theme.color3,
    },
    content: {
      color: theme.color1,
      lineHeight: '1.5em',
      marginBottom: 30,
      fontWeight: 300,
    },
    contact: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 'lighter',
      marginBottom: 10,
      ':hover': {
        opacity: 0.7,
      },
    },
    footerContactIcon: {
      fontSize: 30,
      marginRight: 10,
    },
  });