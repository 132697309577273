import { Theme, VehicleStandard } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMapGL from 'react-map-gl';
import { fitBounds } from 'viewport-mercator-project';
import { carsEndpoint } from '../../../../Utils/API';
import { getSouthWestAndNorthEast, usePageTitle } from '../../../../Utils/Helpers';
import { apiAccessToken } from '../../../../Utils/Mapbox';
import MapMarkerCar from '../../Components/MapMarkerCar';
import MapNavigationControl from '../../Components/MapNavigationControl';

const defaultViewport = {
  longitude: 8.3422445,
  latitude: 46.7873778,
  zoom: 7,
};

var initialViewport = defaultViewport;

const useMap = () => {
  const [initialCars] = carsEndpoint.useEntries();
  const [cars, setCars] = useState([]);
  const [viewport, setViewport] = useState();

  useEffect(() => {
    if (!initialCars || initialCars.length === 0) {
      setViewport(defaultViewport);
      return;
    }

    const coords = [];
    const cars = {};

    initialCars.forEach((car) => {
      cars[car.id] = car;

      if (!car.lastPosition) {
        return;
      }

      const [longitude, latitude] = JSON.parse(car.lastPosition);
      coords.push([longitude, latitude]);
    });

    setCars(cars);

    if (coords.length === 0) {
      setViewport(defaultViewport);
      return;
    }

    const bounds = fitBounds({
      width: 986,
      height: 400,
      padding: 60,
      bounds: getSouthWestAndNorthEast(coords),
    });

    if (bounds.zoom > 18) {
      bounds.zoom = 18;
    }

    setViewport({
      longitude: bounds.longitude,
      latitude: bounds.latitude,
      zoom: bounds.zoom,
    });

    initialViewport = {
      longitude: bounds.longitude,
      latitude: bounds.latitude,
      zoom: bounds.zoom,
    };

  }, [initialCars]);

  return [viewport, setViewport, cars, setCars];
};

export default () => {
  const [viewport, setViewport, cars, setCars] = useMap();
  const { t } = useTranslation();
  usePageTitle(t('dashboard'));
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const onViewportChange = (newViewPort) => setViewport(newViewPort);
  const onChangeCar = (carId) => (car) => setCars({ ...cars, [carId]: car });

  const allCars = Object.values(cars);
  const freeCars = allCars.filter((car) => car.status === 'free' && car.essentials_driver !== null);
  const occupiedCars = allCars.filter(
    (car) => car.status === 'occupied' && car.essentials_driver !== null
  );
  const noDriverCars = allCars.filter((car) => car.essentials_driver === null);

  return (
    <div id="map" className={css(styles.map)}>
      <ReactMapGL
        mapboxApiAccessToken={apiAccessToken}
        {...viewport}
        onViewportChange={onViewportChange}
        width="100%"
        height="100%"
        maxZoom={18}
        attributionControl={false}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      >
        {allCars.map((car) => (
          <MapMarkerCar
            key={car.id}
            carId={car.id}
            initialData={car}
            onChange={onChangeCar(car.id)}
          />
        ))}
        <MapNavigationControl />
      </ReactMapGL>
      {false && (
        <button className={css(styles.reset)} onClick={()=>setViewport(initialViewport)}>
          Reset
        </button>
      )}
      <div className={css(styles.footer)}>
        <div className={css(styles.statBox)}>
          <div className={css(styles.statBoxIcon, styles.statBoxIconFree)}>
            <VehicleStandard />
          </div>
          <div className={css(styles.statBoxAmount)}>
            {freeCars.length}
            <div className={css(styles.statBoxAmountLabel)}>{t('free')}</div>
          </div>
        </div>
        <div className={css(styles.statBox)}>
          <div className={css(styles.statBoxIcon, styles.statBoxIconOccupied)}>
            <VehicleStandard />
          </div>
          <div className={css(styles.statBoxAmount)}>
            {occupiedCars.length}
            <div className={css(styles.statBoxAmountLabel)}>{t('occupied')}</div>
          </div>
        </div>
        <div className={css(styles.statBox)}>
          <div className={css(styles.statBoxIcon, styles.statBoxIconNoDriver)}>
            <VehicleStandard />
          </div>
          <div className={css(styles.statBoxAmount)}>
            {noDriverCars.length}
            <div className={css(styles.statBoxAmountLabel)}>{t('noDriver')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    map: {
      flex: 1,
      position: 'relative',
    },
    reset: {
      position: 'absolute',
      top: 20,
      left: 20,
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.30) 100%)',
      padding: 30,
      display: 'flex',
      justifyContent: 'center',
      zIndex: 4,
    },
    statBox: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 5px 24px -6px rgba(44,62,80,0.25)',
      borderRadius: 5,
      width: 270,
      height: 80,
      padding: 20,
      margin: '0 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    statBoxIcon: {
      fontSize: 45,
      lineHeight: '45px',
      marginRight: 20,
    },
    statBoxIconFree: {
      color: theme.colorSuccess,
    },
    statBoxIconOccupied: {
      color: theme.colorError,
    },
    statBoxIconNoDriver: {
      color: '#8B96A3',
    },
    statBoxAmount: {
      fontWeight: 'lighter',
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: 0.3,
      color: theme.color3,
      display: 'flex',
      alignItems: 'flex-end',
    },
    statBoxAmountLabel: {
      fontWeight: 'lighter',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.09,
      color: theme.color1,
      marginLeft: 10,
    },
  });
