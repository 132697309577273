import { createSnackbar } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { bugsnagClient } from '../../../../bugsnag';
import { customersEndpoint, employeesEndpoint, ordersEndpoint, fleetsEndpoint } from '../../../../Utils/API';
import {
  convertEmptyStringsToNull,
  fullScreenStyles,
  getTimeAndDateAsUnix,
  s,
  getPathParent,
  usePageTitle,
  verticalScrollStyles,
} from '../../../../Utils/Helpers';
import { getCalculatedPrice } from '../../../../Utils/Price';
import CustomerSidePanel from '../../SidePanels/Customer';
import CustomerSelection from './CustomerSelection';
import OrderDetails from './Details';
import useValidator from './validator';
import OrderHeader from '../../Components/OrderHeader';
import { useSettings } from '../../../Settings';
import { getShortestRoute } from '../../../../Utils/Mapbox';

const { createEntry: createOrder } = ordersEndpoint;
const { getEntry: getOrder } = ordersEndpoint;
const { useEntry: useOrder } = ordersEndpoint;
const { updateEntry: updateOrder } = ordersEndpoint;
const { deleteEntry: deleteOrder } = ordersEndpoint;
const { createEntry: createCustomer } = customersEndpoint;
const { getEntry: getCustomer } = customersEndpoint;
const { getEntry: getEmployee } = employeesEndpoint;
const { useEntries: useFleets } = fleetsEndpoint;

export default ({ match, history, location }) => {
  const { t } = useTranslation();
  usePageTitle(t('createOrder'));

  const [settings, setSettings] = useSettings();

  const [submitted, setSubmitted] = useState(false);
  const [customer, setCustomer] = useState();
  const [extraCustomers, setExtraCustomers] = useState([]);
  const [origin, setOrigin] = useState();
  const [stopovers, setStopovers] = useState([]);
  const [destination, setDestination] = useState();
  const [route, setRoute] = useState();
  const [fleets] = useFleets();
  const [fleet, setFleet] = useState();
  const [time, setTime] = useState(moment().format(t('timeFormat')));
  const [date, setDate] = useState(moment());
  const [endTime, setEndTime] = useState(moment().format(t('timeFormat')));
  const [endDate, setEndDate] = useState(moment());
  const [pricing, setPricing] = useState('taxometer');
  const [type, setType] = useState('one-way');
  const [timing, setTiming] = useState('now');
  const [passengerAmount, setPassengerAmount] = useState(1);
  const [returnOrderForId, setReturnOrderForId] = useState();
  const [price, setPrice] = useState();
  const [briefing, setBriefing] = useState('');
  const [identityId] = useGlobal('identityId');
  const [orderTemplate, setOrderTemplate] = useGlobal('orderTemplate');
  const [returnTemplate, setReturnTemplate] = useGlobal('returnTemplate');
  const errors = useValidator({
    type: type,
    lastName: customer && customer.lastName,
    mailAddress: customer && customer.mailAddress,
    phone: customer && customer.phone,
    route,
    date,
    time,
    endDate,
    endTime,
    pricing,
    price,
    fleet,
  });
  const [updatingOrder, error] = useOrder(match.params.update_id);

  useEffect(() => {
    if (!origin || !destination) {
      setRoute();
      return;
    }

    let stopoverData = [];

    for (let i = 0; i < stopovers.length; i++) {

      if (typeof stopovers[i].position !== 'undefined') {
        stopoverData.push({ name: stopovers[i].name, position: stopovers[i].position, source: stopovers[i].source });
      }
      
      if (!stopovers[i]) {
        setRoute();
        return;
      }
    }

    (async () => {
      const route = await getShortestRoute(origin, destination, stopoverData);

      setRoute(route);
    })();
  }, [origin, destination, stopovers, setRoute]);

  useEffect(() => {
   if (settings.canUseDayTrip) {
     setType('one-way');
   }
  }, [settings]);

  useEffect(() => {
    if (!orderTemplate) {
      return;
    }

    setOrigin(orderTemplate.origin);
    setStopovers(orderTemplate.stopovers);
    setDestination(orderTemplate.destination);

    applyBaseOrderTemplate(orderTemplate);

    // setTiming('preorder');
    setTime(moment.unix(orderTemplate.estimatedTimespan.startTimestamp).format(t('timeFormat')));
    // setFleet(orderTemplate.fleet);
    // setPricing(orderTemplate.pricing);
    // setBriefing(orderTemplate.noteText);

    setOrderTemplate(null);

    // eslint-disable-next-line
  }, [orderTemplate]);

  useEffect(() => {
    if (!returnTemplate) {
      return;
    }

    let returnStopovers = JSON.parse(JSON.stringify(returnTemplate.stopovers)).reverse();

    setOrigin(returnTemplate.destination);
    setStopovers(returnStopovers);
    setDestination(returnTemplate.origin);

    applyBaseOrderTemplate(returnTemplate);

    // setTiming('preorder');
    setTime(moment.unix(returnTemplate.estimatedTimespan.endTimestamp + (60 * 10)).format(t('timeFormat')));
    // setFleet(returnTemplate.fleet);
    // setPricing(returnTemplate.pricing);
    // setBriefing(returnTemplate.noteText);

    setReturnOrderForId(returnTemplate._id);

    setReturnTemplate(null);

    // eslint-disable-next-line
  }, [returnTemplate]);

  useEffect(() => {
    if (!updatingOrder) {
      return;
    }

    if (['new', 'assigning', 'assigned', 'problem'].indexOf(updatingOrder.status) === -1) {
      history.push('/essentials/dashboard');
    }

    setOrigin(updatingOrder.origin);
    setStopovers(updatingOrder.stopovers);
    setDestination(updatingOrder.destination);

    applyBaseOrderTemplate(updatingOrder);

    // setTiming('preorder');
    setDate(moment.unix(updatingOrder.estimatedTimespan.startTimestamp).toDate())
    setTime(moment.unix(updatingOrder.estimatedTimespan.startTimestamp).format(t('timeFormat')));

    if (updatingOrder.estimatedTimespan.startTimestamp < moment().unix()) {
      setTiming('now');
      setTime(moment().format(t('timeFormat')));
      setDate(moment().toDate());
    }

    // setFleet(updatingOrder.fleet);
    // setPricing(updatingOrder.pricing);
    setPrice(updatingOrder.price);
    // setBriefing(updatingOrder.noteText);

    setCustomer(updatingOrder.customer);

    // eslint-disable-next-line
  }, [updatingOrder]);

  useEffect(() => {
    if (fleet || !fleets) {
      return;
    }

    if (fleets.length === 1) {
      setFleet(fleets[0]);
    }

    // eslint-disable-next-line
  }, [fleets]);

  const applyBaseOrderTemplate = (orderTemplate) => {
    setTiming('preorder');
    setFleet(orderTemplate.fleet);
    setPricing(orderTemplate.pricing);
    setBriefing(orderTemplate.noteText);
    if (orderTemplate.passengerAmount) {
      setPassengerAmount(orderTemplate.passengerAmount);
    }
    if (orderTemplate.extraCustomers) {
      setExtraCustomers(orderTemplate.extraCustomers);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (submitted) {
      return;
    }

    setSubmitted(true);
    let newCustomer = customer;

    try {
      if (!customer.id) {
        const id = await createCustomer(convertEmptyStringsToNull(customer));
        newCustomer = { ...newCustomer, id };
      }

      const newOrder = {
        origin,
        destination,
        stopovers,
        customer: newCustomer,        
        fleet,
        estimatedDuration: route.duration.value,
        estimatedDistance: route.distance.value,
        mapboxGeoJson: route,
        pricing,
        isPaid: false,
        hasInvoiceBeenSent: false,
        status: 'new',
        stage: 0,
        source: 'cockpit',
        returnOrderForId: returnOrderForId,
      };

      if (settings.canUsePassengerAmount) {
        newOrder.passengerAmount = parseInt(passengerAmount);
      }

      if (settings.canAddExtraCustomers) {
        let _extraCustomers = [];
        extraCustomers.map((customer) => {
          if (customer.phone) {
            customer.passengerAmount = parseInt(customer.passengerAmount);
            _extraCustomers.push(customer);
          }
        });

        newOrder.extraCustomers = _extraCustomers;
      }

      const startTimestamp = getTimeAndDateAsUnix(time, date);
      let endTimestamp = startTimestamp + newOrder.estimatedDuration * 60;

      if (settings.canUseDayTrip) {
        newOrder.type = type;
        if (type == 'day-trip') {
          endTimestamp = getTimeAndDateAsUnix(endTime, endDate);
        }
      };

      newOrder.estimatedTimespan = { startTimestamp, endTimestamp };
      newOrder.estimatedPrice = getCalculatedPrice(route, fleet);

      const author = await getEmployee(identityId);
      newOrder.noteTimestamp = moment().unix();
      newOrder.noteAuthor = s(author.lastName, author.firstName);
      newOrder.noteText = briefing;

      if (pricing === 'fixed') {
        newOrder['price'] = price;
        newOrder['currency'] = fleet.rateCurrency;
      }

      if (match.params.update_id) {
        await deleteOrder(updatingOrder._id);
      }
      const orderId = await createOrder(newOrder);

      createSnackbar(
        'success',
        t('successfullyCreatedOrderForCustomer', { name: s(customer.lastName, customer.firstName) })
      );

      history.push(`/essentials/orders/overview/0/${orderId}`);
    } catch (exc) {
      setSubmitted(false);

      bugsnagClient.notify('Could not create order for customer', { metaData: exc });

      createSnackbar(
        'error',
        t('couldNotCreateOrderForCustomer', { name: s(customer.lastName, customer.firstName) })
      );
    }
  };

  const onBackPress = () => history.push(getPathParent(match.url));

  const onCustomerSelectionDelete = () => {
    setCustomer();
    setOrigin();
    setStopovers([]);
    setDestination();
    setFleet();
    setBriefing();
  };

  if (match.params.update_id && !updatingOrder) {
    return (<></>);
  }

  return (
    <>
      {!orderTemplate && (
        <div className={css(styles.containerOuter)}>
          <div className={css(styles.container)}>
            {match.params.update_id && (
              <OrderHeader order={updatingOrder} onBackPress={onBackPress}/>
            )}
            <CustomerSelection
              match={match}
              history={history}
              location={location}
              passengerAmount={passengerAmount}
              onChange={setCustomer}
              onPassengerAmountChange={setPassengerAmount}
              onDelete={onCustomerSelectionDelete}
              errors={errors}
            />
            {customer && (
              <OrderDetails
                settings={settings}
                onSubmit={onSubmit}
                customer={customer}
                passengerAmount={passengerAmount}
                extraCustomers={extraCustomers}
                route={route}
                origin={origin}
                stopovers={stopovers}
                destination={destination}
                time={time}
                date={date}
                endTime={endTime}
                endDate={endDate}
                type={type}
                timing={timing}
                fleet={fleet}
                pricing={pricing}
                price={price}
                briefing={briefing}
                onChangeCustomer={setCustomer}
                onChangeExtraCustomers={setExtraCustomers}
                onChangeOrigin={setOrigin}
                onChangeStopovers={setStopovers}
                onChangeDestination={setDestination}
                onChangeTime={setTime}
                onChangeDate={setDate}
                onChangeEndTime={setEndTime}
                onChangeEndDate={setEndDate}
                onChangeTiming={(value) => {
                  if (value === 'now') {
                    setTime(moment().format(t('timeFormat')));
                    setDate(moment().toDate());
                  }
                  setTiming(value);
                }}
                onChangeType={setType}
                onChangeFleet={setFleet}
                onChangePricing={setPricing}
                onChangePrice={setPrice}
                onChangeBriefing={setBriefing}
                errors={errors}
              />
            )}
          </div>
        </div>
      )}
      <Route path={`${match.url}/:id`} component={CustomerSidePanel} />
    </>
  );
};

const styles = StyleSheet.create({
  containerOuter: {
    ...fullScreenStyles,
    ...verticalScrollStyles,
  },
  container: {
    maxWidth: 1060,
    margin: '0 auto',
    flexDirection: 'column',
    padding: 30,
  },
  title: {
    fontSize: 16,
    color: '#8B97A3',
    fontWeight: 'lighter',
    marginBottom: 30,
  },
});
