import { useEffect } from 'react';
import { createSnackbar } from '@yourmileag/ui-kit';
import { subscriptionsEndpoint } from '../../../../Utils/API';
import moment from 'moment';

export const useSubscriptionPaymentHandler = (subscription, history, match, t) => {
  useEffect(() => {
    const url = new URL(window.location.href);

    if (!subscription || url.searchParams.get('status') === null) {
      return;
    }

    if (url.searchParams.get('status') !== 'success') {
      createSnackbar('error', t('unexpectedError'));
      return;
    }

    createSnackbar('success', t('successfullyPaid'));

    let newCarLicenses = subscription.licences;
    let newModules = subscription.modules;

    if (subscription.changes) {
      if (subscription.changes.add) {
        if (subscription.changes.add.licences) {
          newCarLicenses += subscription.changes.add.licences;
        }

        if (subscription.changes.add.modules) {
          newModules = newModules.concat(subscription.changes.add.modules);
        }
      }

      if (subscription.changes.remove) {
        if (subscription.changes.remove.licences) {
          newCarLicenses -= subscription.changes.remove.licences;
        }

        if (subscription.changes.remove.modules) {
          newModules = newModules.filter(id => !subscription.changes.remove.modules.includes(id));
        }
      }
    }

    const start = moment()
      .startOf('day')
      .unix();
    const end = moment()
      .startOf('day')
      .add(30, 'days')
      .unix();

    subscriptionsEndpoint.updateEntry(subscription._id, {
      datatrans: Object.fromEntries(url.searchParams),
      cardAlias: {
        alias: url.searchParams.get('aliasCC'),
        expiryYear: url.searchParams.get('expy'),
        expiryMonth: url.searchParams.get('expm'),
      },
      licences: newCarLicenses,
      modules: newModules,
      status: 'paid',
      start,
      end,
      changes: null,
    });

    history.replace(match.url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);
};
