import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

export const today = moment()
  .startOf('day')
  .unix();

export const join = (base, ...paths) => {
  let url = base.charAt(base.length - 1) === '/' ? base.slice(0, -1) : base;

  paths.forEach(path => {
    let newPath = path;

    if (typeof newPath === 'string') {
      if (newPath.charAt(0) === '/') {
        newPath = newPath.slice(0, 1);
      }

      if (newPath.charAt(path.length - 1) === '/') {
        newPath = newPath.slice(0, -1);
      }
    }

    url = `${url}/${newPath}`;
  });

  return url;
};

export const getPathParent = path => path.substring(0, path.lastIndexOf('/'));

export const s = (...strings) => strings.join(' ');

export const toKm = valueInM => Math.round(valueInM / 1000);

export const toDbPhone = phone => {
  const parsed = parsePhoneNumberFromString(phone, 'CH');
  if (!parsed || !parsed.isValid()) {
    return '';
  }

  return parsed.number;
};

export const toHumanPhone = phone => {
  if (!phone) {
    return '';
  }

  const parsed = parsePhoneNumberFromString(phone, 'CH');

  return parsed.formatInternational();
};

export const getRelationStringAsIdArray = relationString =>
  relationString === undefined ||
  (relationString !== null && relationString.length === 0) ||
  relationString === null
    ? []
    : relationString.split(',').map(Number);

export const getTimeAndDateAsUnix = (time, date) => {
  const [hours, minutes] = time.split(':');

  return moment(date)
    .startOf('day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .unix();
};

export const useEveryMinute = handler => {
  useEffect(() => {
    let timeout;

    const start = () => {
      handler();

      const now = new Date();
      const interval = 60000;
      const delay = interval - (now % interval);
      timeout = setTimeout(start, delay);
    };

    start();

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  }, []);
};

export const getTimespanFilterForDay = (timespan, timestampOrDate) => {
  const date = typeof timestampOrDate === 'number' ? moment.unix(timestampOrDate) : timestampOrDate;

  const startOfDay = date.startOf('day').unix();
  const endOfDay = date.endOf('day').unix();

  return `[gte]${timespan}.startTimestamp:${startOfDay};[lte]${timespan}.startTimestamp:${endOfDay}`;
};

export const getStartFilterForDay = (timespan, timestampOrDate) => {
  const date = typeof timestampOrDate === 'number' ? moment.unix(timestampOrDate) : timestampOrDate;

  const startOfDay = date.startOf('day').unix();

  return `[gte]${timespan.replace('start__', '')}.startTimestamp:${startOfDay}`;
};

export const getEndFilterForDay = (timespan, timestampOrDate) => {
  const date = typeof timestampOrDate === 'number' ? moment.unix(timestampOrDate) : timestampOrDate;

  const endOfDay = date.endOf('day').unix();

  return `[lte]${timespan.replace('end__', '')}.endTimestamp:${endOfDay}`;
};

export const getEstimatedTodayFilter = () => getTimespanFilterForDay('estimatedTimespan', today);

export const getTodayFilter = () => getTimespanFilterForDay('timespan', today);

export const fullScreenStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
};

export const verticalScrollStyles = {
  overflowY: 'scroll',
  '-webkit-overflow-scrolling': 'touch',
};

export const horizontalScrollStyles = {
  overflowX: 'scroll',
  '-webkit-overflow-scrolling': 'touch',
};

export const convertEmptyStringsToNull = obj => {
  const newObj = { ...obj };

  // eslint-disable-next-line
  for (let key in newObj) {
    if (!newObj.hasOwnProperty(key)) {
      continue;
    }

    if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      newObj[key] = convertEmptyStringsToNull(newObj[key]);
      continue;
    }

    if (newObj[key] === '') {
      newObj[key] = null;
    }
  }

  return newObj;
};

export const getSouthWestAndNorthEast = coords => {
  const longitudes = coords.map(c => c[0]);
  const latitudes = coords.map(c => c[1]);

  const minLng = Math.min(...longitudes);
  const minLat = Math.min(...latitudes);
  const maxLng = Math.max(...longitudes);
  const maxLat = Math.max(...latitudes);

  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];

  return [southWest, northEast];
};

export const getDurationAsMinutes = duration => {
  const isPositive = duration > 0;

  const durationInMinutes = Math.ceil(
    moment.duration(isPositive ? duration : -duration, 'seconds').asMinutes()
  );

  return isPositive ? durationInMinutes : -durationInMinutes;
};

export const getOrderDelays = order => {
  let originDelay;
  let destinationDelay;

  if (order.timespan) {
    if (
      order.timespan.startTimestamp &&
      order.timespan.startTimestamp !== order.estimatedTimespan.startTimestamp
    ) {
      originDelay = getDurationAsMinutes(
        order.timespan.startTimestamp - order.estimatedTimespan.startTimestamp
      );
    }

    if (
      order.timespan.endTimestamp &&
      order.timespan.endTimestamp !== order.estimatedTimespan.endTimestamp
    ) {
      destinationDelay = getDurationAsMinutes(
        order.timespan.endTimestamp - order.estimatedTimespan.endTimestamp
      );
    }
  }

  return {
    originDelay,
    destinationDelay,
  };
};

export const usePageTitle = title => {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = `${title} | go! Fleet Cockpit`;

    return () => {
      document.title = previousTitle;
    };
  }, [title]);
};

export const useContainerDimensions = () => {
  const container = useRef();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    if (!container || !container.current) {
      return;
    }

    setWidth(container.current.offsetWidth);
    setHeight(container.current.offsetHeight);
  }, [container]);

  return [container, width, height];
};

export const formatThousands = (x) => {return parseFloat(x).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, "'");};

export const isDevMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
