import { Nav, useSnackbar } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useGlobal } from 'reactn';
import './Data/State';
import Loading from './Modules/Essentials/Screens/Loading';
import Login from './Modules/Essentials/Screens/Login/index';
import TourManager from './Modules/Tours/TourManager';
import getNavItems from './NavItems';
import SettingsWs from './Utils/SettingsWs';
import { employeesEndpoint, getOrganisationId, organisationsEndpoint } from './Utils/API';
import { fullScreenStyles, s } from './Utils/Helpers';

const { useEntry: useEmployee } = employeesEndpoint;
const { useEntry: useOrganisation } = organisationsEndpoint;

const getEmployeeInitials = (employee) => {
  let initials = '';

  if (employee.lastName) {
    initials += employee.lastName[0].toUpperCase();
  }
  if (employee.firstName) {
    initials += employee.firstName[0].toUpperCase();
  }

  return initials;
};

const App = () => {
  const [isSidePanelVisible] = useGlobal('isSidePanelVisible');
  const [isNavExpanded, setNavExpanded] = useGlobal('isNavExpanded');
  const [identityId, setIdentityId] = useGlobal('identityId');
  const [employee] = useEmployee(identityId);
  const [organisation] = useOrganisation(getOrganisationId());
  const { t } = useTranslation();

  if (!identityId) {
    return <Login />;
  }

  const onExpandedChange = (isExpanded) => {
    Cookies.set('isNavExpanded', isExpanded ? 'YES' : 'NO');
    setNavExpanded(isExpanded);
  };

  const onLogoutPress = () => {
    Cookies.remove('token');
    setIdentityId(null);
  };

  const routes = getNavItems(t, getOrganisationId());

  return (
    <>
      <main
        className={css(
          styles.container,
          isNavExpanded && styles.containerNavExpanded,
          isSidePanelVisible && styles.containerSidePanelVisible
        )}
      >
        <SettingsWs />
        <Switch>
          <Redirect from="/verify" to={routes[0].path} />
          {routes.map((route) => (
            <Route key={route.path} path={route.path} component={route.component} />
          ))}
          <Redirect from="/" to={routes[0].path} />
        </Switch>
      </main>
      <div className={css(styles.nav)}>
        {employee && (
          <Nav
            navItems={routes}
            title={'Cockpit'}
            titleHref={'/essentials/dashboard'}
            companyName={organisation ? organisation.name : ''}
            userName={s(employee.lastName, employee.firstName)}
            userNameInitials={getEmployeeInitials(employee)}
            isExpanded={isNavExpanded}
            onExpandedChange={onExpandedChange}
            onLogoutPress={onLogoutPress}
            footerClassName={css(styles.navFooter)}
            headerId="main-nav-header"
          />
        )}
      </div>
      <TourManager />
    </>
  );
};

export default () => {
  const snackbar = useSnackbar();

  return (
    <>
      <Loading ContentComponent={App} />
      {snackbar}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    ...fullScreenStyles,
    left: 80,
  },
  containerNavExpanded: {
    left: 300,
  },
  containerSidePanelVisible: {
    right: 560,
  },
  nav: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
  },
  navFooter: {
    backgroundColor: 'rgba(246, 247, 247, 0.06)',
    backgroundImage: 'url(/assets/images/pattern.png) !important',
    backgroundSize: 'contain',
  },
});
