import { InfoRow, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticMap } from 'react-map-gl';
import { apiAccessToken } from '../../../../Utils/Mapbox';
import InventoryDetail from '../../Components/InventoryDetail';
import MapMarker from '../../Components/MapMarker';

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);

  const position = JSON.parse(entry.position);

  return (
    <InventoryDetail entry={entry} history={history} match={match}>
      <InfoRow label={t('name')}>{entry.name}</InfoRow>
      <div className={css(styles.map)}>
        <StaticMap
          mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
          mapboxApiAccessToken={apiAccessToken}
          width="100%"
          height={400}
          zoom={15}
          {...position}
        >
          <MapMarker {...position}>POI</MapMarker>
        </StaticMap>
      </div>
    </InventoryDetail>
  );
};

const getThemedStyles = (theme) => StyleSheet.create({});
