import { Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ moduleName, calculationDesc, price }) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={css(styles.costOverviewRow)}>
      <div className={css(styles.costOverviewRowTitle)}>{t(moduleName)}</div>
      <div className={css(styles.costOverviewRowCalc)}>{calculationDesc}</div>
      <div className={css(styles.costOverviewRowPrice)}>{price}</div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    costOverviewRow: {
      display: 'flex',
      borderBottom: `1px solid ${theme.color9}`,
      paddingBottom: 10,
      marginBottom: 10,
      alignItems: 'center',
    },
    costOverviewRowTitle: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 16,
    },
    costOverviewRowCalc: {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 'lighter',
      letterSpacing: 0.1,
      color: theme.color1,
      marginRight: 10,
    },
    costOverviewRowPrice: {
      fontWeight: 'bold',
      fontSize: 16,
    },
  });
