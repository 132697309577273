import React from 'react';
import { useTranslation } from 'react-i18next';
import { s } from '../../../Utils/Helpers';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Theme } from '@yourmileag/ui-kit';

export default ({ currency, baseFee, perKm }) => {
  const { t } = useTranslation();
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.row, styles.rowFirst)}>
        <div className={css(styles.price)}>{s(currency, baseFee)}</div>
        <div className={css(styles.label)}>{t('baseFee')}</div>
      </div>
      <div className={css(styles.row)}>
        <div className={css(styles.price)}>{s(currency, perKm)}</div>
        <div className={css(styles.label)}>{t('perKm')}</div>
      </div>
    </div>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      fontWeight: 'lighter',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    rowFirst: {
      marginBottom: 5,
    },
    price: {
      marginRight: 5,
      fontWeight: 'bold',
    },
    label: {
      fontSize: 12,
      color: theme.color1,
    },
  });
