import {
  Button,
  ButtonPlain,
  createSnackbar,
  Crossmark,
  FleetColumn,
  PickerInput,
  Plus,
  SectionHeader,
  Theme,
  VehicleStandard,
} from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { carsEndpoint } from '../../../../Utils/API';
import { getRelationStringAsIdArray, s } from '../../../../Utils/Helpers';

const { updateEntry: updateCar, useEntries: useCars } = carsEndpoint;

export default ({ entry: fleet }) => {
  const { t } = useTranslation();
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);

  const [assignedCars] = useCars({ query: `essentials_fleets:${fleet.id}` });
  const [cars] = useCars();

  if (!cars) {
    return null;
  }

  const onSelect = async (car) => {
    const ids = getRelationStringAsIdArray(car.essentials_fleets);

    if (ids.includes(fleet.id)) {
      return;
    }

    ids.push(fleet.id);

    await updateCar(car.id, { essentials_fleets: ids.join(',') });

    createSnackbar(
      'success',
      t('successfullyAssignedCarToFleet', { name: s(car.manufacturer, car.model) })
    );
  };

  const onRemove = (car) => async () => {
    const ids = getRelationStringAsIdArray(car.essentials_fleets);

    const newIds = ids.filter((id) => id !== fleet.id).join(',');
    await updateCar(car.id, { essentials_fleets: newIds === '' ? null : newIds });

    createSnackbar(
      'success',
      t('successfullyRemovedCarFromFleet', { name: s(car.manufacturer, car.model) })
    );
  };

  const onAddButtonPress = () => document.getElementById('c-carToAdd').focus();

  return (
    <div className={css(styles.container)}>
      <SectionHeader>{t('cars')}</SectionHeader>
      <div className={css(styles.header)}>
        <div className={css(styles.searchField)}>
          <PickerInput
            id="c-carToAdd"
            onSelect={onSelect}
            label={t('searchCar')}
            clean
            suggestions={cars}
            renderSuggestion={(car) => (
              <FleetColumn
                icon={<VehicleStandard />}
                car={car.licensePlate}
                licensePlate={s(car.manufacturer, car.model)}
                licensePlateLight
              />
            )}
            getSuggestionValue={(car) => s(car.manufacturer, car.model, car.licensePlate)}
          />
        </div>
        <Button onPress={onAddButtonPress} icon={<Plus />}>
          {t('add')}
        </Button>
      </div>
      <div className={css(styles.cars)}>
        {assignedCars &&
          assignedCars.map((car) => (
            <div className={css(styles.car)} key={car.id}>
              <FleetColumn
                icon={<VehicleStandard />}
                car={car.licensePlate}
                licensePlate={s(car.manufacturer, car.model)}
                licensePlateLight
              />
              <div className={css(styles.remove)}>
                <ButtonPlain onClick={onRemove(car)}>
                  <Crossmark />
                </ButtonPlain>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      padding: 45,
    },
    header: {
      display: 'flex',
      marginTop: 20,
      alignItems: 'center',
    },
    searchField: {
      flex: 1,
      marginRight: 20,
    },
    cars: {
      marginTop: 45,
    },
    car: {
      position: 'relative',
      display: 'inline-block',
      width: 'calc(50% - 15px)',
      marginRight: 15,
      padding: 20,
      marginBottom: 15,
      borderRadius: 3,
      border: `solid ${theme.color16} 1px`,
    },
    remove: {
      position: 'absolute',
      top: 0,
      right: 10,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: 30,
      color: theme.color1,
    },
  });
