import { TabNav, Theme, NotificationBubble, StickyNote } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import Title from '../Components/Title';
import Drivers from './Drivers';
import Orders from './Orders';

export default ({
  selectedOrder,
  onSelectOrder,
  selectedDriver,
  onSelectDriver,
  onDragOrder,
  date,
  useOrders,
  useCars,
  useDrivers,
  history,
}) => {
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  const newOrders = useOrders(date);
  const problemOrders = useOrders(date, 'problem');

  return (
    <div className={css(styles.container)} id="dispatcher-pending">
      <Title style={styles.title}>{t('pending')}</Title>
      <TabNav
        navItems={[
          { title: t('orders'), path: `/dispatcher/orders` },
          {
            title: (
              <>
                {t('problems')}
                {/* { problemOrders && problemOrders.length > 0 && <><NotificationBubble label={problemOrders.length} /><StickyNote title={t('Achtung: TBD')} text={t('Aufträge mit Problem ausstehend. TBD')} onClick={() => {}} buttonLabel={t('AUSBLENDEN TBD')} /></> } */}
                { problemOrders && problemOrders.length > 0 && <><NotificationBubble label={problemOrders.length} /></> }
              </>
            ),
            path: `/dispatcher/problems`,
          },
          { title: t('drivers'), path: `/dispatcher/drivers` },
        ]}
        className={css(styles.header)}
      />
      <Switch>
        <Route
          path={'/dispatcher/orders'}
          render={(props) => (
            <Orders
              {...props}
              hasAutoDisptacher={true}
              selectedOrder={selectedOrder}
              onSelect={onSelectOrder}
              onDrag={onDragOrder}
              date={date}
              history={history}
              orders={newOrders}
            />
          )}
        />
        <Route
          path={'/dispatcher/problems'}
          render={(props) => (
            <Orders
              {...props}
              selectedOrder={selectedOrder}
              onSelect={onSelectOrder}
              onDrag={onDragOrder}
              date={date}
              orders={problemOrders}
              noOrdersFor={t('noProblemsFor', { date: date.format(t('longDateFormat')) })}
            />
          )}
        />
        <Route
          path={'/dispatcher/drivers'}
          render={(props) => (
            <Drivers
              {...props}
              selectedDriver={selectedDriver}
              onSelect={onSelectDriver}
              useCars={useCars}
              useDrivers={useDrivers}
            />
          )}
        />
      </Switch>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      width: 390,
      backgroundColor: theme.color2,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      boxShadow: theme.shadow3,
      overflowY: 'auto',
      padding: '0 20px',
      zIndex: 1,
    },
    header: {
      padding: '0 !important',
      marginBottom: 25,
      borderBottom: `1px solid ${theme.color12}`,
    },
    title: {
      marginBottom: 45,
    },
  });
