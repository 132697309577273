import React, { useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { GoLogo, Theme } from '@yourmileag/ui-kit';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles } from '../../../Utils/Helpers';
import DataStatus from '../Components/DataStatus';

const animationDuration = 600;

export default ({
  isBreaksInitialized,
  isCarsInitialized,
  isDriversInitialized,
  isFleetsInitialized,
  isOrdersInitialized,
  children,
}) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const [isFinishing, setFinishing] = React.useState(false);
  const [isFinished, setFinished] = React.useState(false);
  const [animationPhase, setAnimationPhase] = React.useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFinishing) {
      return;
    }

    setTimeout(() => setFinished(true), animationDuration);
  }, [isFinishing]);

  useEffect(() => {
    if (
      !isBreaksInitialized ||
      !isCarsInitialized ||
      !isDriversInitialized ||
      !isFleetsInitialized ||
      !isOrdersInitialized
    ) {
      return;
    }

    setTimeout(() => setFinishing(true), 500);
  }, [
    isBreaksInitialized,
    isCarsInitialized,
    isDriversInitialized,
    isFleetsInitialized,
    isOrdersInitialized,
  ]);

  useEffect(() => {
    if (isFinishing) {
      return;
    }

    if (animationPhase === undefined) {
      setAnimationPhase(1);
    }

    const newAnimationPhase = animationPhase === 0 ? 1 : 0;

    setTimeout(() => setAnimationPhase(newAnimationPhase), animationDuration);
  }, [animationPhase, isFinishing]);

  return (
    <>
      {isFinishing && children}
      {!isFinished && (
        <div className={css(styles.container, isFinishing && styles.containerFinishing)}>
          <div
            className={css(
              styles.logo,
              styles[`logoAnimationPhase${animationPhase}`],
              isFinishing && styles.logoFinishing
            )}
          >
            <GoLogo />
          </div>
          <div className={css(styles.statusContainer)}>
            <div className={css(styles.status)}>
              <div className={css(styles.label)}>{t('orders')}</div>
              <DataStatus isInitialized={isOrdersInitialized} />
            </div>
            <div className={css(styles.status)}>
              <div className={css(styles.label)}>{t('drivers')}</div>
              <DataStatus isInitialized={isDriversInitialized} />
            </div>
            <div className={css(styles.status)}>
              <div className={css(styles.label)}>{t('cars')}</div>
              <DataStatus isInitialized={isCarsInitialized} />
            </div>
            <div className={css(styles.status)}>
              <div className={css(styles.label)}>{t('fleets')}</div>
              <DataStatus isInitialized={isFleetsInitialized} />
            </div>
            <div className={css(styles.status)}>
              <div className={css(styles.label)}>{t('breaks')}</div>
              <DataStatus isInitialized={isBreaksInitialized} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const getThemedStyles = theme =>
  StyleSheet.create({
    container: {
      ...fullScreenStyles,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 1,
      transitionProperty: 'opacity',
      transitionDuration: `${animationDuration}ms`,
    },
    containerFinishing: {
      opacity: 0,
    },
    logo: {
      color: theme.color6,
      fontSize: 60,
      transform: 'scale3d(1, 1, 1)',
      transitionProperty: 'transform',
      transitionDuration: `${animationDuration}ms`,
      transitionTimingFunction: 'linear',
    },
    logoAnimationPhase0: {
      transform: 'scale3d(1, 1, 1)',
    },
    logoAnimationPhase1: {
      transform: 'scale3d(1.2, 1.2, 1)',
    },
    logoFinishing: {
      transform: 'scale3d(0, 0, 0)',
    },
    statusContainer: {
      marginTop: 30,
      fontSize: 14,
      fontWeight: 'lighter',
      color: theme.color1,
      width: 300,
    },
    status: {
      marginTop: 10,
      paddingTop: 10,
      height: 50,
      borderTop: `1px solid ${theme.color12}`,
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      flex: 1,
    },
  });
