import { InfoRow } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { carsEndpoint } from '../../../../Utils/API';
import { getFleetIcon } from '../../../../Utils/Fleet';
import InventoryDetail from '../../Components/InventoryDetail';
import Rate from '../../Components/Rate';

const { useEntries: useCars } = carsEndpoint;

export default ({ entry, history, match }) => {
  const { t } = useTranslation();
  const [cars] = useCars({ query: `essentials_fleets:${entry.id}` });

  return (
    <InventoryDetail entry={entry} history={history} match={match}>
      <InfoRow center label={t('icon')}>
        <div className={css(styles.icon)}>{getFleetIcon(entry.icon)}</div>
      </InfoRow>
      <InfoRow center label={t('carCount')}>{cars ? cars.length : '-'}</InfoRow>
      <InfoRow center label={t('seats')}>
        <div className={css(styles.label)}>{entry.peopleCount}</div>
      </InfoRow>
      <InfoRow center label={t('baggage')}>
        <div className={css(styles.label)}>{entry.baggageCount}</div>
      </InfoRow>
      <InfoRow label={t('rate')}>
        <Rate currency={entry.rateCurrency} baseFee={entry.rateBaseFee} perKm={entry.ratePerKm} />
      </InfoRow>
    </InventoryDetail>
  );
};

const styles = StyleSheet.create({
  icon: {
    fontSize: 30,
  },
});
