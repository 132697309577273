import { Theme } from '@yourmileag/ui-kit';
import React, { useContext, useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { Card } from '.';
import { essentialsWsEndpoint } from '../../../../../Utils/API';
import { getEstimatedTodayFilter } from '../../../../../Utils/Helpers';
import moment from 'moment';

const useRevenue = () => {
  const orders = essentialsWsEndpoint.useLiveEntries('orders', undefined, {
    query: `${getEstimatedTodayFilter()};status:completed`,
    fields: '_id,estimatedTimespan,status,price',
  });
  const [revenue, setRevenue] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let currentTotal = 0;
    const hours = {
      '00': 0,
      '01': 0,
      '02': 0,
      '03': 0,
      '04': 0,
      '05': 0,
      '06': 0,
      '07': 0,
      '08': 0,
      '09': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
    };

    if (orders) {
      orders.forEach((order) => {
        if (!order.price || order.status !== 'completed') {
          return;
        }

        currentTotal++;

        hours[moment.unix(order.estimatedTimespan.endTimestamp).format('HH')]++;
      });
    }

    setTotal(currentTotal);

    setRevenue(
      Object.keys(hours)
        .sort()
        .map((hour) => ({ name: `${hour}:00`, revenue: hours[hour] }))
    );
  }, [orders]);

  return { revenue, total };
};

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  if (active) {
    return (
      <div className={css(styles.tooltip)}>
        <p className={css(styles.tooltipLable)}>{`${t('orders')}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default () => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const { revenue, total } = useRevenue();

  return (
    <Card title={t('orders')} lable="Heute" lableValue={`${total}`}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={revenue} syncId="orgId">
          <defs>
            <linearGradient id="bgColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#01CBFF" stopOpacity={0.2} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid horizontal={false} stroke={theme.color12} />
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            axisLine={false}
            tickLine={false}
            stroke={theme.color1}
            fontSize="12"
            fontWeight="300"
          />
          <XAxis
            axisLine={false}
            tickLine={false}
            stroke={theme.color1}
            fontSize="12"
            fontWeight="300"
            dataKey="name"
          />
          <Area
            type="monotone"
            dataKey="revenue"
            strokeWidth="2"
            stroke={theme.color5}
            fillOpacity={1}
            fill="url(#bgColor)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    tooltip: {
      backgroundColor: theme.color2,
      border: `solid ${theme.color10} 1px`,
      padding: 10,
      borderRadius: 3,
      boxShadow: theme.shadow1,
    },
    tooltipLable: {
      fontSize: 12,
      fontWeight: 300,
      color: theme.color15,
    },
  });
