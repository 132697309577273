import { Theme, InfoRow, Female, Male, Company } from '@yourmileag/ui-kit';
import React, { useContext } from 'react';
import { formatThousands } from '../../../Utils/Helpers';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTranslation } from 'react-i18next';
import { Card } from '.';

export default (props) => {
  const { t } = useTranslation();
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const orders = props.orders;
  const customers = props.customers;

  let customerData = {};

  let totalFemale = 0,
      avgFemale = 0,
      totalMale = 0,
      avgMale = 0,
      totalCompany = 0,
      avgCompany = 0;

  let countRevenue = 0,
      avgRevenue = 0,
      countDuration = 0,
      avgDuration = 0,
      countDistance = 0,
      avgDistance = 0,
      avgOrders = 0;
  
  if (orders.length !== 0 && customers.length !== 0) {
    customers.map(function(customer) {
      customerData[customer.id] = 0;
    });

    // get data
    orders.forEach((order) => {
      if (!order.price || order.status !== 'completed' || typeof order.customer === 'undefined' || order.customer === null) {
        return;
      }

      customerData[order.customer.id]++;
      countRevenue += parseFloat(order.price);
      countDistance += order.estimatedDistance;
      countDuration += order.estimatedDuration;
      
      if (order.customer.type === 'company') {
        totalCompany++;
      } else {
        if (order.customer.gender === 'male') {
          totalMale++;
        } else if (order.customer.gender === 'female') {
          totalFemale++;
        }
      }
    });
    
    // calculate averages
    let filteredCustomerData = Object.values(customerData).filter(entry => entry > 0);
    let totalCustomerOrders = filteredCustomerData.reduce((a, b) => {
      return a + b;
    }, 0);

    let totalOrders = orders.length;
    let totalCustomers = customers.length;

    avgFemale = parseFloat((totalFemale / totalCustomers) * 100).toFixed(1);
    avgMale = parseFloat((totalMale / totalCustomers) * 100).toFixed(1);
    avgCompany = parseFloat((totalCompany / totalCustomers) * 100).toFixed(1);
    
    avgOrders = (totalCustomerOrders / filteredCustomerData.length).toFixed(1);
    avgRevenue = Math.floor(countRevenue / totalOrders);
    avgDistance = Math.floor((countDistance / totalOrders) / 1000);
    avgDuration = Math.floor(countDuration / totalOrders);
  }

  return (
    <Card title={t('avgCustomer')} cardHeight="auto">
      <div className={css(styles.AvgCustomer)}>
        <div className={css(styles.IconRowWrapper)}>
          <div className={css(styles.IconRow)}>
            <div className={css(styles.IconRowItem)}>
              <div className={css(styles.IconRowItemIcon)}>
                <Female/>
              </div>
              <p className={css(styles.IconRowItemText)}>{`${avgFemale}%`}</p>
            </div>
            <div className={css(styles.IconRowItem)}>
              <div className={css(styles.IconRowItemIcon)}>
                <Male/>
              </div>
              <p className={css(styles.IconRowItemText)}>{`${avgMale}%`}</p>
            </div>
            <div className={css(styles.IconRowItem)}>
              <div className={css(styles.IconRowItemIcon)}>
                <Company/>
              </div>
              <p className={css(styles.IconRowItemText)}>{`${avgCompany}%`}</p>
            </div>
          </div>
        </div>
        <div className={css(styles.InfoRowWrapper)}>
          <InfoRow
            center={false}
            label={t('avgCustomerRevenue')}
            required={false}
            apart={true}
          >
            {`CHF ${formatThousands(avgRevenue)}`}
          </InfoRow>
          <InfoRow
            center={false}
            label={t('avgCustomerDistance')}
            required={false}
            apart={true}
          >
            {`${formatThousands(avgDistance)} km`}
          </InfoRow>
          <InfoRow
            center={false}
            label={t('avgCustomerDuration')}
            required={false}
            apart={true}
          >
            {`${formatThousands(avgDuration)} min`}
          </InfoRow>
          <InfoRow
            center={false}
            label={t('avgCustomerOrders')}
            required={false}
            apart={true}
          >
            {`${formatThousands(avgOrders)}`}
          </InfoRow>
        </div>
      </div>
    </Card>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    AvgCustomer: {
      width: '100%',
      height: '100%',
    },
    IconRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 15px 30px',
      marginBottom: '5px',
      borderBottom: '1px solid ' + theme.color12
    },
    IconRowItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 15px',
    },
    IconRowItemIcon: {
      height: 25,
      marginRight: 15,
      fontSize: 25,
      color: theme.color1
    },
    IconRowItemText: {
      color: theme.color3,
    }
  });
