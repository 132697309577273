import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fullScreenStyles, usePageTitle } from '../../../../Utils/Helpers';
import KPIs from './KPIs';
import Map from './Map';
import { useTourOnMount } from '../../../Tours/TourManager';

export default () => {
  const { t } = useTranslation();
  usePageTitle(t('dashboard'));
  useTourOnMount('main');

  return (
    <div className={css(styles.container)}>
      <KPIs />
      <Map />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    ...fullScreenStyles,
    display: 'flex',
  },
  title: {},
});
