import { TextInput, Theme } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { carsEndpoint, fleetsEndpoint } from '../../../../Utils/API';
import { defaultCar, useCarValidator } from '../../SidePanels/Car/CreateOrUpdate';
import Page from './Components/Page';

export default ({ onRequestNextPage }) => {
  const theme = React.useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();
  const [car, setCar] = useState({ ...defaultCar });
  const errors = useCarValidator(car);
  const isNextDisabled = Object.keys(errors).length > 0;

  const onNextPress = async () => {
    const fleets = await fleetsEndpoint.getEntries({ fields: 'id' });

    await carsEndpoint.createEntry({
      ...car,
      essentials_fleets: fleets.items.map((fleet) => fleet.id).join(','),
    });

    onRequestNextPage();
  };

  const onChange = (attribute) => (e) => setCar({ ...car, [attribute]: e.target.value });

  const content = (
    <>
      <h2 className={css(styles.title)}>{t('registerCarTitle')}</h2>
      <h5 className={css(styles.desc)}>{t('registerCarDesc')}</h5>

      <TextInput
        id="manufacturer"
        label={t('manufacturer')}
        value={car.manufacturer}
        onChange={onChange('manufacturer')}
        errorMessage={car.manufacturer.length > 0 ? errors.manufacturer : ''}
        required
        className={css(styles.input)}
      />
      <TextInput
        id="model"
        label={t('model')}
        value={car.model}
        onChange={onChange('model')}
        errorMessage={car.model.length > 0 ? errors.model : ''}
        required
        className={css(styles.input)}
      />
      <TextInput
        id="model"
        label={t('licensePlate')}
        value={car.licensePlate}
        onChange={onChange('licensePlate')}
        errorMessage={car.licensePlate.length > 0 ? errors.licensePlate : ''}
        required
        className={css(styles.input)}
      />
    </>
  );

  return (
    <>
      <Page onNextPress={onNextPress} isNextDisabled={isNextDisabled}>
        {content}
      </Page>
    </>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    title: {
      display: 'flex',
      fontSize: 30,
      fontWeight: 700,
      color: theme.color3,
      letterSpacing: 0.15,
      marginBottom: 20,
      alignItems: 'center',
    },
    desc: {
      color: theme.color17,
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '30px',
      paddingBottom: 63,
    },
    input: {
      marginBottom: 15,
    },
  });
