import { Table, Theme, VehicleStandard, PagePrev, PageNext } from '@yourmileag/ui-kit';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { getPathParent, join } from '../../../Utils/Helpers';
import NetworkRequestError from './NetworkRequestError';

export default ({ match, history, useData, useDataAdditionalOptions, onChange, emptyContainer, ...rest }) => {
  const page = parseInt(match.params.page);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(true);
  const [data, error] = useData({
    ...useDataAdditionalOptions,
    includeMeta: true,
    skip: page * 20,
    limit: 20,
  });
  const theme = useContext(Theme);
  const styles = getThemedStyles(theme);
  const { t } = useTranslation();

  useEffect(() => {
    if (!data) {
      return;
    }

    // TODO: go to first page if filters change

    onChange(data.items);
    setTotal(data.total);
    setTotalPages(Math.ceil(data.total / 20));
    setIsInfiniteLoading(false);

    // eslint-disable-next-line
  }, [data, onChange]);

  useEffect(() => {
    setIsInfiniteLoading(true);
  }, [page]);

  const onPageChange = (newPage) => history.push(join(getPathParent(match.url), newPage.selected));

  if (error) {
    return <NetworkRequestError error={error} />;
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.tableContainer)}>
        <Table
          {...rest}
          content={
            total === 0 && (
              emptyContainer
              ? emptyContainer()
              : (
                <div className={css(styles.noOrders)}>
                  <VehicleStandard className={css(styles.noOrdersIcon)} />
                  <div>{t('noDataAvailable')}</div>
                </div>
                )
            )
          }
          isLoading={isInfiniteLoading} 
        />
      </div>
      <div className={css(styles.pagination)}>
        <div className={css(styles.total)}>{total} Einträge</div>
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={2}
          marginPagesDisplayed={3}
          forcePage={page}
          onPageChange={onPageChange}
          hrefBuilder={(index) => `${getPathParent(match.path)}/${index}`}
          containerClassName={css(styles.list)}
          nextLabel={<PageNext className={css(styles.icon)} />}
          previousLabel={<PagePrev className={css(styles.icon)} />}
          pageLinkClassName={css(styles.linkFocus)}
          breakLinkClassName={css(styles.linkFocus)}
          activeLinkClassName={css(styles.linkFocus && styles.activeLinkFocus)}
          previousLinkClassName={css(styles.linkFocus)}
          nextLinkClassName={css(styles.linkFocus)}
          pageClassName={css(styles.link)}
          breakClassName={css(styles.link)}
          activeClassName={css(styles.link)}
          // previousClassName={css(styles.link)}
          // nextClassName={css(styles.link)}

          disabledClassName={css(styles.disabled)}
        />
      </div>
    </div>
  );
};

const getThemedStyles = (theme) =>
  StyleSheet.create({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    noOrders: {
      marginTop: 50,
      color: theme.color10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noOrdersIcon: {
      fontSize: 60,
    },
    tableContainer: {
      flex: 1,
      position: 'relative',
    },
    pagination: {
      position: 'relative',
      margin: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    total: {
      fontSize: 14,
      fontWeight: 300,
      position: 'absolute',
      left: 0,
      color: theme.color1,
    },
    icon: {
      display: 'inline-block',
      backgroundColor: theme.color2,
      borderColor: theme.color2,
      boxShadow: theme.shadow1,
      borderWidth: 2,
      borderStyle: 'solid',
      width: 32,
      height: 32,
      borderRadius: 16,
      textAlign: 'center',
      fontSize: 28,
      ':hover': {
        backgroundImage: theme.gradient7,
      },
    },
    list: {
      fontSize: 14,
      fontWeight: 300,
      display: 'inline-flex',
    },
    linkFocus: {
      color: theme.color1,
      width: 32,
      height: 32,
      borderRadius: 16,
      textAlign: 'center',
      display: 'inline-block',
      lineHeight: '32px',
      ':hover': {
        color: theme.color15,
      },
      ':focus': {
        outline: 0,
      },
    },
    activeLinkFocus: {
      color: theme.color2,
      backgroundImage: theme.gradient3,
      ':hover': {
        color: theme.color2,
      },
    },
    link: {
      margin: '0 3px',
      display: 'inline-block',
    },
    disabled: {
      opacity: 0.2,
    },
  });